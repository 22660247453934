import React from 'react';
import { RiCloseCircleLine } from "react-icons/ri";

const DependencyDeleteBtn = ({jobID,searchID,update}) => {

    const deleteSearchInDependency = async (job,search)=>{
        console.log('DependencyDeleteBtn-job:',job);
        console.log('DependencyDeleteBtn-search:',search);

        try{
            // let deleteSearchInURL = `/api/dependency/deleteSearch?jobId=${job}&searchId=${search}`;//json data version
            let deleteSearchInURL = `/api/dependency/deleteSearchMongo?jobId=${job}&searchId=${search}`;//Mongo DB

            const res = await fetch(deleteSearchInURL,{method:'DELETE'});
            const json = await res.json();
            if(json.message === 'search deleted from dependency'){
                await update();
            }
            console.log('deleteSearchInDependency JSON:',json);

        }catch (e) {
            console.log('deleteSearchInDependency ERROR:',e)
        }

    };

    return (
        <div>
            <div style={{marginLeft:"6px"}} onClick={()=>{deleteSearchInDependency(jobID,searchID)}}>
                <RiCloseCircleLine className="advanced-dependencyDelete"/>
            </div>
        </div>
    );
};

export default DependencyDeleteBtn;
