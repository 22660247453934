import React from 'react';
import FieldBuilder from "./FieldBuilder";

const SingleRecursive = ({config,itemDataRec,itemId,module}) => {

    let prepareRecId;
    if(module === 'Candidates'){
        prepareRecId = itemDataRec.response.result.Candidates.row.FL;
    }else if(module === 'JobOpenings'){
        prepareRecId = itemDataRec.response.result.JobOpenings.row.FL;
    }

    return (
        <>
            <FieldBuilder  IndividualConfig={config} prepareRecId={prepareRecId} itemId={itemId} module={module}/>
        </>
    );
};

export default SingleRecursive;
