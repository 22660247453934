import React from 'react';
// import EditNote from "../EditNote/EditNote";
// import DeleteNote from "../DeleteNote/DeleteNote";
import EditJobNote from "../EditJobNote/EditJobNote";
import DeleteJobNote from "./DeleteJobNote";
import NoteTypeIcon from "../../Notes/NoteTypeIcon/NoteTypeIcon";
import NoteDateParser from "../../Notes/NoteDateParser/NoteDateParser";
import NoteUserParser from "../../Notes/NoteUserParser/NoteUserParser";

const JobNoteItem = ({ noteItem, jobID, updateNotesFunc }) =>{

    return (
        <div className="notes-itemMain">
            <div className="notes-itemTop">
                <NoteTypeIcon noteType={noteItem.noteType}/>
                <div>{noteItem.noteTitle}</div>
                <div style={{display:"flex"}}>
                    <NoteUserParser noteUser={noteItem.createdByUser.userName}/>
                    <NoteDateParser noteDate={noteItem.modifiedTime}/>
                </div>
            </div>
            <div className="notes-content">{noteItem.noteContent}</div>
            <div className="notes-itemBottom">
                <EditJobNote
                    jobID={jobID}
                    noteID={noteItem._id}
                    currentType={noteItem.noteType}
                    createdTime={noteItem.createdTime}
                    currentTitle={noteItem.noteTitle}
                    currentText={noteItem.noteContent}
                    updateNotesFunc={updateNotesFunc}/>

                <DeleteJobNote
                    jobID={jobID}
                    noteID={noteItem._id}
                    updateNotesFunc={updateNotesFunc}/>
            </div>

        </div>
    );
};

export default JobNoteItem;
