import React,{ useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import MultiRecursive from "./MultiRecursive";
import SingleRecursive from './SingleRecursive';
import ItemDependency from "./ItemDependency";
import './DisplayItem.css';
import headerReq from "../../functions/headers";
import Loader from "../Loader/Loader";
import RefreshToken from "../RefreshToken/RefreshToken";
import DisplayPhoto from "../DisplayPhoto/DisplayPhoto";
//Icons
import { BsReplyFill} from "react-icons/all";
import { BsArrowReturnLeft} from "react-icons/all";
import {BsArrowLeft} from "react-icons/all";
import headerReqZohoAuth from "../../functions/headerReqZohoAuth";
import ItemActionWrapper from "./ItemActionWrapper";


const DisplayItem = (props) => {
    console.log('DISPLAY ITEM PROPS:', props);

    //get Query params
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const locationPath = useLocation().pathname.slice(1);

    const queryParams = useQuery();
    const idItem = queryParams.get("id");
    // setItemId(idItem);
    console.log('ID:',idItem);
    const titleItem = queryParams.get("title");
    // setItemTitle(titleItem);
    console.log('TITLE:',titleItem);

    //get module
    let module;
    //set module
    if(locationPath === 'candidate'){
        module = 'Candidates';
    }else if(locationPath === 'job'){
        module = 'JobOpenings';
    }

    const [ dataFields, setDataFields] = useState(null);
    const [ dataItem, setDataItem ] = useState(null);
    const [ itemId, setItemId ] = useState(idItem);
    const [ itemTitle, setItemTitle ] = useState(titleItem);
    const [ metaData, setMetaData ] = useState(null);

    const getFieldsMetaData = async () =>{
        const requestOption = await headerReqZohoAuth();
        const getFieldsMetaDataURL = `/proxyZohoRecruit/recruit/v2/settings/fields?module=${module}`;

        try{
            const res = await  fetch(getFieldsMetaDataURL,{...requestOption});
            const json = await res.json();
            console.log('get META DATA - JSON:', json);
            setMetaData(json);
        }catch (e) {
            console.log('get META DATA - ERROR;', e);
        }
    };

    async function ComponentDidMount(){

        //get data fields and item
        let config = headerReq();
        const getFieldsUrl=`/proxyZohoRecruit/recruit/private/json/${module}/getFields?version=2`;
        const getDataFields = () =>
            fetch(getFieldsUrl, {...config}).then(response => response.json());

        const getDataCandidate = (getItemUrl) =>
            fetch(getItemUrl, {...config}).then(response => response.json());

        //use effect before version
        let jsonFields;
        let jsonItem;

        try{
            const jsonDataFields = await getDataFields();
            console.log('JSON DATA FIELDS:',jsonDataFields);
            jsonFields = await jsonDataFields;
            // await setDataFields(jsonDataFields);
        }catch (e) {
            console.log('data fields ERROR:',e)
        }

        try{
            const getItemUrl=`/proxyZohoRecruit/recruit/private/json/${module}/getRecordById?version=2&id=${itemId}`;
            const jsonDataItem = await getDataCandidate(getItemUrl);
            console.log('JSON DATA ITEM:', jsonDataItem);
            jsonItem = await jsonDataItem;
            await setDataItem(jsonDataItem);
        }catch (e) {
            console.log('data item ERROR:',e)
        }

        await getFieldsMetaData();

        setDataItem(jsonItem);
        setDataFields(jsonFields);
    }

    let historyTest = useHistory();
    const prevPage = ()=>{
        console.log('HISTORY:',historyTest);
        historyTest.goBack();
    };

    useEffect(async ()=>{
        await ComponentDidMount();
    },[]);

    const formik = useFormik({
        initialValues: {
        },
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
        },
    });
    // console.log(formik, response);
    let dataSections;
    // console.log('DaTA module:',`dataFields.${module}` );

    if(!dataFields || !dataItem){
        return <Loader/>
    }else if(!dataFields.Candidates && !dataFields.JobOpenings ){
        if(dataFields.response.error.code === "4834" || dataItem.response.error.code === "4834" ){
            console.log('ERROR 4834');
            return (<div style={{textAlign:"center"}}>
                <h3 style={{color:"red"}}>Your token has expired!</h3>
                <p><RefreshToken update={ComponentDidMount}/></p>
            </div>);}
    }else{
        console.log('DATA FIELDS DisplayItem:',dataFields);
        if(module === 'Candidates'){
            dataSections = dataFields.Candidates.section;
        }else if(module === 'JobOpenings'){
            dataSections = dataFields.JobOpenings.section;
        }

        return (
            <>
                {itemTitle && <div className="displayItem-mainTitle">
                    <button className="blueWhite-Btn displayItem-BackBtn" onClick={()=>{prevPage()}}><BsArrowLeft/>Back</button>
                    {itemTitle}
                    {module !== 'JobOpenings' && <ItemActionWrapper candidateId={itemId} headerName={itemTitle} dataFields={dataFields} module={module} meta={metaData}/>}
                </div>}

                {module !== 'JobOpenings' && <div style={{display:"flex",justifyContent:"center"}}>
                    <DisplayPhoto candidateID={itemId}/>
                </div>}

                <div className="container">

                    {module === 'JobOpenings' && <ItemDependency itemId={itemId} itemTitle={itemTitle}/>}
                    {/*<form onSubmit={formik.handleSubmit}>*/}
                        {dataSections.map((section,index)=>{
                            return(
                                <>
                                    <h2 className="section-title">{section.name}</h2>
                                    {Array.isArray(section.FL) ?
                                        <MultiRecursive config={section.FL} itemDataRec={dataItem} key={index} itemId={itemId} module={module} meta={metaData}/>:
                                        <SingleRecursive config={section.FL} itemDataRec={dataItem} key={index} itemId={itemId} module={module} meta={metaData}/>}
                                </>
                            );
                        })}

                        {/*<button type="submit">Submit</button>*/}
                    {/*</form>*/}
                </div>
            </>
        );
    }
};

export default DisplayItem;
