import React, {useState} from 'react';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import PreviewCV from "./PreviewCV";
//Icons
import { RiFileUserLine } from "react-icons/ri";
import { RiFileTextLine } from "react-icons/ri";
import { RiFileSearchLine } from "react-icons/ri";


const PreviewCvWrapper = ({btnName,header,id,dataItemFields,module,meta}) => {
    const [ previewCv, setPreviewCv ] = useState(false);

    const prevItemTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Preview CV
        </Tooltip>
    );

    return (
        <div className={ module === 'JobOpenings' ? 'prevItem-actionBox-job':'prevItem-actionBox' }>
            <div style={{width:"80%",textAlign:"center"}}>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={prevItemTooltip}>
                    <RiFileSearchLine  className="modal-link" fontSize={"21px"} onClick={()=>{setPreviewCv(!previewCv)}}/>
                </OverlayTrigger>
            </div>
            <PreviewCV previewItem={previewCv} setPreviewItem={setPreviewCv} header={header} id={id} dataItemFields={dataItemFields} module={module} meta={meta}/>
        </div>
    );
};

export default PreviewCvWrapper;
