import React,{useState,useEffect} from 'react';
import { Modal } from "react-bootstrap";
import Loader from "../Loader/Loader";
import SingUp from "./SingUp";
import ForgotPassword from "./ForgotPassword";
import './role.css';
// REDUX
import { connect } from "react-redux";
import { signIn,signOut } from '../../redux/Actions/actionsCreator';
//Icons
import { BiLogIn } from "react-icons/bi";
import { BiLogOut } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash  } from "react-icons/fa";

const Role = (props) => {
    const [ show, setShow ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ pass, setPass ] = useState('');
    const [ passwordShown, setPasswordShown ] = useState(false);
    const [ isSending, setIsSending ] = useState(false);
    const [ result, setResult ] = useState(null);
    const [ error, setError] = useState(null);

    //FOR MODAL WINDOW
    const handleClose = () => {
        setResult(null);
        setError(null);
        setIsSending(false);
        setShow(false);
    };
    const handleShow = () => setShow(true);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const changeInputValue = (event, setState)=>{
        event.preventDefault();
        setState(event.target.value);
    };

    //submit login
    const singInRequest = async (email,pass)=>{
        console.log('login-email:',email);
        console.log('login-pass:',pass);
        setIsSending(true);
        const singInRequestURL = '/api/user/singIn';

        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email:email, password:pass})
        };

        try {
            const res = await fetch(singInRequestURL,reqOptions);
            const json = await res.json();
            console.log('singInRequest JSON:',json);
            if(json.response === 'SUCCESS'){
                setResult(json.response);
                localStorage.setItem('firstName',json.user.firstName);
                localStorage.setItem('secondName',json.user.secondName);
                localStorage.setItem('id',json.user.id);
                localStorage.setItem('role',json.user.role);
                props.signIn(json.user.id, json.user.role, json.user.firstName, json.user.secondName);
            }else{
                setResult(json.response);
                setError(json.message);
            }
        }catch (e) {
            console.log('singInRequest ERROR:',e);
            setResult('ERROR');
        }
    };

    const closeSending = ()=>{
        setResult(null);
        setError(null);
        setIsSending(false);
        if(result === 'SUCCESS'){
            handleClose();
        }
    };

    const singOut = ()=>{
        localStorage.removeItem('firstName');
        localStorage.removeItem('secondName');
        localStorage.removeItem('id');
        localStorage.removeItem('role');
        props.signOut();
    };

    useEffect(()=>{
        const userId = localStorage.getItem('id');
        const userRole = localStorage.getItem('role');
        const userFName = localStorage.getItem('firstName');
        const userSName = localStorage.getItem('secondName');

        if(userId && userRole && userFName && userSName){
            props.signIn(userId, userRole, userFName, userSName);
        }else{
            singOut();
        }
    },[]);

    return (
        <div className="role-main">
            {props.isAuthRedux ?
                <div className="role-btn" onClick={singOut}>
                    <span>Logout</span>
                    <div>
                        <BiLogOut/>
                    </div>
                </div>:
                <div className="role-btn" onClick={handleShow}>
                    <span>Login</span>
                    <div>
                        <BiLogIn/>
                    </div>
                </div>
            }

            <Modal show={show} onHide={handleClose} dialogClassName="loginModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start", background:"#2b066b"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Login</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="singIn-body">
                        <div>
                            <input type="email"
                                   placeholder={'Email'}
                                   onChange={(e)=>{changeInputValue(e,setEmail)}}
                                   style={{width:'100%'}}/>
                        </div>
                        <div className="loginModal-pass">
                            <input type={passwordShown ? "text" : "password"}
                                   placeholder={'Password'}
                                   onChange={(e)=>{changeInputValue(e,setPass)}}
                                   style={{width:'100%'}}/>
                            <div className="loginModal-passEye"
                                 onClick={togglePassword}>
                                {passwordShown ? <FaEye style={{color:"#33e426"}}/> :<FaEyeSlash style={{color:"#6c6a6b"}}/> }
                            </div>
                        </div>
                        <div className="loginModal-forgotSing">
                            <ForgotPassword closeParentFunc={handleClose}/>
                            {/*<span className="loginModal-forgotBtn" onClick={()=>{forgotRequest(email)}}>Forgot your password?</span>*/}
                            <SingUp closeParentFunc={handleClose}/>
                        </div>

                        {isSending &&
                            <div className="singIn-cover">
                                <div className="singIn-sending">
                                    {result ?
                                        <div>
                                            <div className={result === 'SUCCESS' ? 'singIn-success' : 'singIn-error'}>{result}</div>
                                            {error && <div className="singIn-error">{error}</div>}
                                            <button className="blueFill-btn" style={{marginTop:"20px" }} onClick={closeSending}>OK</button>
                                        </div>
                                        :<Loader/>}
                                </div>
                            </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{background:"#ececec"}}>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn" onClick={()=>{singInRequest(email,pass)}} disabled={isSending || result === 'SUCCESS'}>
                            Submit
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

// SING IN WITH REDUX
const mapStateToProps = state => {
    return{
        isAuthRedux: state.signinReducer.auth,
        userIdRedux: state.signinReducer.userId,
        roleRedux: state.signinReducer.role,
        labelRedux: state.signinReducer.label,
        firstNameRedux: state.signinReducer.firstName,
        secondNameRedux: state.signinReducer.secondName
    }
};

const mapDispatchToProps = {
    signIn,
    signOut
};

export default connect(mapStateToProps,mapDispatchToProps)(Role);
