import React,{useState,useEffect} from 'react';
import PickListHookJob from "./PickListHookJob";
import PickListHookMultiJob from "./PickListHookMultiJob";


function FieldBuilderJob  ({IndividualConfig,formikValue,formikChange,valueName}) {
    const [ inputValue, setInputValue ] = useState();
    console.log('JOB FIELD BUILDER individual config:',IndividualConfig);
    console.log('JOB FIELD BUILDER formikValues:',formikValue);
    console.log('JOB FIELD BUILDER formikChange:',formikChange);


    //change input value function
    const changeInpValue = (event)=>{
        event.preventDefault();
        setInputValue(event.target.value);
    };


    switch (IndividualConfig.type) {
        case 'Text':
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={valueName}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={formikValue}
                               id={valueName}
                               name={valueName}
                               maxLength={IndividualConfig.maxlength}
                               // key={IndividualConfig.dv}
                               readOnly={false}
                               onChange={formikChange}
                        />
                    </div>

                </>
            );

        case 'TextArea':
            return (
                <>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{IndividualConfig.dv}</span>
                        </div>
                            <textarea className="form-control"
                                      key={IndividualConfig.dv}
                                      value={inputValue}
                                      readOnly={false}
                                      onChange={changeInpValue}
                                      aria-label={IndividualConfig.dv}
                                      maxLength={IndividualConfig.maxlength}/>
                    </div>

                </>
            );


        // try select with hook
        case 'Picklist':

            return (
                <>
                    <div >
                        <PickListHookJob opt={IndividualConfig.val} name={IndividualConfig.dv}  key={IndividualConfig.dv}/>
                    </div>
                </>
            );

        case 'MultiPicklist':

            return (
                <>
                    <div>
                        <PickListHookMultiJob opt={IndividualConfig.val} name={IndividualConfig.dv}  key={IndividualConfig.dv}/>
                    </div>
                </>
            );


        case 'number':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               // value={inputValDataNUM}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                            // onChange={formik.handleChange}
                        />
                    </div>
                </>
            );

        case 'Phone':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={IndividualConfig.type}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                            // onChange={formik.handleChange} style={{...IndividualConfig.style}}
                        />
                    </div>
                </>
            );

        case 'Website':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={IndividualConfig.type}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'Email':
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={IndividualConfig.type}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={false}
                               maxLength={IndividualConfig.maxlength}
                               onChange={changeInpValue}
                        />
                    </div>
                </>
            );

        case 'DateTime':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={IndividualConfig.type}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'Currency':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={IndividualConfig.type}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'Lookup':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={formikValue}
                               id={valueName}
                               name={valueName}
                               maxLength={IndividualConfig.maxlength}
                            // key={IndividualConfig.dv}
                               readOnly={false}
                               onChange={formikChange}
                        />
                    </div>
                </>
            );

        case 'OwnerLookup':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={IndividualConfig.type}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'UploadText':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={IndividualConfig.type}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'Boolean':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={IndividualConfig.type}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        default:
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               name={IndividualConfig.dv}
                               value={IndividualConfig.type}
                               className="displCand-input"
                        />
                    </div>
                </>
            )
    }

};

export default FieldBuilderJob;
