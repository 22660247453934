import React from 'react';
import { Link,NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { clearDataRecords } from "../../redux/Actions/actionsCreator";
import Login from "../Login/Login";
import Role from "../Role/Role";
import ZohoConnect from "../Login/ZohoConnect";
import User from "../User/User";

const Header = (props) => {
    let curLocaction = useLocation();
    let curLocationPath = curLocaction.pathname;

    function pageRestart() {
        props.clearDataRecords();
    }

    const userRoutesArr = [{value:'Candidates', linkTo:'/candidates'}];

    const recruiterRoutesArr = [{value:'Candidates', linkTo:'/candidates'},{value:'Create', linkTo:'/createCandidate'}];

    const managerRoutesArr = [{value:'Job Openings', linkTo:'/jobOpenings'},{value:'Candidates', linkTo:'/candidates'},{value:'Dashboard', linkTo:'/dashboard'},{value:'Create', linkTo:'/createCandidate'}];

    const adminRoutesArr = [{value:'Job Openings', linkTo:'/jobOpenings'},{value:'Candidates', linkTo:'/candidates'},{value:'Dashboard', linkTo:'/dashboard'},{value:'Create', linkTo:'/createCandidate'},{value:'Admin Panel', linkTo:'/adminPanel'}];

    console.log('LINK PArSER role:',props.roleRedux);

    return(
        <>
            <div className="header-main">
                <div className="header-nav">
                    <ul className="nav nav-pills">
                        <li className="nav-item">
                            <NavLink exact to='/' className="nav-link">
                                Search
                            </NavLink>
                        </li>
                        {props.roleRedux === 'User' &&
                            <>
                                {userRoutesArr.map(routeItem=>
                                    <li className="nav-item">
                                        <NavLink to={routeItem.linkTo} className="nav-link">
                                            {routeItem.value}
                                        </NavLink>
                                    </li>)
                                }
                            </>}

                        {props.roleRedux === 'recruiter_hr' &&
                        <>
                            {recruiterRoutesArr.map(routeItem=>
                                <li className="nav-item">
                                    <NavLink to={routeItem.linkTo} className="nav-link">
                                        {routeItem.value}
                                    </NavLink>
                                </li>)
                            }
                        </>}

                        {props.roleRedux === 'naya_manager' &&
                        <>
                            {managerRoutesArr.map(routeItem=>
                                <li className="nav-item">
                                    <NavLink to={routeItem.linkTo} className="nav-link">
                                        {routeItem.value}
                                    </NavLink>
                                </li>)
                            }
                        </>}

                        {props.roleRedux === 'naya_rt_admin' &&
                            <>
                                {adminRoutesArr.map(routeItem=>
                                    <li className="nav-item">
                                        <NavLink to={routeItem.linkTo} className="nav-link">
                                            {routeItem.value}
                                        </NavLink>
                                    </li>)
                                }
                            </>}


                        {/*<li className="nav-item">*/}
                        {/*    <NavLink to='/jobOpenings' className="nav-link">*/}
                        {/*        Job Openings*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <NavLink to='/candidates' className="nav-link">*/}
                        {/*        Candidates*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <NavLink to='/dashboard' className="nav-link">*/}
                        {/*        Dashboard*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <NavLink to='/createCandidate' className="nav-link">*/}
                        {/*        Create*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <NavLink to='/adminPanel' className="nav-link">*/}
                        {/*        Admin Panel*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                    </ul>
                </div>
                <div style={{display:"flex",flexDirection:"row"}}>
                    <Role/>
                    <ZohoConnect/>
                    {/*<Login/>*/}
                </div>
            </div>


            <div className='container'>
                <div className="header-top">
                    {curLocationPath == '/' ?
                        <Link to='/' onClick={pageRestart} style={{textDecoration:"none", color:"#0f4c75", margin: "15px 0"}}>
                            <h2>NAYA Recruiting</h2>
                        </Link> :
                        <Link to='/' style={{textDecoration:"none", color:"#0f4c75", margin: "15px 0"}}>
                            <h2>NAYA Recruiting</h2>
                        </Link>}
                        <User/>
                </div>
            </div>
        </>

    );
};

//redux
const mapStateToProps = state => {
    return{
        isLogin: state.loginReducer.isLogin,
        roleRedux: state.signinReducer.role,
    }
};


const mapDispatchToProps =  {
    clearDataRecords,
};

export default connect(mapStateToProps,mapDispatchToProps)(Header);
