import React,{useState,useEffect} from 'react';
import candidatesDefaultColumns from "../../functions/candidatesDefaultColumns";
import titleNameReplacer from "../../functions/titleNameReplacer";
import FilterDisableWrapper from "./FilterDisableWrapper/FilterDisableWrapper";
import './dnd.css';

const DragAndDrop = ({ fields, boards, setBoards, selectedColumns, filtersArray }) => {
    console.log('D&D fields:',fields);

    const [ searchAvailable, setSearchAvailable ] = useState('');
    const [ currentBoard, setCurrentBoard ] = useState();
    const [ currentItem, setCurrentItem ] = useState();
    // const [ boards, setBoards ] = useState();

    const prepareBoardsItems = (itemsArray)=>{
        let prepareItemsArr = [];
        itemsArray.forEach( item =>{
            const titleReplace = titleNameReplacer(item);
            const prepareItem = Object.create({},{
                id:{
                    value:`${titleReplace}_ID`,
                    enumerable:true,
                    writable:true,
                },
                title:{
                    value:item,
                    enumerable:true,
                    writable:true,
                }

            });

            prepareItemsArr.push(prepareItem);
        });
        return prepareItemsArr
    };

    const filterSelectedValues = (availableArr,selectedArr)=>{
        let filteredArr = availableArr;
        selectedArr.forEach(selectedItem =>{
            filteredArr = filteredArr.filter( availItem => availItem !== selectedItem);
        });
        return filteredArr;
    };

    const componentDidMount = ()=>{
        let defaultValues;
        if(selectedColumns){
            console.log('use selected columns');
            const regExp = /\(([^)]+)\)/;
            const matches = regExp.exec(selectedColumns);
            const doneColArr = matches[1].split(',');
            console.log('D&D doneColArr:',doneColArr);

            defaultValues = doneColArr;
        }else{
            console.log('use selected columns');
            defaultValues = candidatesDefaultColumns();
        }

        const selectedArray = prepareBoardsItems(defaultValues);
        console.log('D&D SELECTED COLUMNS:',selectedArray);

        const getAvailableFields = (fields)=>{
            const AvailableArr = [];
            fields.forEach(field=>{
                // console.log(field.FL);
                // console.log(Array.isArray(field.FL));
                if(Array.isArray(field.FL)){
                    field.FL.map( fieldItem=>{
                        AvailableArr.push(fieldItem.dv);
                    });
                }else{
                    AvailableArr.push(field.FL.dv);
                }
            });
            return AvailableArr;
        };
        const availableValues = getAvailableFields(fields);
        const availableFilteredValues = filterSelectedValues(availableValues,defaultValues);
        const availableArray = prepareBoardsItems(availableFilteredValues);
        console.log('D&D AVAILABLE ARR:',availableArray);

        setBoards([
            {id:'board1', title:'Available',items:availableArray},
            {id:'board2', title:'Selected',items:selectedArray}
        ]);
    };

    useEffect(()=>{
        componentDidMount();
    },[]);

    useEffect(()=>{
        componentDidMount();
    },[selectedColumns]);

    //functions for columns boards
    function dragStartHandlerCol( e, board, item ) {
        setCurrentBoard(board);
        setCurrentItem(item);
    }

    function dragLeaveHandlerCol(e) {
        // console.log('DRAG LEAVE:',e.target);
        e.target.style.borderBottom = 'none';
    }

    function dragEndHandlerCol(e) {
        // console.log('DRAG END:',e.target);
        e.target.style.borderBottom = 'none';
    }

    function dragOverHandlerCol(e) {
        e.preventDefault();
        if(e.target.className === 'dnd-item'){
            e.target.style.borderBottom = 'solid 2px #cbc8c8';
        }
    }

    function onDropHandlerCol(e,board,item) {
        e.preventDefault();
        const currentIndex = currentBoard.items.indexOf(currentItem);
        currentBoard.items.splice(currentIndex,1);
        const dropIndex = board.items.indexOf(item);
        board.items.splice( dropIndex+1, 0, currentItem);
        setBoards(boards.map(b=>{
            if(b === board.id){
                return board;
            }
            if(b === currentBoard.id){
                return currentBoard;
            }
            return b;
        }));
        e.target.style.borderBottom = 'none';
    }

    function onDropCardHandler(e, board) {
        if(e.target.className !=="dnd-item") {
            console.log('CLASS NAME E TARGET:',e.target.className);
            board.items.push(currentItem);
            const currentIndex = currentBoard.items.indexOf(currentItem);
            currentBoard.items.splice(currentIndex,1);
            setBoards(boards.map(b=>{
                if(b === board.id){
                    return board;
                }
                if(b === currentBoard.id){
                    return currentBoard;
                }
                return b;
            }));
        }

    }

    return (
        <>
        {boards &&
        <div style={{ marginBottom:"10px", paddingBottom:"14px"}}>
            <div>
                <div className="dnd-mainTitle">
                    Choose columns:
                </div>

                <div style={{display:"flex"}}>
                    {boards.map((board,index) =>
                        <div style={{display:"flex",flexDirection:"column"}} key={index}>
                            <span style={{color:"#afafaf",marginLeft:"15px"}}>{board.title}</span>
                            <div className="dnd-Board"
                                 onDragOver={(e)=>{dragOverHandlerCol(e)}}
                                 onDrop={(e)=>{onDropCardHandler(e,board)}}
                            >
                                {board.title === 'Available' &&
                                <input
                                    className="dnd-availableSearch"
                                    type="text"
                                    placeholder="Search Column..."
                                    onChange={(event)=>{setSearchAvailable(event.target.value)}}
                                    value={searchAvailable}/>}

                                {board.items.map( item =>{
                                    // console.log('D&D ITEM:',item);
                                    if(board.title === 'Available' && item.title.toLowerCase().includes(searchAvailable.toLowerCase())){
                                        return(
                                            <div className="dnd-item"
                                                 key={item.id}
                                                 draggable={true}
                                                 onDragStart={(e)=>{dragStartHandlerCol(e,board,item)}}
                                                 onDragLeave={(e)=>{dragLeaveHandlerCol(e)}}
                                                 onDragEnd={(e)=>{dragEndHandlerCol(e)}}
                                                 onDragOver={(e)=>{dragOverHandlerCol(e)}}
                                                 onDrop={(e)=>{onDropHandlerCol(e,board,item)}}
                                            >{item.title}</div>);
                                    }else if(board.title === 'Available' && !item.title.toLowerCase().includes(searchAvailable.toLowerCase())){
                                        return (<div className="dnd-item-hide" key={item.id}>{item.title}</div>);
                                    }else if(item.title === 'First Name' || item.title === 'Last Name'){
                                        return (<div className="dnd-item-disable" key={item.id}>
                                            {item.title}
                                            <span style={{marginLeft:"4px"}}>(default)</span>
                                        </div>);
                                    }else{
                                        return (
                                            <>
                                            <FilterDisableWrapper
                                                item={item}
                                                filtersArray={filtersArray}
                                                board={board}
                                                dragStartHandlerCol={dragStartHandlerCol}
                                                dragLeaveHandlerCol={dragLeaveHandlerCol}
                                                dragEndHandlerCol={dragEndHandlerCol}
                                                dragOverHandlerCol={dragOverHandlerCol}
                                                onDropHandlerCol={onDropHandlerCol}
                                                currentBoard={currentBoard}
                                            />

                                            {/*<div className="dnd-item"*/}
                                            {/*     key={item.id}*/}
                                            {/*     draggable={true}*/}
                                            {/*     onDragStart={(e)=>{dragStartHandlerCol(e,board,item)}}*/}
                                            {/*     onDragLeave={(e)=>{dragLeaveHandlerCol(e)}}*/}
                                            {/*     onDragEnd={(e)=>{dragEndHandlerCol(e)}}*/}
                                            {/*     onDragOver={(e)=>{dragOverHandlerCol(e)}}*/}
                                            {/*     onDrop={(e)=>{onDropHandlerCol(e,board,item)}}*/}
                                            {/*>{item.title}</div>*/}
                                            </>
                                        );
                                    }

                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        }
       </>
    );
};

export default DragAndDrop;
