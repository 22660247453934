import React from 'react';
import "./candidateItemParser.css"
import ExceptionCandidatesItem from "./ExceptionCandidatesItem";

const ExceptionItemParser = ({ exceptionList,jobID,getJobCandidates}) => {

    return (
        <div className="candidateItemParser">
            <div className="candidateItemParser-block">
                <div className="candidateItemParser-blockName">exceptions for this job</div>
                {exceptionList.map( candidateItem =><ExceptionCandidatesItem exceptionCandidate={candidateItem} jobID={jobID} getJobCandidates={getJobCandidates}/>)}
            </div>
        </div>
    );
};

export default ExceptionItemParser;
