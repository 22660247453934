import React,{useState,useEffect} from 'react';
import { Modal, Tooltip, OverlayTrigger, Form, Spinner } from "react-bootstrap";

const ZohoCandidate = ({candidateID}) =>{
    //tooltip
    const zohoTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Check in Zoho
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 750, hide: 150 }}
            overlay={zohoTooltip}>
            <a href={`https://recruit.zoho.com/recruit/org679244433/EntityInfo.do?module=Candidates&id=${candidateID}&submodule=Candidates`} target='_blank' style={{textDecoration:"none"}}>
                <span className="blueWhite-Btn" style={{fontSize:"13px",fontWeight:"700"}}>Z</span>
            </a>
        </OverlayTrigger>
    );
};

export default ZohoCandidate;
