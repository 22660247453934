import React, {useState, useEffect, useContext} from 'react';
import {Accordion, Card, useAccordionToggle, AccordionContext, OverlayTrigger, Tooltip} from 'react-bootstrap';
import TableRow from "./TableRow";
import TableRowDetails from "./TableRowDetails";
import headerReq from "../../../functions/headers";
import mergeJobsData from "../functions/mergeJobsData";
import DbTotalCounter from "./DBTotalCounter";
import RefreshToken from "../../RefreshToken/RefreshToken";
import Loader from "../../Loader/Loader";
import SelectNaya from "../../SelectNaya/SelectNaya";
//Icons
import { BsFillCaretDownFill } from "react-icons/bs";

const DashBoardTable = ({ setRefreshToken }) => {
    const [ allJobs, setAllJobs ] = useState(null);
    const [ tableData, setTableData ] = useState(null);
    const [ filterStatus, setFilterStatus ] = useState('Open');
    const [ showError,setShowError ] = useState(null);

    const statusOptions = ['All', 'Open', 'Closed', 'Empty'];

    const getAllDependency = async ()=>{
        const getAllDependencyURL = 'api/dependency/getAll';

        try{
            const res = await fetch(getAllDependencyURL);
            const json = await res.json();
            console.log('getAllDependency JSON:',json);
            // setAllJobs(json);
            return json;
        }catch (e) {
            console.log('getAllDependency ERROR:',e);
        }
    };

    const getJobsData = async ()=>{
        let config = headerReq();
        let jobsData;
        const url = '/proxyZohoRecruit/recruit/private/json/JobOpenings/getRecords?version=2&fromIndex=1&toIndex=200';//server proxy
        const response = await  fetch(url,{...config});
        const json = await response.json();
        console.log('JOBS ZOHO json:',json);
        if(!json.response.error){
            console.log('JOBS DATA data:',json.response.result.JobOpenings.row);
            jobsData = json.response.result.JobOpenings.row;
            return jobsData;
        }else {
            return {error:{...json.response.error}};
        }

    };

    const ComponentDidMount = async ()=>{
        setAllJobs(null);
        setTableData(null);
        setShowError(null);
        let jobsMongoData = await getAllDependency();
        let jobsData = await getJobsData();
        console.log('JOBS ZOHO jobsData:',jobsData);
        if(jobsData.error){
            if(jobsData.error.message === 'Invalid OAuthtoken'){
                setRefreshToken(true);
            }else{
                setShowError(<div>ERROR! {jobsData.error.message}</div>)
            }
        }else{
            const mergeJobsArr = await mergeJobsData(jobsMongoData,jobsData);
            setAllJobs(mergeJobsArr);
        }
    };

    useEffect(async ()=>{
        await ComponentDidMount();
    },[]);

    //function for filtering jobOpening data by status
    const filterJobByStatus = (data, filterValue)=>{
        if (filterValue === 'All') {
            return data;
        }
        if (filterValue === 'Empty') {
            return data
                .filter(item => item.Job_Status === undefined)
        } else {
            return data
                .filter(item => item.Job_Status !== undefined)
                .filter(item => item.Job_Status.includes(filterValue));
        }
    };

    useEffect(()=>{
        if(allJobs){
            const preparedJobs = filterJobByStatus(allJobs,filterStatus);
            setTableData(preparedJobs);
        }
    },[allJobs,filterStatus]);

    // for accordion with active link
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <span
                className={ isCurrentEventKey ? 'searchDetailsBtn-active':'searchDetailsBtn'}
                onClick={decoratedOnClick}
            >
                {children}
            </span>
        );
    }

    //for tooltip
    const jobDetailsTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Show Details
        </Tooltip>
    );

    return (
        <div style={{marginLeft:"15px"}}>
            <div>
                <div className="BDTable-settings">
                    <SelectNaya label={'Status'} selectValue={filterStatus} setSelectValue={setFilterStatus} options={statusOptions}/>
                </div>
            </div>

            <div className="BDTable-top-sticky">
                <div className="BDTable-top-title">Title</div>
                <div className="BDTable-top-column">Exceptions</div>
                <div className="BDTable-top-column">Selected</div>
                <div className="BDTable-top-column">Sent</div>
                <div style={{minWidth:"2%"}}></div>
            </div>
            <DbTotalCounter allJobs={tableData}/>

            {showError && <div style={{color:"red"}}>{showError}</div>}

            {!tableData ? <Loader/> :
                <Accordion style={{width:"100%"}}>
                    { tableData.map((jobItem,index)=>
                        <Card key={jobItem._id}>
                            <Card.Header className="BDTable-itemMain">
                                <TableRow key={jobItem._id} jobItem={jobItem} idx={index}/>
                                <div style={{width:"2%"}}>
                                    <ContextAwareToggle eventKey={index+1}>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 750, hide: 150 }}
                                            overlay={jobDetailsTooltip}
                                        >
                                            <BsFillCaretDownFill/>
                                        </OverlayTrigger>
                                    </ContextAwareToggle>
                                </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey={index+1}>
                                <Card.Body>
                                    <TableRowDetails key={jobItem._id} jobItem={jobItem}/>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        )}
                </Accordion>}
        </div>
    );
};

export default DashBoardTable;
