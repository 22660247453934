//functions for prepare download data (from zoho API) to candidates table data
import createSuperObject from "./createSuperObject";

const createTableObjects = (rowsArray,dataItemFields,metaDataFields,addToExceptionFunction,exceptions,setExceptions,jobId,jobCandidatesArr,updateJCandidatesSTATE,withTags)=>{
    if(Array.isArray(rowsArray)){
        const getCandidateIndex = rowsArray.map( (curCandidate,index) =>{
            if(index > 0 && index < rowsArray.length - 1){
                // console.log(`TEST curCANDIDATE:${index}`,curCandidate.FL[0].content);
                // console.log('prev:',rowsArray[index-1].FL[0].content);
                // console.log('next:',rowsArray[index+1].FL[0].content);
                // console.log('===========================================');
            }
        });
    }

    const getJobCandidates = async (jobId)=>{
        const getDependencyByIdURL = `/api/dependency/getByIdMongo?id=${jobId}`;//Mongo DB
        try{
            const res = await fetch(getDependencyByIdURL, {method:"get"});
            const json = await  res.json();
            console.log('DEP  BY ID json-getJobCandidates:',json);
            console.log('DEP  BY ID candidatesForJob-getJobCandidates:',json.candidatesForJob);
            if(json.candidatesForJob.length > 1){
                return jobCandidatesArr = json.candidatesForJob;

            }else{
                return jobCandidatesArr = [];
            }
        }catch (e) {
            console.log('ERR',e);
        }
    };

    let superDataArray = [];

    if(Array.isArray(rowsArray)){
        superDataArray = rowsArray.map(candidateItem =>createSuperObject(candidateItem.FL,dataItemFields,metaDataFields,addToExceptionFunction,exceptions,setExceptions,jobId,jobCandidatesArr,updateJCandidatesSTATE,withTags ));
    } else {
        let row = createSuperObject(rowsArray.FL,dataItemFields,metaDataFields,addToExceptionFunction,exceptions,setExceptions,jobId,jobCandidatesArr,updateJCandidatesSTATE,withTags);
        superDataArray.push(row);
    }

    return (superDataArray);
};

export default createTableObjects;
