import React,{useState,useEffect} from 'react';
import UsersTable from "./UsersTable";
import Loader from "../Loader/Loader";
import './adminPanel.css';
import isAuth from "../../functions/isAuth";
import { useLocation, Redirect } from 'react-router-dom';

const AdminPanel = () => {
    const [ usersArr, setUsersArr ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const getAllUsers = async ()=>{
        setIsLoading(true);
        const getAllUsersURL = '/api/user/getAll';

        try{
            const res = await fetch(getAllUsersURL);
            const json = await res.json();
            console.log('getAllUsers JSON:',json);
            setUsersArr(json);
        }catch (e) {
            console.log('getAllUsers ERROR:',e);
        }
        setIsLoading(false);
    };

    useEffect(async ()=>{
        await getAllUsers();
    },[]);

    return (
        <div className="container">
            <h3 className="containter__main-title">Admin Panel</h3>
            <div style={{textAlign:"center", marginTop:"10px"}}><h5>Users</h5></div>
            {isLoading ? <Loader/> : <UsersTable usersArr={usersArr} updateUsers={getAllUsers}/>}
        </div>
    );
};

export default AdminPanel;
