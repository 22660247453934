import React from 'react';
import PDFviewerItem from "./PDFviewerItem";


const ResumeWrapperBothItem = ({extension,linkToCV,candidateId}) => {
    return (
        <>
            {extension ==='pdf' ? <PDFviewerItem url={linkToCV}/> :
                //microsoft office viewer
                <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${linkToCV}`}
                    style={{width:'100%',height:'88vh'}}
                    title={candidateId} />
            }
        </>
    );
};

export default ResumeWrapperBothItem;
