import React from "react";
import Select from "react-select";


function PickListSimpleJob ({name,optValue,selectedOpt,setSelectedOpt}) {

    const rawOptions = optValue;

    function prepareArrOptions(e) {
        if(typeof e === 'string'){
            return Object.create({}, { value:{value:e},label:{value:e}});
        } else {return Object.create({}, { value:{value:e.content},label:{value:e.content}})}
    }

    const changeInpValue = selectedOpt => {
        setSelectedOpt({ selectedOpt });
        console.log(`Option selected:`, selectedOpt);
    };

    const options = rawOptions.map(e => prepareArrOptions(e));

    // console.log('selectedOpt',selectedOpt);
    return (
        <div className="PickHook">
            <span className="select-label">{name}</span>
            <Select
                defaultValue={selectedOpt}
                onChange={changeInpValue}
                options={options}
            />
        </div>
    );
}

export default PickListSimpleJob;

