//clear function for create edit item (use same names parameters - state advanced search component)
function createEditSearchItem(curItemId,searchTitle,criteriasInp,selectedColumns,AdvancedSearchUrl,objCriterias,exceptions,excCriteriaInp){
    let advancedSearchItem = Object.create({},{
        //json data version
        // searchId:{
        //     value:curItemId,
        //     enumerable:true
        // },
        _id:{
            value:curItemId,
            enumerable:true
        },
        searchTitle:{
            value: searchTitle,
            enumerable:true,
            writable:true,
        },
        searchCriteria:{
            value:{},
            enumerable:true,
            writable:true,
        },
        searchAddCriterias:{
            value: [...criteriasInp],
            enumerable:true,
            writable:true,
        },
        searchFilterCriterias:{
            value: [...excCriteriaInp],
            enumerable:true,
            writable:true,
        },
        searchColumns:{
            value: selectedColumns,
            enumerable:true,
            writable:true,
        },
        searchString:{
            value: AdvancedSearchUrl,
            enumerable:true,
            writable:true,
        },
        searchExceptions:{
            value:exceptions,
            enumerable:true,
            writable:true,
        }

    });

    advancedSearchItem.searchCriteria = objCriterias;
    return advancedSearchItem;
}

export default createEditSearchItem;
