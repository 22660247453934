import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import useAuth from "../hook/useAuth";

const PrivateRoute = ({children}) => {
    const location = useLocation();
    console.log('PrivateRoute location:',location);

    const user = useAuth();
    console.log('PrivateRoute user:',user);

    if(user.role !== 'admin'){
        return <Redirect to="/" />
    }

    return children;
};

export default PrivateRoute;
