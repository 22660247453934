import React,{useState} from 'react';
import {Link} from "react-router-dom";
import {OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import SentTimeWrapper from "./SentTimeWrapper";
import './candidateItemParser.css';

//BsArrowLeftCircle;BiLeftArrowCircle;FiArrowLeftCircle;MdArrowBackIosNew
import { BiLeftArrowCircle } from "react-icons/bi";

const SentCandidatesItem = ({ sentCandidate, jobID, getJobCandidates  }) =>{
    const [ isSending,setIsSending ]= useState(false);

    //change candidate status "send"
    const clearStatus = async (candidateID,candidateFullName,jobID,updateFunc)=>{
        setIsSending(true);
        console.log('clearStatus-candidateID:',candidateID);
        console.log('clearStatus-candidateFullName:',candidateFullName);
        console.log('clearStatus-jobID:',jobID);

        const sendToCustomerURL = '/api/dependency/clearCandidateStatus';

        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                jobId: jobID,
                updateCandidate: {candidateID:candidateID, candidateFullName:candidateFullName, candidateStatus:null, statusHistory:[]}
            })
        };

        try{
            const res = await fetch(sendToCustomerURL, {...reqOptions});
            const json = await res.json();
            console.log('clearStatus JSON:',json);
            if(json.response === "SUCCESS"){
                await updateFunc(jobID);
            }
        }catch (e) {
            console.log('clearStatus ERROR');
        }
        setIsSending(false);
    };

    const TooltipBackSelected = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Back To Selected
        </Tooltip>
    );

    return (
        <div className="candidateItemParser-candidateItem">

                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={TooltipBackSelected}>
                    <span className="modal-link" onClick={()=>{clearStatus(sentCandidate.candidateID,sentCandidate.candidateFullName,jobID, getJobCandidates)}}>
                        {isSending ? <Spinner animation="border" size="sm"/> : <BiLeftArrowCircle fontSize={"18px"}/>}
                    </span>
                </OverlayTrigger>
                <Link to={{pathname:'/candidate',search:`id=${sentCandidate.candidateID}&title=${sentCandidate.candidateFullName}`}}
                      className="candidateItemParser-candidateTitle">
                    {sentCandidate.candidateFullName}
                </Link>
            <SentTimeWrapper statusHistory={sentCandidate.statusHistory}/>
        </div>
    );
};

export default SentCandidatesItem;
