import React from "react";

const FiltersTitle = () =>{
    return(
        <div className="criteria-title">
            <div className="criteria-name">
                <span className="criteria-titleSpan">Filter Name</span>
            </div>
            <div className="criteria-condition">
                <span className="criteria-titleSpan">Condition</span>
            </div>
            <div className="criteria-value">
                <span className="criteria-titleSpan">Filter Value</span>
            </div>
        </div>
    );
};

export default FiltersTitle;
