import React, {useEffect, useState} from 'react';
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const DBoardBigTextWrapper = ({text , characters = 70 ,noteDate = null}) => {
    const [ mailSent, setMailSent ] = useState(null);
    const [ mailSubject, setMailSubject ] = useState(null);
    const [ mailText, setMailText ] = useState(null);

    // const newLine = (string)=>{
    //     return string.replace(/\n /gi,'<br>');
    // };

    const textArr = text.split('\n');

    const getMailDetails = (strArr , findStr , setStateFunc) => {
        const sentLine = strArr.filter( line => line.includes(findStr));
        if(sentLine.length > 0){
            const val = sentLine[0].split(':');
            setStateFunc( val[1]);
        }
    };

    useEffect(()=>{
        getMailDetails(textArr,'SENT TO', setMailSent);
        getMailDetails(textArr,'SUBJECT', setMailSubject);
        getMailDetails(textArr,'TEXT', setMailText);
    },[]);

    const bigTextToolTip = (props)=>{
        // console.log(text);
        return(
            <Tooltip id="bigText-toolTip" className="bigText-Tool"  {...props}>
                {mailSent ?
                    <div>
                        {mailSent && <div><span style={{color:"#25055c",fontSize:"14px",fontWeight:"700"}}>Sent to:</span><span>{mailSent}</span></div>}
                        {mailSubject && <div><span style={{color:"#25055c",fontSize:"14px",fontWeight:"700"}}>Subject:</span><span>{mailSubject}</span></div>}
                        {mailText && <div><span style={{color:"#25055c",fontSize:"14px",fontWeight:"700"}}>Text:</span><span>{mailText}</span></div>}
                    </div>
                    :
                    <span>{text}</span>}
            </Tooltip>);
    };


    return (
        <>
            <div>
                {text.length > characters &&
                <OverlayTrigger
                    placement="auto"
                    delay={{ show: 750, hide: 150 }}
                    overlay={bigTextToolTip}
                    text={text}
                >
                    <div  className="table-BigTextWrapper">
                        {text.slice(0,characters)}
                        <span className="table-showMore">...</span>
                    </div>
                </OverlayTrigger>

                }
            </div>
        </>
    );
};

export default DBoardBigTextWrapper;
