import React,{useState,useEffect} from 'react';
import JobListItem from "./jobListItem";
import {Form} from "react-bootstrap";

const SelectJobModal = ({allJobs,candidatesJob,candidateID,candidateFullName,updateCandidatesJob}) =>{
    console.log('SELECTJOB-allJobs:', allJobs);
    console.log('SELECTJOB-candidatesJob:', candidatesJob);
    //Job_Opening_Status: "In-progress"
    const [ availableJob, setAvailableJob ]= useState(null);
    const [ searchAvailable, setSearchAvailable ] = useState('');
    const [ inProgress, setInProgress ] = useState(true);

    const getAvailableJob = (selectedJobs,allJobs)=>{
        let cloneAllJobs = [...allJobs];
        if (inProgress){
            cloneAllJobs = cloneAllJobs.filter(item => item.Job_Status !== undefined).filter( jobItem => jobItem.Job_Status.includes('Open'));
        }
        if(selectedJobs.length > 0){
            // let cloneAllJobs = [...allJobs];
            selectedJobs.forEach( selectedItem =>{
                cloneAllJobs = cloneAllJobs.filter( job => job.JOBOPENINGID !== selectedItem.itemId);
            });
            return setAvailableJob([...cloneAllJobs]);
        }else {
            return setAvailableJob([...cloneAllJobs]);
        }
    };

    useEffect(()=>{
        getAvailableJob(candidatesJob,allJobs);
    },[]);

    useEffect(()=>{
        getAvailableJob(candidatesJob,allJobs);
    },[candidatesJob,inProgress]);

    const inProgressHandle = ()=>{
        setInProgress(!inProgress);
    };

    return (
        <div>
            <div className="addToJob-listAvailableTitle">Selected jobs:</div>
            {candidatesJob.length > 0 ?
                <div>{candidatesJob.map(jobItem =>
                                            <JobListItem
                                                candidateID={candidateID}
                                                candidateFullName={candidateFullName}
                                                updateCandidatesJob={updateCandidatesJob}
                                                jobTitle={jobItem.itemTitle}
                                                jobId={jobItem.itemId}
                                                action={'remove'}
                                                key={jobItem.itemId}/>)}
                </div>
                :<div style={{fontStyle:"italic",color:"#666668"}}>No selected jobs</div>}

            <div className="addToJob-listAvailableMain" style={{marginTop:"18px"}}>
                    <span className="addToJob-listAvailableTitle">List of available jobs:</span>
                    <Form.Check
                        // disabled ={ true }
                        onChange={()=>{inProgressHandle()}}
                        checked={ inProgress }
                        inline
                        label="Open jobs"
                        type={'checkbox'}
                        id={'inProgressCheck'}/>
            </div>
            <input
                className="dnd-availableSearch"
                type="text"
                placeholder="Search job..."
                onChange={(event)=>{setSearchAvailable(event.target.value)}}
                value={searchAvailable}/>
            {availableJob &&
                <div>{availableJob.map(jobItem =>{
                if(jobItem.Posting_Title.toLowerCase().includes(searchAvailable.toLowerCase())){
                    return(<JobListItem
                            candidateID={candidateID}
                            candidateFullName={candidateFullName}
                            updateCandidatesJob={updateCandidatesJob}
                            jobTitle={jobItem.Posting_Title}
                            jobId={jobItem.JOBOPENINGID}
                            action={'add'}
                            key={jobItem.JOBOPENINGID}/>);
                }else {
                    return(<JobListItem
                            candidateID={candidateID}
                            candidateFullName={candidateFullName}
                            updateCandidatesJob={updateCandidatesJob}
                            jobTitle={jobItem.Posting_Title}
                            jobId={jobItem.JOBOPENINGID}
                            action={'hide'}
                            key={jobItem.JOBOPENINGID}/>);
                }

            })}</div>}
        </div>
    );
};

export default SelectJobModal;
