import React,{ useState, useEffect } from 'react';
import CreateNotes from "../CreateNotes/CreateNotes";
import AddNote from "../AddNote/AddNote";
import NoteItem from "../NoteItem/NoteItem";
import './../notes.css';

const DisplayNotes = ({candidateID, fullName, candidateNotes, setCandidateNotes})=>{
    const [ candidateNotesPrepared, setCandidateNotesPrepared ] = useState();

    const updateNotes = async ()=>{
        const updateNotesURL = `/api/candidate/getByCandidateId?id=${candidateID}`;

        try{
            const res = await fetch(updateNotesURL);
            const json = await res.json();
            console.log('updateNotes JSON:',json);
            if(json.response){
                if(json.response.notes){
                    console.log('updateNotes JSON.notes:',json.response.notes);
                    console.log('updateNotes JSON.notes.notes:',json.response.notes.notes);
                    await setCandidateNotes(json.response.notes.notes);
                }
            }
        }catch (e) {
            console.log('updateNotes ERROR:',e);
        }
    };

    useEffect(()=>{
        if(candidateNotes){
            const preparedArray = candidateNotes.reverse();
            setCandidateNotesPrepared([...preparedArray]);
        }

    },[candidateNotes]);


    return (
        <div className="notes-displayMain">
            {candidateNotesPrepared ?
                <>
                <AddNote candidateID={candidateID} updateNotesFunc={updateNotes}/>
                <div>{candidateNotesPrepared.map(noteItem => <NoteItem key={noteItem._id} noteItem={noteItem} candidateID={candidateID} updateNotesFunc={updateNotes}/>)}</div>
                </>:
                <CreateNotes candidateID={candidateID} candidateFullName={fullName} updateNotesFunc={updateNotes}/>}
        </div>
    );
};

export default DisplayNotes;
