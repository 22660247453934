import React,{useState,useEffect} from 'react';
import {Button,OverlayTrigger,Tooltip} from "react-bootstrap";
import ResumeWrapperBothItem from "./ResumeWrapperBothItem";
import EmailModal from "../Email/EmailModal";
import RefreshToken from "../RefreshToken/RefreshToken";
import NoteQuickCv from "../Notes/NoteQuickCv/NoteQuickCv";
import Loader from "../Loader/Loader";
import ZohoCandidate from "../ZohoCandidate/ZohoCandidate";
import AddCandidateJobBtn from "../AddCandidateToJob/AddCandidateJobBtn";
import CreateNotesPriority from "../Notes/CreateNotesPriority/CreateNotesPriority";
//Icons
import { BsX } from 'react-icons/bs';
import { RiFileUserLine } from "react-icons/ri";


const PreviewItemCV = ({previewItemCV,setPreviewItemCV,candidateId,headerName,previewItem,showEdit,setShowEdit}) =>{
    const [ linkToCV, setLinkCV ] = useState(null);
    const [ refrToken,setRefrToken ] = useState(false);
    const [ documentExtension,setDocumentExtension ] = useState();
    const [ noData, setNoData ] = useState(false);

    console.log('PREVIEW ITEM:',previewItem);

    const getFileExtension = async (stringURL) => {
        const urlArr = await stringURL.toString().split('.');
        const fileExtension = await urlArr[urlArr.length-1];
        console.log('stringURL-EXTENSION:',fileExtension );
        return fileExtension;
    };

    const replaceLink = async (linkFull)=>{
        console.log('linkFull:',linkFull);
        const testProxyLinkCom = linkFull.split('.com');
        const linkProxy = `/s3bucket${testProxyLinkCom[1]}`;
        console.log('linkProxy:',linkProxy);
        return linkProxy;
    };

    const getLinkCV = async (candId)=>{
        let acToken = await localStorage.getItem('ZohoToken');
        console.log('acToken', acToken);
        const requestURL = `/api/resume?id=${candId}&token=${acToken}`;
        console.log('start fetch CV');
        try{
            const res = await fetch(requestURL, {method:"get"});
            const json = await res.json();
            console.log(json);
            if(json.error){
                console.log(json.error.error);
                console.log('INVALID:', (json.error.error === 'Invalid OAuthtoken'));
                if(json.error.error === 'Invalid OAuthtoken'){
                    console.log('INVALID2:', (json.error.error === 'Invalid OAuthtoken'));
                    setRefrToken(true);
                }else{
                    console.log('INVALID3:', (json.error.error === 'Invalid OAuthtoken'));
                    setRefrToken(false);
                    setNoData(true);
                }
                return ;
            }else {
                console.log('response json get cv link:',json.linkCV);
                const extension = await getFileExtension(json.linkCV);
                console.log('CONST EXTENSION:',extension);
                setDocumentExtension(extension);
                if(extension === 'pdf'){
                    const proxyLink = await replaceLink(json.linkCV);
                    console.log('response json get proxy link:',proxyLink);
                    setLinkCV(proxyLink);
                    return ;
                }
                setLinkCV(json.linkCV);//for test with amazon link
            }

        }catch (err) {
            console.log('fetch to get link response with ERROR:',err);
        }
        console.log('finish fetch CV');
    };

    useEffect(async ()=>{
        if(previewItemCV === true && linkToCV === null){
            console.log('DOWNLOAD CV : previewItemCV === true');
            await getLinkCV(candidateId);
        }

    },[previewItemCV]);

    //Tooltips
    const QuickEditTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Quick Edit
        </Tooltip>
    );


    return (
        <div className={previewItemCV ? "prevItem-CV-active":"prevItem-CV"} onClick={event => event.stopPropagation()}>
            <div className="previewItem-CV-top" style={{display:"flex",marginBottom:"5px"}}>
                <h3 style={{color:"#F57272",textAlign:"center"}}>Quick CV <span style={{color:"#1f1ede",fontSize:"20px",marginLeft:"8px"}}>{headerName}</span></h3>

                <div className="prevCV-actionWrapper">
                    <span className="blueWhite-Btn">
                        <EmailModal candidateId={candidateId} candidateFullName={headerName} btnName={'BsEnvelope'} forTable={false}/>
                    </span>

                    <span className="blueWhite-Btn" onClick={()=>{setShowEdit(!showEdit)}}>
                         <OverlayTrigger
                             placement="right"
                             delay={{ show: 750, hide: 150 }}
                             overlay={QuickEditTooltip}>
                             <RiFileUserLine/>
                         </OverlayTrigger>
                    </span>

                    <NoteQuickCv candidateID={candidateId} fullName={headerName} showCV={previewItemCV}/>
                    <AddCandidateJobBtn candidateID={candidateId} candidateFullName={headerName} forCV={true}/>
                    <CreateNotesPriority candidateID={candidateId} candidateFullName={headerName} forComponent={'cv'} parentOpen={previewItemCV}/>
                    <ZohoCandidate candidateID={candidateId}/>
                </div>

                <Button variant="secondary" className="prevItem-CV-closeBtn" onClick={()=>{setPreviewItemCV()}}>
                    <BsX size={'22px'}/>
                </Button>
            </div>
            <div className="previewItem-CV-body">
                {linkToCV ? <ResumeWrapperBothItem extension={documentExtension} linkToCV={linkToCV} candidateId={candidateId}/>
                    : refrToken ?
                        <div style={{width:'100%',height:'400px',textAlign:"center"}}>
                            <h3 style={{color:"red"}}>Your token has expired!</h3>
                            <p> <RefreshToken update={()=>{getLinkCV(candidateId)}}/> </p>
                        </div> :
                        noData ?
                            <div style={{width:'100%',height:'400px',textAlign:"center"}}>
                                <h3 style={{color:"red"}}>No data to show!</h3>
                            </div>:
                        <div style={{width:'100%',height:'400px'}}><Loader/></div>}
            </div>
            <div className="previewItem-CV-footer">
                {documentExtension && <span style={{color:"#918e90",fontSize:"14px",fontWight:"600"}}>{documentExtension}</span>}
            </div>
        </div>
    );
};

export default PreviewItemCV;
