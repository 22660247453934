import React from 'react';
import PreviewItemWrapper from "../PreviewItem/PreviewItemWrapper";
import PreviewCvWrapper from "../PreviewCV/PreviewCvWrapper";
import EmailModal from "../Email/EmailModal";
import NoteQuickCv from "../Notes/NoteQuickCv/NoteQuickCv";
import AddCandidateJobBtn from "../AddCandidateToJob/AddCandidateJobBtn";
import ZohoCandidate from "../ZohoCandidate/ZohoCandidate";

const ItemActionWrapper = ({candidateId, candidateFullName, dataFields, module, meta}) => {
    console.log('META DATA ItemActionWrapper', meta);
    return (
        <div className="displayItem-actionsWrapper" style={{display:"flex", flexDirection:"row", width:"31%"}}>
            <span className="blueWhite-Btn">
                <PreviewCvWrapper header={candidateFullName} id={`${candidateId}`} btnName={'RiFileUserLine'}
                        dataItemFields={dataFields} module={module} meta={meta} />
            </span>
            <span className="blueWhite-Btn">
                <PreviewItemWrapper header={candidateFullName} id={`${candidateId}`} btnName={'RiFileUserLine'}
                        dataItemFields={dataFields} module={module} meta={meta} />
            </span>
            <span className="blueWhite-Btn">
                <EmailModal candidateId={candidateId} candidateFullName={candidateFullName} btnName={'BsEnvelope'} forTable={false}/>
            </span>
            <NoteQuickCv candidateID={candidateId} fullName={candidateFullName} showCV={true}/>
            <AddCandidateJobBtn candidateID={candidateId} candidateFullName={candidateFullName} forCV={true}/>
            <ZohoCandidate candidateID={candidateId}/>
        </div>
    );
};

export default ItemActionWrapper;
