import React, {useState} from 'react';
import { useLocation,useHistory } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import Loader from "../Loader/Loader";
import RefreshToken from "../RefreshToken/RefreshToken";

const CreateJobBtn = ({postingTitle, departmentName, jobStatus}) =>{
    const [ show, setShow ] = useState(false);
    const [ validation, setValidation ] = useState();
    const [ requestLoader, setRequestLoader ] = useState(false );
    const [ requestResult, setRequestResult ] = useState( null );
    const [ needRefresh, setNeedRefresh ] = useState( false);

    let history = useHistory();

    const validationJobFields = (postingTitle, departmentName, jobStatus)=>{
        if(postingTitle === ''){
            console.log('VALIDATION postingTitle:', postingTitle);
            return false;
        }else if (departmentName === ''){
            console.log('VALIDATION departmentName:', departmentName);
            return false;
        }else if (jobStatus === undefined){
            console.log('VALIDATION jobStatus:', jobStatus);
            return false;
        }else{
            console.log('VALIDATION TRUE');
            return true;
        }
    };

    const handleClose = () =>{
        setRequestLoader(false);
        setRequestResult(null);
        setShow(false);
    };

    const handleShow = async () => {
        console.log('show pop up');
        const allValidation = await validationJobFields(postingTitle, departmentName, jobStatus);
        if(allValidation === false){
            setValidation(false);
        }else {
            setValidation(true);
        }

        setShow(true);
    };

    //create new job request
    const createNewJob = async ()=>{
        setRequestResult(null);
        setRequestLoader(true);
        const createNewJobURL = '/proxyZohoRecruit/recruit/v2/JobOpenings';
        let token = localStorage.getItem('ZohoToken');

        const dataArr = [
            {
                Posting_Title: postingTitle,
                Job_Opening_Name: postingTitle,
                Department_Name: departmentName,
                Job_Status: jobStatus.selectedOpt.value
            }
        ];

        const requestOptions = {
            method:"POST",
            headers:{
                'Authorization':`Zoho-oauthtoken ${token}`,
                'Content-Type':'application/json'
            },
            cache: "no-cache",
            body:JSON.stringify({data: dataArr })
        };

        console.log('DATA:',dataArr);
        console.log('REQUEST:',requestOptions);
        try {
            const res = await fetch(createNewJobURL, {...requestOptions});
            const json = await res.json();
            if(json.code){
                console.log('createNewJob JSON:',json);
                if(json.code === 'INVALID_TOKEN'){
                    setRequestResult(<span style={{color:"red"}}>{json.message}<RefreshToken update={createNewJob}/></span>)
                }
            }else{
                console.log('createNewJob JSON:',json);
                setRequestResult(json.data[0].code);
            }
        }catch (e) {
            console.log('createNewJob ERROR:',e);
        }

        await setRequestLoader(false);
        // await handleClose();
    };

    const creatingFinish = ()=>{
        if(requestResult === 'SUCCESS'){
            history.push('/jobOpenings');
        }else{
            handleClose();
        }
    };

    return (
        <>
            <button
                type="button"
                className="blueFill-btn"
                style={{marginTop:"15px"}}
                onClick={handleShow}
            >Create Job Opening</button>

            <Modal dialogClassName="test-dialog" show={show} onHide={handleClose}>
                <Modal.Title style={{display:"flex",justifyContent:"center",color:"#F57272",background:"#efeeef"}}>
                    CREATE NEW JOB OPENING
                </Modal.Title>
                <Modal.Body className="createJob-saveWindow">
                    <div className="advanced-saveWindow-bodyWrapper">
                        {validation ?
                            <div>
                                <h4>Create <span style={{color:"#004b9c",fontWeight:"600"}}>"{postingTitle}"</span></h4>
                                <div style={{color:"#828080"}}>Do you want to create new job opening? </div>
                                {requestLoader &&
                                    <div style={{display:"flex", width:"100%",justifyContent:"center",marginTop:"12px"}}>
                                        <Loader/>
                                    </div>}

                                {requestResult &&
                                    <div style={{display:"flex", width:"100%",justifyContent:"center"}}>
                                        <div>{requestResult === 'SUCCESS' ?
                                            <span style={{color:"green", fontSize:"16px",fontWeight:"600"}}>{requestResult}</span>:
                                            requestResult}
                                        </div>
                                    </div>}
                            </div>:
                            <div style={{color:'red'}}>
                                <h4>Error!!!</h4>
                                <p>Please, check all fields</p>
                            </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{background:"#efeeef"}}>
                    {requestResult ?
                        <>
                            <Button variant="secondary" onClick={handleClose} disabled={requestLoader}>
                                Back
                            </Button>

                            <Button variant="success" disabled={requestLoader} onClick={()=>{creatingFinish()}}>
                                Ok
                            </Button>
                        </>:
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>

                            <Button variant="success" disabled={!validation} onClick={()=>{createNewJob()}}>
                                Confirm
                            </Button>
                        </>}

                </Modal.Footer>
            </Modal>

        </>
    );
};

export default CreateJobBtn;
