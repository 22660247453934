import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import Loader from "../Loader/Loader";
import RefreshToken from "../RefreshToken/RefreshToken";
import candidatesDefaultColumnsString from "../../functions/candidatesDefaultColumnsString";
import axios from "axios";
//Redux
import { connect } from "react-redux";
import {clearDataRecords,fetchDataRecordsKeyWord} from "../../redux/Actions/actionsCreator";

const CreateNewCandidateBtn = ({ firstName, lastName, email, emailValidation ,company, cvFile, cvEvaluation, phoneEvaluation , collegeRecommended }) =>{
    const [ show, setShow ] = useState(false);
    const [ validation, setValidation ] = useState();
    const [ requestLoader, setRequestLoader ] = useState(false );
    const [ requestResult, setRequestResult ] = useState( null );

    const history = useHistory();

    const validationJobFields = (firstName, lastName, email, company)=>{
        if(firstName === ''){
            console.log('VALIDATION firstName:',firstName);
            return false;
        }else if (lastName === ''){
            console.log('VALIDATION departmentName:',lastName);
            return false;
        }else{
            console.log('VALIDATION TRUE');
            return true;
        }
    };

    const handleClose = () =>{
        setRequestLoader(false);
        setRequestResult(null);
        setShow(false);
    };

    const handleShow = async () => {
        console.log('show pop up');
        const allValidation = await  validationJobFields(firstName, lastName, email, company);
        if(allValidation === false){
            setValidation(false);
        }else {
            setValidation(true);
        }

        setShow(true);
    };

    //create body with data for create new candidate request
    const createDataArr = (company,firstName,lastName,email,cvEvaluation,phoneEvaluation,collegeRecommended)=>{
        return(
            [
                {
                    "Company": company,
                    "First_Name": firstName,
                    "Last_Name": lastName,
                    "Email": email,
                    "field2":cvEvaluation,
                    "field": phoneEvaluation,
                    "College_Recommended": collegeRecommended,
                    "Candidate_Status": "New"
                }
            ]
        );
    };

    //function for add tag college recommended
    const addTagRecommended = async (newCandidateID)=>{
        console.log('addTag candidate id:', newCandidateID);
        const userID = localStorage.getItem('NRuserID');
        const userName = localStorage.getItem('NRuserName');
        const currentDate = new Date();

        const bodyObj = {
            candidateId: newCandidateID,
            candidateFullName: `${firstName} ${lastName}`,
            candidateTag:'College pipe',
            createdTime: currentDate,
            userID: userID,
            userName: userName,
            noteType:'tag',
            noteTitle:'Add Tag',
            noteContent:'added tag "College pipe" to created candidate'
        };

        const addTagURL = '/api/note/createNewMongo';
        console.log('addTagURL:',addTagURL);

        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...bodyObj})
        };

        try{
            const res = await fetch(addTagURL, reqOptions);
            const json = await res.json();
            console.log('addTag JSON:',json);
        }catch (err) {
            console.log('addTag response with ERROR:',err);
        }
    };

    //create new candidate request
    const createNewCandidate = async ()=>{
        setRequestResult(null);
        setRequestLoader(true);
        const createCandidateURL = '/proxyZohoRecruit/recruit/v2/Candidates';
        let token = localStorage.getItem('ZohoToken');

        const dataArr = await createDataArr(company,firstName,lastName,email,cvEvaluation,phoneEvaluation, collegeRecommended);

        const requestOptions = {
            method:"POST",
            headers:{
                'Authorization':`Zoho-oauthtoken ${token}`,
                'Content-Type':'application/json'
            },
            cache: "no-cache",
            body:JSON.stringify({data: dataArr })
        };

        console.log('DATA:',dataArr);
        console.log('REQUEST:',requestOptions);
        try {
            const res = await fetch(createCandidateURL, {...requestOptions});
            const json = await res.json();
            if(json.code){
                console.log('createNewJob JSON:',json);
                if(json.code === 'INVALID_TOKEN'){
                    setRequestResult(<span style={{color:"red"}}>{json.message}<RefreshToken update={createNewCandidate}/></span>)
                }
            }else{
                console.log('createNew JSON:',json);
                const newCandidateID  = json.data[0].details.id;
                console.log('createNew candidateID:',newCandidateID);
                if(collegeRecommended){
                    await addTagRecommended(newCandidateID);
                }
                setRequestResult(json.data[0].code);
            }
        }catch (e) {
            setRequestResult(<span>Create new candidate ERROR</span>);
            console.log('createNewJob ERROR:',e);
        }

        await setRequestLoader(false);
        // await handleClose();
    };

    //function for upload attachment
    const uploadAttachmentCV = async (candidateID)=>{
        console.log('UPLOAD to candidate:',candidateID);
        // const uploadAttachmentURL = `/proxyZohoRecruit/recruit/v2/Candidates/${candidateID}/Attachments?attachments_category=Resume`;//normal
        const uploadAttachmentURL = `/api/zohoservice/uploadAttachment?candidateId=${candidateID}`;//test back end
        let token = localStorage.getItem('ZohoToken');

        //form data
        const FD = new FormData();
        FD.append('file',cvFile);

        try {
            const res = await axios.post(uploadAttachmentURL,FD,{headers : {
                        'Content-Type':'multipart/form-data',
                        'Authorization':`Zoho-oauthtoken ${token}`}}
                );
                console.log('upAttach RES:',res);
        }catch (e) {
            console.log('TEST upload ERROR:',e);
            setRequestResult(<span style={{color:"red"}}>upload error</span>);
        }
    };

    //create new candidate with CV
    const createNewCandidateCV = async ()=>{
        setRequestResult(null);
        setRequestLoader(true);
        const createCandidateURL = '/proxyZohoRecruit/recruit/v2/Candidates';
        let token = localStorage.getItem('ZohoToken');

        const dataArr = await createDataArr(company,firstName,lastName,email,cvEvaluation,phoneEvaluation,collegeRecommended);

        const requestOptions = {
            method:"POST",
            headers:{
                'Authorization':`Zoho-oauthtoken ${token}`,
                'Content-Type':'application/json'
            },
            cache: "no-cache",
            body:JSON.stringify({data: dataArr })
        };

        console.log('DATA:',dataArr);
        console.log('REQUEST:',requestOptions);
        try {
            const res = await fetch(createCandidateURL, {...requestOptions});
            const json = await res.json();
            if(json.code){
                console.log('createNew + cv JSON:',json);
                if(json.code === 'INVALID_TOKEN'){
                    setRequestResult(<span style={{color:"red"}}>{json.message}<RefreshToken update={createNewCandidate}/></span>)
                }
            }else{
                console.log('createNew JSON:',json);
                const newCandidateID  = json.data[0].details.id;
                console.log('createNew candidateID:',newCandidateID);
                if(collegeRecommended){
                    await addTagRecommended(newCandidateID);
                }

                await uploadAttachmentCV(newCandidateID);
                setRequestResult(json.data[0].code);
            }
        }catch (e) {
            console.log('createNewJob ERROR:',e);
        }

        await setRequestLoader(false);
        // await handleClose();
    };

    const creatingFinish = ()=>{
        handleClose();
        history.push('/candidates');
    };

    return (
        <>
            <button
                type="button"
                className="blueFill-btn"
                style={{marginTop:"15px"}}
                onClick={handleShow}
            >Create Candidate</button>

            <Modal dialogClassName="test-dialog" show={show} onHide={handleClose}>
                <Modal.Title style={{display:"flex",justifyContent:"center",color:"#F57272",background:"#efeeef"}}>
                    CREATE NEW CANDIDATE
                </Modal.Title>
                <Modal.Body className="createJob-saveWindow">
                    <div className="advanced-saveWindow-bodyWrapper">
                        {validation ?
                            <div>
                                <h4>
                                    Create
                                    <span style={{color:"#004b9c",fontWeight:"600",marginLeft:"4px"}}>"{firstName}</span>
                                    <span style={{color:"#004b9c",fontWeight:"600",marginLeft:"4px"}}>{lastName}"</span>
                                </h4>

                                {cvFile &&
                                    <div>
                                        <span style={{color:"#828080",fontWeight:"600"}}>Attached CV:</span>
                                        <span style={{color:"#004b9c",fontWeight:"500",marginLeft:"4px"}}>'{cvFile.name}'</span>
                                    </div>}

                                <div style={{color:"#828080"}}>Do you want to create new candidate? </div>
                                {requestLoader &&
                                <div style={{display:"flex", width:"100%",justifyContent:"center",marginTop:"12px"}}>
                                    <Loader/>
                                </div>}

                                {requestResult &&
                                <div style={{display:"flex", width:"100%",justifyContent:"center"}}>
                                    <div>{requestResult === 'SUCCESS' ?
                                        <span style={{color:"green", fontSize:"16px",fontWeight:"600"}}>{requestResult}</span>:
                                        requestResult}
                                    </div>
                                </div>}
                            </div>
                            :
                            <div style={{color:'red'}}>
                                <h4>Error!!!</h4>
                                <p>Please, check all fields</p>
                            </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{background:"#efeeef"}}>
                    {requestResult ?
                        <>
                            <Button variant="secondary" onClick={handleClose} disabled={requestLoader}>
                                Back
                            </Button>

                            <Button variant="success" disabled={requestLoader} onClick={()=>{creatingFinish()}}>
                                Ok
                            </Button>
                        </>:
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>

                            {cvFile ?
                                <>
                                <Button variant="success" disabled={!validation || emailValidation === 'invalid'} onClick={()=>{createNewCandidateCV()}}>
                                    Confirm
                                </Button>
                                </>
                                :
                                <Button variant="success" disabled={!validation || emailValidation === 'invalid'} onClick={()=>{createNewCandidate()}}>
                                    Confirm
                                </Button>}
                        </>}
                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return{
        recordsDataRed: state.mainReducer.searchDataRecords,
    }
};

const mapDispatchToProps =  {
    clearDataRecords,
    fetchDataRecordsKeyWord
};

export default connect(mapStateToProps,mapDispatchToProps)(CreateNewCandidateBtn);
