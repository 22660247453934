// MAIN REDUCER
export const CLEAR_RECORDS = 'MAIN/CLEAR_RECORDS';
export const SEARCH_RECORDS = 'MAIN/SEARCH_RECORDS';
export const SEARCH_RECORDS_KEYWORD = 'MAIN/SEARCH_RECORDS_KEYWORD';

//APP REDUCER
export const SHOW_LOADING = 'APP/SHOW_LOADING';
export const HIDE_LOADING = 'APP/HIDE_LOADING';

// LOGIN
export const LOGIN = 'LOGIN/LOGIN';
export const LOGOUT = 'LOGIN/LOGOUT';

// SAVED SEARCH
export const UPDATE_SEARCH = 'SAVED_SEARCH/UPDATE_SEARCH';

//DEMO DATA
export const WITH_DEMO = 'DEMO_DATA/WITH_DEMO';
export const WITHOUT_DEMO = 'DEMO_DATA/WITHOUT_DEMO';

//SIGN IN
export const SIGN_IN = 'SIGN_IN/SIGN_IN';
export const SIGN_OUT = 'SIGN_IN/SIGN_OUT';
