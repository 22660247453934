import React,{ useState,useEffect} from 'react';
import { Modal,Tooltip, OverlayTrigger} from "react-bootstrap";
import { useSelector } from 'react-redux';
//Icons
import { BsCheckBox } from "react-icons/bs";
import { BsPlusSquare } from "react-icons/bs";

const AddCandidateToJob = ({ candidateID, candidateFullName, jobID, jobCandidatesArr, updateJCandidatesSTATE, exceptions }) =>{
    const [ selectedCandidate, setSelectedCandidate ] = useState(false);
    const [ show, setShow ] = useState(false);
    const [ candidateStatus, setCandidateStatus ] = useState(null);
    const currentJob = useSelector((state)=>{
        return state.mainReducer.forJobTitle
    });


    const ComponentDidMount = async ()=>{
        // console.log('ADD CANDIDATE:',candidateID);
        const idx = jobCandidatesArr.findIndex( item => item.candidateID === candidateID);
        // console.log('IDX:',idx);
        if(idx > -1){
            if(jobCandidatesArr[idx].candidateStatus){
                console.log('STATUS fullName:',candidateFullName);
                console.log('STATUS:',jobCandidatesArr[idx].candidateStatus);
                setCandidateStatus(jobCandidatesArr[idx].candidateStatus);
                console.log('STATUS history:',jobCandidatesArr[idx].statusHistory);
            }
            setSelectedCandidate(true);
        }else {
            setSelectedCandidate(false);
        }
    };

    useEffect(async ()=>{
        await ComponentDidMount();
    },[]);

    useEffect(async ()=>{
        await ComponentDidMount();
    },[exceptions,jobCandidatesArr]);

    //FOR MODAL WINDOW
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    //function for add candidate to job dependency
    const addCandidateToJob = async (candidateID,candidateFullName,jobID)=>{
        console.log('candidateID:',candidateID);
        console.log('jobID:',jobID);
        console.log('Add Candidate to Job STATE:',jobCandidatesArr);
        const addCandidateToJobURL = '/api/dependency/addCandidateToJobMongo';

        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                jobId: jobID,
                newCandidate: {candidateID:candidateID,candidateFullName:candidateFullName},
            })
        };

        try{
            const res = await fetch(addCandidateToJobURL, {...reqOptions});
            const json = await res.json();
            console.log('addCandidateToJob JSON:',json);
            if(json.message === "new candidate added"){
                await updateJCandidatesSTATE(jobID);
                await handleClose();
            }
        }catch (e) {
            console.log('addCandidateToJob ERROR');
        }
    };

    //change candidate status "send"
    const sendToCustomer = async (candidateID,jobID)=>{
        console.log('sendToCustomer-candidateID:',candidateID);
        console.log('sendToCustomer-jobID:',jobID);

        const sendToCustomerURL = '/api/dependency/updateCandidateStatus';

        //meta data for status history
        const userID = localStorage.getItem('NRuserID');
        const userName = localStorage.getItem('NRuserName');
        const currentDate = new Date();

        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                jobId: jobID,
                updateCandidate: {candidateID:candidateID, candidateFullName:candidateFullName, candidateStatus:'sent'},
                statusHistoryItem: { modifiedByID:userID, modifiedByName: userName, modifiedData:currentDate, changedTo:'sent' }
            })
        };

        try{
            const res = await fetch(sendToCustomerURL, {...reqOptions});
            const json = await res.json();
            console.log('sendToCustomer JSON:',json);
            if(json.response === "SUCCESS"){
                await updateJCandidatesSTATE(jobID);
                await handleClose();
            }
        }catch (e) {
            console.log('sendToCustomer ERROR');
        }
    };

    //function for remove candidate from job dependency
    const deleteJobsCandidate = async (candidateID,jobID)=>{
        console.log('candidateID:',candidateID);
        console.log('jobID:',jobID);
        console.log('Delete Candidate from Job STATE:',jobCandidatesArr);
        const deleteJobsCandidateURL = `/api/dependency/removeJobsCandidateMongo?jobId=${jobID}&candidateId=${candidateID}`;

        try{
            const res = await fetch( deleteJobsCandidateURL, {method:"delete"});
            const json = await res.json();
            console.log('deleteJobsCandidate Mongo JSON:',json);
            if(json.message === "removed item"){
                // await setSelectedCandidate(!selectedCandidate);
                await updateJCandidatesSTATE(jobID);
                await handleClose();
            }
        }catch (e) {
            console.log('deleteSearchById Mongo ERROR');
        }
    };

    //tooltips
    const addCandidateTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Add Candidate To Job
        </Tooltip>
    );

    const removeCandidateTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Remove Candidate From Job
        </Tooltip>
    );

    return (
        <>
            <div style={{width:"28px", display:"flex",justifyContent:"center"}}>
            <div style={{width:"80%",textAlign:"center"}}>
                {selectedCandidate && candidateStatus ?
                    <BsCheckBox  className="modal-link-green" fontSize={"18px"}
                                 onClick={()=>{handleShow()}}/> :
                    selectedCandidate ?
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 750, hide: 150 }}
                        overlay={removeCandidateTooltip}
                    >
                        <BsCheckBox  className="modal-link-green" fontSize={"18px"}
                                     onClick={()=>{handleShow()}}/>
                    </OverlayTrigger>:
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 650, hide: 150 }}
                        overlay={addCandidateTooltip}
                    >
                        <BsPlusSquare  className="modal-link" fontSize={"15px"}
                                     onClick={()=>{handleShow()}}/>
                    </OverlayTrigger>
                }

            </div>
        </div>

            <Modal show={show} onHide={handleClose} dialogClassName="confirmModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Confirm</h3>
                </Modal.Title>
                <Modal.Body>
                    {selectedCandidate ?
                        <div>
                            Do you want to change status
                            <span style={{fontWeight:"700", color:"#6f6f6f", marginLeft:"4px", marginRight:"4px"}}>{candidateFullName}</span>
                            for job
                            <span style={{fontWeight:"700", color:"#6f6f6f", marginLeft:"4px", marginRight:"4px"}}>{currentJob}</span>?
                        </div> :
                        <div>
                            Do you want to add candidate
                            <span style={{fontWeight:"700", color:"#6f6f6f", marginLeft:"4px", marginRight:"4px"}}>{candidateFullName}</span>
                            to list for job
                            <span style={{fontWeight:"700", color:"#6f6f6f", marginLeft:"4px", marginRight:"4px"}}>{currentJob}</span>?
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        {selectedCandidate && candidateStatus ?
                            <button className="blueFill-btn" style={{marginRight:"4px"}} onClick={()=>{deleteJobsCandidate (candidateID,jobID)}} >
                                Remove from job
                            </button>:
                            selectedCandidate ?
                            <>
                                <button className="blueFill-btn" style={{marginRight:"4px"}} onClick={()=>{deleteJobsCandidate (candidateID,jobID)}} >
                                    Remove from job
                                </button>
                                <button className="greenFill-btn" onClick={()=>{sendToCustomer (candidateID,jobID)}} >
                                    Send to customer
                                </button>
                            </> :
                            <button className="blueFill-btn" onClick={()=>{addCandidateToJob (candidateID,candidateFullName,jobID)}} >
                                Ok
                            </button> }


                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}  >
                            Cancel
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddCandidateToJob;
