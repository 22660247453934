import React, { useState } from "react";
import Select from "react-select";
import getApiName from "../../functions/getFieldsApiName";
import RefreshToken from "../RefreshToken/RefreshToken";

function PickListHook(props) {
    // console.log('pick list MODULE:',props.module);
    const [ saveChanges,setSaveChanges ] = useState(false);
    const [ saveResult,setSaveResult ] = useState(null);

    const rawOptions = props.opt;

    function prepareArrOptions(e) {
        if(typeof e === 'string'){
            return Object.create({}, { value:{value:e},label:{value:e}});
        } else {return Object.create({}, { value:{value:e.content},label:{value:e.content}})}
    }

    function prepareValue(v) {
        if(typeof v === 'string'){
            return Object.create({}, { value:{value:v},label:{value:v}});
        }else if(typeof v === 'boolean'){
            return { value:v,label:`${v}`};
        } else {return null}
    }

    //function for update any field (without "Status")
    const updateRecord = async (name)=>{
        const token = localStorage.getItem('ZohoToken');
        const itemId = `${props.itemId}`;
        const requestSingleURL = `/proxyZohoRecruit/recruit/v2/${props.module}/${itemId}`;

        console.log('update id:',props.itemId);
        console.log('update name:',name);
        console.log('update value:',selectedOpt.selectedOpt.value);

        // let prepareName = name.replace(/ /g,'_');
        let prepareName = await getApiName( props.meta,name );// for update customer field with api_name
        console.log('update PREPARE NAME:',prepareName);

        const reqBodySingle = {
            "data": []
        };

        const reqBody = {[prepareName]: selectedOpt.selectedOpt.value};
        reqBodySingle.data.push(reqBody);

        console.log('reqBodySingle:',reqBodySingle);

        const requestOptions = {method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(reqBodySingle)
        };

        setSaveChanges(false);

        try{
            const res = await fetch(requestSingleURL,{...requestOptions});
            const resJson = await res.json();
            console.log('resJson:',resJson);
            if(resJson.code){
                if(resJson.code === 'INVALID_TOKEN'){
                    console.log('NEED REFRESH NOKEn');
                    setSaveResult(<RefreshToken update={()=>{updateRecord(name)}}/>);
                }
            }else{
                setSaveResult(resJson.data[0].code);
                setTimeout(function() {
                    console.log('clear result');
                    setSaveResult(null);
                }, 4000);
            }
        }catch (e) {
            console.log('fetch update record ERROR:',e);
            setSaveResult('error');
            setTimeout(function() {
                console.log('clear result');
                setSaveResult(null);
            }, 4000);
        }

    };

    const updateStatus = async (name)=>{
        console.log('UPDATE  STATUS:',name);
        console.log('update id:',props.itemId);
        console.log('update name:',name);
        console.log('update value:',selectedOpt.selectedOpt.value);
        console.log('update module:',module);

        let currentModuleURL;
        if(name === 'Job Opening Status'){
            console.log('MODULE url: Job_Openings');
            currentModuleURL = 'Job_Openings';
        }else{
            currentModuleURL = 'Candidates';
        }

        const token = localStorage.getItem('ZohoToken');
        const itemId = `${props.itemId}`;
        const requestSingleURL = `/proxyZohoRecruit/recruit/v2/${currentModuleURL}/status`;

        // let prepareName = name.replace(/ /g,'_');
        let prepareName = await getApiName( props.meta,name );// for update customer field with api_name
        console.log('update PREPARE NAME:',prepareName);

        const reqBodySingle = {
            "data": []
        };

        const reqBody = { "ids":[itemId], [prepareName]:selectedOpt.selectedOpt.value };
        reqBodySingle.data.push(reqBody);

        console.log('reqBodySingle:',reqBodySingle);

        const requestOptions = {method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(reqBodySingle)
        };

        setSaveChanges(false);

        try{
            const res = await fetch(requestSingleURL,{...requestOptions});
            const resJson = await res.json();
            console.log('resJson:',resJson);
            if(resJson.code){
                if(resJson.code === 'INVALID_TOKEN'){
                    console.log('NEED REFRESH NOKEn');
                    setSaveResult(<RefreshToken update={()=>{updateStatus(name)}}/>);
                }
            }else{
                setSaveResult(resJson.data[0].code);
                setTimeout(function() {
                    console.log('clear result');
                    setSaveResult(null);
                }, 4000);
            }
        }catch (e) {
            console.log('fetch update record ERROR:',e);
            setSaveResult('error');
            setTimeout(function() {
                console.log('clear result');
                setSaveResult(null);
            }, 4000);
        }

    };

    const changeInpValue = selectedOpt => {
        setSelectedOpt({ selectedOpt });
        console.log(`Option selected:`, selectedOpt);
        setSaveChanges(true);
    };

    let preOptions;
    if(rawOptions === 'boolean'){
        preOptions = [{value:true, label:'true'},{value:false, label:'false'}]
    }else{
        preOptions = rawOptions.map(e => prepareArrOptions(e));
    }

    const options = preOptions;

    const rawVal = props.value;
    const valDone = prepareValue(rawVal);
    const stateVal = valDone;

    const [selectedOpt, setSelectedOpt] = useState(stateVal);
    // console.log('selectedOpt',selectedOpt);
    return (
        <div className="PickHook">
            <span className="select-label">{props.name}</span>
            <Select
                defaultValue={selectedOpt}
                onChange={changeInpValue}
                options={options}
            />
            {saveChanges &&
                <div>
                    {props.name === 'Job Opening Status' || props.name === 'Candidate Status' ?
                        <span className="displCand-saveChangesBtn" onClick={()=>{updateStatus(props.name)}}>Save Changes</span>:
                        <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(props.name)}}>Save Changes</span>}
                </div>}
            {saveResult != null ? <span className="displCand-saveResult" >saved {saveResult}</span>:<span></span>}
        </div>
    );
}

export default PickListHook;

