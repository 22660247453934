import React from 'react';

const BackUp = () => {
    const createBackupPoint = async ()=>{
        const createBackupPointURL ='api/backup/getAll';

        try{
            const res = await fetch(createBackupPointURL);
            const json = await res.json();
            console.log('createBackupPoint JSON:',json);
        }catch (e) {
            console.log('createBackupPoint ERROR:',e);
        }
    };

    const getDirFiles = async ()=>{
        console.log('getTempFiles');
        const getDirFilesURL ='/api/backup/readDir';
        try {
            const res = await fetch(getDirFilesURL );
            const json = await res.json();
            console.log('getDirFiles JSON:',json);
        }catch (e) {
            console.log('getDirFiles ERROR:',e);
        }
    };

    const readBackupFile = async ()=>{
        console.log('readBackupFile ');
        const readBackupFileURL ='/api/backup/readFile';
        try {
            const res = await fetch(readBackupFileURL);
            const json = await res.json();
            console.log('readBackupFile  JSON:',json);
        }catch (e) {
            console.log('readBackupFile  ERROR:',e);
        }
    };

    return (
        <div>
            <button onClick={()=>{createBackupPoint()}}>backup mongo</button>
            <button onClick={()=>{getDirFiles()}}>backup dir</button>
            <button onClick={()=>{readBackupFile()}}>read BackupFile</button>
        </div>
    );
};

export default BackUp;
