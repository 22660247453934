import React from "react";

const CriteriasTitle = () =>{
    return(
        <div className="criteria-title">
            <div className="criteria-name">
                <span className="criteria-titleSpan">Criteria Name</span>
            </div>
            <div className="criteria-condition">
                <span className="criteria-titleSpan">Condition</span>
            </div>
            <div className="criteria-value">
                <span className="criteria-titleSpan">Criteria Value</span>
            </div>
        </div>
    );
};

export default CriteriasTitle;
