// function for prepare simple object from zoho jobs data
import titleNameReplacer from "../../../functions/titleNameReplacer";
import TableBigTextWrapper from "../../Candidates/TableBigTextWrapper";
import React from "react";

const createSimpleObjectJob = (zohoJobData)=>{
    let simpleObject = {
        is:'Job'
    };

    zohoJobData.forEach(dataItem =>{
        const prepareKeyName = titleNameReplacer(dataItem.val);

        Object.defineProperty(simpleObject,prepareKeyName,{
                value: dataItem.content,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );
    });

    return simpleObject;
};

export default createSimpleObjectJob;
