//functions for prepare download data (from zoho API) to candidates table data
import createSuperObjectJob from "./createSuperObjectJob";

const createTableObjectsJob = (rowsArray, dataItemFields, module, meta) => {
    let superDataArray = [];

    if (Array.isArray(rowsArray)) {
        superDataArray = rowsArray.map(jobOpeningItem => createSuperObjectJob(jobOpeningItem.FL, dataItemFields, module, meta));
    } else {
        let row = createSuperObjectJob(rowsArray.FL, dataItemFields, module, meta);
        superDataArray.push(row);
    }

    return (superDataArray);
};

export default createTableObjectsJob;
