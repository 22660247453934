//function for validation both (all parameters & filter) before send request
import validationCheck from "./validationCheck";
import validationFiltersCheck from "./validationFiltersCheck";

const validationAllCheck = async (word,criteria,filters)=>{
    const validationResult = await validationCheck(word,criteria);
    const validationFiltersResult = await validationFiltersCheck(filters);
    if( validationResult === false || validationFiltersResult === false){
        console.log('ALL VALIDATION:false');
        return false;
    }else {
        console.log('ALL VALIDATION:true');
        return true;
    }
};

export default validationAllCheck;
