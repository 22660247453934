import { SEARCH_RECORDS } from "./actionTypes";
import { SEARCH_RECORDS_KEYWORD } from "./actionTypes";
import { CLEAR_RECORDS } from "./actionTypes";
import { LOGIN,LOGOUT } from "./actionTypes";
import { SHOW_LOADING,HIDE_LOADING } from "./actionTypes";
import { UPDATE_SEARCH } from "./actionTypes";
import { WITH_DEMO, WITHOUT_DEMO } from "./actionTypes";
import { SIGN_IN, SIGN_OUT} from "./actionTypes";

import requestResultFilter from "../../functions/requestResultFilter";
import headerReqZohoAuth from "../../functions/headerReqZohoAuth";
import refreshTokenFunc from "../../functions/refreshTokenFunc";
import roleLabelConverter from "../../functions/roleLabelConverter";

//app reducer (loading)
export function showLoading (){
    return{
        type: SHOW_LOADING
    }
}

export function hideLoading () {
    return{
        type: HIDE_LOADING
    }
}

// main reducer
export function clearDataRecords () {
    return {
        type: CLEAR_RECORDS
    }
}

export function fetchDataRecords (searchUrl, searchTitle = null, searchId = null, forJobTitle = null, forJobId = null){
    return async dispatch => {
        dispatch(showLoading());
        const headersZohoAuth = headerReqZohoAuth();
        console.log('REDUX URL:',searchUrl);
        console.log('REDUX ZOHO AUTH:',headersZohoAuth);
        const response = await fetch(searchUrl,headersZohoAuth);
        const json = await response.json();
        const checkedJson = await refreshTokenFunc(json,searchUrl);
        dispatch({ type:SEARCH_RECORDS , payload:checkedJson , payloadString:searchUrl ,payloadTitle: searchTitle, payloadId:searchId, payloadJobTitle:forJobTitle ,payloadJobId:forJobId });
        dispatch(hideLoading());
    }
}

export function fetchDataRecordsKeyWord (searchUrl, keyWord){
    return async dispatch => {
        dispatch(showLoading());
        const headersZohoAuth = headerReqZohoAuth();
        console.log('REDUX URL:',searchUrl);
        console.log('REDUX ZOHO AUTH:',headersZohoAuth);
        const response = await fetch(searchUrl,headersZohoAuth);
        const json = await response.json();
        console.log('REDUX JSON fetchDataRecordsKeyWord :',json);
        const checkedJson = await refreshTokenFunc(json,searchUrl);
        dispatch({ type:SEARCH_RECORDS_KEYWORD, payload:checkedJson, payloadString:searchUrl, payloadKeyWord:keyWord });
        dispatch(hideLoading());
    }
}

export function fetchDataRecordsFilter(searchUrl, exclusion, searchTitle = null, searchId = null, forJobTitle = null, forJobId = null){
    return async dispatch => {
        dispatch(showLoading());
        const headersZohoAuth = headerReqZohoAuth();

        console.log('REDUX URL:', searchUrl);
        console.log('REDUX ZOHO AUTH:', headersZohoAuth);
        console.log('REDUX EXCLUSION:', exclusion);

        var candidates = null
        var checkedJson = null
        var result = false
        var i = 0
        for (i = 1;; i += 200) {
            var from = i
            var to = i + 200
            const searchUrlWithIndex = searchUrl + `&fromIndex=${from}&toIndex=${to}`
            console.log('REDUX search URL with index:', searchUrlWithIndex)
            const response = await fetch(searchUrlWithIndex, headersZohoAuth)
            let json = await response.json()
            checkedJson = await refreshTokenFunc(json, searchUrlWithIndex)
            var responseCandidatesRow = []
            if (checkedJson.response.result) {
                result = true
                responseCandidatesRow = checkedJson.response.result.Candidates.row;
                console.log('REDUX Candidates row:', responseCandidatesRow)
                if (candidates === null) candidates = checkedJson.response.result.Candidates.row
                else candidates = candidates.concat(responseCandidatesRow)
                console.log('REDUX candidatesRow:', candidates)
            }

            if (checkedJson === null || responseCandidatesRow.length < 200) { break; }
        }

        if (result) {
            const filteredCandidatesRow = await requestResultFilter(candidates, exclusion);
            console.log('REDUX FILTERED ARR:', filteredCandidatesRow);

            checkedJson.response.result.Candidates.row = filteredCandidatesRow
            dispatch({ type:SEARCH_RECORDS, payload:checkedJson, payloadString:searchUrl, payloadTitle: searchTitle, payloadId:searchId, payloadJobTitle:forJobTitle, payloadJobId:forJobId });
        } else {
            dispatch({ type:SEARCH_RECORDS, payload:checkedJson, payloadString:searchUrl, payloadTitle: searchTitle, payloadId:searchId, payloadJobTitle:forJobTitle, payloadJobId:forJobId });
        }
        dispatch(hideLoading());

    }
}

// login reducer
export function logIn() {
    return{
        type: LOGIN
    }
}

export function logOut() {
    return{
        type: LOGOUT
    }
}

// saved search reducer
export function updateSearchArr(newSearchArr){
    return{
        type: UPDATE_SEARCH,
        updateArr:newSearchArr
    }
}

//demo data reducer
export function handleWithDemo() {
    return{
        type: WITH_DEMO
    }
}

export function handleWithOutDemo() {
    return{
        type: WITHOUT_DEMO
    }
}

// sign in reducer
export function signIn(userId, role, firstName, secondName) {
    return dispatch => {
        const label = roleLabelConverter(role);
        dispatch({ type:SIGN_IN, payloadUserId:userId, payloadRole:role, payloadLabel:label, payloadFirstName:firstName ,payloadSecondName:secondName });
    }
}

export function signOut() {
    return{
        type: SIGN_OUT
    }
}
