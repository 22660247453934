import React,{useState,useEffect,useRef} from 'react';
import Select from 'react-select';
//Redux
import { connect } from "react-redux";
import { clearDataRecords } from "../../redux/Actions/actionsCreator";
import { fetchDataRecords} from "../../redux/Actions/actionsCreator";
import { fetchDataRecordsFilter } from "../../redux/Actions/actionsCreator";

const SearchConfigSelect = (props) => {
    // console.log('SELECT TITLE:',props.currentTitle);
    const [ selectedOpt,setSelectedOpt ] = useState();
    const [ title, setTitle ] = useState();

    //for clear select value
    const selectInputRef = useRef();
    const onClear = () => {
        selectInputRef.current.select.clearValue();
        setTitle(null);
    };

    useEffect(()=>{
        if(!props.searchID){
            onClear();
        }
    },[props.searchID]);

    //set title
    useEffect(()=>{
        console.log('CURRENT TITLE:',props.currentTitle);
        setTitle(props.currentTitle);
        },[props.currentTitle]);


    //get records for selected search
    function getRecordsByItem (itemString,itemTitle,itemId,itemFilters){
        props.clearDataRecords();
        if(itemFilters.length > 0){
            console.log('With Filter');
            console.log('FILTER MULTI:',itemFilters);
            props.fetchDataRecordsFilter(itemString,itemFilters,itemTitle,itemId);
        }else{
            console.log('With Out Filter');
            props.fetchDataRecords(itemString,itemTitle,itemId);
        }
    }

    //change search config
    const changeInpValue = selectedOpt => {
        if(selectedOpt){
            console.log('SELECTED OPTIONS (before):',selectedOpt);
            setSelectedOpt({ selectedOpt });
            console.log('Option selected:', selectedOpt);
            getRecordsByItem(selectedOpt.string,selectedOpt.label,selectedOpt.id,selectedOpt.filters);
        }
    };


    return (
        <div style={{width:"40%", marginLeft:"15px", marginBottom:"-34px"}}>
                <Select
                    ref={selectInputRef}
                    // defaultValue={selectedOpt}
                    onChange={changeInpValue}
                    options={props.prepareOptions}
                    placeholder={title ? `${title}`:'Switch Search Config'}
                />
            {/*<button onClick={onClear}>Clear Value</button>*/}
        </div>
    );
};

const mapStateToProps = state => {
    return{
        recordsTitleRed: state.mainReducer.searchTitle,
        searchID:state.mainReducer.searchId
    }
};

const mapDispatchToProps =  {
    clearDataRecords,
    fetchDataRecords,
    fetchDataRecordsFilter
};

export default connect(mapStateToProps,mapDispatchToProps)(SearchConfigSelect);
