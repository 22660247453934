// clear function for create object with criterias (resume,fist name,last name)-use for create advanced search item
function createObjectCreterias(keyWords,allFieldsChek,firstNameChek) {
    let objCriterias = Object.create({},{
        keyWorld:{
            value:keyWords,
            enumerable:true,
            writable:true,
        },
        resume:{
            value:false,
            enumerable:true,
            writable:true,
        },
        lastName:{
            value:false,
            enumerable:true,
            writable:true,
        },
        firstName:{
            value:false,
            enumerable:true,
            writable:true,
        }
    });

    if(keyWords){
        if(keyWords){
            objCriterias.resume = true;
        }
        if(allFieldsChek === true){
            objCriterias.lastName = true;
        }
        if(firstNameChek === true){
            objCriterias.firstName = true;
        }
    }

    console.log('Object Criterias:',objCriterias);
    return objCriterias;
}

export default createObjectCreterias;
