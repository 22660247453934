import {LOGIN,LOGOUT} from "../Actions/actionTypes";

const init = {
    isLogin : false
};

export default function loginReducer( state = init, action ) {
    switch (action.type) {
        case LOGIN:
            return {...state, isLogin : true };

        case LOGOUT:
            return {...state, isLogin : false };
        default: return state
    }
}
