import React, {useEffect, useState} from 'react';
import { Modal, Tooltip, OverlayTrigger, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import Loader from "../../Loader/Loader";
import SelectNaya from "../../SelectNaya/SelectNaya";
//Icons
import { FaRegStar } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import { FaPen } from "react-icons/fa";


const CreateNotesPriority = ({ candidateID, candidateFullName, updateFunc = null , forComponent = 'table' , parentOpen = false ,withTag}) => {
    const [ show, setShow ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ noteText, setNoteText ] = useState('');
    const [ noteTitle, setNoteTitle] = useState('Add Tag');
    const [ isNotes , setIsNotes ] = useState(false);
    const [ isTag , setIsTag ] = useState(false);
    const [ selectedTag, setSelectedTag ] = useState('General');
    const [ selectedRadio, setSelectedRadio ] = useState('General');

    const tagOptions = ['Sent to customer', 'Ready to be sent to customer', 'Passed HR need interview', 'Internal email', 'General', 'College pipe', 'Misc'];

    //for open & close modal window
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //note type
    const noteOptions = [{value:'tag',label:'tag'},{value:'text',label:'text'},{value:'call',label:'call'},{value:'email',label:'email'},{value:'meeting',label:'meeting'}];
    const initVal = noteOptions[0];

    const [selectedOpt, setSelectedOpt] = useState({...initVal});
    const changeInpValue = selectedOpt => {
        setSelectedOpt({ selectedOpt });
        console.log(`Option selected:`, selectedOpt);
    };

    const getSelectedValue = ()=>{
        if(selectedOpt.selectedOpt){
            console.log('getSelectedValue-selectedOpt.selectedOpt.value', selectedOpt.selectedOpt.value);
            return selectedOpt.selectedOpt.value;
        }else{
            console.log('getSelectedValue-selectedOpt.value', selectedOpt.value);
            return selectedOpt.value;
        }
    };

    //check candidate,tag,notes
    const checkTagNotes = async ()=>{
        setIsLoading(true);
        const checkTagNotesURL = `/api/note/checkTagNote?candidateId=${candidateID}`;

        try{
            const res = await fetch(checkTagNotesURL);
            const json = await res.json();
            console.log('checkTagNotes JSON:',json);
            if(json.curTag !== 'no tag' && json.curTag !== null){
                setIsTag(true);
                setNoteTitle('Update Tag');
                setSelectedTag(json.curTag);
                // setSelectedRadio(json.curTag);
            }else {
                setIsTag(false);
                setNoteTitle('Add Tag');
                setSelectedTag('General');
            }
            if(json.curNotes){
                await setIsNotes(true);
            }
        }catch (e) {
            console.log('checkTagNotes ERROR:',e);
        }
        setIsLoading(false);
    };

    useEffect(()=>{
        if(withTag){
            setIsTag(true);
        }
    },[withTag]);

    useEffect(async ()=>{
        if(show){
            await setShowError(false);
            await checkTagNotes();
        }
    },[show]);

    useEffect(async ()=>{
        if(parentOpen){
            console.log('CHECK PARENT');
            await setShowError(false);
            await checkTagNotes();
        }
    },[parentOpen]);

    //chang text fields value
    const changeInputValue = ( event, setInputState )=>{
        event.preventDefault();
        console.log('changeInputValue:',event.target.value);
        setInputState(event.target.value);
    };

    //Create NOTE
    const createNoteBody = ()=>{
        const userID = localStorage.getItem('NRuserID');
        const userName = localStorage.getItem('NRuserName');
        const currentDate = new Date();

        const noteType = getSelectedValue();

        let noteContent;
        if(noteText === ''){
            noteContent = `${noteTitle} to ${selectedTag}`;
        }else{
            noteContent = noteText;
        }

        return({
            candidateId:candidateID,
            candidateFullName:candidateFullName,
            candidateTag:selectedTag,
            createdTime:currentDate,
            userID:userID,
            userName:userName,
            noteType:noteType,
            noteTitle:noteTitle,
            noteContent:noteContent
        });
    };

    const createAddTagUrl = ( isNotes )=>{
        if(isNotes){
            return '/api/note/updateTag';
        }else {
            return '/api/note/createNewMongo';
        }
    };

    const addTag = async () =>{
        console.log('NOTE create new note start');
        setIsLoading(true);
        // const createNoteURL = '/api/note/updateTag'; //for candidates with notes
        // const createNoteURL = '/api/note/createNewMongo'; //for candidates without notes

        const addTagURL = createAddTagUrl(isNotes);
        console.log('NOTE-addTagURL:',addTagURL);

        const noteBody = await createNoteBody();
        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...noteBody})
        };

        try{
            const res = await fetch(addTagURL, reqOptions);
            const json = await res.json();
            console.log('addTag JSON:',json);
            if(json.message === 'success' || json.message ==='New note added to candidate'){
                if(updateFunc){
                    updateFunc();
                }
                await setIsTag(true);
                await handleClose();
            }else {
                setShowError(true);
            }
        }catch (err) {
            console.log('addTag response with ERROR:',err);
            setShowError(true);
        }
        setIsLoading(true);
    };

    const removeTag = async ()=>{
        console.log('removeTag:',candidateID);
        const removeTagURL = `/api/note/removeTag?candidateId=${candidateID}`;

        const noteBody = await createNoteBody();
        const reqOptions = {
            method:"PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...noteBody})
        };

        try{
            const res = await fetch(removeTagURL, reqOptions);
            const json = await  res.json();
            console.log('removeTag JSON:',json);
            if(json.message === 'tag removed'){
                console.log('update component func');
                if(updateFunc){
                    await updateFunc();
                }
                await setIsTag(false);
                await handleClose();
            }else{
                console.log('removeTag ERROR:',json);
                setShowError(true);
            }

        }catch (e) {
            console.log('removeTag ERROR:',e);
            setShowError(true);
        }
    };

    //tooltip
    const putPipeTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Put in Pipe
        </Tooltip>
    );

    //change tag value for select
    const handleChangeTag = (event)=>{
        setSelectedTag(event.target.value);
    };

    //for radio btn
    const isRadioSelected = (value)=> selectedRadio === value;
    //change tag value for radio
    const handelRadioClick = (e)=> {
        console.log('event.cur target.value',e.currentTarget.value);
        setSelectedRadio(e.currentTarget.value);
    };

    return (
        <>
            {forComponent === 'cv' ?
                <span className="blueWhite-Btn" onClick={handleShow}>
                    <OverlayTrigger
                        placement="auto"
                        delay={{ show: 750, hide: 150 }}
                        overlay={putPipeTooltip}>
                        {isTag ? <FaStar/> : <FaRegStar/>}
                    </OverlayTrigger>
                </span>:
                forComponent === 'dashBoard' ?
                    <FaPen onClick={handleShow} className="setTagBtn"/> :
                    <div style={{width:"28px", display:"flex",justifyContent:"center"}}>
                        <div style={{width:"80%",textAlign:"center"}}>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 750, hide: 150 }}
                                overlay={putPipeTooltip}>
                                {isTag ?
                                    <FaStar  className="modal-link" fontSize={"18px"} onClick={handleShow} />
                                    : <FaRegStar  className="modal-link" fontSize={"18px"} onClick={handleShow}/>}
                            </OverlayTrigger>
                        </div>
                    </div>
            }


            <Modal show={show} onHide={handleClose} dialogClassName="notes-addModalMain">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Put in Pipe</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="notesForm-main">
                        <div className="notesForm-form">
                            <div>
                                {isLoading && <div style={{display:"flex",width:"100%",justifyContent:"center"}}><Loader/></div>}
                                {showError && <div style={{display:"flex",width:"100%",color:"red",justifyContent:"center"}}>Error!</div>}
                                <div><span className="notesForm-fieldsTitle">CANDIDATE:</span>{candidateFullName}</div>
                                <div style={{display:"flex"}}>
                                    <div>
                                        {isTag ? <span className="notesForm-fieldsTitle">Change Tag:</span>:<span className="notesForm-fieldsTitle">Set Tag:</span>}
                                    </div>
                                    {/*<div key={'inline-radio'} className="mb-3">*/}

                                    {/*    <Form.Check*/}
                                    {/*        inline*/}
                                    {/*        label="Global"*/}
                                    {/*        name="group1"*/}
                                    {/*        type='radio'*/}
                                    {/*        id='inline-radio-1'*/}
                                    {/*        value='Global'*/}
                                    {/*        checked={isRadioSelected('Global')}*/}
                                    {/*        onChange={handelRadioClick}*/}
                                    {/*    />*/}
                                    {/*    <Form.Check*/}
                                    {/*        inline*/}
                                    {/*        label="Good Junior"*/}
                                    {/*        name="group1"*/}
                                    {/*        type='radio'*/}
                                    {/*        id='inline-radio-2'*/}
                                    {/*        value='Good Junior'*/}
                                    {/*        checked={isRadioSelected('Good Junior')}*/}
                                    {/*        onChange={handelRadioClick}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                                    <label className="sendMail-label">Tag:</label>
                                    <select value={selectedTag} onChange={handleChangeTag} className="tag-Select">
                                        {tagOptions.map( item =><option value={item} key={item}>{item}</option> )}
                                    </select>
                                </div>
                            </div>

                            <div>
                                <span className="notesForm-fieldsTitle">Note:</span>
                            </div>
                            <div className="notesForm-top">
                                <div style={{display:"flex",flexWrap:"wrap"}}>
                                    <label className="sendMail-label">Type:</label>
                                    <Select
                                        defaultValue={selectedOpt}
                                        onChange={changeInpValue}
                                        options={noteOptions}
                                        isDisabled
                                        className="notes isDisable"/>
                                </div>
                                <div>
                                    <label className="sendMail-label">Title:</label>
                                    <input type='text'
                                           className="notesForm-title"
                                           name="sendMail-from"
                                           value={noteTitle}
                                           readOnly={false}
                                           onChange={(e)=>{changeInputValue(e,setNoteTitle)}}/>
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Note:</span>
                                </div>
                                <textarea className="form-control"
                                          value={noteText}
                                          readOnly={false}
                                          onChange={(e)=>{changeInputValue(e,setNoteText)}}
                                ></textarea>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        {isTag && <button className="redFill-btn" style={{marginRight:"210px"}} onClick={()=>{removeTag()}} disabled={isLoading}>Remove from pipe</button>}
                        <button className="blueFill-btn" onClick={()=>{addTag()}} disabled={isLoading}>
                            Save
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose} disabled={isLoading}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateNotesPriority;
