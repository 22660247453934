import React,{ useState,useEffect} from 'react';
import { Modal,Tooltip, OverlayTrigger} from "react-bootstrap";
import { useSelector } from 'react-redux';
//Icons
// import { BsBookmarkDash } from "react-icons/bs";
import { BsDashSquare } from "react-icons/bs";

//tooltips
const ExcludeForSearch = (props) =>(
    <Tooltip id="button-tooltip"  {...props}>
        Exclude from Search
    </Tooltip>
);

const ExcludeCandidateForSearch = ({candidateFullName,candidateID,exceptions,setExceptions}) =>{
    const [ show, setShow ] = useState(false);

    const searchID = useSelector((state)=>{
        return state.mainReducer.searchId
    });
    const currentSearchTitle = useSelector((state)=>{
        return state.mainReducer.searchTitle
    });
    //FOR MODAL WINDOW
    //for open & close modal window
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const excludeCandidateForSearch = async ( candidateID, searchID, except, setExcept ) =>{
        console.log('excludeCandidateForSearch -candidateID:',candidateID);
        console.log('excludeCandidateForSearch -searchID:',searchID);

        const addSearchExceptionURL = '/api/search/addSearchExceptionsMongo';
        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                searchId: searchID,
                newExceptionItem: {candidateID:candidateID,candidateFullName:candidateFullName},
            })
        };
        try{
            console.log('start exc req');
            const res = await fetch(addSearchExceptionURL, {...reqOptions});
            const json = await res.json();
            console.log('addSearchException JSON:',json);
            if(json.message === 'new exception added'){
                setExcept ( except => [...except,candidateID]);
            }
        }catch (e){
            console.log('error edit req:',e);
        }

        await handleClose();
    };

    return (
        <>
            <div style={{width:"28px", display:"flex",justifyContent:"center"}}>
                <div style={{width:"80%",textAlign:"center"}}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 750, hide: 150 }}
                        overlay={ExcludeForSearch}>

                        <BsDashSquare
                            style={{paddingTop:"2px"}}
                            className="modal-link"
                            fontSize={"18px"}
                            onClick={()=>{handleShow()}}/>
                    </OverlayTrigger>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} dialogClassName="confirmModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Confirm</h3>
                </Modal.Title>
                <Modal.Body>
                    <div>
                        Do you want to exclude candidate
                        <span style={{fontWeight:"700", color:"#6f6f6f", marginLeft:"4px", marginRight:"4px"}}>{candidateFullName}</span>
                        for current search
                        <span style={{fontWeight:"700", color:"#6f6f6f", marginLeft:"4px", marginRight:"4px"}}>{currentSearchTitle}</span>?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn" onClick={()=>{excludeCandidateForSearch (candidateID, searchID, exceptions, setExceptions)}} >
                            Remove
                        </button> :

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}  >
                            Cancel
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ExcludeCandidateForSearch;
