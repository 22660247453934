import React from 'react';
import TagTableName from "./TagTableName";
import TagTableNote from "./TagTableNote";
import TagTableDate from "./TagTableDate";
import TagTableLinks from "./TagTableLinks";

const TagTableItem = ({ candidate, updateTable, dataItemFields, metaData }) => {
    const newLine = (string)=>{
        return string.replace(/\n /gi,'<br>');
    };

    let testNotes;
    //candidate 501602000009057006
    if(candidate.candidateId === '501602000009057021'){
        console.log('NOTES CHECK BREAK:',candidate.notes.notes);
        console.log('NOTES CHECK BREAK:',candidate.notes.notes[0].noteContent);
        const textArr = newLine(candidate.notes.notes[0].noteContent);
        console.log('NOTES CHECK ARR:',textArr);
        testNotes = textArr;
    }else{
        testNotes = candidate.notes.notes;
    }

    return (
        <div className="tagTable-listItem">
            <TagTableName candidateID={candidate.candidateId} candidateName={candidate.fullName} dataItemFields={dataItemFields} metaData={metaData}/>
            <TagTableNote notes={candidate.notes}/>
            <TagTableDate addedDate={candidate.addedDate}/>
            <TagTableLinks
                candidateID={candidate.candidateId}
                fullName={candidate.fullName}
                candidateNotes={candidate.notes.notes}
                linkedinProfile={candidate.linkedin}
                updateTable={updateTable}/>
        </div>
    );
};

export default TagTableItem;
