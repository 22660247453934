import React,{useState,useEffect} from 'react';
import { Modal, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
// icons
import { FaLinkedinIn } from "react-icons/fa";
import { FaLinkedin} from "react-icons/fa";//feel


const Linkedin = ({ candidateId, candidateFullName, linkToLinkedin, setLinkedinLink }) => {
    const [ show, setShow ] = useState(false);
    const [ inputValue, setInputValue ] = useState(null);
    const [ validation, setValidation ] = useState(null);
    const [ saving, setSaving ] = useState(false);
    const [ savingResult, setSavingResult ] = useState(null);
    const [ update, setUpdate ] = useState(false);

    const handleInputChange = (event)=>{
        event.preventDefault();
        console.log('linkedin input:',event.target.value);
        setInputValue(event.target.value);

        // const pattern = new RegExp( '((https?:\\/\\/)?((www|\\w\\w)\\.)?linkedin\\.com\\/)((([\\w]{2,3})?)|([^\\/]+\\/(([\\w|\\d-&#?=])+\\/?){1,}))$' );//old pattern
        const pattern = new RegExp('^(http(s)?:\\/\\/)?([\\w]+\\.)?linkedin\\.com\\/(pub|in|profile)');
        if(event.target.value.length > 0){
            if(event.target.value.match(pattern)){
                setValidation('valid');
            }else{
                setValidation('invalid');
            }
        }else{
            setValidation(null);
        }
    };

    useEffect(async ()=>{
        if(show){
            console.log('Linkedin - Component Did Mount function');
            // setLinkToLinkedin('https://www.linkedin.com/in/volodarskyid/');
            // setSaving(true);
            // setSavingResult('SUCCESS');
            if(linkToLinkedin){
                setInputValue(linkToLinkedin);
                const pattern = new RegExp('^(http(s)?:\\/\\/)?([\\w]+\\.)?linkedin\\.com\\/(pub|in|profile)');
                if(linkToLinkedin.match(pattern)){
                    setValidation('valid');
                }else{
                    setValidation('invalid');
                }
            }
        }
    },[show]);

    useEffect(()=>{
        if(show && linkToLinkedin && inputValue){
            if( linkToLinkedin !== inputValue){
                setUpdate(true);
            }else{
                setUpdate(false);
            }
        }
    },[show, linkToLinkedin, inputValue]);

    //FOR MODAL WINDOW
    const windowClose = () => setShow(false);
    const windowShow = () => setShow(true);

    //tooltip
    const toLinkedinTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
          To Linkedin
        </Tooltip>
    );

    const addLinkedinTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Add Linkedin
        </Tooltip>
    );

    const addLinkedin = async ( candidateId, fullName, linkLinkedin )=>{
        setSaving(true);

        const addLinkedinURL = '/api/candidate/addLinkedin';

        const reqOptions = {
            method:"POST",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({candidateId:candidateId, fullName:fullName, linkedin:linkLinkedin})
        };


        try{
            const res = await fetch(addLinkedinURL,{...reqOptions});
            const json = await res.json();
            console.log('addLinkedin JSON:',json);
            if(json.message === 'linkedin saved to mongo DB'){
                setSavingResult('SUCCESS');
                setLinkedinLink(inputValue);
            }else{
                setSavingResult('ERROR');
            }
        }catch (e) {
            console.log('addLinkedin ERROR:',e);
            setSavingResult('ERROR');
        }
    };

    const updateLinkedin = async (candidateId, linkedinValue)=>{
        console.log('candidateId:', candidateId);
        console.log('linkedinValue:', linkedinValue);
        setSaving(true);

        const updateLinkedinURL = `/api/candidate/updateLinkedin?candidateId=${candidateId}`;

        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({linkedinNew:linkedinValue})
        };

        try{
            const res = await fetch(updateLinkedinURL,{...reqOptions});
            const json = await res.json();
            console.log('updateLinkedin JSON:',json);
            if(json.message === 'linkedin updated in mongo DB'){
                setSavingResult('SUCCESS');
                setLinkedinLink(linkedinValue);
            }else{
                setSavingResult('ERROR');
            }
        }catch (e) {
            console.log('updateLinkedin ERROR:',e);
            setSavingResult('ERROR');
        }

    };

    const finishSaving = ()=>{
        setSavingResult(null);
        setSaving(false);
        setShow(false);
    };

    return (
        <>
            {linkToLinkedin ?
                <span className="blueWhite-Btn" onClick={()=>{windowShow()}}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 750, hide: 150 }}
                        overlay={toLinkedinTooltip}>

                            <FaLinkedinIn  fontSize={"11px"}/>
                    </OverlayTrigger>
                </span>
                :
                <span className="greyWhite-Btn" onClick={()=>{windowShow()}}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 750, hide: 150 }}
                        overlay={addLinkedinTooltip}>

                        <FaLinkedinIn fontSize={"15px"}/>
                    </OverlayTrigger>
                </span>
            }

            <Modal show={show} onHide={windowClose} dialogClassName="linkedinModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Linkedin</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="linkedinModal-main">
                        {saving &&
                        <div className="linkedinModal-cheking">
                            {savingResult ?
                                <div >
                                <span className={savingResult === 'SUCCESS' ? 'linkedinModal-success':'linkedinModal-error'}>{savingResult}</span>
                                    <button className="linkedinModal-finishBtn" onClick={()=>{finishSaving()}}>OK</button>
                                </div>:
                                <>
                                <Spinner  animation="grow" size="sm" variant="light"/>
                                <span style={{marginLeft:"4px"}}>Saving</span>
                                </>}

                        </div>}

                        <label className="displCand-label" htmlFor={'LinkToLinkedin'}>Link</label>
                        <input type='text'
                               className="displCand-input"
                               placeholder={'type here link to linkedin'}
                               value={inputValue}
                               name={'LinkToLinkedin'}
                               maxLength={60}
                               key={'LinkToLinkedin'}
                               readOnly={false}
                               onChange={handleInputChange}
                        />
                        { validation === 'invalid' && <div style={{color:"red",fontSize:"10px",textAlign:"center"}}>Invalid value</div>}

                        {!linkToLinkedin &&
                            <div className="linkedinModal-note">
                                *before add link to candidate, please make sure, that link refers to current candidate. Use
                                <span className="linkedinModal-noteBold">"View Profile"</span> for it.
                            </div>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <div className="sendMail-btn">
                        <a target="_blank" href={inputValue} style={{marginRight:"4px"}}>
                            <button className="blueFill-btn" disabled={validation === 'invalid' || validation === null || saving}>
                                View Profile
                            </button>
                        </a>

                        {linkToLinkedin ?
                            <button
                                className="orangeFill-btn"
                                onClick={()=>{updateLinkedin(candidateId, inputValue)}}
                                disabled={validation === 'invalid' || validation === null || saving || !update}>
                                Update Link
                            </button>:
                            <button
                                className="greenFill-btn"
                                onClick={()=>{addLinkedin(candidateId, candidateFullName, inputValue)}}
                                disabled={validation === 'invalid' || validation === null || saving}>
                                Add Link
                            </button>
                        }

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={()=>{windowClose()}} disabled={saving}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Linkedin;
