
const requestResultFilter = async (row, exclusion)=>{
    console.log('REQUEST FILTER');
    console.log('REDUX request-filter EXCL:', exclusion);

    let globalFilteredArray = [];

    const getArrayByExclusion = (row, exclusionItem)=>{
        console.log('REDUX ArrayByExclusion EXC ITEM:', exclusionItem);

        let filteredArray = [];

        row.forEach( item =>{
//            console.log('REDUX EXC ITEM-criteriaName.value:', exclusionItem.criteriaName.value);
            const idx = item.FL.findIndex( e => e.val === exclusionItem.criteriaName.value );
//            console.log('REDUX IDX:', idx);
            if(idx > -1){
                // console.log('REDUX criteria found');
                let check = true;
                exclusionItem.criteriaValue.forEach( filter =>{
//                    console.log('!!!REDUX CHECK filter:',filter.value);
//                    console.log('!!!REDUX CHECK content:',item.FL[idx].content);
                    if( item.FL[idx].content.toString().includes(`${filter.value}`) ){
//                        console.log('!!!TRUE REDUX CHECK filter:',filter.value);
//                        console.log('!!!TRUE REDUX CHECK content:',item.FL[idx].content);
                        // console.log('REDUX CHECK filter.value:',filter.value);
                        // console.log('REDUX CHECK item:',item);
                        // console.log('REDUX CHECK item.FL[i]cont:',item.FL[idx].content);
                        check = false;
                    }
                });

                if(check === true){
                    filteredArray.push(item);
                }

            }else{
                // console.log('REDUX criteria not found');
                filteredArray.push(item);
            }
        });

        console.log('REDUX filteredArray:',filteredArray);
        return filteredArray;
    };

    //filter "AND"
    const filterAND = (prevArr,curArr)=>{
        console.log('REDUX prevArr:',prevArr);
        console.log('REDUX curArr:',curArr);
        let mergeGlobalArray = [];

        if(!prevArr){
            prevArr = curArr;
        }

        let mergeFilterArr = curArr.map( curArrItem =>{
            const testItem = prevArr.filter( i =>{
                if(i === curArrItem){
                    return true;
                }
            } );

            if(testItem[0]) {
                mergeGlobalArray.push(testItem[0]);
            }

        });

        console.log('REDUX mergeFilterArr:',mergeFilterArr);
        console.log('REDUX mergeGLOBAL:',mergeGlobalArray);
        return mergeGlobalArray;
    };

    const filterOR = async (prevArr,curArr)=>{
        //filter unique data items
        const arrayUnique = async (array) =>{
            let a = array.concat();
            for(let i=0; i<a.length; ++i) {
                for(let j=i+1; j<a.length; ++j) {
                    if(a[i] === a[j])
                        a.splice(j--, 1);
                }
            }

            return a;
        };

        const mergeGlobalArray = await arrayUnique(prevArr,curArr);
        console.log('REDUX mergeGLOBAL:',mergeGlobalArray);
        return  mergeGlobalArray;

    };

    exclusion.forEach( excl =>{
        // globalFilteredArray.push(excl.criteriaWith.value);
        const arr = getArrayByExclusion(row,excl);
        console.log('REDUX Arr(filtered):',arr);
        if(excl.criteriaWith.value === 'AND'){
            console.log('REDUX with:',excl.criteriaWith.value);
            console.log('REDUX global:',globalFilteredArray);
            const mergeForGlobal = filterAND(globalFilteredArray[0],arr);

            globalFilteredArray[0] = mergeForGlobal;
        }else if(excl.criteriaWith.value === 'OR'){
            console.log('REDUX with:',excl.criteriaWith.value);
            console.log('REDUX global:',globalFilteredArray);
            const mergeForGlobal = filterOR(globalFilteredArray[0],arr);

        }else{
            globalFilteredArray.push(arr);
        }
        // globalFilteredArray.push(arr);
    });

    console.log('REDUX GLOBAL ARR:',globalFilteredArray);

    return globalFilteredArray[0];
};

export default requestResultFilter;
