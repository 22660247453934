import React,{useState,useEffect} from "react";
import { useHistory } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
//REDUX
import { connect } from "react-redux";
import { logIn } from "../../redux/Actions/actionsCreator";
import { logOut } from "../../redux/Actions/actionsCreator";

const RefreshTokenLine = (props)=>{
    // console.log('REFRESH props update:',props.update);
    const [ refreshing,setRefreshing ] = useState(false);
    let history = useHistory();

    const logOutRefresh = ()=>{
        localStorage.removeItem('ZohoToken');
        localStorage.removeItem('ZohoRefToken');
        localStorage.removeItem('NRuserID');
        localStorage.removeItem('NRuserName');
        localStorage.removeItem('NRuserRole');
        props.logOut();
        history.push('/');
    };

    const getFreshToken = async ()=>{
        const refToken = localStorage.getItem('ZohoRefToken');
        console.log('REF TOKEN:',refToken);

        setRefreshing(true);

        const getFreshTokenUrl ='/api/zohoservice/refreshToken';
        const requestOptions = {
            method: 'POST',
            headers : {'Content-Type': 'application/json'},
            body : JSON.stringify({
                refresh_token: refToken
            }),
        };

        try{
            const res = await fetch(getFreshTokenUrl, {...requestOptions});
            const json = await res.json();
            console.log('getNewTokenServer JSON',json);
            if(json.newAccToken.error){
                logOutRefresh();
            }else{
                localStorage.setItem('ZohoToken',json.newAccToken.access_token);
                setRefreshing(false);
                props.update();
                // history.push("/");
            }
        }catch (e) {
            console.log('fetch getTokenServer error:',e)
        }

    };

    //new refresh token version
    useEffect(async ()=>{
        await getFreshToken();
    },[]);

    return(
        <>
            {/*new refresh token version*/}
            { refreshing &&
            <span style={{color:"blue"}}>
                <span style={{marginLeft:"8px",color:"#316fc2"}}><Spinner animation="grow" size="sm" variant="primary"/>Refresh token,please wait</span>
            </span>}
        </>

    );
};

const mapStateToProps = state => {
    return{
        isLogin: state.loginReducer.isLogin
    }
};

const mapDispatchToProps = {
    logIn,
    logOut
};

export default connect(mapStateToProps,mapDispatchToProps)(RefreshTokenLine);
