import React from 'react';
import { useLocation } from 'react-router-dom';
import { Route,Switch } from 'react-router-dom';
import './App.css';
import Header from "./Components/Header/Header";
import MainFunc from "./Components/Main/MainFunc";
import DisplayItem from "./Components/DisplayItem/DisplayItem";
import AdvancedSearch from "./Components/AdvacedSearch/AdvancedSearch";
import ShouldLogin from "./Components/ShouldLogin/ShouldLogin";
import JobOpenings from "./Components/JobOpenings/JobOpenings";
import CreateNewCandidate from "./Components/CreateNewCandidate/CreateNewCandidate";
import CreateJobOpening from "./Components/CreateJobOpening/CreateJobOpening";
import SearchMain from "./Components/SearchMain/SearchMain";
import Email from "./Components/Email/Email";
import ZohoTest from "./Components/Zoho/Zoho";
import DashBoard from "./Components/DashBoard/DashBoard";
import BackUpBoard from "./Components/BackUpBoard/BackUpBoard";
import ResetPasswordPage from "./Components/ResetPasswordPage/ResetPasswordPage";
import AdminPanel from "./Components/AdminPanel/AdminPanel";
import TempFiles from "./Components/TempFiles/TempFiles";
import PrivateRoute from "./Components/hoc/PrivateRoute";
import ProtectedRoute from "./Components/hoc/ProtectedRoute";
// REDUX
import { connect } from "react-redux";
import { logIn } from "./redux/Actions/actionsCreator";
import { logOut } from "./redux/Actions/actionsCreator";


function App(props) {

    //new system get zoho token start
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let queryParams = useQuery();
    let zohoCode = queryParams.get("code");

    const getTokenServer = async (zohoCode) =>{
        console.log('zoho code in function:',zohoCode);

        const getTokenUrl ='/api/zohoservice/auth'; //dev new
        // const getTokenUrl ='https://accounts.zoho.com/oauth/v2/token';//production
        const requestOptions = {
            method: 'POST',
            headers : {'Content-Type': 'application/json'},
            body : JSON.stringify({
                grant_type: 'authorization_code',
                client_id:'1000.UL0H86CHZWXRFECM2UW8K73BJGT2WN',
                client_secret:'f5b32a283a2c716ced04c7283571735235e3ef3fbe',
                redirect_uri:'https://naya-cv.link',
                code:zohoCode}),
            };

        try{
            const res = await fetch(getTokenUrl, {...requestOptions});
            const json = await res.json();
            console.log('getTokenServer JSON',json);
            if(!json.accToken.error){
                localStorage.setItem('ZohoToken',json.accToken.access_token);
                localStorage.setItem('ZohoRefToken',json.accToken.refresh_token);
                localStorage.setItem('NRuserID','501602000000225003');
                localStorage.setItem('NRuserName','Yoni');
                localStorage.setItem('NRuserRole','Owner');
                props.logIn();
            }
        }catch (e) {
            console.log('fetch getTokenServer error:',e)
        }
    };

    if(zohoCode){
        getTokenServer(zohoCode);
    }
    //new system get zoho token finish

    let curToken = localStorage.getItem('ZohoToken');
    if (curToken){
        props.logIn();
    }

    console.log('APP role:',props.roleRedux);

    return (
    <div className="App">
        <Header />
        <Switch>
            { props.isLogin ? <Route path='/' exact  component={SearchMain} /> : <Route path='/' exact  component={ShouldLogin} />}
            {/*<Route path='/candidates' component={MainFunc}/>*/}
            {/*<Route path='/jobOpenings' component={JobOpenings}/>*/}
            {/*<Route path='/advancedSearch' component={AdvancedSearch}/>*/}
            {/*<Route path='/candidate' component={DisplayItem} />*/}
            {/*<Route path='/job' component={DisplayItem} />*/}
            {/*<Route path='/createJob' component={CreateJobOpening} />*/}
            {/*<Route path='/createCandidate' component={CreateNewCandidate} />*/}
            {/*/!*<Route path='/email' component={Email} />*!/*/}
            {/*/!*<Route path='/testPanel' component={ZohoTest} />*!/*/}
            {/*<Route path='/dashboard' component={DashBoard} />*/}
            {/*<Route path='/backupBoard' component={BackUpBoard} />*/}
            {/*<Route path='/resetPassword' component={ResetPasswordPage} />*/}
            {/*<Route path='/adminPanel' component={AdminPanel} />*/}

            {props.roleRedux && <ProtectedRoute path='/candidates' role={props.roleRedux} permission={['User','recruiter_hr','naya_manager','naya_rt_admin']} component={MainFunc}/>}

            {props.roleRedux && <ProtectedRoute path='/jobOpenings' role={props.roleRedux} permission={['naya_manager','naya_rt_admin']} component={JobOpenings}/>}
            {props.roleRedux && <ProtectedRoute path='/advancedSearch' role={props.roleRedux} permission={['User','recruiter_hr','naya_manager','naya_rt_admin']} component={AdvancedSearch}/>}

            {props.roleRedux && <ProtectedRoute path='/candidate' role={props.roleRedux} permission={['naya_manager','naya_rt_admin']} component={DisplayItem}/>}
            {props.roleRedux && <ProtectedRoute path='/job' role={props.roleRedux} permission={['naya_manager','naya_rt_admin']} component={DisplayItem}/>}
            {props.roleRedux && <ProtectedRoute path='/createJob' role={props.roleRedux} permission={['naya_manager','naya_rt_admin']} component={CreateJobOpening}/>}
            {props.roleRedux && <ProtectedRoute path='/createCandidate' role={props.roleRedux} permission={['recruiter_hr','naya_manager','naya_rt_admin']} component={CreateNewCandidate}/>}

            {props.roleRedux && <ProtectedRoute path='/dashboard' role={props.roleRedux} permission={['naya_manager','naya_rt_admin']} component={DashBoard}/>}
            {props.roleRedux && <ProtectedRoute path='/backupBoard' role={props.roleRedux} permission={['naya_rt_admin']} component={BackUpBoard}/>}
            {props.roleRedux && <ProtectedRoute exact path='/adminPanel' role={props.roleRedux} permission={['naya_rt_admin']} component={AdminPanel}/>}
            <Route path='/resetPassword' component={ResetPasswordPage} />

           <Route path='*' exact render={()=>(<h3 style={{color:"red"}}>Not Found 404</h3>)} />
        </Switch>
        {/*<TempFiles/>*/}
    </div>
  );
}

// LOGIN WITH REDUX
const mapStateToProps = state => {
    return{
        isLogin: state.loginReducer.isLogin,
        roleRedux: state.signinReducer.role,
    }
};

const mapDispatchToProps = {
    logIn,
    logOut,
};

export default connect(mapStateToProps,mapDispatchToProps)(App);
