import React, {useEffect, useState} from 'react';
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaPen } from "react-icons/fa";
import Loader from "../Loader/Loader";
import './adminPanel.css';

const SetUserRole = ({userID,currentRole,roleOptions,setRole,firstName,secondName}) => {
    const [ show, setShow ] = useState(false);
    const [ selectRole, setSelectRole ] = useState(currentRole);
    const [ isSending, setIsSending ] = useState(false);
    const [ result, setResult ] = useState(null);

    const handleChange = (event)=>{
        setSelectRole(event.target.value);
    };

    const updateRole = async (userID, selectedRole)=>{
        console.log('Selected user id:',userID);
        console.log('Selected Role:',selectedRole);
        setIsSending(true);
        const singUpRequestURL = '/api/user/updateRole';

        const reqOptions = {
            method:"POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({userID,selectedRole})
        };

        try {
            const res = await fetch(singUpRequestURL,reqOptions);
            const json = await res.json();
            console.log('singInRequest JSON:',json);
            if(json.response === 'SUCCESS'){
                setResult(json.response);
                setRole(selectedRole);
            }else{
                setResult(json.response);
            }
        }catch (e) {
            console.log('singInRequest ERROR:',e);
            setResult('ERROR');
        }
        setIsSending(false);
    };

    //FOR MODAL WINDOW
    const handleClose = () => {
        setResult(null);
        setShow(false);
    };
    const handleShow = () => setShow(true);

    //tooltip
    const setRoleTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Change role
        </Tooltip>
    );

    return (
        <>
            <OverlayTrigger
                placement="auto"
                delay={{ show: 750, hide: 150 }}
                overlay={setRoleTooltip}>
                <span className="modal-link" onClick={handleShow}><FaPen/></span>
            </OverlayTrigger>
            <Modal show={show} onHide={handleClose} dialogClassName="setRole-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start", background:"#2b066b"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Set Role</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="setRole-main">
                        <div className="setRole-userName">
                            <span>{firstName}</span>
                            <span style={{marginLeft:"4px"}}>{secondName}</span>
                        </div>
                        <div>
                            <select value={selectRole} onChange={handleChange}>
                                {roleOptions.map( item =><option value={item.value} key={item.value}>{item.label}</option> )}
                            </select>
                        </div>
                    </div>

                    {isSending && <Loader/>}

                    {result &&
                        <div style={{textAlign:"center"}}>
                            {result === 'SUCCESS' ? <span style={{color:"#33e426"}}>{result}</span>:<span style={{color:"#e60000"}}>{result}</span>}
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn"
                                onClick={()=>{updateRole(userID,selectRole)}}
                                disabled={isSending || result}>
                            Save
                        </button>
                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SetUserRole;
