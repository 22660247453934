import React,{useState} from 'react';
import {Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import RemoveItemList from "../../buttons/RemoveItemList";
import './candidateItemParser.css';

const SelectedCandidatesItem = ({ selectedCandidate, jobID, getJobCandidates }) => {
    const [ isSending, setIsSending ] = useState(false);

    //change candidate status "send"
    const sendToCustomer = async (candidateID,candidateFullName,jobID,updateFunc)=>{
        setIsSending(true);
        console.log('sendToCustomer-candidateID:',candidateID);
        console.log('sendToCustomer-candidateFullName:',candidateFullName);
        console.log('sendToCustomer-jobID:',jobID);

        const sendToCustomerURL = '/api/dependency/updateCandidateStatus';

        //meta data for status history
        const userID = localStorage.getItem('NRuserID');
        const userName = localStorage.getItem('NRuserName');
        const currentDate = new Date();

        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                jobId: jobID,
                updateCandidate: {candidateID:candidateID, candidateFullName:candidateFullName, candidateStatus:'sent'},
                statusHistoryItem: { modifiedByID:userID, modifiedByName: userName, modifiedData:currentDate, changedTo:'sent' }
            })
        };

        try{
            const res = await fetch(sendToCustomerURL, {...reqOptions});
            const json = await res.json();
            console.log('sendToCustomer JSON:',json);
            if(json.response === "SUCCESS"){
                await updateFunc(jobID);
            }
        }catch (e) {
            console.log('sendToCustomer ERROR');
        }
        setIsSending(false);
    };

    return (
        <div className="candidateItemParser-candidateItem">
            <RemoveItemList
                jobID={jobID}
                candidateID={selectedCandidate.candidateID}
                candidateFullName={selectedCandidate.candidateFullName}
                updateFunc={getJobCandidates}
                removeFrom={'CandidateList'}
            />
            <Link to={{pathname:'/candidate',search:`id=${selectedCandidate.candidateID}&title=${selectedCandidate.candidateFullName}`}}
                  className="candidateItemParser-candidateTitle">
                {selectedCandidate.candidateFullName}
            </Link>

            <button className="candidateItemParser-actionBtn" onClick={()=>{sendToCustomer(selectedCandidate.candidateID,selectedCandidate.candidateFullName,jobID,getJobCandidates)}}>{isSending ? <Spinner animation="border" size="sm" variant="light" style={{marginRight:"10px",marginLeft:"10px"}}/> : <span>SENT</span>}</button>

        </div>
    );
};

export default SelectedCandidatesItem;
