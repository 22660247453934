import React, {useState} from 'react';
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { RiCloseCircleLine } from "react-icons/ri";
import Loader from "../Loader/Loader";
import './adminPanel.css';

const DeleteUser = ({userID, firstName,secondName,updateParent}) => {
    const [ show, setShow ] = useState(false);
    const [ isSending, setIsSending ] = useState(false);
    const [ result, setResult ] = useState(null);

    //FOR MODAL WINDOW
    const handleClose = () => {
        setResult(null);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const deleteUser = async (userID,updateArr)=>{
        console.log('Selected user id:',userID);
        setIsSending(true);
        const deleteUserURL = `/api/user/delete?id=${userID}`;

        const reqOptions = {
            method:"DELETE",
            headers: {'Content-Type': 'application/json'},
        };

        try {
            const res = await fetch(deleteUserURL,reqOptions);
            const json = await res.json();
            console.log('delete user JSON:',json);
            if(json.response === 'SUCCESS'){
                setResult(json.response);
                await updateArr();
            }else{
                setResult(json.response);
            }
        }catch (e) {
            console.log('delete user ERROR:',e);
            setResult('ERROR');
        }
        setIsSending(false);
    };

    //tooltip
    const deleteUserTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Delete user
        </Tooltip>
    );

    return (
        <>
            <OverlayTrigger
                placement="auto"
                delay={{ show: 750, hide: 150 }}
                overlay={deleteUserTooltip}>
                <span className="modal-link" style={{marginLeft:"6px"}} onClick={handleShow}><RiCloseCircleLine/></span>
            </OverlayTrigger>
            <Modal show={show} onHide={handleClose} dialogClassName="setRole-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start", background:"#2b066b"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Delete User</h3>
                </Modal.Title>
                <Modal.Body>
                    <div>Do you want delete?</div>
                    <div className="setRole-main">
                        <div className="setRole-userName">
                            <span>{firstName}</span>
                            <span style={{marginLeft:"4px"}}>{secondName}</span>
                        </div>
                    </div>

                    {isSending && <Loader/>}

                    {result &&
                        <div style={{textAlign:"center"}}>
                            {result === 'SUCCESS' ? <span style={{color:"#33e426"}}>{result}</span>:<span style={{color:"#e60000"}}>{result}</span>}
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn"
                                onClick={()=>{deleteUser(userID,updateParent)}}
                                disabled={isSending || result}>
                           Delete
                        </button>
                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteUser;
