import React,{ useState,useEffect} from 'react';
import CreateNewCandidateBtn from "./CreateNewCandidateBtn";
import TextAreaInput from "./TextAreaInput";
import { FaCheckCircle } from "react-icons/fa";
import TempFiles from "../TempFiles/TempFiles";
import './createCandidate.css';
import {Form} from "react-bootstrap";


const WrapperFieldBuilderCand = ({jobFields}) => {
    console.log('WrapperFieldBuilderJob-JOB_FIELDS:',jobFields);
    const [ selectedFields,setSelectedFields ] = useState();
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ emailValidation, setEmailValidation ] = useState(null);
    const [ company, setCompany ] = useState('');
    const [ uploadFile, setUploadFile ] = useState(null);
    const [ cvEvaluation, setCvEvaluation ] = useState('');
    const [ phoneEvaluation, setPhoneEvaluation ] = useState('');
    const [ collegeRecommended, setCollegeRecommended ] = useState(false);

    const chooseFields =['Candidate Status'];

    const getFilteredFields = (fieldsArray,chooseFields)=>{
        let filteredArray = [];
        fieldsArray.section.forEach (section =>{
            section.FL.map( sectionItem =>{
                chooseFields.forEach(chooseItem =>{
                    if(chooseItem === sectionItem.dv){
                        filteredArray.push(sectionItem)
                    }
                })
            });
        });
        return filteredArray;
    };

    useEffect(async ()=>{
        const arr = await getFilteredFields(jobFields,chooseFields);
        console.log('ARRAY:',arr);
        setSelectedFields(arr)
    },[]);

    //change input value function
    const handleChangeInput = (event, setStateFunction)=>{
        event.preventDefault();
        setStateFunction(event.target.value);
    };

    //change input value function with validation for e-mail
    const changeInpValueEmail = (event)=>{
        event.preventDefault();
        setEmail(event.target.value);
        const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,4}$/;

        if(event.target.value.length > 0){
            if(event.target.value.match(pattern)){
                setEmailValidation('valid');
            }else{
                setEmailValidation('invalid');
            }
        }else{
            setEmailValidation(null);
        }
    };

    const selectUploadFile = async (event)=>{
        event.preventDefault();
        console.log('uploadCV-EVENT:',event);
        console.log('uploadCV-EVENT:',event.target.files[0]);
        setUploadFile(event.target.files[0]);
    };

    const handleCheckBox = (curState, setCurState )=>{
        setCurState(!curState);
    };

    return (
        <div>
            <h4 style={{color:"#afafaf"}}>Please, fill this fields for create new candidate</h4>

            {selectedFields &&
                <div>
                    <div>
                        <label className="displCand-label" htmlFor={'postingTitle'}>First Name *</label>
                        <input type='text'
                               className="displCand-input"
                               value={firstName}
                               id={'postingTitle'}
                               name={'postingTitle'}
                               readOnly={false}
                               onChange={ (event)=>{handleChangeInput(event,setFirstName)} }
                        />
                    </div>

                    <div>
                        <label className="displCand-label" htmlFor={'departmentName'}>Last Name *</label>
                        <input type='text'
                               className="displCand-input"
                               value={lastName}
                               id={'departmentName'}
                               name={'departmentName'}
                               readOnly={false}
                               onChange={ (event)=>{handleChangeInput(event,setLastName)} }
                        />
                    </div>

                    <div>
                        <label className="displCand-label" htmlFor={'departmentName'}>Email</label>
                        <input type='email'
                               className="displCand-input"
                               value={email}
                               id={'email'}
                               name={'email'}
                               readOnly={false}
                               onChange={ changeInpValueEmail }
                        />
                        {emailValidation === 'invalid' && <span className="displCand-invalidValue">Invalid value</span>}
                    </div>

                    <div>
                        <label className="displCand-label" htmlFor={'departmentName'}>Company</label>
                        <input type='text'
                               className="displCand-input"
                               value={company}
                               id={'company'}
                               name={'company'}
                               readOnly={false}
                               onChange={ (event)=>{handleChangeInput(event,setCompany)} }
                        />
                    </div>

                    <div className="createCandidate-main">
                        <label className="displCand-label uploadFile-input" htmlFor={'uploadCV'}>Upload CV</label>
                        <input type="file"
                               className="createCandidate-inputFile"
                               id="uploadCV"
                               accept=".doc,.docx,.pdf"
                               onChange={(e)=>{selectUploadFile(e)}}/>

                               { uploadFile ?
                                <FaCheckCircle className="createCandidate-withCv"/>:
                                <FaCheckCircle className="createCandidate-withOut"/>  }
                    </div>
                    <TextAreaInput label={'CV Evaluation'} parentState={cvEvaluation} setParentState={setCvEvaluation}/>

                    <TextAreaInput label={'Phone Call Evaluation'} parentState={phoneEvaluation} setParentState={setPhoneEvaluation}/>

                    <div style={{marginTop:"22px"}}>
                        <Form.Check
                            checked={ collegeRecommended }
                            inline
                            label="College Recommended"
                            type={'checkbox'}
                            id={'recommendedCheck'}
                            onChange={()=>{handleCheckBox(collegeRecommended, setCollegeRecommended)}}/>
                        {collegeRecommended && <span style={{color:"grey",fontStyle:"italic"}}>Put in college pipe as well</span>}
                    </div>

                    <div style={{fontSize:"14px",fontStyle:"italic",color:"#c4c5c6"}}>*-mandatory fields</div>
                    <CreateNewCandidateBtn
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        emailValidation={emailValidation}
                        company={company}
                        cvFile={uploadFile}
                        cvEvaluation={cvEvaluation}
                        phoneEvaluation={phoneEvaluation}
                        collegeRecommended={collegeRecommended}/>
                </div>
            }
        </div>
    );
};

export default WrapperFieldBuilderCand;
