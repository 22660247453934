
let headerReq = () => {
    let token = localStorage.getItem('ZohoToken');
    return(
        {method: 'get',
            headers: {
             'Authorization': `Bearer ${token}`,
             'Content-Type': 'application/json;charset=utf-8'
            },
            cache: "no-cache"}
    );

};

export default  headerReq;
