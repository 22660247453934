import React from "react";
import {handleWithDemo, handleWithOutDemo, updateSearchArr} from "../../redux/Actions/actionsCreator";
import {connect} from "react-redux";
import saveSearchesData from "../../mockUp/dataSaveSearches";


const  DemoData = (props) =>{

    const downloadDemoData = () => {
        props.updateSearchArr(saveSearchesData);
        props.handleWithDemo();
    };

    return(
        <div style={{textAlign:"center",marginTop:"20px"}}>
            {/*<div>{props.isDemo ? <span>true demo</span> : <span>false demo</span>}</div>*/}
            <p style={{color:"red",fontSize:"20px",marginBottom:"5px"}}>Server whit saved searches items is not available now</p>
            <p style={{color:"red",fontSize:"20px"}}>You can try with demo data</p>
            <button onClick={downloadDemoData}>Download demo</button>
            {/*<button onClick={props.handleWithOutDemo}>with out demo</button>*/}
        </div>
    );
};

const mapStateToProps = state => {
    return{
        isDemo: state.demoReducer.isDemo
    }
};

const mapDispatchToProps =  {
    handleWithDemo,
    handleWithOutDemo,
    updateSearchArr
};

export default connect(mapStateToProps,mapDispatchToProps)(DemoData);

