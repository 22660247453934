import React from 'react';
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";

const NoteDateParser = ({noteDate}) =>{
    const prepareDate = new Date(noteDate);
    return (
        <div className="notes-dateParser">
            <div className="notes-dateItem">
                <FaRegCalendarAlt/>
                <div style={{marginLeft:"3px"}}>{prepareDate.toLocaleDateString()}</div>
            </div>
            <div className="notes-dateItem" style={{marginLeft:"8px", marginRight:"5px"}}>
                <FaRegClock/>
                <div style={{marginLeft:"3px"}}>{prepareDate.toLocaleTimeString()}</div>
            </div>
        </div>
    );
};

export default NoteDateParser;
