import React,{useState,useEffect} from 'react';

const TableRow = ({jobItem,idx}) => {
    // console.log(`JOB ITEM ${idx}:`,jobItem);
    const[ selectedCandidates,setSelectedCandidates ] = useState([]);
    const[ sentCandidates,setSentCandidates ] = useState([]);

    const separateCandidates = (allSelected)=>{
        let selectedArr = [];
        let sentArr = [];

        allSelected.map( candidateItem =>{
            if(candidateItem.candidateStatus){
                if(candidateItem.candidateStatus === 'sent'){
                    sentArr.push(candidateItem);
                }else {
                    selectedArr.push(candidateItem);
                }

            }
        });

        setSentCandidates(sentArr);
    };

    useEffect(async ()=>{
        await separateCandidates(jobItem.candidatesForJob);
    },[]);

    useEffect(async ()=>{
        await separateCandidates(jobItem.candidatesForJob);
    },[jobItem]);

    return (
        <div className="BDTable-itemMain">
            <div className="BDTable-top-title">{jobItem.itemTitle}</div>
            <div className="BDTable-top-column">{jobItem.jobExceptions.length}</div>
            <div className="BDTable-top-column">{jobItem.candidatesForJob.length}</div>
            <div className="BDTable-top-column">{sentCandidates.length}</div>
        </div>
    );
};

export default TableRow;
