import React, {useState} from 'react';
import {Tooltip, OverlayTrigger, Modal} from "react-bootstrap";
import { RiCloseCircleLine } from "react-icons/ri";

const RemoveItemList = ({jobID, candidateID, candidateFullName, updateFunc, removeFrom}) =>{
    const [ show, setShow ] = useState(false);

    //FOR MODAL WINDOW
    //for open & close modal window
    const handleClose = () => setShow(false);
    const handleShow = () => {
        console.log('close pop up');
        setShow(true);
    };

    // function for remove candidate from selected list for this job
    const removeItem = async ( jobId, candidateId, updateFunc )=>{
        console.log('jobId:',jobId);
        console.log('candidateID:',candidateId);
        console.log('removeFrom:',removeFrom);

        let removeItemURL;
        if(removeFrom === 'CandidateList'){
            removeItemURL = `/api/dependency/removeJobsCandidateMongo?jobId=${jobId}&candidateId=${candidateId}`;
        }else if (removeFrom === 'JobException'){
            removeItemURL = `/api/dependency/removeJobExceptionMongo?jobId=${jobId}&candidateId=${candidateId}`;
        }

        // const removeJobsCandidateURL = `/api/dependency/removeJobsCandidateMongo?jobId=${jobId}&candidateId=${candidateId}`;
        // const removeJobsCandidateURL = `/api/dependency/removeJobExceptionMongo?jobId=${jobId}&candidateId=${candidateId}`;

        try{
            const res = await fetch( removeItemURL, {method:"delete"});
            const json = await res.json();
            console.log('removeItemList Mongo JSON:',json);
            if(json.message === 'removed item'){
                await updateFunc(jobId);
            }
        }catch (e){
            console.log('removeJobsCandidate Mongo ERROR');
        }
    };

    //close modal after added candidate
    const removeItemRequest = async ( jobId, candidateId, updateFunc )=>{
        await removeItem( jobId, candidateId, updateFunc );
        handleClose();
    };

    //tooltip
    const TooltipRemoveCandidate = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Remove From List
        </Tooltip>
    );

    return (
        <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={TooltipRemoveCandidate}
            >
                <RiCloseCircleLine  className="modal-link" fontSize={"18px"} onClick={()=>{handleShow()}}/>
            </OverlayTrigger>

            <Modal show={show} onHide={handleClose} dialogClassName="confirmModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Confirm</h3>
                </Modal.Title>
                <Modal.Body>
                    <h5>Do you want to remove {candidateFullName} from this list?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn" onClick={()=>{removeItemRequest(jobID,candidateID,updateFunc)}} >
                            Ok
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}  >
                            Cancel
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RemoveItemList;
