import React,{ useState,useEffect } from 'react';
import PickListSimpleJob from "./PickListSimpleJob";
import CreateJobBtn from "./CreateJobBtn";
import { useFormik } from 'formik';
import FieldBuilderJob from "./FieldBuilderJob";

const WrapperFieldBuilderJob = ({jobFields}) => {
    console.log('WrapperFieldBuilderJob-JOB_FIELDS:',jobFields);
    const [ newJobFields,setNewJobFields ] = useState();
    const [ postingTitle, setPostingTitle ] = useState('');
    const [ departmentName, setDepartmentName ] = useState('');
    const [ selectedOpt, setSelectedOpt ] = useState();

    const chooseFields =['Posting Title','Department Name','Job Status'];

    const getFilteredFields = (fieldsArray,chooseFields)=>{
        let filteredArray = [];
        fieldsArray.section.forEach (section =>{
            section.FL.map( sectionItem =>{
                chooseFields.forEach(chooseItem =>{
                    if(chooseItem === sectionItem.dv){
                        filteredArray.push(sectionItem)
                    }
                })
            });
        });
        return filteredArray;
    };

    useEffect(async ()=>{
        const arr = await getFilteredFields(jobFields,chooseFields);
        console.log('ARRAY:',arr);
        setNewJobFields(arr)
    },[]);

    //change input value function
    const handleChangeInput = (event, setStateFunction)=>{
        event.preventDefault();
        setStateFunction(event.target.value);
    };



    // //For formik
    // const formik = useFormik({
    //     initialValues: {
    //         firstName: '',
    //         lastName: '',
    //         email: '',
    //     },
    //
    //     onSubmit: values => {
    //         console.log(JSON.stringify(values, null, 2));
    //     },
    // });


    return (
        <div>
            <h4 style={{color:"#afafaf"}}>Please, fill this fields for create new job opening</h4>

            {newJobFields &&
                <div>
                    <div>
                        <label className="displCand-label" htmlFor={'postingTitle'}>Posting Title</label>
                        <input type='text'
                               className="displCand-input"
                               value={postingTitle}
                               id={'postingTitle'}
                               name={'postingTitle'}
                               readOnly={false}
                               onChange={ (event)=>{handleChangeInput(event,setPostingTitle)} }
                        />
                    </div>

                    <div>
                        <label className="displCand-label" htmlFor={'departmentName'}>Department Name</label>
                        <input type='text'
                               className="displCand-input"
                               value={departmentName}
                               id={'departmentName'}
                               name={'departmentName'}
                               readOnly={false}
                               onChange={ (event)=>{handleChangeInput(event,setDepartmentName)} }
                        />
                    </div>

                    <PickListSimpleJob name={newJobFields[2].dv} optValue={newJobFields[2].val} selectedOpt={selectedOpt} setSelectedOpt={setSelectedOpt} />

                    {/*<button onClick={()=>{createNewJob()}}>CREATE</button>*/}
                    <CreateJobBtn postingTitle={postingTitle} departmentName={departmentName} jobStatus={selectedOpt}/>
                </div>
            }

            {/*<form onSubmit={formik.handleSubmit}>*/}
            {/*    <label htmlFor="firstName">First Name</label>*/}
            {/*    <input*/}
            {/*        id="firstName"*/}
            {/*        name="firstName"*/}
            {/*        type="text"*/}
            {/*        onChange={formik.handleChange}*/}
            {/*        value={formik.values.firstName}*/}
            {/*    />*/}
            {/*    <label htmlFor="lastName">Last Name</label>*/}
            {/*    <input*/}
            {/*        id="lastName"*/}
            {/*        name="lastName"*/}
            {/*        type="text"*/}
            {/*        onChange={formik.handleChange}*/}
            {/*        value={formik.values.lastName}*/}
            {/*    />*/}
            {/*    <label htmlFor="email">Email Address</label>*/}
            {/*    <input*/}
            {/*        id="email"*/}
            {/*        name="email"*/}
            {/*        type="email"*/}
            {/*        onChange={formik.handleChange}*/}
            {/*        value={formik.values.email}*/}
            {/*    />*/}
            {/*    <button type="submit">Submit</button>*/}
            {/*</form>*/}
        </div>
    );
};

export default WrapperFieldBuilderJob;
