import React,{useState,useEffect} from 'react';
import SearchConfigSelect from "./SearchConfigSelect";
import Loader from "../Loader/Loader";
import Select from "react-select";

const SearchConfigSelectWrapper = ({currentSearchID,searchTitle}) => {
    // console.log('SEARCH SELECT current id:',currentSearchID);
    // console.log('SEARCH SELECT searchTitle:',searchTitle);
    const [ prepareOptions, setPrepareOptions ] = useState();
    const [ value,setValue ] = useState();
    const [ selectedOpt, setSelectedOpt] = useState();

    function prepareOptionsObject(e) {
        return Object.create({},
            {
                value:{
                    value:e.searchTitle,
                    enumerable:true,
                    writable:true
                },
                label:{
                    value:e.searchTitle,
                    enumerable:true,
                    writable:true
                },
                string:{
                    value:e.searchString,
                    enumerable:true,
                    writable:true
                },
                id:{
                    value:e._id,
                    enumerable:true,
                    writable:true
                },
                filters:{
                    value:e.searchFilterCriterias,
                    enumerable:true,
                    writable:true
                }
            }
        )
    }

    const getSearchConfig = async () =>{
        // console.log('start getSearchConfig');
        try{
            // const res = await fetch('/api/search', {method:"get"});//json data version
            const res = await fetch('/api/search/getAllMongo', {method:"get"});
            const json = await res.json();
            // console.log('getSearchConfig JSON:',json);
            return json;
        }catch (e) {
            console.log('getSearchConfig ERROR:',e);
        }
    };

    useEffect(async ()=>{
        const searchConfigJson = await getSearchConfig();
        // const prepareOptionsArray = await searchConfigJson.searchArr.map(e => prepareOptionsObject(e));//json data version
        const prepareOptionsArray = await searchConfigJson.map(e => prepareOptionsObject(e));
        console.log('PREPARE OPTIONS:',prepareOptionsArray);
        setPrepareOptions(prepareOptionsArray);

        if(currentSearchID &&  prepareOptions){
            const idx = await  prepareOptions.findIndex(item=>item.id === currentSearchID);
            setValue(idx);
        }
    },[]);

    return (
        <>
            {prepareOptions && <SearchConfigSelect prepareOptions={prepareOptions} currentTitle={searchTitle}/>}
        </>
    );
};

export default SearchConfigSelectWrapper;
