import React, { useState,useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';//old
// import 'react-pdf/dist/Page/AnnotationLayer.css';


const PDFviewerItem = ({url})=>{

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;//cloud

    // pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';//new version

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    //offset test
    function removeTextLayerOffset() {
        const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
        textLayers.forEach(layer => {
            console.log("Layer check:",layer);
            const { style } = layer;
            style.background = 'rgb(255,252,252)';
            style.color='#6f6f6f';
            style.width="100%";
            style.top = "0";
            style.left = "0";
            // style.left = "16px";
            style.transform = "";
        });

    }


    return (
        <>
            <div className="main">
                <Document
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    externalLinkTarget={"_blank"}>
                    <Page
                        pageNumber={pageNumber}
                        onLoadSuccess={removeTextLayerOffset}/>
                </Document>
                <div className="pdfViewer-pagesBox">
                    <div className="pagec">
                        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                    </div>
                    <div className="buttonc">
                        <button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            className="Pre"

                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>

            </div>
        </>
    );
};


export default PDFviewerItem;

