// function for create advanced search URL. It contain all parameters for search. Use with redux function "fetchDataRecords"
import advancedToken from "../../../config/advancedToken";
import selectedColumnsString from "../../../functions/selectedColumnsString";
import getSelectedColumnsString from "./getSelectedColumnsString";

function createAdvancedSearchURL(keyWords,allFieldsChek,firstNameChec,criteriasInp,columns) {

    let inpVal = keyWords;
    let resume = '';
    let lName = '';
    let fName = '';
    let addCriterias = '';
    let searchConditionsString;

    if(keyWords){
        if(keyWords){
            // resume  = `(Resume%3A${inpVal})`;
            const resumeValue = `Resume:${inpVal}`;
            resume  = `(${encodeURIComponent(resumeValue) })`;
        }
        if(allFieldsChek === true){
            // lName = `OR(Skill%20Set%3A${inpVal})`;
            const lNameValue = `Skill Set:${inpVal}`;
            lName = `OR(${encodeURIComponent(lNameValue) })`;
        }
        if(firstNameChec === true){
            // fName = `OR(Current%20Job%20Title%3A${inpVal})`;
            const fNameValue = `Current Job Title:${inpVal}`;
            fName = `OR(${encodeURIComponent(fNameValue) })`;
        }
    }

    if(criteriasInp.length !== 0){
        criteriasInp.map((addedCriteria) => {
            addCriterias += `${addedCriteria.criteriaWith.value}(${addedCriteria.criteriaName.value}${addedCriteria.criteriaCondition.value}${addedCriteria.criteriaValue.value})`;
            // console.log('CRIT STR',addCriterias);
            // console.log(`CRIT STR encode=${encodeURIComponent((addCriterias))}`);
        });
    }

    if((keyWords) && (criteriasInp.length === 0)){
        // console.log('VARIANT1');
        searchConditionsString = `(${resume+lName+fName})`;
    }else if((!keyWords) && (criteriasInp.length > 0)){
        // console.log('VARIANT2');
        searchConditionsString = `(${encodeURIComponent((addCriterias))})`;
    }else {
        // console.log('VARIANT3');
        searchConditionsString = `(${resume + lName + fName})AND(${encodeURIComponent((addCriterias))})`;
    }

    console.log('ADVANCED URL condition:',searchConditionsString);
    // const reqColumns = selectedColumnsString();//default columns variant
    const reqColumns = getSelectedColumnsString(columns);
    console.log('SELECTED COLUMNS:',reqColumns);
    // const advancedSearchUrl = `/proxyZohoRecruit/recruit/private/json/Candidates/searchRecords?criteria=(${searchConditionsString})&authtoken=${advancedToken}&selectColumns=${reqColumns}&newFormat=1&fromIndex=0&toIndex=200`; // "old token system static token"&"format 1" with out null fields

    const advancedSearchUrl = `/proxyZohoRecruit/recruit/private/json/Candidates/searchRecords?criteria=(${searchConditionsString})&selectColumns=${reqColumns}&newFormat=1&sortColumnString=Created%20Time&sortOrderString=desc`;
    return advancedSearchUrl;
}

export default createAdvancedSearchURL;
