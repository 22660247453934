import {SIGN_IN,SIGN_OUT} from "../Actions/actionTypes";

const init = {
    auth:false,
    userId:null,
    role:null,
    label:null,
    firstName:null,
    secondName: null
};

export default function singReducer( state = init, action ) {
    switch (action.type) {
        case SIGN_OUT:
            return {...state, auth:false, userId:null,role:null,label:null, firstName:null, secondName: null };

        case SIGN_IN:
            return {...state, auth: true, userId:action.payloadUserId, role: action.payloadRole, label: action.payloadLabel, firstName: action.payloadFirstName, secondName:action.payloadSecondName};

        default: return state
    }
}
