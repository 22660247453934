import React,{ useState, useEffect} from 'react';
import { Form } from 'react-bootstrap';
import JobTable from "./JobTable";
import Loader from "../Loader/Loader";
import selectedColumnsStringJob from "../../functions/selectedColumnsStringJob";
import headerReq from "../../functions/headers";
//REDUX
import { connect } from "react-redux";
import { clearDataRecords } from "../../redux/Actions/actionsCreator";
import { fetchDataRecords} from "../../redux/Actions/actionsCreator";
//Icons
import { BsSearch } from 'react-icons/bs';
import { BsX } from 'react-icons/bs';


const JobOpenings = (props) =>{
    const [ loading, setLoading ] = useState(true);
    const [ recordsData, setRecordsData ] = useState(null);
    const [ jobSearch, setJobSearch ] = useState('');
    const [ searchWord, setSearchWord ] = useState(null);
    const [ searchSelected, setSearchSelected ] = useState('Posting Title');


    console.log('Job OPENINGS',props);

    // // function "cleaner" for dependency data (remove item with out dependency & job exceptions) json data version
    // const dependencyDataCleaner = async ()=>{
    //     const dataCleanerURL = `/api/dependency/dataCleaner`;
    //     try{
    //         const res = await fetch( dataCleanerURL, {method:"delete"});
    //         const json = await  res.json();
    //         console.log('DEPENDENCY DataCleaner JSON:',json);
    //     }catch (e) {
    //         console.log('ERR',e);
    //     }
    // };

    async function componentDidMount(){
        let config = headerReq();
        const url = '/proxyZohoRecruit/recruit/private/json/JobOpenings/getRecords?version=2&fromIndex=1&toIndex=200';//server proxy
        const response = await  fetch(url,{...config});
        const data = await response.json();
        console.log('JOB OPENING DATA:',data);
        setRecordsData(data);
        setLoading(false);
    }

    // TRY ASYNC EFFECT
    useEffect(()=>{
        componentDidMount();
    },[]);

    //search for JobOpenings
    const handleInputChange = (event)=>{
        event.preventDefault();
        setJobSearch(event.target.value);
    };

    const handleRadioChange = (event)=>{
        setSearchSelected(event.target.value);
    };

    const createJobSearchURL = (criteria,keyWord)=>{
        const condition = `${criteria}|contains|${keyWord}`;
        const encodeCondition = `(${encodeURIComponent((condition))})`;
        const reqColumns = selectedColumnsStringJob() ;
        const jobSearchURL = `/proxyZohoRecruit/recruit/private/json/JobOpenings/getSearchRecords?version=2&newFormat=1&selectColumns=${reqColumns}&searchCondition=${encodeCondition}&fromIndex=0&toIndex=100`;
        console.log('createJobSearchURL:',jobSearchURL);
        return jobSearchURL;
    };

    const sendSearchRequest = async (criteria,keyWord)=>{
        const reqURL = createJobSearchURL(criteria,keyWord);
        let config = headerReq();

        try{
            const res = await fetch(reqURL,{...config});
            const json = await res.json();
            console.log('JOB SearchRequest JSON:',json);
            setSearchWord(keyWord);
            setRecordsData(json);
        }catch (e) {
            console.log('JOB SearchRequest error:',e);
        }

    };

    return(
        <>
        <div className='container'>
            <div className="searchJob-wrapper">
                <div className="searchJob-box">
                <span className="search-cleanBtn" onClick={()=>{setJobSearch('')}}>
                    <BsX size={'22px'}/>
                </span>
                    <input
                        type={'text'}
                        id={'searchInput'}
                        placeholder={'type request'}
                        value={jobSearch}
                        onChange={handleInputChange}
                    />
                    <span
                        id='search'
                        className="search-searchBtn"
                        onClick={()=>{sendSearchRequest(searchSelected,jobSearch)}}>
                    < BsSearch />
                    search
                </span>
                </div>

                <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"baseline"}}>
                    <Form>
                        <div key={`inline-radio`} className="mb-3">
                            <span className="searchJob-by">Search by:</span>
                            <Form.Check
                                inline
                                label="Title"
                                type={'radio'}
                                id={'rTitle'}
                                value={'Posting Title'}
                                checked={searchSelected === 'Posting Title'}
                                onChange={handleRadioChange}/>

                            <Form.Check
                                inline
                                label="Description"
                                type={'radio'}
                                id={'rDescription'}
                                value={'Job Description'}
                                checked={searchSelected === 'Job Description'}
                                onChange={handleRadioChange}/>

                            <Form.Check
                                inline
                                label="Customer"
                                type={'radio'}
                                id={'rClient'}
                                value={'Customer'}
                                checked={searchSelected === 'Customer'}
                                onChange={handleRadioChange}/>

                            {/*<Form.Check*/}
                            {/*    inline*/}
                            {/*    label="Status"*/}
                            {/*    name="searchJob"*/}
                            {/*    type={'radio'}*/}
                            {/*    id={'rStatus'}*/}
                            {/*    value={'Job Opening Status'}*/}
                            {/*    checked={searchSelected === 'Job Opening Status'}*/}
                            {/*    onChange={handleRadioChange}/>*/}
                        </div>
                    </Form>
                </div>

            </div>

            <div>
                {/*need to fix reducer from search to job records*/}
                {/*{(loading) ? <Loader/>: props.recordsDataRed ? <JobTable mainData={props.recordsDataRed}/> : <JobTable mainData={recordsData}/>}*/}

                {(loading) ?
                    <Loader/>:
                    <JobTable
                        mainData={recordsData}
                        searchWord={searchWord}
                        setSearchWord={setSearchWord}
                        componentUpdate={componentDidMount}/>}
            </div>
            </div>
        </>

    );
};

const mapStateToProps = state => {
    return{
        // recordsDataRed: state.mainReducer.searchDataRecords,
        isLogin: state.loginReducer.isLogin
    }
};

const mapDispatchToProps =  {
    clearDataRecords,
    fetchDataRecords
};

export default connect(mapStateToProps,mapDispatchToProps)(JobOpenings);

