import React,{ useState, useEffect} from 'react';
import Candidates from "../Candidates/Candidates";
import headerReq from "../../functions/headers";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import selectedColumnsString from "../../functions/selectedColumnsString";
import SearchConfigSelectWrapper from "../SearchConfigSelect/SearchConfigSelectWrapper";
//Redux
import { connect } from "react-redux";
import { clearDataRecords } from "../../redux/Actions/actionsCreator";
import { fetchDataRecords } from "../../redux/Actions/actionsCreator";
import { fetchDataRecordsKeyWord } from "../../redux/Actions/actionsCreator";
//Icons
import { BsSearch } from 'react-icons/bs';
import { BsX } from 'react-icons/bs';
import candidatesDefaultColumnsString from "../../functions/candidatesDefaultColumnsString";


const MainFunc = (props) =>{
    console.log('MAIN FUNC props:',props);
    const [ loading, setLoading ] = useState(true);
    const [ recordsData, setRecordsData ] = useState(null);
    const [ inputVal, setInputVal ] = useState('');

    async function componentDidMount(){
        console.log('MAIN FUNC didmount start');
        let config = headerReq();

        const requestColumns = candidatesDefaultColumnsString();
        const url = `/proxyZohoRecruit/recruit/private/json/Candidates/getRecords?selectColumns=${requestColumns}&version=2&fromIndex=0&toIndex=200`;//server proxy
        const response = await  fetch(url,{...config});
        const data = await response.json();
        console.log('MAIN FUNC didmount DATA:',data);
        setRecordsData(data);
        setLoading(false);
        console.log('MAIN FUNC didmount finish');
    }

    // TRY ASYNC EFFECT
    useEffect(()=>{
        componentDidMount();
    },[]);


    const handleInputChange = (event)=>{
        event.preventDefault();
        console.log(event.target.value);
        setInputVal(event.target.value);
    };

    const sendSearchRequest = () => {
        // FETCH SEARCH START
        console.log('sendSearchRequest start');
        console.log('inputVal',inputVal);
        let inpVal = inputVal;

        // TRY FETCH MULTISEARCH (add token from "https://accounts.zoho.com/apiauthtoken/create?SCOPE=ZohoRecruit/recruitapi,ZohoSearch/SearchAPI")
        const requestColumns = candidatesDefaultColumnsString();
        const requestCriteria =`((Email%3A${inpVal})OR(First%20Name%3A${inpVal})OR(Last%20Name%3A${inpVal})OR(Current%20Job%20Title%3A${inpVal})OR(Resume%3A${inpVal}))`;

        // const multiSearchUrl = `/proxyZohoRecruit/recruit/private/json/Candidates/searchRecords?criteria=${requestCriteria}&authtoken=a37076a175d7e71594645d74356f464d&selectColumns=${requestColumns}&newFormat=1&fromIndex=0&toIndex=200`;//static token
        const multiSearchUrl = `/proxyZohoRecruit/recruit/private/json/Candidates/searchRecords?criteria=${requestCriteria}&selectColumns=${requestColumns}&newFormat=1&fromIndex=0&toIndex=201&sortColumnString=Created%20Time&sortOrderString=desc`;

        console.log('my fetch start');
        // with thunk and redux
        props.fetchDataRecordsKeyWord(multiSearchUrl,inpVal);
        console.log('sendSearchRequest finish');
        // FETCH SEARCH FINISH
    };


    return(
        <>
             <div className="container">
            <SearchConfigSelectWrapper currentSearchID={props.recordsId} searchTitle={props.recordsTitleRed}/>
            <div className="search-box">
                <span className="search-cleanBtn" onClick={()=>{setInputVal('')}}>
                    <BsX size={'22px'}/>
                </span>

                <input
                    type={'text'}
                    id={'searchInput'}
                    placeholder={'type request'}
                    value={inputVal}
                    onChange={handleInputChange}
                />

                <span
                    id='search'
                    className="search-searchBtn"
                    onClick = {sendSearchRequest}>
                    < BsSearch />
                    search
                </span>
                <Link to={'/advancedSearch'} style={{marginLeft:"45px",marginTop:"2px",fontWeight:"600"}}>Advanced Search</Link>
            </div>
            <div>
                {(loading || props.isLoading) ? <Loader/>: props.recordsDataRed ?
                    <Candidates mainData={props.recordsDataRed} title={props.recordsTitleRed} id={props.recordsId} setInputVal={setInputVal} /> :
                    <Candidates mainData={recordsData} componentUpdate={componentDidMount}/>}
            </div>
            </div>
        </>

    );
};

const mapStateToProps = state => {
    return{
        recordsDataRed: state.mainReducer.searchDataRecords,
        recordsTitleRed: state.mainReducer.searchTitle,
        recordsId: state.mainReducer.searchId,
        isLoading: state.appReducer.isLoading
    }
};

const mapDispatchToProps =  {
    clearDataRecords,
    fetchDataRecords,
    fetchDataRecordsKeyWord
};

export default connect(mapStateToProps,mapDispatchToProps)(MainFunc);
