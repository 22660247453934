//function for merge jobs data from mongo & zoho
import createSimpleObjectJob from "./createSimpleObjectJob";
import CreateHybridObjectJob from "./createHybridObjectJob";
import createDeletedObjectJob from "./createDeletedObjectJob";

const mergeJobsData = (mongoData,zohoData)=>{
    const zohoSimpleArr = zohoData.map(zohoJobItem => createSimpleObjectJob(zohoJobItem.FL) );

    const hybridJobsArr = mongoData.map(jobItemMongo =>{
        const currentJob = zohoSimpleArr.find(item=>item.JOBOPENINGID === jobItemMongo.itemId);
        // console.log('DBoard-CURR JOB:',currentJob);
        if(currentJob !== undefined){
            const mergeObj = CreateHybridObjectJob(jobItemMongo,currentJob);
            return mergeObj;
        }else {
            console.log('JOB UNDEFINED:',jobItemMongo);
            const deletedJob = createDeletedObjectJob(jobItemMongo);
            return deletedJob;
        }
    });
    return hybridJobsArr;
};

export default mergeJobsData;
