import React from 'react';
import UserTableItem from "./UserTableItem";
import './adminPanel.css'

const UsersTable = ({usersArr,updateUsers}) => {
    return (
        <div className="adminPanel-usersTable">
            <div className="adminPanel-usersTableTop">
                <div className="adminPanel-usersName">Full name</div>
                <div className="adminPanel-usersEmail">Email</div>
                <div className="adminPanel-usersRole">Role</div>
            </div>
            {usersArr.map( userItem =><UserTableItem userItem={userItem} updateUsers={updateUsers}/>)}
        </div>
    );
};

export default UsersTable;
