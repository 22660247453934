import React, {useEffect, useState} from 'react';
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import SelectJobModal from "./SelectJobModal";
import RefreshToken from "../RefreshToken/RefreshToken";
import Loader from "../Loader/Loader";
//functions
import headerReq from "../../functions/headers";
import createSimpleJobObject from "./fuctions/createSimpleJobObject";
//Icons
import { BsCheckBox } from "react-icons/bs";
//CSS
import './addCandidateJobBtn.css';

const AddCandidateJobBtn = ({ candidateID, candidateFullName, updateParent = false ,forCV = false }) => {
    const [ show, setShow ] = useState(false);
    const [ jobList, setJobList ] = useState();
    const [ candidatesJobs, setCandidatesJobs ] = useState();
    const [ needRefresh,setNeedRefresh ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    //get all job openings
    const getJobList = async ()=>{
        setNeedRefresh(false);
        setIsLoading(true);
        let config = headerReq();
        const url = '/proxyZohoRecruit/recruit/private/json/JobOpenings/getRecords?version=2&fromIndex=1&toIndex=200';

        try {
            const res = await  fetch(url,{...config});
            const json = await res.json();
            console.log('getJobList JSON:',json);
            if(json.response.error){
                if(json.response.error.message === 'Invalid OAuthtoken'){
                    setNeedRefresh(true);
                }
            }else {
                const rowsArray = json.response.result.JobOpenings.row;
                console.log('getJobList rowsArray:',rowsArray);
                const prepareData = rowsArray.map( jobItem => createSimpleJobObject(jobItem.FL) );
                console.log('JOB LIST PREPARE:',prepareData);
                setJobList([...prepareData]);
            }
        }catch (e) {
            console.log('getJobList ERROR:',e);
        }
        setIsLoading(false);
    };

    //getSelectedJobs
    const getCandidatesJobs = async (candidateID)=>{
        const getCandidatesJobsURL = `/api/dependency/allCandidatesJobs?candidateId=${candidateID}`;

        try{
            const res = await fetch(getCandidatesJobsURL);
            const json = await res.json();
            console.log('getCandidatesJobs JSON:',json);
            setCandidatesJobs(json);
        }catch (e) {
            console.log('getCandidatesJobs ERROR:',e);
        }
    };

    //get data
    useEffect( async ()=>{
        if(show){
            await getJobList();
            await getCandidatesJobs(candidateID);
        }
    },[show]);

    useEffect(async ()=>{
        if(updateParent !== false){
            await updateParent(candidateID);
        }
    },[candidatesJobs]);

    //FOR MODAL WINDOW
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //tooltip
    const addJobTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Add candidate to job
        </Tooltip>
    );

    return (
        <>
            {forCV ?
                <span className="blueWhite-Btn" onClick={handleShow}>
                    <OverlayTrigger
                        placement="auto"
                        delay={{ show: 750, hide: 150 }}
                        overlay={addJobTooltip}>
                        <BsCheckBox/>
                    </OverlayTrigger>
                </span>:
                <div className="notes-addNote" onClick={handleShow}><BsCheckBox/>Add Job</div>
            }

            <Modal show={show} onHide={handleClose} dialogClassName="addToJob-modalDialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Add To Job</h3>
                </Modal.Title>
                <Modal.Body>
                    <div>
                        {isLoading && <Loader/>}
                        {jobList && candidatesJobs &&
                            <SelectJobModal
                                allJobs={jobList}
                                candidatesJob={candidatesJobs}
                                candidateID={candidateID}
                                candidateFullName={candidateFullName}
                                updateCandidatesJob={getCandidatesJobs}/>}

                        {needRefresh &&
                        <div className="addToJob-modalError">
                            <h6 style={{color:"red"}}>Your token has expired!</h6>
                            <RefreshToken update={()=>{getJobList()}}/>
                        </div>}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose} >
                            Close
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>

        </>
    );
};

export default AddCandidateJobBtn ;
