import React from 'react';
import { BsPlusCircle } from "react-icons/bs";
import { BsDashCircle } from "react-icons/bs";
import { BsDashCircleFill } from "react-icons/bs";
import { BsPlusCircleFill } from "react-icons/bs";

const JobListItem = ({candidateID,candidateFullName,updateCandidatesJob,jobTitle,jobId,action}) =>{

    //function for add candidate to job dependency
    const addJobToCandidate = async (candidateID,candidateFullName,jobID)=>{
        console.log('candidateID:',candidateID);
        console.log('jobID:',jobID);
        const addCandidateToJobURL = '/api/dependency/addCandidateToJobMongo';

        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                jobId: jobID,
                jobTitle:jobTitle,
                newCandidate: {candidateID:candidateID,candidateFullName:candidateFullName},
            })
        };

        try{
            const res = await fetch(addCandidateToJobURL, {...reqOptions});
            const json = await res.json();
            console.log('addCandidateToJob JSON:',json);
            if(json.message === "new candidate added"){
                await updateCandidatesJob(candidateID);
            }
        }catch (e) {
            console.log('addCandidateToJob ERROR');
        }
    };

    //function for remove candidate from job dependency
    const removeJobCandidate = async (candidateID,jobID)=>{
        console.log('candidateID:',candidateID);
        console.log('jobID:',jobID);
        const removeJobCandidateURL = `/api/dependency/removeJobsCandidateMongo?jobId=${jobID}&candidateId=${candidateID}`;

        try{
            const res = await fetch( removeJobCandidateURL, {method:"delete"});
            const json = await res.json();
            console.log('removeJobCandidate Mongo JSON:',json);
            if(json.message === "removed item"){
                await updateCandidatesJob(candidateID);
            }
        }catch (e) {
            console.log('deleteJobsCandidate Mongo ERROR');
        }
    };

    return (
        <div className={action === 'hide' ? "addToJob-listItemHide":"addToJob-listItem"}>
            {action === 'add' ?
                <span  className="addToJob-handlerBtn" onClick={()=>{addJobToCandidate(candidateID,candidateFullName,jobId)}}><BsPlusCircle/></span>
                :<span className="addToJob-handlerBtn" onClick={()=>{removeJobCandidate(candidateID,jobId)}}><BsDashCircle/></span>}

            <span>{jobTitle}</span>
        </div>
    );
};

export default JobListItem;
