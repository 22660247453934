//function for prepare normal object data from zoho response
import titleNameReplacer from "../../../functions/titleNameReplacer";

const createSimpleJobObject = (data)=>{
    let superObject = {
        is:'Job'
    };

    data.forEach( dataItem =>{
        const prepareKeyName = titleNameReplacer(dataItem.val);

        Object.defineProperty(superObject,prepareKeyName,{
                value: dataItem.content,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );
    });

    return superObject;
};

export default createSimpleJobObject;
