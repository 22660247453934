import React from 'react';

const TagTableDateParser = ({noteDate}) =>{
    const prepareDate = new Date(noteDate);
    return (
        <span style={{fontSize:"12px"}}>
            {prepareDate.toLocaleDateString()}
        </span>
    );
};

export default TagTableDateParser;
