import React from "react";
import {Link} from "react-router-dom";

const CreateNewSearch = () => {
    return(
        <div className="searchMain-new">
            <Link to={'/advancedSearch'} >
                <button className="searchMain-newBtn">Create New Search</button>
            </Link>
        </div>
    );
};

export default CreateNewSearch;
