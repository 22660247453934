import React,{useState} from 'react';
import { FaTimes } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import mailFrom from "../../config/mailFrom";

const ContactItem = ({contact,updateContactList}) => {
    const [ editable, setEditable ] = useState(false);
    const [ contactValue, setContactValue ] = useState(contact.email);
    const [ saveChanges, setSaveChanges ] = useState(true);
    const [ validation , setValidation ] = useState(null);

    const changeInputValue = (event)=>{
        event.preventDefault();
        setContactValue(event.target.value);

        const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,4}$/;

        if(event.target.value.length > 0){
            if(event.target.value.match(pattern)){
                setValidation('valid');
                setSaveChanges(true);
            }else{
                setValidation('invalid');
                setSaveChanges(false);
            }
        }else{
            setValidation(null);
            setSaveChanges(true);
        }
    };

    const updateContact = async (contactID)=>{
        console.log('updateContact id:',contactID);
        const updateContactURL = '/api/email/contactEdit';

        const reqOptions = {
            method:"PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({editItem:{ _id:contactID, email:contactValue }})
        };

        try{
            const res = await fetch(updateContactURL, {...reqOptions});
            const json = await res.json();
            console.log('edit contact JSON:',json);
            if(json.message === "contact edited"){
                console.log('delete contact JSON.message:',json.message);
                await setEditable(false);
                await updateContactList();
            }
        }catch (e) {
            console.log('edit contact ERROR:',e);
        }
    };

    const deleteContact = async (contactID)=>{
        console.log('delete contact id:',contactID);
        const deleteContactURL = `/api/email/contactDelete?id=${contactID}`;
        const reqOptions = {
            method:"DELETE",
            headers: {'Content-Type': 'application/json'}
        };

        try{
            const res = await fetch(deleteContactURL, {...reqOptions});
            const json = await res.json();
            console.log('delete contact JSON:',json);
            if(json.message === "contact deleted"){
                console.log('delete contact JSON.message:',json.message);
                await updateContactList();
            }
        }catch (e) {
            console.log('delete contact ERROR:',e);
        }

    };

    return (
        <div className="contactItem-main">
            <div className="contactItem-editContact">
                <span className={editable ?"contactItem-actionBtn-active":"contactItem-actionBtn"} onClick={ ()=>{setEditable(!editable)} }><FaPen/></span>
                {editable ?
                    <div>
                        <input type='email'
                               className="contactItem-contactInput"
                               value={contactValue}
                               readOnly={false}
                               onChange={(e)=>{changeInputValue(e)}}/>

                        {validation === 'invalid' ? <span className="displCand-invalidValue">Invalid value</span> :
                            saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateContact(contact._id)}}>Save</span>}
                    </div>:
                    <span className="contactItem-email">{contact.email}</span>
                }

            </div>
            <span className="contactItem-actionBtn"  onClick={()=>{deleteContact(contact._id)}}><FaTimes/></span>
        </div>
    );
};

export default ContactItem;
