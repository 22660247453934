import React,{useState,useEffect} from 'react';
import headerReqZohoAuth from "../../functions/headerReqZohoAuth";
import './avatar.css';

const Avatar = ({fullName,candidateID}) =>{
    // console.log('AVATAR test1');
    const [ letter, setLetter ]= useState(null);
    const [ photo, setPhoto ] = useState(false);
    const [ downloadPhoto, setDownloadPhoto ] = useState(null);

    const headersZohoAuth = headerReqZohoAuth();

    const testImageURL = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA4xddDfW39pNdhsfl2FprjQLJ-2lMYHlRWg&usqp=CAU';
    const nickLevinId = '501602000007827389';

    //get photo avatar from zoho
    const getCandidatePhoto = async (candidateID)=>{
        const getCandidatePhotoURL = `/proxyZohoRecruit/recruit/private/json/Candidates/downloadPhoto?scope=ALL&id=${candidateID}&version=2`;

        let outside;
        try {
            const response = await fetch(getCandidatePhotoURL,headersZohoAuth);
            // console.log('my response:',response);
            // console.log('my response type:',response.type);
            const myBlob = await response.blob();
            // console.log('myBlob:',myBlob);
            // console.log('myBlob type:',myBlob.type);
            if(myBlob.type === 'image/jpeg'){
                outside = await URL.createObjectURL(myBlob);
                // console.log(outside);
                setDownloadPhoto(outside);
            }else {
                outside = 'no photo';
                // console.log(outside);
                setDownloadPhoto(outside);
            }
        }catch (e) {
            // console.log('error blob:',e);
            outside = 'no photo';
            // console.log(outside);
            setDownloadPhoto(outside);
        }

    };

    useEffect(async ()=>{
        const charArr = fullName.split('');
        // console.log('AVATAR char arr:',charArr);
        setLetter(charArr[0]);

        if(candidateID === '501602000007916005'){
            // console.log('AVATAR test2-1');
            setPhoto(true);
        }
        // console.log('AVATAR test2');

        if(candidateID === '501602000007827389'){
            // console.log('AVATAR test Nick');
            await getCandidatePhoto(candidateID);
        }
    },[]);

    return (
        <div className="avatar-circle">
            {downloadPhoto ?
                <div
                    className="avatar-photo"
                    style={{backgroundImage:`url(${downloadPhoto})` }}>
                </div>
                : photo ?
                <div
                className="avatar-photo"
                style={{backgroundImage:`url(${testImageURL})` }}>
                </div>
                :letter ? <span>{letter}</span>
                    : <span>?</span>}
        </div>
    );
};

export default Avatar;
