import React, {useState} from 'react';
import {Tooltip, OverlayTrigger, Modal} from "react-bootstrap";
import { RiCloseCircleLine } from "react-icons/ri";
//REDUX
import { useSelector } from 'react-redux';

const TableDeleteBtn = ( {candidateFullName, candidateID, addToExceptionFunc} ) =>{
    const [ show, setShow ] = useState(false);

    //get current search name & job name from redux state
    const currentSearch = useSelector((state)=>{
        return state.mainReducer.searchTitle
    });

    const currentJob = useSelector((state)=>{
        return state.mainReducer.forJobTitle
    });

    //FOR MODAL WINDOW
    //for open & close modal window
    const handleClose = () => setShow(false);
    const handleShow = () => {
        console.log('close pop up');
        setShow(true);
    };

    //close modal after added candidate
    const addExceptionRequest = async ( candidateId, candidateName ,addFunction )=>{
        await addFunction( candidateId, candidateName );
        handleClose();
    };

    //tooltip
    const TooltipExcludeFromList = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Exclude from list
        </Tooltip>
    );

    const TooltipExcludeFromJob = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Exclude from job
        </Tooltip>
    );

    const TooltipExcludeFromSearch = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Exclude from search
        </Tooltip>
    );

    // onClick={()=>addToExceptions(superObject.CANDIDATEID)} function for delete example

    return (
        <>
            <div style={{width:"100%", display:"flex",justifyContent:"center"}}>
                <div style={{width:"80%",textAlign:"center"}}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={currentJob ? TooltipExcludeFromJob : currentSearch ? TooltipExcludeFromSearch : TooltipExcludeFromList}
                    >
                        <RiCloseCircleLine  className="modal-link" fontSize={"18px"} onClick={()=>{handleShow()}}/>
                    </OverlayTrigger>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} dialogClassName="confirmModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Confirm</h3>
                </Modal.Title>
                <Modal.Body>
                    <h5>Add to excluded?</h5>
                    <p>Candidate:<span className="confirmModal-textBold">{candidateFullName}</span>
                        {currentJob ?
                            <span style={{marginLeft:"12px"}}>For job:<span className="confirmModal-textBold">{currentJob}</span></span>:
                            currentSearch ?
                                <span style={{marginLeft:"12px"}}>For search:<span className="confirmModal-textBold">{currentSearch}</span></span>:
                            <span></span> }
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn" onClick={()=>{addExceptionRequest(candidateID,candidateFullName,addToExceptionFunc)}} >
                            Ok
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}  >
                            Cancel
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TableDeleteBtn;
