import { SHOW_LOADING,HIDE_LOADING } from "../Actions/actionTypes";

const init = {
    isLoading : false
};

export default function appReducer( state = init, action ) {
    switch (action.type) {
        case SHOW_LOADING:
            return {...state, isLoading : true };

        case HIDE_LOADING:
            return {...state, isLoading : false };
        default: return state
    }
}
