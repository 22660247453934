import React,{useEffect} from 'react';


const CandidateSentTime = ({statusHistory}) =>{
    console.log('CandidateSentTime-statusHistory:',statusHistory);


    const sentTime = statusHistory.modifiedData;
    console.log('SENT time:',sentTime);
    console.log('SENT time type:',typeof (sentTime));

    const test = new Date(sentTime);

    return (
        <div className="candidateItemParser-actionBtn-dis" >
            {test.toLocaleDateString()}
        </div>
    );
};

export default CandidateSentTime;
