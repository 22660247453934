import React, {useState} from 'react';
import { Modal, Tooltip, OverlayTrigger, Form, Spinner } from "react-bootstrap";
import Loader from "../../Loader/Loader";
//Icons
import { FaRegEdit } from "react-icons/fa";
import Select from "react-select";


const CreateJobNotes = ({ jobID, updateNotesFunc }) => {
    const [ show, setShow ] = useState(false);
    const [ noteText, setNoteText ] = useState();
    const [ noteTitle, setNoteTitle] = useState('Note');

    const userID = localStorage.getItem('NRuserID');
    const userName = localStorage.getItem('NRuserName');
    console.log('Add job notes-userName:',userName);
    console.log('Add job notes-userID:',userID);

    //note type
    const noteOptions = [{value:'text',label:'text'},{value:'call',label:'call'},{value:'email',label:'email'},{value:'meeting',label:'meeting'}];
    const initVal = noteOptions[0];
    console.log('Option selected:', initVal);
    const [selectedOpt, setSelectedOpt] = useState({...initVal});
    const changeInpValue = selectedOpt => {
        setSelectedOpt({ selectedOpt });
        console.log(`Option selected:`, selectedOpt);
    };
    const getSelectedValue = ()=>{
        if(selectedOpt.selectedOpt){
            console.log('getSelectedValue-selectedOpt.selectedOpt.value', selectedOpt.selectedOpt.value);
            return selectedOpt.selectedOpt.value;
        }else{
            console.log('getSelectedValue-selectedOpt.value', selectedOpt.value);
            return selectedOpt.value;
        }
    };

    //chang text fields value
    const changeInputValue = ( event, setInputState )=>{
        event.preventDefault();
        console.log('changeInputValue:',event.target.value);
        setInputState(event.target.value);
    };

    //Create NOTE
    const createNoteBody = ()=>{
        const currentDate = new Date();
        console.log('NOTE currentDate:',currentDate);
        const noteType = getSelectedValue();
        console.log('NOTE noteType:',noteType);

        return({
            jobId:jobID,
            createdTime:currentDate,
            userID:userID,
            userName:userName,
            noteType:noteType,
            noteTitle:noteTitle,
            noteContent:noteText
        });
    };

    const createNewNote = async () =>{
        console.log('NOTE create new note start');
        const createJobNoteURL = '/api/jobNote/createNewMongo';

        const noteBody = createNoteBody();
        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...noteBody})
        };

        try{
            const res = await fetch(createJobNoteURL, reqOptions);
            const json = await res.json();
            console.log('Note JSON:',json);
            if(json.error){
                console.log('Note ERROR',json.error);
                return ;
            }else {
                console.log('response json note:',json);
                if(json.message === 'created notes for job'){
                    await updateNotesFunc();
                    handleClose();
                }
            }

        }catch (err) {
            console.log('fetch to send mail response with ERROR:',err);
        }

        console.log('NOTE create new note finish');
    };

    //FOR MODAL WINDOW
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="notes-addNote" onClick={handleShow}><FaRegEdit/>Create Note</div>

            <Modal show={show} onHide={handleClose} dialogClassName="notes-addModalMain">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Create Job Note</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="notesForm-main">
                        <div className="notesForm-form">
                            <div className="notesForm-top">
                                <div style={{display:"flex",flexWrap:"wrap"}}>
                                    <label className="sendMail-label">Type:</label>
                                    <Select
                                        defaultValue={selectedOpt}
                                        onChange={changeInpValue}
                                        options={noteOptions}
                                        className="notes"/>
                                </div>
                                <div>
                                    <label className="sendMail-label">Title:</label>
                                    <input type='email'
                                           className="notesForm-title"
                                           name="sendMail-from"
                                           value={noteTitle}
                                           readOnly={false}
                                           onChange={(e)=>{changeInputValue(e,setNoteTitle)}}/>
                                </div>
                            </div>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Note:</span>
                                </div>
                                <textarea className="form-control"
                                          value={noteText}
                                          readOnly={false}
                                          onChange={(e)=>{changeInputValue(e,setNoteText)}}
                                ></textarea>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn" onClick={()=>{createNewNote()}} >
                            Save Note
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose} >
                            Close
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>

        </>
    );
};

export default CreateJobNotes;
