import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useTable,useSortBy } from 'react-table';
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import RefreshToken from "../RefreshToken/RefreshToken";
import { BsTrashFill } from 'react-icons/bs';
import SearchParameters from "./SearchParameters";
//Functions
import headerReq from "../../functions/headers";
import headerReqZohoAuth from "../../functions/headerReqZohoAuth";
import createTableObjects from "./functions/createTableObjects";
import createTableColumns from "./functions/createTableColumns";
import candidatesDefaultColumns from "../../functions/candidatesDefaultColumns";
//Redux
import { connect } from "react-redux";
import { clearDataRecords } from "../../redux/Actions/actionsCreator";

function Table({ columns, data, indexTo , totalLength, currentId }) {
    const [sortBy, setSortBy] = useState([]);
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy: internalSortBy }
    } = useTable(
        {
        columns,
        data,
        initialState: { sortBy }
        },
        useSortBy);

    useEffect(() => {
        // Only update external state if it's different from the internal state
        if (JSON.stringify(sortBy) !== JSON.stringify(internalSortBy)) {
            setSortBy(internalSortBy);
        }
    }, [internalSortBy]); // Depend on internalSortBy to trigger

    // Render the UI for your table
    return (
        <table {...getTableProps()}>
            <thead>

                <div className="table-topLabel">
                    <SearchParameters searchID={currentId}/>
                    {data.length < indexTo ?
                        <div className="table-topResult">
                            <span className="table-topShow">Show:</span>
                            <span className="table-topIdx">{data.length}</span>
                            <span className="table-topTotalRes">Total results:</span>
                            <span className="table-topIdx">{data.length}</span>
                        </div>:
                        <div className="table-topResult">
                            <span className="table-topShow">Show:</span>
                            <span className="table-topIdx">{indexTo+1}</span>
                            <span className="table-topTotalRes">Total results:</span>
                            <span className="table-topIdx">{totalLength}</span>
                        </div>
                    }
                </div>

            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                    : ''}
                            </span>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {

                if(i <= indexTo){
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                }

            })}
            </tbody>
        </table>
    );
}

function Candidates(props) {
    const [ curItem, setCurItem ] = useState();
    const [ exceptions, setExceptions ] = useState([]);
    const [ showIndex, setShowIndex ] = useState(20);
    const [ columnsList, setColumnsList ] = useState();
    const [ jobCandidates, setJobCandidates ] = useState(null);
    const [ dataItemFields, setDataItemFields ] = useState(null);
    const [ metaDataFields, setMetaDataFields ] = useState(null);
    const [ withTags , setWithTags ] = useState([]);

    let getRecordsData = props.mainData;
    let candidatesArr;
    let showError;

    //alert before reload page
    useEffect(() => {
        if(props.recordsId){
            window.addEventListener("beforeunload", alertUser);
            return () => {
                window.removeEventListener("beforeunload", alertUser);
            };
        }

    }, []);
    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };


    useEffect(()=>{
        setShowIndex(100);//change to 100 (20)
    },[props.mainData]);

    const clearLastReqest = ()=>{
        props.setInputVal('');
        setExceptions([]);
        props.clearDataRecords();
    };

    //get exception data for search
    const getExceptionDataSearch = async (searchID) =>{
        // const getItemURL = `/api/search/getItem?id=${searchID}`;//json data version
        const getItemURL = `/api/search/getByIdMongo?id=${searchID}`;//Mongo DB
        let data;
        try{
            const res = await fetch(getItemURL);
            const json = await res.json();
            const exceptionIdList = await json.searchExceptions.map( excItem => excItem.candidateID);
            console.log('EXCEPTIONS LIST Search:',exceptionIdList);
            data = exceptionIdList;
            setCurItem(json);
        }catch (e) {
            console.log('get item by id ERROR:',e);
            data = [];
        }
        return data;
    };

    //get exceptions data for job
    const getExceptionDataJob = async (jobID) =>{
        console.log('start fetch dependency by id');
        let data;
        // const getDependencyByIdURL = `/api/dependency/getItem?id=${jobID}`;//json data version
        const getDependencyByIdURL = `/api/dependency/getByIdMongo?id=${jobID}`;//Mongo DB
        try{
            const res = await fetch(getDependencyByIdURL, {method:"get"});
            const json = await  res.json();
            console.log('DEP  BY ID json:',json);
            console.log('DEP  BY ID jobExceptions:',json.jobExceptions);
            setJobCandidates(json.candidatesForJob);

            const exceptionIdList = json.jobExceptions.map( excItem => excItem.candidateID);
            console.log('EXCEPTIONS LIST Job:',exceptionIdList);
            data = exceptionIdList;
            // setExceptions([...exceptions,...json.jobExceptions]);
        }catch (e) {
            console.log('ERR',e);
            data = [];
        }
        return data;
    };

    //filter unique data items
    const arrayUnique = async (array) =>{
        let a = array.concat();
        for(let i=0; i<a.length; ++i) {
            for(let j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }

        return a;
    };

    useEffect(async()=>{
        if(props.id && !props.forJobIdRed){
            const exceptionsDataSearch = await getExceptionDataSearch(props.id);
            setExceptions(exceptionsDataSearch);
        }else if(props.id && props.forJobIdRed ){
            const exceptionsDataSearch = await getExceptionDataSearch(props.id);
            const exceptionsDataJob = await getExceptionDataJob(props.forJobIdRed);
            const exceptionMerges = await arrayUnique(exceptionsDataSearch.concat(exceptionsDataJob));
            setExceptions(exceptionMerges);
        }else{
            console.log('NO EXCEPTIONS');
        }

        const currentSearchString = props.currentSearchURL;
        console.log('CANDIDATES REDUX SEARCH STRING:', currentSearchString);

    },[props.id]);

    //set table columns
    const setTableColumns = ()=>{
        let prepareColumns;
        if(props.currentSearchURL){
            const searchParams = new URLSearchParams(props.currentSearchURL);
            const col = searchParams.get('selectColumns');

            const regExp = /\(([^)]+)\)/;
            const matches = regExp.exec(col);
            const doneColArr = matches[1].split(',');
            console.log('CANDIDATES EFFECT doneColArr:',doneColArr);

            prepareColumns = createTableColumns(doneColArr);
            console.log('ARR PREPARE COLUMNS:', prepareColumns);
            const prepareColumnsHeader =  [{ Header: 'Candidates List', columns: [...prepareColumns]}];
            setColumnsList(prepareColumnsHeader);
        }else{
            const defaultColumns = candidatesDefaultColumns();
            prepareColumns = createTableColumns(defaultColumns);
            console.log('PREPARE COLUMNS:',prepareColumns);
            const prepareColumnsHeader =  [{ Header: 'Candidates List', columns: [...prepareColumns]}];
            setColumnsList(prepareColumnsHeader);
        }
    };

    useEffect(()=>{
        const defaultColumns = candidatesDefaultColumns();
        let prepareColumns = createTableColumns(defaultColumns);
        console.log('PREPARE COLUMNS USE EFE:',prepareColumns);
        const prepareColumnsHeader =  [{ Header: 'Candidates List', columns: [...prepareColumns]}];
        setColumnsList(prepareColumnsHeader);
    },[]);

    useEffect(()=>{
        setTableColumns();
    },[props.id,props.currentSearchURL]);


    //get module
    const location = useLocation();
    const locationPath = location.pathname.slice(1);

    let module;
    if(locationPath === 'candidates'){
        module = 'Candidates';
    }else if(locationPath === 'jobOpenings'){
        module = 'JobOpenings';
    }

    //FOR PREVIEW ITEM download fields & fields meta data
    const getFieldsMetaData = async () =>{
        const requestOption = await headerReqZohoAuth();
        const getFieldsMetaDataURL = `/proxyZohoRecruit/recruit/v2/settings/fields?module=${module}`;

        try{
            const res = await  fetch(getFieldsMetaDataURL,{...requestOption});
            const json = await res.json();
            console.log('get META DATA - JSON:',json);
            setMetaDataFields(json);
        }catch (e) {
            console.log('get META DATA - ERROR;',e);
        }
    };

    //get array candidates with tag
    const getTagsArr = async ()=>{
        const getTagsArrURL = 'api/candidate/getWithTagArr';

        try {
            const res = await  fetch(getTagsArrURL);
            const json = await res.json();
            console.log('getTagsArr JSON:',json);
            setWithTags(json.response);
        }catch (e) {
            console.log('getTagsArr ERROR:',e);
        }
    };

    async function ComponentDidMountCandidates(){
        let config = headerReq();
        const getFieldsUrl=`/proxyZohoRecruit/recruit/private/json/${module}/getFields?version=2`;
        const getDataFlields = () =>
            fetch(getFieldsUrl,{...config}).then(response => response.json());

        try{
            const jsonDataFields = await getDataFlields();
            console.log('PREVIEW jsonDataFields:',jsonDataFields);
            await setDataItemFields(jsonDataFields);
        }catch (e) {
            console.log('data fields ERROR:',e)
        }

        await getFieldsMetaData();
        await getTagsArr();
    }

    async function ComponentUpdateBoth(){
        await ComponentDidMountCandidates();
        await props.componentUpdate();
    }

    useEffect(async () => {
       await ComponentDidMountCandidates();
    },[]);



    // RESPONSE WITH ERROR CODE
    if(getRecordsData.response.error){
        if(getRecordsData.response.error.code === "9422"){
                showError = 'Not found! Try another request ';
                candidatesArr = {no:"1", FL:[{val:"First Name", content:"-"},{val:"Last Name", content:"-"}]};
        }
        if(getRecordsData.response.error.code === "4832"){
            showError = 'Invalid request. Check all criterias';
            candidatesArr = {no:"1", FL:[{val:"First Name", content:""},{val:"Last Name", content:""}]};
        }
        if(getRecordsData.response.error.code === "4834"){
            showError = (<div>Invalid token {props.componentUpdate ? <RefreshToken update={ComponentUpdateBoth}/>:
                <RefreshToken update={()=>{ComponentDidMountCandidates()}}/>}</div>);
            candidatesArr = {no:"1", FL:[{val:"First Name", content:"-"},{val:"Last Name", content:""}]};
        }
    }else if(getRecordsData.response.nodata){
        console.log("NO RESULTS");
        candidatesArr = {no:"1", FL:[{val:"First Name", content:"Not found"},{val:"Last Name", content:"Try another request"}]};
    }else{
            candidatesArr = getRecordsData.response.result.Candidates.row;
        }

    //check token for download dataFields (use in preview item)
    console.log('CANDIDATES-dataItemFields:',dataItemFields);
    if(dataItemFields){
        if(!dataItemFields.Candidates){
            console.log('CANDIDATES-dataItemFields:ERROR');
            if(dataItemFields.response.error.code === "4834"){
                showError = (<div>Invalid token. {props.componentUpdate ? <RefreshToken update={ComponentUpdateBoth}/>:
                    <RefreshToken update={()=>{ComponentDidMountCandidates()}}/>}</div>);
                candidatesArr = {no:"1", FL:[{val:"First Name", content:"-"},{val:"Last Name", content:""}]};
            }
        }
    }


    const addExceptionToJob = async (jobId,excCandidateId,excCandidateName)=>{
        const expIdString = `${excCandidateId}`;
        // const dependencyAddExcepURL = '/api/dependency/addJobExceptions';//json data version
        const dependencyAddExcepURL = '/api/dependency/addJobExceptionsMongo';//Mongo DB

        const reqOptions = {
            method:"put",
            headers: {'Content-Type': 'application/json'},
            body:JSON.stringify({jobId:jobId, newExceptionItem:{candidateID:expIdString, candidateFullName:excCandidateName} })
        };

        try {
            const res = await fetch(dependencyAddExcepURL,reqOptions);
            const json = await res.json();
            if(json.message === 'new exception added'){
                setExceptions ( exceptions => [...exceptions,expIdString]);
            }
            console.log('addExceptionToJob JSON:',json);
        }catch (e) {
            console.log('addExceptionToJob error:',e)
        }
    };

    const addToExceptions = async (itemId , itemFullName = null)=>{
        if(props.forJobIdRed){
            console.log('Add to job exceptions function');
            console.log('Add to job exceptions JOB ID:',props. forJobIdRed);
            console.log('Add to job exceptions CAND ID:',itemId);
            console.log('Add to job exceptions CAND NAME:',itemFullName);
            await addExceptionToJob(props.forJobIdRed,itemId,itemFullName);
        }else if(!props.forJobIdRed && props.recordsId){
            console.log('CANDIDATES add exp START');
            console.log('CANDIDATES props.recordsId:',props.recordsId);

            const addSearchExceptionURL = '/api/search/addSearchExceptionsMongo';
            const reqOptions = {
                method:"PUT",
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    searchId: props.recordsId,
                    newExceptionItem: {candidateID:itemId,candidateFullName:itemFullName},
                })
            };
            try{
                console.log('start exc req');
                const res = await fetch(addSearchExceptionURL, {...reqOptions});
                const json = await res.json();
                console.log('addSearchException JSON:',json);
                if(json.message === 'new exception added'){
                    setExceptions ( exceptions => [...exceptions,itemId]);
                }
            }catch (e){
                console.log('error edit req:',e);
            }
            console.log('CANDIDATES add exc FINISH');
        }else{
            console.log('CANDIDATES add exc from list:',itemId);
            setExceptions ( exceptions => [...exceptions,itemId]);
        }

    };

    //pagination show btn
    const showIndexHandler = (dataLength)=>{
        const showPlus = showIndex + 30;
        if(dataLength < showPlus){
            setShowIndex(dataLength);
        }else{
            setShowIndex(showIndex + 30);
        }
    };

    //function for update value 'jobs candidates'
    const updateJCandidatesSTATE = async (jobID)=>{
        console.log('update jobs candidates start',jobCandidates);
        const getDependencyByIdURL = `/api/dependency/getByIdMongo?id=${jobID}`;//Mongo DB
        try{
            const res = await fetch(getDependencyByIdURL, {method:"get"});
            const json = await  res.json();
            setJobCandidates(json.candidatesForJob);
        }catch (e) {
            console.log('update jobs candidates ERR',e);
        }
        console.log('update jobs candidates finish',jobCandidates);
    };

    //TEST with ENGLISH TITLE start
    let preparedNayaData = createTableObjects(candidatesArr,dataItemFields,metaDataFields,addToExceptions,exceptions,setExceptions,props.forJobIdRed,jobCandidates,updateJCandidatesSTATE, withTags);

    console.log('EXCEP length:',exceptions);
    if(exceptions !== undefined){
        if(exceptions.length > 0){
            exceptions.forEach(expItem=>{
                preparedNayaData = preparedNayaData.filter( dataItem => dataItem.CANDIDATEID !== expItem);
            });
        }
    }
    //TEST with ENGLISH TITLE finish
    console.log('SUPER DATA:',preparedNayaData);
    const data = preparedNayaData
                            .sort(function (a, b) {
                                if (a.Created_Time.props.createdTime > b.Created_Time.props.createdTime) {
                                    return -1;
                                }
                                if (a.Created_Time.props.createdTime < b.Created_Time.props.createdTime) {
                                    return 1;
                                }
                                return 0;
                            });
    const showData = data.slice(0,showIndex);

    return (
        <>
            {/*<button onClick={()=>{console.log('CHECK STRING:',currentSearchString)}}>redux string</button>*/}
            { showError ? <div style={{fontSize:"16px",color:"red",textAlign:"center"}}>{showError}</div> : <div></div>}
            <div style={{display:"flex",justifyContent:"space-between"}} className="">
                <div style={{display:"flex"}}>
                    { props.forJobTitleRed && <div className="table-labelJob">For Job: {props.forJobTitleRed}</div>}
                    { props.title && <div className={ props.forJobTitleRed && props.title ? 'table-labelSearchBoth':'table-labelSearchSingle'}>Search: {props.title}</div>}
                </div>

                <div style={{display:"flex",justifyContent:"flex-end",marginBottom:"5px"}}>
                    { props.id && <div>
                        <Link to={{pathname:'/advancedSearch',
                            search:`${props.id}`}}
                              style={{fontWeight:"700",fontSize:"14px",color:"#1d1ccd"}}>
                            <button className="blueWhite-Btn">
                                edit search conditions
                            </button>
                        </Link>
                    </div>}

                    {props.recordsDataRed &&<div>
                        <button className="redWhite-Btn" onClick={clearLastReqest}>
                            <BsTrashFill/>
                            last request result
                        </button>
                    </div>}

                </div>

            </div>

                { dataItemFields && metaDataFields ?
                    <Table columns={columnsList} data={showData} indexTo={showIndex-1} totalLength={data.length} currentId={props.recordsId}/> : <Loader/>}

            <div style={{display:"flex",justifyContent:"center",marginTop:"8px",marginBottom:"20px"}}>
                { data.length > showIndex && <span className="blueWhite-Btn" onClick={()=>{showIndexHandler(data.length)}}>Show More</span>}
            </div>


        </>
    );
}

const mapStateToProps = state => {
    return{
        recordsDataRed: state.mainReducer.searchDataRecords,
        recordsTitleRed: state.mainReducer.searchTitle,
        recordsId: state.mainReducer.searchId,
        forJobTitleRed: state.mainReducer.forJobTitle,
        forJobIdRed: state.mainReducer.forJobId,
        currentSearchURL: state.mainReducer.searchString
    }
};

const mapDispatchToProps =  {
    clearDataRecords
};

export default connect(mapStateToProps,mapDispatchToProps)(Candidates);
