import React from 'react';

const CreatedTimeWrapper = ({createdTime}) =>{
    const createdData = createdTime.split(' ').splice(0,1).toString();
    return (
        <div className="table-createdTime">
            {createdData}
        </div>
    );
};

export default CreatedTimeWrapper;
