import React from 'react';
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import { BsTextLeft } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { FaRegEnvelope } from "react-icons/fa";
import { FaRegStar } from 'react-icons/fa';

const NoteTypeIcon = ({noteType}) => {
    switch (noteType) {
        case 'text':
            return (<div className="notes-noteIcon">
                        <BsTextLeft/>
                    </div>);

        case 'call':
            return (<div className="notes-noteIcon">
                        <FaPhoneAlt/>
                    </div>);

        case 'meeting':
            return (<div className="notes-noteIcon">
                <FaUserFriends/>
            </div>);

        case 'email':
            return (<div className="notes-noteIcon">
                <FaRegEnvelope/>
            </div>);

        case 'tag':
            return (<div className="notes-noteIcon">
                <FaRegStar/>
            </div>);

        default:
            return (<div>Type</div>);}
};

export default NoteTypeIcon;
