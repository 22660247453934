// function for get api_name value from meta data (by dv value)

const getApiName = async (meta, nameDV)=>{
    console.log('getApiName-meta:',meta);
    console.log('getApiName-nameDV:',nameDV);
    const fields = meta.fields;
    let apiName;
    await fields.map( fieldItem =>{
        if(fieldItem.display_label === nameDV){
            console.log('getApiName-fieldItem:',fieldItem);
            apiName = fieldItem.api_name;
            console.log('getApiName-api_name:',apiName);
        }
    });
    console.log('getApiName-nameDV:',apiName);
    return apiName;
};

export default getApiName;
