import React,{useState,useEffect} from 'react';
import FiltersTitle from "../FiltersTitle";
import Select from "react-select";
import OptionsBuilderMulti from "../OptionsBuilderMulty";
import getSelectedColumnsString from "../AdvSearchFunctions/getSelectedColumnsString";
import candidatesDefaultColumns from "../../../functions/candidatesDefaultColumns";

const optionsCriteriaCond = [{value: "!:",label: "not contain "}];

const optionsCriteriaWith = [
    { value: 'OR', label: 'OR' },
    { value: 'AND', label: 'AND' }
];

const ExclusionsCriteria = ({criteriasInp,setCriteriasInp,optionsCriteria,fields,word,criteriaArray,selectedCol,setSelectedCol}) =>{
    const [ filterDisable, setFilterDisable ] = useState(true);
    const [ availableOptions, setAvailableOptions ] = useState(optionsCriteria);
    const filtersLimit = 3;
    const defaultColumns = candidatesDefaultColumns();
    console.log('STATE COLUMNS:',selectedCol);

    useEffect(()=>{
        if(word || criteriaArray.length > 0){
            setFilterDisable(false);
        }else{
            setCriteriasInp([]);
            setFilterDisable(true);
        }

    },[word,criteriaArray]);

    //function for remove choose criteria from available
    const getAvailableOptions = async ()=>{
        console.log('EXCLUSION criteriasInp:',criteriasInp);
        let restOptions = optionsCriteria;
        if(criteriasInp.length > 0){
            criteriasInp.forEach( criteria =>{
                console.log('EXCLUSION criteriasInp-criteria:',criteria.criteriaName.value);
                restOptions = restOptions.filter( option => option.value !== criteria.criteriaName.value );
                console.log('EXCLUSION rest option:',criteria.criteriaName.value);
            });
        }
        return restOptions;
    };

    useEffect(async ()=>{
        const rest = await getAvailableOptions();
        console.log('EXCLUSION rest:',rest);
        setAvailableOptions(rest);
    },[criteriasInp]);

    //functions for add choose criteria to selected columns
    const prepareSelectedColumnsString = (columnsArray) => {
        return (`Candidates(${columnsArray.toString()})`);
    };

    const getSelectedColumnsArray = ( selectedColString )=>{
        const regExp = /\(([^)]+)\)/;
        const matches = regExp.exec(selectedColString);
        const selectedColArr = matches[1].split(',');
        console.log('CHECK selectedColArr func:',selectedColArr);
        return selectedColArr;
    };

    // useEffect(()=>{
    //     console.log('USE EF selected col:',selectedCol);
    // },[selectedCol]);

    useEffect(()=>{
            console.log('CRITERIA CHECK split col');
            const regExp = /\(([^)]+)\)/;
            const matches = regExp.exec(selectedCol);
            const selectedColArr = matches[1].split(',');
            console.log('CHECK selectedColArr:',selectedColArr);

            let newColumnsArr = [...selectedColArr];
            console.log('CHECK newColumnsArr1:',newColumnsArr);

            if(criteriasInp.length > 0){
                criteriasInp.map( criteria =>{
                    console.log('CRITERIA CHECK:',criteria.criteriaName.value);
                    if(criteria.criteriaName.value !== undefined){
                        const idx = selectedColArr.findIndex( item => item === criteria.criteriaName.value );
                        if(idx < 0){
                            console.log('CRITERIA idx < 0:',criteria.criteriaName.value);
                            newColumnsArr = [ ...newColumnsArr,criteria.criteriaName.value ];
                        }
                    }

                });
            }
            console.log('CHECK newColumnsArr:',newColumnsArr);
            const newColumnsString = prepareSelectedColumnsString(newColumnsArr);
            console.log('CHECK newColumnsString:',newColumnsString);
            setSelectedCol(newColumnsString);

    },[criteriasInp]);

    const removeAllFiltersFromColumn = ()=>{
        console.log('REMOVE ALL-criteriasInp:',criteriasInp);
        console.log('REMOVE ALL-default columns:',defaultColumns);
        const values = [...criteriasInp];
        let newColumnsArray = getSelectedColumnsArray(selectedCol);

        values.forEach( filterCriterion => {
            const defIdx = defaultColumns.findIndex( item => item === filterCriterion.criteriaName.value);
            if( defIdx < 0){
                console.log('CHECK NOT DEFAULT COLUMN:',filterCriterion.criteriaName.value);
                newColumnsArray = newColumnsArray.filter( columnItem => columnItem !== filterCriterion.criteriaName.value);
            }else {
                console.log('CHECK DEFAULT COLUMN:',filterCriterion.criteriaName.value);
            }
        });

        console.log('CHECK REMOVE ALL newColumnsArray:',newColumnsArray);
        const newColumnsString = prepareSelectedColumnsString(newColumnsArray);
        console.log('CHECK REMOVE ALL newColumnsString:',newColumnsString);
        setSelectedCol(newColumnsString);
    };

    //function for add & remove exclusions fields
    const handleAddCriteriaFields = () => {
        if(criteriasInp.length === 0){
            setCriteriasInp([...criteriasInp,{criteriaWith:{ value: '', label: '' },criteriaName:"",criteriaCondition:optionsCriteriaCond[0] ,criteriaValue:""}]);
        }else{
            removeAllFiltersFromColumn();
            setCriteriasInp([]);
        }
    };

    //function for change selects value
    const handleChangeCriteria = (index,event,name) =>{
        console.log('CHECK ChangeCriteria start:',criteriasInp);
        console.log('CHECK ChangeCriteria:',index, event, name);

        const ArrayPrev = [...criteriasInp];
        const PrevCriterionVal = ArrayPrev[index].criteriaName.value;
        console.log('CHECK ChangeCriteria ArrayPrev:',ArrayPrev);
        console.log('CHECK ChangeCriteria PrevCriterionVal:',PrevCriterionVal);

        const values = [...criteriasInp];
        values[index][name] = event;
        if( name === 'criteriaName'){
            const defIdx = defaultColumns.findIndex( item => item === PrevCriterionVal);
            if( defIdx < 0){
                console.log('CHECK NOT DEFAULT COLUMN');
                console.log('CHECK removeCriteria values[index]:',PrevCriterionVal);
                const columnsArr = getSelectedColumnsArray(selectedCol);
                console.log('CHECK removeCriteria columnsArr:',columnsArr);
                const newColumnsArr = columnsArr.filter( item => item !== PrevCriterionVal);
                console.log('CHECK removeCriteria newColumnsArr:',newColumnsArr);
                const newColumnsString = prepareSelectedColumnsString(newColumnsArr);
                console.log('CHECK removeCriteria newColumnsString:',newColumnsString);
                setSelectedCol(newColumnsString);
            }else{
                console.log('CHECK DEFAULT COLUMN');
            }

            console.log('CHECK ChangeCriteria prevVal:',values[index].criteriaName);
            values[index]['criteriaValue'] = "";
        }
        setCriteriasInp(values);
    };

    //function for add filter criteria
    const handleAddFilterCriteria = () => {
        console.log('CHECK AddFilterCriteria criteriasInp:',criteriasInp);
        setCriteriasInp([...criteriasInp,{criteriaWith:optionsCriteriaWith[1],criteriaName:"", criteriaCondition:optionsCriteriaCond[0], criteriaValue:""}]);
    };

    //function for remove filter criteria
    const handleRemoveFilterCriteria = (index) => {
        console.log('CHECK removeCriteria index:',index);
        const values = [...criteriasInp];


        console.log('CHECK default:',defaultColumns);
        const defIdx = defaultColumns.findIndex( item => item === values[index].criteriaName.value);
        if( defIdx < 0){
            console.log('CHECK NOT DEFAULT COLUMN');
            console.log('CHECK removeCriteria values[index]:',values[index].criteriaName.value);
            const columnsArr = getSelectedColumnsArray(selectedCol);
            console.log('CHECK removeCriteria columnsArr:',columnsArr);
            const newColumnsArr = columnsArr.filter( item => item !== values[index].criteriaName.value);
            console.log('CHECK removeCriteria newColumnsArr:',newColumnsArr);
            const newColumnsString = prepareSelectedColumnsString(newColumnsArr);
            console.log('CHECK removeCriteria newColumnsString:',newColumnsString);
            setSelectedCol(newColumnsString);
        }else{
            console.log('CHECK DEFAULT COLUMN');
        }

        console.log('VALUE before:',values);
        values.splice(index,1);
        console.log('VALUE after:',values);
        setCriteriasInp(values);
    };

    return (
        <>
            <button
                disabled={filterDisable}
                className="advanced-criteriaHandler"
                onClick={() => handleAddCriteriaFields()}>
                { criteriasInp.length === 0 ? '+ Add Filter': '- Remove Filter' }
            </button>

            <div className="advanced-criterias">
                {criteriasInp.map((criteria, index)=>(
                    <div key={index}>
                        { index === 0 ? <FiltersTitle/> :
                            <div className="criteria-with">
                                <Select
                                    value={criteria.criteriaWith}
                                    name="criteriaName"
                                    onChange={ event => handleChangeCriteria(index,event,'criteriaWith')}
                                    options={optionsCriteriaWith}/>
                            </div>
                        }

                        <div className="criteria-name">
                            <Select
                                value={criteria.criteriaName}
                                name="criteriaName"
                                onChange={ event => handleChangeCriteria(index,event,'criteriaName')}
                                options={availableOptions}/>
                        </div>

                        <div className="criteria-condition">
                            <Select
                                value={criteria.criteriaCondition}
                                name="criteriaName"
                                onChange={ event => handleChangeCriteria(index,event,'criteriaCondition')}
                                options={optionsCriteriaCond}/>
                        </div>

                        {criteria.criteriaName.value ?
                            <>
                                <OptionsBuilderMulti
                                    optionsTitle={criteria.criteriaName.value}
                                    changeFunc={handleChangeCriteria}
                                    idx={index}
                                    val={criteria.criteriaValue}
                                    fields={fields}/>

                                <button className="advanced-criteria-addBtn" onClick={()=>handleRemoveFilterCriteria(index)}>-</button>
                                {criteriasInp.length < filtersLimit ?
                                <button className="advanced-criteria-addBtn" onClick={()=>handleAddFilterCriteria()}>+</button>:
                                    <button className="advanced-criteria-addBtn" disabled={true}>+</button>}
                            </>:
                            <span className="advanced-notes">*Please, choose "Criteria Name"</span>
                        }

                    </div>
                ))}
            </div>


        </>
    );
};

export default ExclusionsCriteria;
