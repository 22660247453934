import React, {useEffect, useState} from 'react';
import { useTable,useSortBy } from 'react-table';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Loader from "../Loader/Loader";
import RefreshToken from "../RefreshToken/RefreshToken";
import SelectNaya from "../SelectNaya/SelectNaya";
//functions
import headerReq from "../../functions/headers";
import filterJobByStatus from "./functions/filterJobByStatus";
import createTableObjectsJob from "./functions/createTableObjectsJob";
import getJobDefaultColumns from "./functions/getJobDefaultColumns";
//Icons
import { BsTrashFill } from 'react-icons/bs';
import headerReqZohoAuth from "../../functions/headerReqZohoAuth";


function Table({ columns, data, indexTo , totalLength, currentSearchWord}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    );

    // Render the UI for your table
    return (
        <table {...getTableProps()}>
            <thead>

            <div className="table-topLabel">
                {currentSearchWord ?
                    <div>key word:<span className="table-topIdx">{currentSearchWord}</span></div>:
                    <div></div>
                }
                {data.length < indexTo ?
                    <div className="table-topResult">Show:<span className="table-topIdx">{data.length}</span>of<span className="table-topIdx">{data.length}</span></div>:
                    <div className="table-topResult">Show:<span className="table-topIdx">{indexTo+1}</span>of<span className="table-topIdx">{totalLength}</span></div>
                }
            </div>

            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                    : ''}
                            </span>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}


const JobTable = (props)=>{
    console.log('JOB PROPS',props);
    const [ showIndex,setShowIndex ] = useState(100);
    const [ filterStatus, setFilterStatus ] = useState('Open');
    const [ metaDataFields, setMetaDataFields ] = useState(null);

    const statusOptions = ['Open', 'Closed', 'Empty', 'All'];
    // const statusOptions = ['All','In-progress','Filled','Cancelled','Declined','Inactive'];
    console.log('statusOptions', statusOptions);

    let getRecordsData = props.mainData;
    let jobsArr;
    let showError;

    //FOR PAGINATION
    useEffect(()=>{
        console.log('NEW MAIN DATA');
        setShowIndex(100);
    },[props.mainData]);

    //pagination show btn
    const showIndexHandler = (dataLength)=>{
        const showPlus = showIndex + 20;
        if(dataLength < showPlus){
            setShowIndex(dataLength);
        }else{
            setShowIndex(showIndex + 20);
        }
    };

    //get module
    const location = useLocation();
    const locationPath = location.pathname.slice(1);

    let module;
    if(locationPath === 'candidates'){
        module = 'Candidates';
    }else if(locationPath === 'jobOpenings'){
        module = 'JobOpenings';
    }

    //download data for preview item
    const [ dataItemFields, setDataItemFields ] = useState(null);

    //FOR PREVIEW ITEM download fields & fields meta data
    const getFieldsMetaData = async () =>{
        const requestOption = await headerReqZohoAuth();
        const getFieldsMetaDataURL = `/proxyZohoRecruit/recruit/v2/settings/fields?module=${module}`;

        try{
            const res = await  fetch(getFieldsMetaDataURL,{...requestOption});
            const json = await res.json();
            console.log('get META DATA - JSON:',json);
            setMetaDataFields(json);
        }catch (e) {
            console.log('get META DATA - ERROR;',e);
        }
    };

    const getDataFlields = async () =>{
        let config = headerReq();
        const getFieldsUrl=`/proxyZohoRecruit/recruit/private/json/${module}/getFields?version=2`;

        try{
            const response = await fetch(getFieldsUrl,{...config});
            const jsonDataFields = await response.json();
            console.log('PREVIEW jsonDataFields:',jsonDataFields);
            await setDataItemFields(jsonDataFields);
        }catch (e) {
            console.log('data fields ERROR:',e)
        }
    };

    const updateBoth = async ()=>{
        await getFieldsMetaData();
        await getDataFlields();
        await props.componentUpdate();
    };

    useEffect(async () => {
        await getFieldsMetaData();
        await getDataFlields();
    },[]);


    const cleanLastResult = async ()=>{
        props.setSearchWord(null);
        await updateBoth();
    };


    // TEST WITH ERROR CODE
    if(getRecordsData.response.error){
        if(getRecordsData.response.error.code === "9422"){
            showError = 'Not found! Try another request ';
            jobsArr = {no:"1", FL:[{val:"First Name", content:"-"},{val:"Last Name", content:"-"}]};
        }
        if(getRecordsData.response.error.code === "4832"){
            showError = 'Invalid request. Check all criterias';
            jobsArr = {no:"1", FL:[{val:"First Name", content:""},{val:"Last Name", content:""}]};
        }
        if(getRecordsData.response.error.code === "4834"){
            showError = (<div>Invalid token <RefreshToken update={updateBoth}/></div>);
            jobsArr = {no:"1", FL:[{val:"First Name", content:"-"},{val:"Last Name", content:""}]};
        }
    }else if(getRecordsData.response.nodata){
        console.log("NO RESULTS");
        showError = 'There is no data to show';
        jobsArr = {no:"1", FL:[{val:"First Name", content:"Not found"},{val:"Last Name", content:"Try another request"}]};
    }else{
        jobsArr = getRecordsData.response.result.JobOpenings.row;
        console.log('JOB jobs ARR',jobsArr);
    }


    const jobDefaultColumns = getJobDefaultColumns();
    const columns = React.useMemo(
        () => [
            {
                Header: 'Job Openings List',
                columns: [...jobDefaultColumns],
            },

        ],
        []
    );

    // const data = React.useMemo(() => nayaData, []);

    //TEST WITH ENGLISH TITLES
    const prepareJobData = createTableObjectsJob(jobsArr, dataItemFields, module, metaDataFields);
    console.log('SUPER JOB DATA:', prepareJobData);

    //filter by status before display in table
    const data = filterJobByStatus(prepareJobData, filterStatus);

    const showData = data.slice(0, showIndex);
    console.log('JOB data-showData:', showData);

    return (
        <>
            { showError ? <div style={{fontSize:"16px",color:"red",textAlign:"center"}}>{showError}</div> : <div></div> }

            <div style={{display:"flex",justifyContent:"flex-end",marginBottom:"5px"}}>
                {props.searchWord &&<div>
                    <button className="redWhite-Btn" onClick={()=>{cleanLastResult()}}>
                        <BsTrashFill/>
                        last request result
                    </button>
                </div>}

                <Link to={{pathname:'/createJob'}}
                      style={{fontWeight:"700",fontSize:"14px",color:"#1d1ccd"}}>
                    <button className="blueWhite-Btn">
                        Create Job Opening
                    </button>
                </Link>

            </div>

            <div style={{position:"relative"}}>
                {dataItemFields && <SelectNaya label={'Status'} selectValue={filterStatus} setSelectValue={setFilterStatus} options={statusOptions}/>}
            </div>
            { dataItemFields ? <Table columns={columns} data={showData} indexTo={showIndex-1} totalLength={data.length} currentSearchWord={props.searchWord}/> : <Loader/> }

            <div style={{display:"flex",justifyContent:"center",marginTop:"8px",marginBottom:"20px"}}>
                { data.length > showIndex && <span className="blueWhite-Btn" onClick={()=>{showIndexHandler(data.length)}}>Show More</span>}
            </div>
        </>
    );
};

export default JobTable;

