import React,{useState,useEffect} from 'react';
import AddCandidateJobBtn from "../AddCandidateToJob/AddCandidateJobBtn";
import './candidateJobParser.css';
import CandidatesJobItem from "./CandidatesJobItem";

const CandidatesJobParser = ({candidateID, candidateFullName}) =>{
    const [ currentJobs, setCurrentJobs ] = useState();

    const getCandidatesJobs = async (candidateID)=>{
        const getCandidatesJobsURL = `/api/dependency/allCandidatesJobs?candidateId=${candidateID}`;

        console.log('getCandidatesJobs candidateID:',candidateID);
        try{
            const res = await fetch(getCandidatesJobsURL);
            const json = await res.json();
            console.log('getCandidatesJobs JSON:',json);
            if(json.length < 1){
                setCurrentJobs(null);
            }else{
                setCurrentJobs(json);
            }
        }catch (e) {
            console.log('getCandidatesJobs ERROR:',e);
        }
    };

    useEffect(async ()=>{
        await getCandidatesJobs(candidateID);
    },[]);

    return (
        <div className="candidateJobParser">
            <AddCandidateJobBtn
                candidateID={candidateID}
                candidateFullName={candidateFullName}
                updateParent={getCandidatesJobs}/>

        {currentJobs ?
            <div className="candidateJobParser-main">
                {currentJobs.map(jobItem =>
                    <CandidatesJobItem
                        jobItem={jobItem}
                        candidateID={candidateID}
                        candidateFullName={candidateFullName}
                        getCandidatesJobs={getCandidatesJobs}
                        currentJobs={currentJobs}/>)}
            </div>:
            <div>Jobs no added</div>}
        </div>
    );
};

export default CandidatesJobParser;
