import { combineReducers } from "redux";
import appReducer from './appReducer';
import mainReducer from './mainReducer';
import loginReducer from "./loginReducer";
import savedSearchReducer from "./savedSearchReducer";
import demoReducer from "./demoReducer";
import signinReducer from "./signinReducer";

export default combineReducers({
    appReducer,
    mainReducer,
    loginReducer,
    savedSearchReducer,
    demoReducer,
    signinReducer
});
