import React,{useState} from 'react';
import {Modal, Tooltip, OverlayTrigger} from "react-bootstrap";
import './role.css';
import Loader from "../Loader/Loader";

const ForgotPassword = ({closeParentFunc}) => {
    const [ show, setShow ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ validationEmail, setValidationEmail ] = useState(null);
    const [ isSending, setIsSending ] = useState(false);
    const [ result, setResult ] = useState(null);
    const [ error, setError] = useState(null);


    //FOR MODAL WINDOW
    const handleClose = () => {
        setResult(null);
        setError(null);
        setIsSending(false);
        closeParentFunc();
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const changeInputEmail = (event, setState)=>{
        event.preventDefault();
        setState(event.target.value);

        const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,4}$/;

        if(event.target.value.length > 0){
            if(event.target.value.match(pattern)){
                setValidationEmail('valid');
            }else{
                setValidationEmail('invalid');
            }
        }else{
            setValidationEmail(null);
        }
    };

    //submit form
    const forgotRequest = async (email)=>{
        setIsSending(true);
        const forgotRequestURL = '/api/user/resetPassword';

        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email:email})
        };

        try {
            const res = await fetch(forgotRequestURL,reqOptions);
            const json = await res.json();
            console.log('singInRequest JSON:',json);
            if(json.response === 'SUCCESS'){
                setResult(json.response);
            }else{
                setResult(json.response);
                setError(json.message);
            }
        }catch (e) {
            console.log('singInRequest ERROR:',e);
            setResult('ERROR');
        }

        // setIsSending(false);
    };

    const closeSending = ()=>{
        setResult(null);
        setError(null);
        setIsSending(false);
        if(result === 'SUCCESS'){
            handleClose();
        }
    };

    return (
        <>
            <span className="loginModal-forgotBtn" onClick={handleShow}>Forgot your password?</span>

            <Modal show={show} onHide={handleClose} dialogClassName="loginModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start", background:"#2b066b"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Reset Password</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="singIn-body">
                        <div style={{fontSize:"14px"}}>
                            To reset your password, please enter the email address associated with your account
                            (a link to reset your password will be sent to it).
                        </div>
                        <div>
                            <input type="email"
                                   placeholder={'Email'}
                                   onChange={(e)=>{changeInputEmail(e,setEmail)}}
                                   style={{width:'100%',marginTop:"15px"}}/>
                            {validationEmail === 'invalid' && <span style={{fontSize:"10px",color:"red"}}>incorrect e-mail</span>}
                        </div>

                        {isSending &&
                            <div className="singIn-cover">
                                <div className="singIn-sending">
                                    {result ?
                                        <div>
                                            <div className={result === 'SUCCESS' ? 'singIn-success' : 'singIn-error'}>{result}</div>
                                            {result === 'SUCCESS' && <div className="singIn-error" style={{color:"#39ff2b"}}>Check you email</div>}
                                            {error && <div className="singIn-error">{error}</div>}
                                            <button className="blueFill-btn" style={{marginTop:"20px" }} onClick={closeSending}>OK</button>
                                        </div>
                                        :<Loader/>}
                                </div>
                            </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{background:"#ececec"}}>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn"
                                onClick={()=>{forgotRequest(email)}}
                                disabled={ validationEmail === 'invalid' || validationEmail === null ||  isSending }>
                            Reset
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ForgotPassword;
