import React from 'react';
import RemoveItemList from "../../buttons/RemoveItemList";
import {Link} from "react-router-dom";
import './candidateItemParser.css';

const ExceptionCandidatesItem = ({ exceptionCandidate, jobID, getJobCandidates }) => {

    return (
        <div className="candidateItemParser-candidateItem">
            <RemoveItemList
                jobID={jobID}
                candidateID={exceptionCandidate.candidateID}
                candidateFullName={exceptionCandidate.candidateFullName}
                updateFunc={getJobCandidates}
                removeFrom={'JobException'}
            />
            <Link to={{pathname:'/candidate',search:`id=${exceptionCandidate.candidateID}&title=${exceptionCandidate.candidateFullName}`}}
                  className="candidateItemParser-candidateTitle">
                {exceptionCandidate.candidateFullName}
            </Link>
            <div></div>
        </div>
    );
};

export default ExceptionCandidatesItem;
