import {WITH_DEMO,WITHOUT_DEMO} from "../Actions/actionTypes";

const init = {
    isDemo : false
};

export default function demoReducer( state = init, action ) {
    switch (action.type) {
        case WITH_DEMO:
            return {...state, isDemo : true };

        case WITHOUT_DEMO:
            return {...state, isDemo : false };
        default: return state
    }
}
