import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import CriteriasTitle from "./CriteriasTitle";
import SearchName from "./SearchName/SearchName";
import DependencyDeleteBtn from "../buttons/DependencyDeleteBtn";
import RefreshToken from "../RefreshToken/RefreshToken";
import SearchSaveBtn from "./SearchSaveBtn/SearchSaveBtn";
import SaveChangesBtn from "./SaveChangesBtn/SaveChangesBtn";
import OptionsBuilder from "./OptionsBuilder";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
//ICONS component
import { BsTrashFill } from 'react-icons/bs';
import { BsLink45Deg } from 'react-icons/bs';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';
//REDUX
import { fetchDataRecords } from "../../redux/Actions/actionsCreator";
import { updateSearchArr } from "../../redux/Actions/actionsCreator";
import { fetchDataRecordsFilter } from "../../redux/Actions/actionsCreator";
//FUNCTIONS
import createSearchItem from "./AdvSearchFunctions/createSearchItem";
import createEditSearchItem from "./AdvSearchFunctions/createEditSearchItem";
import createObjectCreterias from "./AdvSearchFunctions/createObjectCreterias";
import createAdvancedSearchURL from "./AdvSearchFunctions/createAdvancedSearchUrl";
import headerReq from "../../functions/headers";
import selectedColumnsString from "../../functions/selectedColumnsString";
import SearchRequestBtn from "./SearchRequestBtn/SearchRequestBtn";
import ExclusionsCriteria from "./ExclusionsCriteria/ExclusionsCriteria";
import getSelectedColumnsString from "./AdvSearchFunctions/getSelectedColumnsString";
import Loader from "../Loader/Loader";
import RemoveSearchException from "../buttons/RemoveSearchException";
import candidatesDefaultColumnsString from "../../functions/candidatesDefaultColumnsString";


// SELECT MODULE
const optionsMod = [
    { value: 'Candidates', label: 'Candidates' },
    { value: 'JobOpenings', label: 'Job Openings' }
    ];

const optionsCriteria = [
    { value: 'Status', label: 'Status' },
    { value: 'Stage In Process', label: 'Stage In Process' },
    { value: 'Team', label: 'Team' },
    { value: 'Technological Domains', label: 'Technological Domains' },
    { value: 'Working Location', label: 'Working Location' },
    { value: 'Candidate Status', label: 'Candidate Status' },
    { value: 'Security Clearance', label: 'Security Clearance' },
];

const optionsCriteriaCond = [
    { value: ':', label: 'contain' },
    { value: ':s', label: 'starts with' }
];

const optionsCriteriaWith = [
    { value: 'OR', label: 'OR' },
    { value: 'AND', label: 'AND' }
];


const AdvancedSearch = (props) => {
    // console.log('ADVANCED SEARCH CLEAN PROPS',props);
    const [ selectedMod, setSelectedMod ] = useState(optionsMod[0]);
    const [ keyWords, setKeyWords ] = useState();
    const [ currentSearchID,setCurrentSearchID ] = useState();
    const [ allFieldsChek, setAllFieldsChek ] = useState(false);
    const [ firstNameChek, setFirstNameChek ] = useState(false);
    const [ resumeChek, setResumeChek ] =useState(false);
    const [ criteriasInp, setCriteriasInp ] = useState([]);
    const [ availableCrit, setAvailableCrit ] = useState();
    const [ saveTitle, setSaveTitle ] = useState();
    const [ searchTitle, setSearchTitle ] = useState();
    const [ edit, setEdit] = useState(false);
    const [ exceptions,setExceptions ] = useState([]);
    const [ showException, setShowException ] = useState(false);
    const [ showDependency, setShowDependency ] = useState(false);
    const [ searchDependency, setSearchDependency ] = useState();
    const [ fields, setFields ] = useState();
    const [ error, setError ] = useState();
    const [ excCriteriaInp, setExcCriteriaInp ] = useState([]);
    const [ boards, setBoards ] = useState();
    const [ selectedColumns, setSelectedColumns ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);

    // ADVANCED SEARCH EDIT
    const advSearchLocation = useLocation();

    // get dependency job for this search ,by search id
    const getItemBySearchId = async (itemId) =>{
        console.log('start fetch dependency by id');
        // const getDependencyByIdURL = `/api/dependency/getItemBySearchId?id=${itemId}`;//json data version
        const getDependencyByIdURL = `/api/dependency/getItemBySearchIdMongo?id=${itemId}`;//Mongo DB
        try{
            const res = await fetch(getDependencyByIdURL, {method:"get"});
            const json = await  res.json();
            console.log('DEP  BY SEARCH ID json:',json);
            if(json.length > 0){
                return json;
            }else{
                setShowDependency(false);
                return null;
            }
        }catch (e) {
            console.log('ERR',e)
        }
    };

    //DID MOUNT START
    async function ComponentDidMount() {
        setIsLoading(true);
        setError(null);
        // get fields for criteria
        const getFields = async ()=>{
            let config = headerReq();
            const getFieldsUrl='/proxyZohoRecruit/recruit/private/json/Candidates/getFields?version=2';
            try {
                const res = await fetch(getFieldsUrl,{...config});
                const json = await res.json();
                console.log('FIELDS:',json);
                if(json.response){
                    console.log('IF JSON:',json);
                    setError(<span style={{color:"red"}}>{json.response.error.message}<RefreshToken update={ComponentDidMount}/></span>);
                }else{
                    console.log('FIELDS:',json.Candidates.section);
                    setFields(json.Candidates.section);
                }
            }catch (e) {
                console.log('get fields ERROR:',e)
            }
        };

        await getFields();

        if(advSearchLocation.search){
            const curSearchId = advSearchLocation.search.slice(1);

            const dependencyData = await getItemBySearchId(curSearchId);
            console.log('GET DEPENDENCY DATA:',dependencyData);
            setSearchDependency(dependencyData);

            const getItemURL = `/api/search/getByIdMongo?id=${curSearchId}`;
            try{
                console.log('get search item start');
                console.log('get search item id:',curSearchId);
                const res = await fetch(getItemURL);
                const json = await res.json();
                setSearchTitle(json.searchTitle);
                setKeyWords(json.searchCriteria.keyWorld);
                setAllFieldsChek(json.searchCriteria.lastName);
                setFirstNameChek(json.searchCriteria.firstName);
                setCriteriasInp(json.searchAddCriterias);
                setExceptions(json.searchExceptions);
                setExcCriteriaInp(json.searchFilterCriterias);
                setSelectedColumns(json.searchColumns);
                setEdit(true);
                setCurrentSearchID(curSearchId);
                console.log('GET SEARCH BY ID:',json);
                console.log('GET SEARCH BY ID COL:',json.searchColumns);
                console.log('get search item finish');
            }catch (e) {
                console.log('get item by id ERROR:',e);
            }
        }else{
            const defaultColumns = candidatesDefaultColumnsString();
            setSelectedColumns(defaultColumns);
        }

        setIsLoading(false);
    }

    //useEffect
    useEffect(async ()=>{
        await ComponentDidMount();
    },[]);
    //DID MOUNT FINISH

    useEffect(() => {
        const values = [...criteriasInp];
        let criteriaCount = 0;

        if(keyWords) {
            criteriaCount += 1;
        }
        if(allFieldsChek === true){
            criteriaCount += 1;
        }
        if(firstNameChek === true){
            criteriaCount += 1;
        }
        criteriaCount += values.length;
        setAvailableCrit( (5-criteriaCount));
    },[keyWords,allFieldsChek,resumeChek,firstNameChek,criteriasInp]);

    let handleKeyWordsInp = (event)=>{
        event.preventDefault();
        console.log('key words:',keyWords);
        console.log('event.target.value.split.length:',event.target.value.split('').length);
        if(event.target.value.split('').length === 0){
            setFirstNameChek(false);
            setAllFieldsChek(false)
        }
        setKeyWords(event.target.value);
    };

    let handleSaveTitle = (event) => {
        event.preventDefault();
        setSaveTitle(event.target.value);
    };

    let handleInpAllFields = ()=>{
        setAllFieldsChek(!allFieldsChek);
    };

    // let handleInpResume = ()=>{
    //     setResumeChek(!resumeChek);
    // };

    let handleInpFirstName = ()=>{
        setFirstNameChek(!firstNameChek);
    };

    const handleChangeCriteria = (index,event,name) => {
        console.log(index, event, name);
        const values = [...criteriasInp];
        values[index][name] = event;
        if( name === 'criteriaName'){
            values[index]['criteriaValue'] = "";
        }
        setCriteriasInp(values);
    };

    const handleAddCriteriaFields = () => {
        if(criteriasInp.length === 0){
            setCriteriasInp([...criteriasInp,{criteriaWith:{ value: '', label: '' },criteriaName:optionsCriteria[0],criteriaCondition:optionsCriteriaCond[0] ,criteriaValue:""}]);
        }else{
            setCriteriasInp([]);
        }
    };

    const handleAddCriteria = () => {
        if( availableCrit === 0){
            alert("You can choose only five creterias");
        }else{
            setCriteriasInp([...criteriasInp,{criteriaWith:optionsCriteriaWith[0],criteriaName:"", criteriaCondition:optionsCriteriaCond[0], criteriaValue:""}]);
        }
    };

    const handleRemoveCriteria = (index) => {
        const values = [...criteriasInp];
        values.splice(index,1);
        setCriteriasInp(values);
    };


    const sendAdvancedSearchRequest = ()=>{
        const advancedSearchUrl = createAdvancedSearchURL(keyWords,allFieldsChek,firstNameChek,criteriasInp,boards[1].items);
        // with thunk and redux
        console.log('ADVANCED URL:',advancedSearchUrl);
        if(excCriteriaInp.length > 0){
            console.log('With Filter');
            console.log('FILTER MULTI:',excCriteriaInp);
            props.fetchDataRecordsFilter(advancedSearchUrl,excCriteriaInp);
            props.history.push('/candidates');
        }else {
            console.log('With Out Filter');
            props.fetchDataRecords(advancedSearchUrl);
            props.history.push('/candidates');
        }
    };

    //add new search config (new variant)
    const saveAdvancedSearchRequest = async ()=>{
        console.log('SAVE start');
        // let selectedColumns = selectedColumnsString();//default columns from function
        let selectedColumns = getSelectedColumnsString(boards[1].items);
        const advancedSearchUrl = createAdvancedSearchURL(keyWords,allFieldsChek,firstNameChek,criteriasInp,boards[1].items);
        let objCriterias = createObjectCreterias(keyWords,allFieldsChek,firstNameChek);
        let advancedSearchItem = createSearchItem(saveTitle,criteriasInp,selectedColumns,advancedSearchUrl,objCriterias,excCriteriaInp);
        console.log('ADVANCED SEARCH ITEM:',advancedSearchItem);

        const saveAndUpdate = async ()=>{
            try {
                console.log('start save req');
                // const saveUrl = '/api/search';//json data version
                const saveUrl = '/api/search/addNewMongo';
                const res = await fetch(saveUrl, {method:"POST", headers:{'Content-Type':'application/json'}, body:JSON.stringify({searchItem:advancedSearchItem}) });
                const json = await res.json();
                console.log('save JSON:',json);
                if(json.message === 'item added'){
                    try {
                        console.log('start fetch naya api');
                        const result = await fetch('/api/search/getAllMongo', {method:"get"});
                        const jsonData = await result.json();
                        await props.updateSearchArr (jsonData);
                        console.log('finish fetch naya api');
                    }catch (e) {
                        console.log('editAndUpdate-update ERROR:',e);
                    }
                }
                props.history.push('/');
            }catch (e) {
                console.log('saveAndUpdate ERROR:',e);
            }
        };

        if(props.isDemo){
            // save new search item with redux
            let prevSearchArr = props.savedSearchRedux;
            let newSearchArr = [...prevSearchArr,advancedSearchItem];
            props.updateSearchArr(newSearchArr);
        }else{
            // save new search item with API
            await saveAndUpdate();
        }

    };

    //edit search new variant
    const editAdvancedSearchRequest = async ()=>{
        console.log('EDIT SEARCH');
        // let selectedColumns = selectedColumnsString();//default columns from function
        let selectedColumns = getSelectedColumnsString(boards[1].items);
        const advancedSearchUrl = createAdvancedSearchURL(keyWords,allFieldsChek,firstNameChek,criteriasInp,boards[1].items);
        let objCriterias = createObjectCreterias(keyWords,allFieldsChek,firstNameChek);
        const curItemId = advSearchLocation.search.slice(1);
        let advancedSearchItem = createEditSearchItem(curItemId,searchTitle,criteriasInp,selectedColumns,advancedSearchUrl,objCriterias,exceptions,excCriteriaInp);

        const editAndUpdate = async ()=>{
            console.log('start edit req');
            // const editUrl = `/api/search/:id=${curItemId}`;// json data version
            const editUrl = '/api/search/editMongo';//mongo db

            try {
                const res = await fetch(editUrl, { method:"put", headers: {'Content-Type': 'application/json'}, body:JSON.stringify({editItem:advancedSearchItem}) });
                const json = await res.json();
                console.log('editAndUpdate JSON:',json);
                if(json.message === 'item edited'){
                    try {
                        console.log('start fetch naya api');
                        const result = await fetch('/api/search/getAllMongo', {method:"get"});
                        const jsonData = await result.json();
                        await props.updateSearchArr (jsonData);
                        console.log('finish fetch naya api');
                    }catch (e) {
                        console.log('editAndUpdate-update ERROR:',e);
                    }
                }
                props.history.push('/');
            }catch (e) {
                console.log('editAndUpdate ERROR:',e);
            }
        };

        if(props.isDemo){
            //edit search item redux
            let prevSearchArr = props.savedSearchRedux;
            let curItemIndex = prevSearchArr.findIndex( item => item.searchId === curItemId);
            prevSearchArr[curItemIndex] = advancedSearchItem;
        }else{
            // fetch edit search item
            await editAndUpdate();
        }

    };

    //Exceptions
    const removeExceptionState = (candidateId)=>{
        console.log('removeExceptionState-candidateId: ',candidateId);
        let cloned = [...exceptions];
        cloned = cloned.filter( item => item.candidateID !== candidateId);
        console.log('removeExceptionState-filtered: ',cloned);
        setExceptions([...cloned]);
    };


    return(
        <>
        <div className="container">
            <h3 className="containter__main-title">Advanced Search {edit && <span style={{marginLeft:"16px",fontSize:"16px"}}>edit</span>}</h3>
            { isLoading ?
                <div style={{display:"flex",justifyContent:"center"}}>
                    <Loader/>
                </div>:
                <>
                    {/*<div>{availableCrit}</div>*/}
                    { error && <div style={{width:"100%",textAlign:"center"}}>{error}</div> }

                    { edit && <SearchName searchTitle={searchTitle} setTitle={setSearchTitle}/> }

                    <div className="advanced-wrapper">
                        <div className="advanced-label">Select Module</div>
                        <div className="advanced-select">
                            <Select defaultValue={selectedMod}
                                    onChange={setSelectedMod}
                                    options={optionsMod}/>
                        </div>
                    </div>
                    <div className="advanced-wrapper">
                        <div className="advanced-label">All Keywords with Criteria</div>
                        <div>
                            <Form.Control
                                value={keyWords}
                                disabled ={ (availableCrit > 0) ? false : true }
                                as="textarea"
                                style={{width:"450px",height:"120px"}}
                                onChange={handleKeyWordsInp}/>

                            <div className="mb-3">
                                <Form.Check
                                    disabled ={ true }
                                    checked={ true }
                                    inline
                                    label="Resume"
                                    type={'checkbox'}
                                    id={'ResumeCheck'}/>

                                <Form.Check
                                    disabled ={ ((keyWords) && (availableCrit >= 1)) ? false : true }
                                    checked={firstNameChek}
                                    inline
                                    label="Job Title"
                                    type={'checkbox'}
                                    id={`firstNameChek`}
                                    onChange={handleInpFirstName}/>

                                <Form.Check
                                    disabled ={ ((keyWords) && (availableCrit >= 1)) ? false : true }
                                    checked={allFieldsChek}
                                    inline
                                    label="Skill Set"
                                    type={'checkbox'}
                                    id={`allFieldsCheck`}
                                    onChange={handleInpAllFields}/>

                                <div className="advanced-maxCriterias">*you can use maximum five search criteria</div>
                            </div>

                        </div>
                    </div>

                    <button
                        className="advanced-criteriaHandler"
                        onClick={() => handleAddCriteriaFields()}>
                        { criteriasInp.length === 0 ? '+ Add Criteria': '- Remove Criterias' }
                    </button>

                    <div className="advanced-criterias">
                        {criteriasInp.map((criteria, index)=>(
                            <div key={index} style={{marginBottom:"12px"}}>
                                { index === 0 ? <CriteriasTitle/> :
                                    <div className="criteria-with">
                                        <Select
                                            value={criteria.criteriaWith}
                                            name="criteriaName"
                                            onChange={ event => handleChangeCriteria(index,event,'criteriaWith')}
                                            options={optionsCriteriaWith}/>
                                    </div>
                                }

                                <div className="criteria-name">
                                    <Select
                                        value={criteria.criteriaName}
                                        name="criteriaName"
                                        onChange={ event => handleChangeCriteria(index,event,'criteriaName')}
                                        options={optionsCriteria}/>
                                </div>

                                <div className="criteria-condition">
                                    <Select
                                        value={criteria.criteriaCondition}
                                        name="criteriaName"
                                        // onChange={ event => handleChangeCriteria(index,event,'criteriaCondition')}
                                        options={optionsCriteriaCond}/>
                                </div>

                                {criteria.criteriaName.value ?
                                    <>
                                        <OptionsBuilder optionsTitle={criteria.criteriaName.value} changeFunc={handleChangeCriteria} idx={index} val={criteria.criteriaValue} fields={fields}/>
                                        <button className="advanced-criteria-addBtn" onClick={()=>handleRemoveCriteria(index)}>-</button>
                                        <button className="advanced-criteria-addBtn" onClick={()=>handleAddCriteria()}>+</button>
                                    </>:<span className="advanced-notes">*Please, choose "Criteria Name"</span>
                                }

                            </div>
                        ))}
                    </div>


                    {selectedColumns &&
                        <ExclusionsCriteria
                            criteriasInp={excCriteriaInp}
                            setCriteriasInp={setExcCriteriaInp}
                            fields={fields}
                            optionsCriteria={optionsCriteria}
                            word={keyWords}
                            criteriaArray={criteriasInp}
                            selectedCol={selectedColumns}
                            setSelectedCol={setSelectedColumns}/>}


                    <div className="advanced-dependencyBlock">
                        { searchDependency &&
                        <div className="advanced-dependencyBtnWrapper">
                            <div style={{display:"inline-block"}} className="blueWhite-Btn" onClick={()=>{setShowDependency(!showDependency)}}>
                                <BsLink45Deg/>
                                {showDependency ? 'Hide Job Dependency':'Show Job Dependency'}
                            </div>
                        </div>}
                        { showDependency &&
                        <div className="advanced-dependencyBox">{searchDependency.map(dependItem=>{return <div key={dependItem.itemId} className="advanced-dependencyItem">
                            {dependItem.itemTitle}<DependencyDeleteBtn jobID={dependItem.itemId} searchID={currentSearchID} update={ComponentDidMount}/></div>})}
                        </div>}
                    </div>


                    <div >
                        { exceptions.length > 0  &&
                        <div className="advanced-dependencyBtnWrapper">
                            <div style={{display:"inline-block"}} className="redWhite-Btn" onClick={()=>{setShowException(!showException)}}>
                                <BsLayoutTextSidebarReverse style={{marginRight:'4px'}}/>
                                {showException ? <span>Hide Excluded Candidate</span>:<span>Show Excluded Candidate</span>}
                            </div>
                        </div>}

                        {showException && exceptions.map( exceptionItem =>
                            <div key={exceptionItem._id} style={{marginLeft:"6px"}}>
                                <RemoveSearchException
                                    searchID={currentSearchID}
                                    candidateID={exceptionItem.candidateID}
                                    candidateFullName={exceptionItem.candidateFullName}
                                    updateFunc={ ()=>{removeExceptionState(exceptionItem.candidateID)}}/>
                                <span>{exceptionItem.candidateFullName}</span>
                            </div>)}
                    </div>


                    {fields &&
                        <DragAndDrop
                            fields={fields}
                            boards={boards}
                            setBoards={setBoards}
                            selectedColumns={selectedColumns}
                            filtersArray={excCriteriaInp}/>}


                    <div style={{display:"flex", marginBottom:"30px"}}>
                        <SearchRequestBtn
                            sendAdvancedSearchRequest={sendAdvancedSearchRequest}
                            word={keyWords}
                            criteria={criteriasInp}
                            filters={excCriteriaInp}/>

                        {edit ?
                            <SaveChangesBtn
                                editAdvancedSearchRequest={editAdvancedSearchRequest}
                                word={keyWords}
                                criteria={criteriasInp}
                                filters={excCriteriaInp}/>
                            :
                            <SearchSaveBtn
                                saveTitle={saveTitle}
                                setSaveTitle={setSaveTitle}
                                handleSaveTitle={handleSaveTitle}
                                saveAdvancedSearchRequest={saveAdvancedSearchRequest}
                                word={keyWords}
                                criteria={criteriasInp}
                                filters={excCriteriaInp}/>
                        }
                    </div>
                </>}


        </div>

        </>
    );
};


const mapStateToProps = state => {
    return{
        recordsDataRed: state.mainReducer.searchDataRecords,
        savedSearchRedux: state.savedSearchReducer.savedSearchArr,
        isDemo: state.demoReducer.isDemo
    }
};

const mapDispatchToProps =  {
    fetchDataRecords,
    updateSearchArr,
    fetchDataRecordsFilter
};

export default connect(mapStateToProps,mapDispatchToProps)(AdvancedSearch);
