let headerReqZohoAuth = () => {
    let token = localStorage.getItem('ZohoToken');
    return(
        {method: 'get',
            headers: {
                'Authorization': `Zoho-oauthtoken ${token}`,
                'Content-Type': 'application/json;charset=utf-8'
            },
            cache: "no-cache"}
    );

};

export default  headerReqZohoAuth;
