import React,{useState,useEffect} from 'react';
import Loader from "../Loader/Loader";
import MultiSelectWrapper from "../DisplayItem/MultiSelectWrapper";
import SelectSearchWrapper from "./SelectSearchWrapper";
import './jobDependency.css'
//Icons
import { BsTrashFill } from 'react-icons/bs';

const JobDependencyQuick = (props) => {
    console.log('JobDependencyQuick ITEM ID:',props.itemId);
    console.log('JobDependencyQuick ITEM TITLE:',props.itemTitle);
    console.log('JobDependencyQuick showParent:',props.showParent);

    const [ options,setOptions ] = useState();
    const [ selectedOption,setSelectedOption ] = useState();
    const [ jobExceptions, setJobExceptions ] = useState();

    //function get saved searches from naya api
    const getSearchesArr = async () =>{
        console.log('start fetch naya api');
        let searchesArr;
        try{
            // const res = await fetch('/api/search', {method:"get"});//json data version
            const res = await fetch('/api/search/getAllMongo', {method:"get"});//Mongo
            const json = await res.json();
            console.log('NAYA SEARCHES:',json);
            // searchesArr = json.searchArr;//json data version
            searchesArr = json;//Mongo DB
        }catch (e) {
            console.log('ERR',e);
        }

        return searchesArr.sort(function (a, b) {
            if (a.searchTitle.toLowerCase() > b.searchTitle.toLowerCase()) {
                return 1;
            }
            if (a.searchTitle.toLowerCase() < b.searchTitle.toLowerCase()) {
                return -1;
            }
            return 0;
        });
    };

    //function for prepare objects for options from fetch data
    function prepareArrOptions(item) {
        return Object.create({}, {
            value:{
                value:item.searchTitle,
                enumerable:true,
                writable:true,
            },
            label:{
                value:item.searchTitle,
                enumerable:true,
                writable:true,
            },
            id:{
                value:item._id,
                enumerable:true,
                writable:true,
            },
            searchURL:{
                value:item.searchString,
                enumerable:true,
                writable:true,
            },
            filters:{
                value:item.searchFilterCriterias,
                enumerable:true,
                writable:true,
            }
        });
    }

    //function for get dependency by item id
    const getDependencyById = async (itemId) =>{
        console.log('start fetch dependency by id');
        let dependencyById;
        // const getDependencyByIdURL = `/api/dependency/getItem?id=${itemId}`;//json data version
        const getDependencyByIdURL = `/api/dependency/getByIdMongo?id=${itemId}`;//Mongo DB
        try{
            const res = await fetch(getDependencyByIdURL, {method:"get"});
            const json = await  res.json();
            console.log('DEP  BY ID json:',json);
            console.log('DEP  BY ID json-dependency:',json.dependency);
            console.log('DEP  BY ID json-jobExceptions:',json.jobExceptions);
            setJobExceptions(json.jobExceptions);
            dependencyById = json.dependency;
        }catch (e) {
            console.log('ERR',e);
        }
        return dependencyById;
    };

    //function for filter value
    const filterDepValue = async (depArr,depValueArr) =>{
        console.log('filterDepValue-depArr:',depArr);
        console.log('filterDepValue-depValueArr:',depValueArr);

        let dependencyValue = [];

        depValueArr.forEach( valueId => {
            depArr.map (e=>{
                if(e._id === valueId){
                    dependencyValue.push(e);
                }
            })
        });

        return dependencyValue;
    };

    // function "ComponentDidMount"
    useEffect(async ()=>{
        if(props.showParent){
            //get saved searches from naya api
            const savedSearches = await getSearchesArr();

            //prepare options
            const preOptions = savedSearches.map(item => prepareArrOptions(item));
            console.log('DEPEND preOptions:',preOptions);
            setOptions(preOptions);

            //prepare value
            let dependencyArrId = await getDependencyById(props.itemId);
            if(dependencyArrId !== undefined){
                let filterValue = await filterDepValue(savedSearches,dependencyArrId);
                const prepareValue = filterValue.map(item => prepareArrOptions(item));
                setSelectedOption(prepareValue);
            }else{
                setSelectedOption([]);
            }
        }

    },[props.showParent]);

    // function for delete exceptions for job
    const deleteAllJobExceptions = async (itemId)=>{
        // const deleteExceptionsURL = `/api/dependency/deleteJobExceptions?id=${itemId}`;//json data version
        const deleteExceptionsURL = `/api/dependency/deleteJobExceptionsMongo?id=${itemId}`;//Mongo DB
        try{
            const res = await fetch(deleteExceptionsURL, {method:"delete"});
            const json = await  res.json();
            console.log('DELETE job exceptions JSON:',json);
            if(json.message ==="item deleted"){
                setJobExceptions([]);
            }
        }catch (e) {
            console.log('ERR',e);
        }
    };

    console.log('OPTIONS DEP:',options);

    return (
        <div className="jobDependency-quickMain">
            {!options || !selectedOption ? <Loader/> : <SelectSearchWrapper opt={options} value={selectedOption} id={props.itemId} title={props.itemTitle}/>}
        </div>
    );
};

export default JobDependencyQuick;
