import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { logIn } from "../../redux/Actions/actionsCreator";
import { logOut } from "../../redux/Actions/actionsCreator";
import { BiWifi } from "react-icons/bi";
import { BiWifiOff } from "react-icons/bi";
import './zohoConnect.css';

function ZohoConnect (props) {
    console.log('LOGIN CHECK',props.isLogin);

    let history = useHistory();
    const handleLogOut = () => {
        localStorage.removeItem('ZohoToken');
        localStorage.removeItem('ZohoRefToken');
        localStorage.removeItem('NRuserID');
        localStorage.removeItem('NRuserName');
        localStorage.removeItem('NRuserRole');
        props.logOut();
        history.push('/');
    };

    //<button className="login-out" onClick={handleLogOut}>Zoho Out</button> // save for test logout
    //<button className="login-in">Zoho In<BiWifiOff/></button> // save for test login

    return (
        <>
            {props.isLogin ?
                <div className="zohoConnect-main">
                    <div className="zohoConnect-connect" onClick={handleLogOut}>
                        <span>Zoho</span>
                        <div style={{marginLeft:"4px"}}>
                            <BiWifi/>
                        </div>
                    </div>
                </div>
                :
                //new token system with secret
                <div className="zohoConnect-mainFlex">
                    <a href='https://accounts.zoho.com/oauth/v2/auth?scope=ZohoRecruit.modules.ALL,ZohoRecruit.settings.all,ZohoSearch.securesearch.READ&client_id=1000.UL0H86CHZWXRFECM2UW8K73BJGT2WN&response_type=code&access_type=offline&redirect_uri=https://naya-cv.link&prompt=consent'
                       style={{textDecoration:"none", color:"#0f4c75"}}>
                        <div className="zohoConnect-disconnect" onClick={handleLogOut}>
                            <span>Zoho</span>
                            <div style={{marginLeft:"4px"}}>
                                <BiWifiOff/>
                            </div>
                        </div>
                    </a>
                </div>
            }
        </>
    );
}

const mapStateToProps = state => {
    return{
        isLogin: state.loginReducer.isLogin
    }
};

const mapDispatchToProps = {
    logIn,
    logOut
};


export default connect(mapStateToProps,mapDispatchToProps)(ZohoConnect);
