import React from "react";
import AutosizeInput from 'react-input-autosize';

const SearchName = ({searchTitle,setTitle}) => {

    const changeInputValue = ( event, setInputState )=>{
        event.preventDefault();
        console.log('changeInputValue:',event.target.value);
        setInputState(event.target.value);
    };

    const cssStyle = {
        fontSize: "20px",
        fontWeight: "600",
        borderRadius: "4px",
        border: "1px solid #ced4da",
        paddingLeft: "10px",
        paddingRight: "10px",
        color: "#5c646d"
    };

    return(
        <div className="advanced-wrapper">
            <div className="advanced-label">Search</div>
            <AutosizeInput
                inputStyle={{...cssStyle}}
                name="form-field-name"
                value={searchTitle}
                onChange={function(event) {
                    changeInputValue(event,setTitle)
                }}/>

            {/*<input type='text'*/}
            {/*       className="advanced-searchName"*/}
            {/*       value={searchTitle}*/}
            {/*       readOnly={false}*/}
            {/*       onChange={(e)=>{changeInputValue(e,setTitle)}}/>*/}

            {/*<div className="advanced-searchName">*/}
            {/*    {searchTitle}*/}
            {/*</div>*/}
        </div>
    );
};

export default SearchName;
