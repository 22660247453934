import { UPDATE_SEARCH } from '../Actions/actionTypes';

const init = {
    savedSearchArr: []
};

export default function savedSearchReducer( state = init, action ){
    switch (action.type) {
        case UPDATE_SEARCH:
            return { ...state, savedSearchArr: [...action.updateArr]};

        default: return state;
    }
}

