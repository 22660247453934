import React, {useState,useEffect} from 'react';
import PreviewItemCVadvanced from "./PreviewItemCVadvance";
import QuickEdit from "./QuickEdit";
import './PreviewCV.css';

const PreviewCV = ({previewItem,setPreviewItem,header,id,dataItemFields,module,meta}) =>{
    const [ previewItemCV, setPreviewItemCV ] = useState(false);
    const [ showEdit, setShowEdit ] = useState(false);


    const handlePreviewCV = ()=>{
        setPreviewItemCV(!previewItemCV)
    };

    const closeBothPopUp = ()=>{
        if(previewItemCV === true){
            setPreviewItemCV(false);
            setShowEdit(false);
            setPreviewItem(false);
        }else{
            setPreviewItem(false);
        }
    };

    useEffect(()=>{
        if(previewItem === true){
            setPreviewItemCV(true);
        }else{
            setPreviewItemCV(false);
        }
    },[previewItem]);

    return (
        <div className={previewItem ? 'prevResume-main-active':'prevResume-main'} onClick={()=>{closeBothPopUp()}}>
            <div className="prevItem-blur">
                {previewItem &&
                    <PreviewItemCVadvanced
                        previewItemCV={previewItemCV}
                        setPreviewItemCV={handlePreviewCV}
                        candidateId={id}
                        headerName={header}
                        previewItem={previewItem}
                        showEdit={showEdit}
                        setShowEdit={setShowEdit}
                        closeBoth={closeBothPopUp}/>}
                {previewItem &&
                    <QuickEdit
                        header={header}
                        id={id}
                        dataItemFields={dataItemFields}
                        module={module}
                        meta={meta}
                        showEdit={showEdit}
                        setShowEdit={setShowEdit}
                        handleShowCV={handlePreviewCV}
                        previewItemCV={previewItemCV}
                        closeBoth={closeBothPopUp}/>}
            </div>
        </div>
    );
};

export default PreviewCV;
