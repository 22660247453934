import React from 'react';
import AddNote from "../../Notes/AddNote/AddNote";
import CreateNotesPriority from "../../Notes/CreateNotesPriority/CreateNotesPriority";
//Icons
import { FaLinkedinIn } from "react-icons/fa";

const TagTableLinks = ({candidateID,fullName,candidateNotes,linkedinProfile,updateTable}) => {
    return (
        <div className="tagTable-topActions">
                <CreateNotesPriority candidateID={candidateID} candidateFullName={fullName} forComponent={'dashBoard'} updateFunc={updateTable}/>
                <AddNote candidateID={candidateID} candidateNotes={candidateNotes} forTag={true} updateNotesFunc={()=>console.log('update note func')}/>

                {linkedinProfile ?
                    <a href={linkedinProfile} target='_blank' style={{color:"#0f4c75"}}><FaLinkedinIn fontSize={"16px"}/></a>:
                    <span style={{color:"darkgrey"}}><FaLinkedinIn fontSize={"16px"}/></span> }
        </div>
    );
};

export default TagTableLinks;
