import React,{useState,useEffect} from 'react';
//REDUX
import { connect } from "react-redux";

const SearchParameters = (props) => {
    const [ currentSearch, setCurrentSearch ] = useState();

    useEffect(async ()=>{
        if(props.searchID){
            // const getItemURL = `/api/search/getItem?id=${props.searchID}`;//json data version
            const getItemURL = `/api/search/getByIdMongo?id=${props.searchID}`;//mongo
            try{
                console.log('get search item start');
                console.log('get search item id:',props.searchID);
                const res = await fetch(getItemURL);
                const json = await res.json();
                setCurrentSearch(json);
                console.log('GET SEARCH BY ID:',json);
                console.log('get search item finish');
            }catch (e) {
                console.log('get item by id ERROR:',e);
            }
        }else {
            setCurrentSearch(null);
        }
    },[props.searchID]);

    return (
        <>
        {currentSearch ?
            <div>key word:<span className="table-topIdx">{currentSearch.searchCriteria.keyWorld}</span></div>:
            props.recordsKeyWorREDUX ? <div>key word:<span className="table-topIdx">{props.recordsKeyWorREDUX}</span></div> :
                <div></div>
        }
        </>
    );
};

const mapStateToProps = state => {
    return{
        recordsKeyWorREDUX: state.mainReducer.keyWord,
    }
};

export default connect(mapStateToProps,null)(SearchParameters);
