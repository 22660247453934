// default columns for Job Opening table
const getJobDefaultColumns = () => {
    return [
        {
            Header: 'Title',
            accessor: 'Job_Title_link',
            sortType: (a, b) => {
                debugger;
                // Extract titles from objects a and b
                let titleA = a.values.Job_Title_link.props.children.toUpperCase();
                let titleB = b.values.Job_Title_link.props.children.toUpperCase();

                // Compare titles and return comparison result
                if (titleA < titleB) {
                    return -1;
                }
                if (titleA > titleB) {
                    return 1;
                }
                return 0; // titles are equal
            }
        },
        {
            Header: 'Preview',
            accessor: 'JobPreview_Link',
        },
        {
            Header: 'Urgency',
            accessor: 'Urgency',
        },
        {
            Header: 'Customer',
            accessor: 'Customer',
        },
        {
            Header: 'Team',
            accessor: 'Team',
        },
        {
            Header: 'Description',
            accessor: 'Description',
        },
        {
            Header: 'Job Status',
            accessor: 'Job_Status',
        },
        {
            Header: 'Created Time',
            accessor: 'Created_Time',
        }
    ];
};

export default getJobDefaultColumns;
