import React, {useEffect, useState} from 'react';
import '../dashBoard.css';
import BdTagTable from "./BDTagTable";
import BdTagTableLong from "./BDTagTableLong";

const DbCandidatesBar = ({ dataFields, metaData }) => {
    console.log('Candidate BAR fieldsData:',dataFields);
    console.log('Candidate BAR metaData:',metaData);

    const [ allCandidates, setAllCandidates ] = useState(null);
    const [ general, setGeneral ] = useState([]);
    const [ internalInterview, setInternalInterview ] = useState([]);
    const [ collegePipe, setCollegePipe ] = useState([]);
    const [ internalEmail, setInternalEmail ] = useState([]);
    const [ bcp, setBcp ] = useState([]);
    const [ misc, setMisc ] = useState([]);
    const [passedHrNeedInterview, setPassedHrNeedInterview] = useState([]);
    const [ sentCustomer, setSentCustomer ] = useState([]);
    const [ readyToBeSentCustomer, setReadyToBeSentCustomer ] = useState([]);

    const getAllCandidates = async ()=>{
        const getAllCandidatesURL = '/api/candidate/getAll';

        try {
            const res = await fetch(getAllCandidatesURL);
            const json = await res.json();
            console.log('DB get candidates JSON:',json);
            setAllCandidates(json.response);
        }catch (e) {
            console.log('DB get candidates JSON:',e);
        }
    };

    useEffect(async ()=>{
        await getAllCandidates();
    },[]);

    //function for filtering candidates data by tag
    const filterByTag = (data, filterValue)=>{
        if (filterValue === 'All'){
            return data;
        } else {
            return data.filter(item => item.tag === filterValue).sort(function (a, b) {
                if (a.notes.notes[0].modifiedTime > b.notes.notes[0].modifiedTime) {
                    return -1;
                }
                if (a.notes.notes[0].modifiedTime < b.notes.notes[0].modifiedTime) {
                    return 1;
                }
                return 0;
            });
        }
    };

    useEffect(async ()=>{
        if(allCandidates){
            const sentCustomerData = filterByTag(allCandidates, 'Sent to customer');
            setSentCustomer(sentCustomerData);

            const readyToBeSentCustomerData = filterByTag(allCandidates, 'Ready to be sent to customer');
            setReadyToBeSentCustomer(readyToBeSentCustomerData);

            const passedHrNeedInterview = filterByTag(allCandidates, 'Passed HR need interview');
            setPassedHrNeedInterview(passedHrNeedInterview);

            const internalEmailData = filterByTag(allCandidates,'Internal email');
            setInternalEmail(internalEmailData);

            const generalData = filterByTag(allCandidates,'General');
            setGeneral(generalData);

            const collegePipeData = filterByTag(allCandidates,'College pipe');
            setCollegePipe(collegePipeData);

            const miscData = filterByTag(allCandidates,'Misc');
            setMisc(miscData);

            const internalInterviewData = filterByTag(allCandidates,'In-Internal-Process');
            setInternalInterview(internalInterviewData);

            const bcpData = filterByTag(allCandidates,'BCP');
            setBcp(bcpData);
        }
    },[allCandidates]);

    return (
        <div className="DBbar-container">
            {/*<BdTagTable tagName={'General'} data={general} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>*/}
            {/*<BdTagTable tagName={'Internal email'} data={internalEmail} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>*/}
            {/*<BdTagTable tagName={'Internal interview'} data={internalInterview} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>*/}
            {/*<BdTagTable tagName={'Sent to customer'} data={sentCustomer} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>*/}
            {/*<BdTagTable tagName={'College pipe'} data={collegePipe} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>*/}
            {/*<BdTagTable tagName={'BCP'} data={bcp} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>*/}
            {/*<BdTagTable tagName={'Misc'} data={misc} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>*/}

            <BdTagTableLong tagName={'Sent to customer'} data={sentCustomer} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>
            <BdTagTableLong tagName={'Ready to be sent to customer'} data={readyToBeSentCustomer} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>
            <BdTagTableLong tagName={'Passed HR need interview'} data={passedHrNeedInterview} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>
            <BdTagTableLong tagName={'Internal email'} data={internalEmail} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>
            <BdTagTableLong tagName={'General'} data={general} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>
            <BdTagTableLong tagName={'College pipe'} data={collegePipe} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>
            <BdTagTableLong tagName={'Misc'} data={misc} updateTable={getAllCandidates} dataFields={dataFields} metaData={metaData}/>

        </div>
    );
};

export default DbCandidatesBar;
