// clear function for create search item
function createSearchItem(saveTitle,criteriasInp,selectedColumns,AdvancedSearchUrl,objCriterias,excCriteriaInp) {
    console.log('create SEARCHITEM-objCriterias:',objCriterias);
    console.log('create SEARCHITEM-criteriasInp:',criteriasInp);
    console.log('create SEARCHITEM-excCriteriaInp:',excCriteriaInp);

    let advancedSearchItem = Object.create({},{
        searchId:{
            value: (new Date().getTime()).toString(36) + new Date().getUTCMilliseconds(),
            enumerable:true
        },
        searchTitle:{
            value: saveTitle,
            enumerable:true,
            writable:true,
        },
        searchCriteria:{
            value:{},
            enumerable:true,
            writable:true,
        },
        searchAddCriterias:{
            value: [...criteriasInp],
            enumerable:true,
            writable:true,
        },
        searchFilterCriterias:{
            value: [...excCriteriaInp],
            enumerable:true,
            writable:true,
        },
        searchColumns:{
            value: selectedColumns,
            enumerable:true,
            writable:true,
        },
        searchString:{
            value: AdvancedSearchUrl,
            enumerable:true,
            writable:true,
        },
        searchExceptions:{
            value:[],
            enumerable:true,
            writable:true,
        }
    });

    advancedSearchItem.searchCriteria = objCriterias;
    console.log('advancedSearchItem function',advancedSearchItem);
    return advancedSearchItem;
}

export default createSearchItem;

