//function for filtering jobOpening data by status
const filterJobByStatus = (data, filterValue) => {
    if (filterValue === 'All') {
        return data;
    }
    if (filterValue === 'Empty') {
        return data
            .filter(item => item.Job_Status === undefined)
    } else {
        return data
            .filter(item => item.Job_Status !== undefined)
            .filter(item => item.Job_Status.includes(filterValue));
    }
};

export default filterJobByStatus;
