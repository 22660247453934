import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
//REDUX
import { connect } from "react-redux";
import { fetchDataRecords, clearDataRecords } from "../../redux/Actions/actionsCreator";
import { fetchDataRecordsFilter } from "../../redux/Actions/actionsCreator";

const MultiSelectWrapper = (props) => {
    const [ options,setOptions ] = useState(props.opt);
    const [ selectedOption,setSelectedOption ] = useState([...props.value]);
    const [ saveChanges,setSaveChanges ] = useState(false);
    const [ saveResult,setSaveResult ] = useState(null);

    let history = useHistory();

    //onChange function
    const changeInpValue = selectedOption => {
        console.log('SELECTED OPTIONS:',selectedOption);
        setSelectedOption({ selectedOption });
        setSaveChanges(true);
    };

    //send update dependency request function
    const updateDependencyReq = async (itemId,itemTitle,newValueArr) =>{
        console.log('updateDependencyReq-itemId:',itemId);
        console.log('updateDependencyReq-itemTitle:',itemTitle);
        console.log('updateDependencyReq-newValueArr:',newValueArr);

        let result;

        const updateDependencyURL = '/api/dependency/updateMongo';
        const requestOptions = {
            method: 'put',
            headers: {'Content-Type': 'application/json;charset=utf-8'},
            body: JSON.stringify({ updateItemID:itemId, updateItemTitle:itemTitle, newDependency:newValueArr })
            };

        try {
            const res = await fetch(updateDependencyURL,{...requestOptions});
            const json = await res.json();
            console.log('updateDependencyReq JSON:',json);
            result=json;
            setSaveResult('success');
            setTimeout(function() {
                console.log('clear result');
                setSaveResult(null);
            }, 3000);
        }catch (e) {
            console.log('updateDependencyReq ERROR:',e);
            result=e;
            setSaveResult('error');
            setTimeout(function() {
                console.log('clear result');
                setSaveResult(null);
            }, 3000);
        }

        return result;
    };

    //delete dependency request
    const deleteDependencyReq = async (itemId) =>{
        console.log('deleteDependencyReq-itemId:',itemId);
        let result;

        const deleteDependencyURL = `/api/dependency/delete?id=${itemId}`;
        try {
            const res = await fetch(deleteDependencyURL,{method: 'delete'});
            const json = await res.json();
            console.log('deleteDependencyReq JSON:',json);
            result=json;
            setSaveResult('success');
            setTimeout(function() {
                console.log('clear result');
                setSaveResult(null);
            }, 4000);
        }catch (e) {
            console.log('deleteDependencyReq ERROR:',e);
            result=e;
            setSaveResult('error');
            setTimeout(function() {
                console.log('clear result');
                setSaveResult(null);
            }, 4000);
        }

        return result;
    };

    //update dependency by id
    const updateDependency = async (itemId,itemTitle)=>{
        console.log('updateDependency-itemId:',itemId);
        setSaveChanges(false);

        if(selectedOption.selectedOption){
            const newDependencyVal = selectedOption.selectedOption.map(optionsItem => optionsItem.id);
            const reqResult = await updateDependencyReq(itemId,itemTitle,newDependencyVal);
            console.log('updateDependency-reqResult:', reqResult);
        }else{
            // const reqResult = await deleteDependencyReq(itemId);
            // console.log('deleteDependency-reqResult:', reqResult);
            console.log('deleteDependency-NO OPTIONS:',selectedOption.selectedOption);
            const newDependencyVal = [];
            const reqResult = await updateDependencyReq(itemId,itemTitle,newDependencyVal);
            console.log('updateDependency-reqResult:', reqResult);
        }

    };

    //path to search results function
    const goToSearchResult = (url,searchTitle,searchId,jobTitle,jobId,filters)=>{
        console.log('goToSearchResult-url:',url);
        console.log('goToSearchResult-searchTitle:',searchTitle);
        console.log('goToSearchResult-searchId:',searchId);
        console.log('goToSearchResult-jobTitle:',jobTitle);
        console.log('goToSearchResult-jobId:',jobId);
        console.log('goToSearchResult-filters:',filters);

        props.clearDataRecords();
        if(filters.length > 0){
            props.fetchDataRecordsFilter(url,filters,searchTitle,searchId,jobTitle,jobId);
            history.push('/candidates');
        }else{
            props.fetchDataRecords(url,searchTitle,searchId,jobTitle,jobId);
            history.push('/candidates');
        }
    };

    console.log('CHECK selectedOption:',selectedOption);

    return (
        <>
            { selectedOption.length > 0 ?
                <div>
                    <span className="dependency-toSearchLabel">Related searches:</span> {selectedOption.map((item,index)=>{
                    return <span key={index} className="dependency-toSearchItem" onClick={()=>{goToSearchResult(item.searchURL,item.label,item.id,props.title,props.id,item.filters)}}>{item.label}</span>})}
                </div>: selectedOption.selectedOption ?
                    <div>
                        <span className="dependency-toSearchLabel">Related searches:</span> {selectedOption.selectedOption.map((item,index)=>{
                        return <span key={index} className="dependency-toSearchItem" onClick={()=>{goToSearchResult(item.searchURL,item.label,item.id,props.title,props.id,item.filters)}}>{item.label}</span>})}
                    </div>:<div></div>
            }

            <div className="PickHook">
                <span className="select-label">Searches</span>
                <Select
                    isMulti
                    defaultValue={selectedOption}
                    onChange={changeInpValue}
                    options={options}/>

                {saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateDependency(props.id,props.title)}}>Save Changes</span>}
                {saveResult != null ? <span className="displCand-saveResult">saved {saveResult}</span>:<span></span>}
            </div>

        </>
    );
};

const mapStateToProps = state => {
    return{
        savedSearchRedux: state.savedSearchReducer.savedSearchArr,
    }
};

const mapDispatchToProps =  {
    fetchDataRecords,
    clearDataRecords,
    fetchDataRecordsFilter
};

export default connect(mapStateToProps,mapDispatchToProps)(MultiSelectWrapper);
