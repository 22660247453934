//function for validation filter parameters before send request
const validationFiltersCheck = async (filterArray)=>{
    if(filterArray.length > 0){
        const newFilterArr = filterArray.filter( item => item.criteriaName !== "" && item.criteriaValue !== "" && item.criteriaValue !== null);
        console.log('Validation-newFilterArr:',newFilterArr);
        if(filterArray.length === newFilterArr.length){
            console.log('Validation:true');
            return true;
        }else{
            console.log('Validation:false');
            return false;
        }
    }else {
        return true;
    }
};

export default validationFiltersCheck;
