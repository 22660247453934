import React,{useState,useEffect} from 'react';
import SentTime from "./SentTime";
import { Link } from "react-router-dom";
import RemoveItemList from "../../buttons/RemoveItemList";
import ClearCandidateSentStatus from "../../PreviewItem/CandidatesItemParser/ClearCandidateSentStatus";

const SelectedSentParser = ({jobId, jobCandidates}) =>{
    const[selectedCandidates, setSelectedCandidates] = useState([]);
    const[sentCandidates, setSentCandidates] = useState([]);
    const[removedCandidateId, setRemovedCandidateId] = useState(null);

    const updateListOfCandidates = async (jodID) =>{
        console.log('getJobCandidates in SelectedSentParser:', jodID);

        const getJobCandidatesURL = `/api/dependency/getByIdMongo?id=${jodID}`;
        try{
            const res = await fetch( getJobCandidatesURL, {method:"get"});
            const json = await res.json();
            separateCandidates(json.candidatesForJob);
//            setJobExceptions(json.jobExceptions);
        }catch (e) {
            console.log('getByIdMongo ERROR');
        }
    };

    const separateCandidates = (allSelected) => {
        let selectedArr = [];
        let selectedArrId = [];
        let sentArr = [];
        let sentArrId = [];

        allSelected.map(candidateItem => {
            if (candidateItem.candidateStatus) {
                if(!sentArrId.includes(candidateItem.candidateID) && candidateItem.candidateStatus === 'sent') {
                    sentArr.push(candidateItem);
                    sentArrId.push(candidateItem.candidateID)
                }
            } else {
                if (!selectedArrId.includes(candidateItem.candidateID) && !sentArrId.includes(candidateItem.candidateID)) {
                    selectedArr.push(candidateItem);
                    selectedArrId.push(candidateItem.candidateID)
                }
            }
        });

        setSelectedCandidates(selectedArr);
        setSentCandidates(sentArr);
    };

    useEffect(async() => {
        await separateCandidates(jobCandidates);
    }, []);

    useEffect(async() => {
        await separateCandidates(jobCandidates);
    }, [jobCandidates]);

    return (
        <div style={{width:"100%"}} className="BDTable-detailsSearch">
            {sentCandidates.length > 0 &&
                <div className="BDTable-detailsSearch">
                    {sentCandidates.map(candidateItem=>
                        <div className="BDTable-selectedSent" key={candidateItem._id}>
                            <div style={{width:"50%"}}>
                                <ClearCandidateSentStatus candidateItem={candidateItem} jobId={jobId} updateFunc={updateListOfCandidates} />
                                <Link to={{pathname:'/candidate', search:`id=${candidateItem.candidateID}&title=${candidateItem.candidateFullName}`}}
                                        target="_blank" rel="noopener noreferrer" style={{fontWeight:"bold",fontSize:"14px"}}>
                                    {candidateItem.candidateFullName}
                                </Link>
                            </div>
                            <div style={{width:"50%"}}>
                                <SentTime statusHistory={candidateItem.statusHistory}/>
                            </div>
                        </div>)}
                </div>
            }

            {selectedCandidates.length > 0 &&
                <div className="BDTable-detailsSearch">
                    {selectedCandidates.map(candidateItem=>
                        <div className="BDTable-selectedSent" key={candidateItem._id}>
                            <div style={{width:"50%"}}>
                                 <RemoveItemList
                                    jobID={jobId}
                                    candidateID={candidateItem.candidateID}
                                    candidateFullName={candidateItem.candidateFullName}
                                    updateFunc={updateListOfCandidates}
                                    removeFrom={'CandidateList'}
                                />
                                <Link to={{pathname:'/candidate', search:`id=${candidateItem.candidateID}&title=${candidateItem.candidateFullName}`}}
                                        target="_blank" rel="noopener noreferrer" style={{fontWeight:"bold",fontSize:"14px"}}>
                                  {candidateItem.candidateFullName}
                                </Link>
                            </div>
                            <div style={{width:"50%"}}>---</div>
                        </div>)}
                </div>
            }
        </div>
    );
};

export default SelectedSentParser;
