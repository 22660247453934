import React,{useState,useEffect} from 'react';
import '../dashBoard.css';

const DbTotalCounter = ({allJobs}) => {
    console.log('TOTAL all jobs:',allJobs);
    const [ totalExceptions, setTotalExceptions ] = useState(0);
    const [ totalSelected, setTotalSelected ] = useState(0);
    const [ totalSent, setTotalSent ] = useState(0);

    const getTotalValue = (allJobs)=>{
        const exceptions = allJobs.reduce((total, jobItem) => total + jobItem.jobExceptions.length, 0);
        setTotalExceptions(exceptions);

        const selected = allJobs.reduce((total,jobItem)=> total + jobItem.candidatesForJob.length, 0);
        setTotalSelected(selected);

        const sent = allJobs.reduce((total,jobItem)=>{
            if(jobItem.candidatesForJob.length){
                const allSent = jobItem.candidatesForJob.filter(candidate => candidate.candidateStatus);
                return total+allSent.length;
            }else{
                return total;
            }
        },0);
        setTotalSent(sent);
    };

    useEffect(()=>{
        if(allJobs){
            getTotalValue(allJobs);
        }
    },[]);

    useEffect(()=>{
        if(allJobs){
            getTotalValue(allJobs);
        }
    },[allJobs]);

    return (
        <div className="BDTable-top">
            <div className="BDTable-top-title">total</div>
            <div className="BDTable-top-column">{totalExceptions}</div>
            <div className="BDTable-top-column">{totalSelected}</div>
            <div className="BDTable-top-column">{totalSent}</div>
            <div style={{minWidth:"2%"}}></div>
        </div>
    );
};

export default DbTotalCounter;
