import React, {useEffect, useState} from 'react';

const NoteContentEmail = ({text}) => {
    const [ mailSent, setMailSent ] = useState(null);
    const [ mailSubject, setMailSubject ] = useState(null);
    const [ mailText, setMailText ] = useState(null);

    // const newLine = (string)=>{
    //     return string.replace(/\n /gi,'<br>');
    // };

    const textArr = text.split('\n');

    const getMailDetails = (strArr , findStr , setStateFunc) => {
        console.log('LINE SENT strArr:',strArr);
        const sentLine = strArr.filter( line => line.includes(findStr));
        if(sentLine.length > 0){
            console.log('LINE SENT:',sentLine);
            const val = sentLine[0].split(':');
            console.log('LINE SENT val:',val);
            setStateFunc( val[1]);
        }
    };

    useEffect(()=>{
        getMailDetails(textArr,'SENT TO', setMailSent);
        getMailDetails(textArr,'SUBJECT', setMailSubject);
        getMailDetails(textArr,'TEXT', setMailText);
    },[]);

    return(
        <div>
            {mailSent ?
                <div>
                    {mailSent && <div><span style={{color:"#25055c",fontSize:"14px",fontWeight:"700"}}>Sent to:</span><span>{mailSent}</span></div>}
                    {mailSubject && <div><span style={{color:"#25055c",fontSize:"14px",fontWeight:"700"}}>Subject:</span><span>{mailSubject}</span></div>}
                    {mailText && <div><span style={{color:"#25055c",fontSize:"14px",fontWeight:"700"}}>Text:</span><span>{mailText}</span></div>}
                </div>
                :
                <span>{text}</span>}
            </div>
        );
};

export default NoteContentEmail;
