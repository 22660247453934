import React, { useEffect, useState } from 'react';
import TagTableItem from "./TagTableItem";

const sortingOrderValues = ["asc", "desc"];

function getDateForItem(notes, candidateId) {
    if(notes){
        const tagsNote = notes.notes.filter( noteItem => noteItem.noteType === "tag" || noteItem.noteType === "email");
        console.log('Notes tag DATE:',tagsNote);
        // console.log('Notes tag DATE last:',tagsNote[tagsNote.length-1]);

        if( tagsNote.length > 0){
            const last = tagsNote[tagsNote.length-1];
            console.log('Notes tag DATE LAST:',last.modifiedTime);
            return last.modifiedTime;
        }else{
            console.log('Notes tag ERROR:',candidateId);
            return new Date(); 
        }

        // const lastUpdate = tagsNote[tagsNote.length-1].modifiedTime;
        // setUpdateDate(lastUpdate);
    }
}

const BdTagTableLong = ({ tagName, data, updateTable, dataFields, metaData }) => {
    const [sortedData, setSortedData] = useState(data);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null); // Initial sort direction

    useEffect(() => {
        const dataWithDateAdded = data.map(item => {
            return {...item, addedDate: getDateForItem(item.notes, item.candidateId)}
        })
        setSortedData(dataWithDateAdded);
    }, [data]);

    const sortByCandidateName = () => {
        let newSortDirection = 'asc';
        let newSortColumn = 'candidateName';
        if (sortColumn === newSortColumn) {
            if (sortDirection === 'asc') {
                newSortDirection = 'desc';
            } else if (sortDirection === 'desc') {
                newSortDirection = null;
                newSortColumn = '';
            }
        }
        const sorted = [...sortedData].sort((a, b) => {
            if (newSortDirection === 'asc') {
                return a.fullName.localeCompare(b.fullName);
            } else if (newSortDirection === 'desc') {
                return b.fullName.localeCompare(a.fullName);
            }
            return 0;
        });
        setSortedData(sorted);
        setSortDirection(newSortDirection);
        setSortColumn(newSortColumn);
    };

    const sortByAddedDate = () => {
        let newSortDirection = 'asc';
        let newSortColumn = 'addedDate';
        if (sortColumn === 'addedDate') {
            if (sortDirection === 'asc') {
                newSortDirection = 'desc';
            } else if (sortDirection === 'desc') {
                newSortDirection = null;
                newSortColumn = "";
            }
        }
        const sorted = [...sortedData].sort((a, b) => {
            if (newSortDirection === 'asc') {
                return new Date(a.addedDate) - new Date(b.addedDate);
            } else if (newSortDirection === 'desc') {
                return new Date(b.addedDate) - new Date(a.addedDate);
            }
            return 0;
        });
        setSortedData(sorted);
        setSortDirection(newSortDirection);
        setSortColumn(newSortColumn);
    };
    
    return (
        <div className="tagTable-main-long">
            <div className="tagTable-tagName"><span>#{tagName}</span></div>
            <div className="tagTable-tagBody">
                <div className="tagTable-top">
                    <div className="tagTable-topName tagTable-header__sortable"  onClick={sortByCandidateName}>Candidate name <span>({sortedData.length})</span>
                        {sortColumn === 'candidateName' && (
                        <span>{sortDirection === 'asc' ? '🔼' : '🔽'}</span>)}
                    </div>
                    <div className="tagTable-topNote">Note</div>
                    <div className="tagTable-date tagTable-header__sortable" onClick={sortByAddedDate}>Added
                        {sortColumn === 'addedDate' && (
                        <span>{sortDirection === 'asc' ? '🔼' : '🔽'}</span>)}
                    </div>
                    <div className="tagTable-topActions">Actions</div>
                    {sortedData.length > 8 && <div className="tagTable-topScrollImitation"></div>}
                </div>
                <div className="tagTable-tagList">
                    {sortedData.map(candidateItem =>
                        <TagTableItem
                            key={candidateItem.candidateId}
                            candidate={candidateItem}
                            updateTable={updateTable}
                            dataItemFields={dataFields}
                            metaData={metaData}/>)}
                </div>
            </div>
        </div>
    );
};

export default BdTagTableLong;
