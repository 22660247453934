//function for convert user role from server to role-label

const roleLabelConverter = (userRole)=>{
    const roleOptions = [{label:'User',value:'User'},{label:'Recruiter',value:'recruiter_hr'},{label:'Manager',value:'naya_manager'},{label:'Admin',value:'naya_rt_admin'}];
    const curOption = roleOptions.find(item => userRole === item.value);
    console.log('roleLabelConverter-curOption:',curOption);
    return curOption.label;
};

export default roleLabelConverter;
