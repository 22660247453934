import React from "react";
import BackUp from "../DashBoard/BackUp/BackUp";

const BackUpBoard = () => {

    return (
        <div className="searchMain">
            <div className="container">
                <h2 className="containter__main-title">BackUP</h2>
            </div>
            <div className="container">
                <BackUp/>
            </div>
        </div>
    );
};

export default BackUpBoard;
