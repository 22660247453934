import React,{useState} from 'react';
import {Modal, Tooltip, OverlayTrigger, Spinner, Form,} from "react-bootstrap";
import './role.css';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash  } from "react-icons/fa";
import Loader from "../Loader/Loader";

const SingUp = ({closeParentFunc}) => {
    const [ show, setShow ] = useState(false);
    const [ firstName, setFistName ] = useState('');
    const [ secondName, setSecondName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ pass, setPass ] = useState('');
    const [ passConfirm, setPassConfirm ] = useState('');
    const [ passwordShown, setPasswordShown ] = useState(false);
    const [ validationEmail, setValidationEmail ] = useState(null);
    const [ validationConfirm, setValidationConfirm ] = useState(null);
    const [ isSending, setIsSending ] = useState(false);
    const [ result, setResult ] = useState(null);
    const [ error, setError] = useState(null);


    //FOR MODAL WINDOW
    const handleClose = () => {
        setResult(null);
        setError(null);
        setIsSending(false);
        closeParentFunc();
        setShow(false);
    };

    const handleShow = () => setShow(true);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const changeInputValue = (event, setState)=>{
        event.preventDefault();
        setState(event.target.value);
    };

    const changeInputEmail = (event, setState)=>{
        event.preventDefault();
        setState(event.target.value);

        const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,4}$/;

        if(event.target.value.length > 0){
            if(event.target.value.match(pattern)){
                setValidationEmail('valid');
            }else{
                setValidationEmail('invalid');
            }
        }else{
            setValidationEmail(null);
        }
    };

    const changeInputConfirm = (event, setState)=>{
        event.preventDefault();
        setState(event.target.value);

        if(event.target.value.length > 0){
            if(event.target.value === pass){
                setValidationConfirm('valid');
            }else{
                setValidationConfirm('invalid');
            }
        }else{
            setValidationConfirm(null);
        }
    };

    //submit form
    const singUpRequest = async (firstName,secondName,email,pass,passConfirm)=>{
        setIsSending(true);
        const singUpRequestURL = '/api/user/singUp';

        const userItem = {
            firstName:firstName,
            secondName:secondName,
            email:email,
            password:pass
        };

        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...userItem})
        };

        try {
            const res = await fetch(singUpRequestURL,reqOptions);
            const json = await res.json();
            console.log('singInRequest JSON:',json);
            if(json.response === 'SUCCESS'){
                setResult(json.response);
            }else{
                setResult(json.response);
                setError(json.message);
            }
        }catch (e) {
            console.log('singInRequest ERROR:',e);
            setResult('ERROR');
        }

        // setIsSending(false);
    };

    const closeSending = ()=>{
        setResult(null);
        setError(null);
        setIsSending(false);
        if(result === 'SUCCESS'){
            handleClose();
        }
    };

    return (
        <>
            <span className="singIn-singBtn" onClick={handleShow}>Sing Up</span>

            <Modal show={show} onHide={handleClose} dialogClassName="loginModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start", background:"#2b066b"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Sing In</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="singIn-body">
                        <div className="singIn-fullName">
                            <div style={{width:"47%"}}>
                                <input type="text"
                                       placeholder={'First Name'}
                                       onChange={(e)=>{changeInputValue(e,setFistName)}}
                                       style={{width:'100%'}}/>
                            </div>
                            <div style={{width:"47%"}}>
                                <input type="text"
                                       placeholder={'Second Name'}
                                       onChange={(e)=>{changeInputValue(e,setSecondName)}}
                                       style={{width:'100%'}}/>
                            </div>
                        </div>

                        <div>
                            <input type="email"
                                   placeholder={'Email'}
                                   onChange={(e)=>{changeInputEmail(e,setEmail)}}
                                   style={{width:'100%',marginTop:"15px"}}/>
                            {validationEmail === 'invalid' && <span style={{fontSize:"10px",color:"red"}}>incorrect e-mail</span>}
                        </div>

                        <div className="loginModal-pass">
                            <input type={passwordShown ? "text" : "password"}
                                   placeholder={'Password'}
                                   onChange={(e)=>{changeInputValue(e,setPass)}}
                                   style={{width:'100%'}}/>
                            <div className="loginModal-passEye"
                                 onClick={togglePassword}>
                                {passwordShown ? <FaEye style={{color:"#33e426"}}/> :<FaEyeSlash style={{color:"#6c6a6b"}}/> }
                            </div>
                        </div>

                        <div className="loginModal-pass">
                            <input type={passwordShown ? "text" : "password"}
                                   placeholder={'Confirm Password'}
                                   onChange={(e)=>{changeInputConfirm(e,setPassConfirm)}}
                                   style={{width:'100%'}}/>
                            <div className="loginModal-passEye"
                                 onClick={togglePassword}>
                                {passwordShown ? <FaEye style={{color:"#33e426"}}/> :<FaEyeSlash style={{color:"#6c6a6b"}}/> }
                            </div>
                            {validationConfirm === 'invalid' && <span style={{fontSize:"10px",color:"red"}}>passwords do not match</span>}
                        </div>

                        {isSending &&
                            <div className="singIn-cover">
                                <div className="singIn-sending">
                                    {result ?
                                        <div>
                                            <div className={result === 'SUCCESS' ? 'singIn-success' : 'singIn-error'}>{result}</div>
                                            {error && <div className="singIn-error">{error}</div>}
                                            <button className="blueFill-btn" style={{marginTop:"20px" }} onClick={closeSending}>OK</button>
                                        </div>
                                        :<Loader/>}
                                </div>
                            </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{background:"#ececec"}}>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn"
                                onClick={()=>{singUpRequest(firstName,secondName,email,pass,passConfirm)}}
                                disabled={validationConfirm === 'invalid' || validationEmail === 'invalid' || validationEmail === null || validationConfirm === null || firstName.length <= 0 || secondName.length <= 0 || isSending}>
                            Submit
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SingUp;
