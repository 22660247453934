import React,{useState,useEffect} from 'react';
import { Modal, Tooltip, OverlayTrigger, Form, Spinner } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import Loader from "../Loader/Loader";
import RefreshToken from "../RefreshToken/RefreshToken";
import RefreshTokenLine from "../RefreshToken/RefreshTokenLine";
import mailFrom from "../../config/mailFrom";
import ContactItem from "./ContactItem";
//icons
import { BsEnvelope } from "react-icons/bs";
import { BsPaperclip } from 'react-icons/bs';
import { BsCheckAll } from 'react-icons/bs';
import { BsExclamationSquare } from 'react-icons/bs';
import ContactList from "./ContactList";


const EmailModal = ({ candidateId, candidateFullName, btnName, forTable }) => {
    const [ show, setShow ] = useState(false);
    const [ linkToCandidate,setLinkToCandidate ] = useState();
    const [ sendLink,setSendLink ] = useState(true);
    const [ mailTo,setMailTo ] = useState();
    const [ mailSubject,setMailSubject ] = useState();
    const [ mailText,setMailText ] = useState('Hello! Please, check this candidate and call.');
    const [ newAddress,setNewAddress ] = useState();
    const [ incorrect,setIncorrect ] = useState();
    const [ contactList , setContactList ] = useState();
    const [ options,setOptions ] = useState();
    const [ sending,setSending ] = useState(false);
    const [ sendingRes,setSendingRes ] = useState(null);
    const [ needRefresh,setNeedRefresh ] = useState(false);
    const [ resumeMeta, setResumeMeta ] = useState(null);
    const [ loadingCV, setLoadingCV ] = useState(false);
    const [ metaRefresh , setMetaRefresh ] = useState( false);
    const [ isTag, setIsTag ] = useState(false);
    const [ isNotes, setIsNotes ] = useState(false);

    // const mailFrom = 'NAYA RECRUIT <recruitnaya@gmail.com> ';

    let candId = candidateId;
    const fullName = candidateFullName;

    //check candidate,tag,notes
    const checkTagNotes = async (candidateId)=>{
        const checkTagNotesURL = `/api/note/checkTagNote?candidateId=${candidateId}`;

        try{
            const res = await fetch(checkTagNotesURL);
            const json = await res.json();
            console.log('checkTagNotes JSON:',json);
            if(json.curTag !== 'no tag' && json.curTag !== null){
                setIsTag(true);
            }else {
                setIsTag(false);
            }
            if(json.curNotes){
                await setIsNotes(true);
            }
        }catch (e) {
            console.log('checkTagNotes ERROR:',e);
        }
    };

    //get meta data for CV from zoho (using our server side)
    const getMetaCV = async (candId)=>{
        setMetaRefresh(false);
        setLoadingCV(true);
        const token = localStorage.getItem('ZohoToken');
        const requestURL = `/api/email/getMetaCV?id=${candId}&token=${token}`;
        console.log('start fetch get meta cv');

        const reqOptions = {
            method:"GET",
            headers: {
                'Content-Type': 'application/json'
            },
        };

        try{
            const res = await fetch(requestURL, reqOptions);
            const json = await res.json();
            console.log('get cv meta JSON:',json);
            if(!json.metaCV.error){
                console.log('get cv meta JSON-metaCV:',json.metaCV);
                setResumeMeta(json.metaCV);
            }else if(json.metaCV.error){
                console.log('get cv meta ERROR:',json.metaCV.error);
                if(json.metaCV.error === 'Invalid OAuthtoken'){
                    console.log('NEED REF TOKEN TEST');
                    setMetaRefresh(true);
                }
            }
        }catch (err) {
            console.log('get cv meta ERROR:',err);
        }
        setLoadingCV(false);
        console.log('finish fetch send mail');
    };

    //getContactList
    const getContactList = async ()=>{
        console.log('getContactsList start');
        let rawOptions;
        const requestURL = '/api/email/contacts';
        const reqOptions = {
            method:"GET",
            headers: {'Content-Type': 'application/json'}
        };

        try{
            const res = await fetch(requestURL, reqOptions);
            const json = await res.json();
            rawOptions = await json;
            console.log('rawOptions:',rawOptions);

        }catch (err) {
            console.log('fetch mail contacts response with ERROR:',err);
        }
        console.log('getContactsList finish');

        // prepare options
        function prepareArrOptions(e) {
            if(typeof e === 'string'){
                return Object.create({}, { value:{value:e.email},label:{value:e.email} });
            } else {return Object.create({}, { value:{value:e.email},label:{value:e.email} })}
        }

        await setContactList(rawOptions);

        rawOptions = rawOptions.sort(function (a, b) {
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
                return 1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
                return -1;
            }
            return 0;
        });

        const preOptions = rawOptions.map(e => prepareArrOptions(e));
        await setOptions(preOptions);
    };

    //FOR E-MAIL
    const handleChange = (newValue, actionMeta) => {
        console.group('Value Changed');
        console.log(newValue);
        console.log(`action value: ${actionMeta.value}`);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();

        let incorrectMail = [];
        let newContacts = [];

        if(newValue !== null){
            let mailArr = newValue.map(item =>{
                console.log('new value item:',item);
                if(item.__isNew__){
                    console.log('NEW ITEM:',item);
                    const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
                    if(item.value.match(pattern)){
                        console.log('NEW E-MAIL CORRECT');
                        newContacts.push(item.value);
                        return item.value;
                    }else {
                        console.log('NEW E-MAIL INCORRECT');
                        incorrectMail.push(item.value);
                        return null;
                    }
                }

                return item.value;
            });
            console.log('SELECTED array:',mailArr);
            const prepareMailArr = mailArr.filter( i => i !== null);
            console.log('SELECTED prepare array:',prepareMailArr);
            setMailTo(prepareMailArr);
        }else{
            let mailArr = null;
            console.log('SELECTED array:',mailArr);
            setMailTo(mailArr);
        }

        if(incorrectMail.length > 0){
            console.log('incorrectMail:',incorrectMail);
            setIncorrect(incorrectMail);
        }else {
            setIncorrect(null);
        }

        if(newContacts.length > 0){
            console.log('newContacts:',newContacts);
            setNewAddress(newContacts);
        }else {
            setNewAddress(null);
        }
    };

    const changeMailSubject = (event)=>{
        event.preventDefault();
        console.log('mail sub:',event.target.value);
        setMailSubject(event.target.value);
    };

    const changeMailText = (event)=>{
        event.preventDefault();
        console.log('mail text:',event.target.value);
        setMailText(event.target.value);
    };

    const withLinkHandler = ()=>{
        setSendLink(!sendLink);
    };

    useEffect(async ()=>{
        setMailSubject(`Check candidate: ${fullName}`);
        const createLink = `<a href="https://naya-cv.link/candidate?id=${candId}&title=${fullName}">Link to candidate</a>`;
        setLinkToCandidate(createLink);
    },[]);

    useEffect(async ()=>{
        setMailSubject(`Check candidate: ${fullName}`);
        const createLink = `<a href="https://naya-cv.link/candidate?id=${candId}&title=${fullName}">Link to candidate</a>`;
        setLinkToCandidate(createLink);
    },[candidateFullName]);

    useEffect(async ()=>{
        if(show){
            await checkTagNotes(candId);
            await getMetaCV(candId);
            await getContactList();
        }
    },[show]);

    //FOR ADD TAG "Internal email"
    const createNoteBody = ( candidateID )=>{
        const userID = localStorage.getItem('NRuserID');
        const userName = localStorage.getItem('NRuserName');
        const currentDate = new Date();

        const noteType = 'email';

        const sendToList = mailTo.toString();
        let noteContent =
            `SENT TO: ${sendToList} \n
             SUBJECT: ${mailSubject}, \n
             TEXT: ${mailText}`;
        
        return({
            candidateId:candidateID,
            candidateFullName:candidateFullName,
            candidateTag:'Internal email',
            createdTime:currentDate,
            userID:userID,
            userName:userName,
            noteType:noteType,
            noteTitle:'Add Tag',
            noteContent:noteContent
        });
    };

    const createAddTagUrl = ( isNotes )=>{
        if(isNotes){
            return '/api/note/updateTag';
        }else {
            return '/api/note/createNewMongo';
        }
    };

    const addTagEmail = async (candidateID) =>{
        const addTagEmailURL = createAddTagUrl(isNotes);
        console.log('NOTE-addTagEmailURL:',addTagEmailURL);

        const noteBody = await createNoteBody(candidateID);
        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...noteBody})
        };

        try{
            const res = await fetch(addTagEmailURL, reqOptions);
            const json = await res.json();
            console.log('addTag JSON:',json);
            if(json.message === 'success' || json.message ==='New note added to candidate'){
                await setIsTag(true);
            }else {
                setSendingRes(<div style={{color:"red"}}><BsExclamationSquare/><h5>Add Tag Error</h5></div>);
            }
        }catch (err) {
            console.log('addTag response with ERROR:',err);
            setSendingRes(<div style={{color:"red"}}><BsExclamationSquare/><h5>Add Tag Error</h5></div>);
        }
    };

    //create mail body
    const createMailBody = async (sendLink)=>{
        if(!sendLink){
            console.log(sendLink);
            return (
                {
                    mailFrom:mailFrom,
                    mailTo:mailTo,
                    mailSubject:mailSubject,
                    mailText:mailText,
                    newContacts:newAddress,
                    withCV:resumeMeta,
                    withLink:null
                }
            );
        }else{
            return (
                {
                    mailFrom:mailFrom,
                    mailTo:mailTo,
                    mailSubject:mailSubject,
                    mailText:mailText,
                    newContacts:newAddress,
                    withCV:resumeMeta,
                    withLink:linkToCandidate
                }
            );
        }
    };

    const sendMail = async (candId)=>{
        const token = localStorage.getItem('ZohoToken');
        const requestURL = `/api/email?id=${candId}&token=${token}`;
        console.log('start fetch send mail');

        const mBody = await createMailBody(sendLink);

        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...mBody})
        };

        setSending(true);

        try{
            const res = await fetch(requestURL, reqOptions);
            const json = await res.json();
            console.log(json);
            if(json.error){
                console.log(json.error.error);
                setSendingRes(<div style={{color:"red"}}><BsExclamationSquare/><h5>Error</h5></div>);
                if(json.error.error === 'Invalid OAuthtoken'){
                    setNeedRefresh(true);
                }
                return ;
            }else {
                console.log('response json e-mail:',json.email);
                setSendingRes(<div style={{color:"#18d32a"}}><BsCheckAll/><h5>Success</h5></div>);
                await addTagEmail(candId);
            }

        }catch (err) {
            console.log('fetch to send mail response with ERROR:',err);
        }
        console.log('finish fetch send mail');
    };

    const reSendMail = async (candId)=>{
        setNeedRefresh(false);
        setSendingRes(false);
        const token = localStorage.getItem('ZohoToken');
        const requestURL = `/api/email?id=${candId}&token=${token}`;
        console.log('start fetch send mail');

        const mBody = await createMailBody(sendLink);

        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...mBody})
        };

        setSending(true);

        try{
            const res = await fetch(requestURL, reqOptions);
            const json = await res.json();
            console.log(json);
            if(json.error || !json.email){
                console.log('response e-mail ERROR:',json.error.error);
                setSendingRes(<div style={{color:"red"}}><BsExclamationSquare/><h5>Error</h5></div>);
                if(json.error.error === 'Invalid OAuthtoken'){
                    setNeedRefresh(true);
                }
                return ;
            }else {
                console.log('response json e-mail:',json.email);
                setSendingRes(<div style={{color:"#18d32a"}}><BsCheckAll/><h5>Success</h5></div>);
                await addTagEmail(candId);
            }

        }catch (err) {
            console.log('fetch to send mail response with ERROR:',err);
        }
        console.log('finish fetch send mail');
    };

    const sendingFinish = ()=>{
        setSending(false);
        setSendingRes(null);
        setShow(false);
    };

    //FOR MODAL WINDOW
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //tooltip
    const emailTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            E-mail
        </Tooltip>
    );

    return (
        <>
            {forTable ?
                <div style={{width:"28px", display:"flex",justifyContent:"center"}}>
                    <div style={{width:"80%",textAlign:"center"}}>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 750, hide: 150 }}
                            overlay={emailTooltip}
                        >
                            { btnName === 'BsEnvelope' ? <BsEnvelope  className="modal-link" fontSize={"21px"} onClick={handleShow}/>:
                                <span className="modal-link" onClick={handleShow}>{btnName}</span>}
                        </OverlayTrigger>
                    </div>
                </div>
                :
                <OverlayTrigger
                    placement="auto"
                    delay={{ show: 750, hide: 150 }}
                    overlay={emailTooltip}
                >
                    { btnName === 'BsEnvelope' ? <BsEnvelope onClick={handleShow}/>:
                        <span className="modal-link" onClick={handleShow}>{btnName}</span>}
                </OverlayTrigger>
            }

            <Modal show={show} onHide={handleClose} dialogClassName="emailModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Submit to Hiring Manager</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="sendMail-main">
                        {sending && <div className="sendMail-sending">
                            <h5>Sending:</h5>
                            <div className="sendMail-sendingRes">
                                {mailTo ? mailTo.map(item=><p>"{item}"</p>):<span></span>}
                                {sendingRes ? <div style={{textAlign:"center"}}><h6>{sendingRes}</h6></div>:<Loader/>}
                                {needRefresh && <div style={{textAlign:"center"}}>
                                    <h6 style={{color:"red"}}>Your token has expired!</h6>
                                    <RefreshToken update={()=>{reSendMail(candId)}}/>
                                </div>}
                            </div>
                            <button
                                className="login-out"
                                style={{marginTop:"10px"}}
                                disabled={sendingRes ? false : true}
                                onClick={()=>{sendingFinish()}}>OK</button>
                        </div>}

                        <div className="sendMail-noteBlock">
                            <div>
                                <span>Selected Candidate:</span>
                                <h5>{fullName}</h5>
                            </div>
                            <div className="sendMail-noteMain">
                                <p className="sendMail-note">Note:</p>
                                <p>Emails bigger than 10MB will be split and send as two or more mails</p>
                            </div>
                        </div>
                        <div className="sendMail-formBlock">
                            <div>
                                <label className="sendMail-label" htmlFor="sendMail-from">From:</label>
                                <input type='email'
                                       className="sendMail-input"
                                       name="sendMail-from"
                                       value={mailFrom}
                                       readOnly={true}
                                />
                            </div>
                            <div className="sendMail-sendToWrap">
                                <label className="sendMail-label" htmlFor="sendMail-sendTo">Send To:</label>

                                <CreatableSelect
                                    isMulti
                                    name="sendMail-sendTo"
                                    onChange={handleChange}
                                    options={options}
                                />

                                <div style={{marginTop:"12px",marginLeft:"6px"}}>
                                    <ContactList contactList={contactList} updateContactList={getContactList}/>
                                </div>
                            </div>

                            {incorrect ?
                                <div className="sendMail-incorrect">
                                    incorrect e-mail:{incorrect.map(item=><span style={{marginLeft:"8px"}}>"{item}"</span>)}
                                </div>:
                                <span></span>}

                            <div>
                                <label className="sendMail-label" htmlFor="sendMail-subject">Subject:</label>
                                <input type='text'
                                       className="sendMail-input"
                                       name="sendMail-subject"
                                       value={mailSubject}
                                       readOnly={false}
                                       onChange={(e)=>{changeMailSubject(e)}}
                                />
                            </div>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Text:</span>
                                </div>
                                <textarea className="form-control"
                                          value={mailText}
                                          readOnly={false}
                                          onChange={(e)=>{changeMailText(e)}}
                                ></textarea>
                            </div>

                            <div className="sendMail-attach">
                                <BsPaperclip style={{fontSize:"18px"}}/>
                                <span>Attachments:
                                    {metaRefresh ? <RefreshTokenLine update={()=>{getMetaCV(candId)}}/>:
                                        loadingCV ? <span style={{marginLeft:"8px",color:"#316fc2"}}><Spinner animation="grow" size="sm" variant="primary"/>Loading</span>:
                                        resumeMeta ? <span style={{marginLeft:"4px"}}>the candidate's resume is automatically attached</span>:
                                        <span style={{color:"#e44d4d", fontStyle:"italic",fontWeight:"400",marginLeft:"4px"}}>the candidate has not uploaded the resume</span>}
                                </span>
                                <div style={{marginLeft:"40px"}}>
                                    <Form.Check
                                        checked={ sendLink }
                                        onChange={withLinkHandler}
                                        inline
                                        label="Add link to candidate"
                                        type={'checkbox'}
                                        id={'linkCheck'}/>
                                </div>
                                { !isTag && <span>#interal email</span>}
                            </div>


                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        {/*<button className="blueFill-btn" onClick={()=>{console.log('res:',resumeMeta)}}>get meta</button>*/}
                        <button className="blueFill-btn" onClick={()=>{sendMail(candId)}} disabled={sending || !mailTo || (!sendLink && !resumeMeta)}>
                            Send Mail
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose} disabled={sending} >
                            Close
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EmailModal;
