import React, {useState} from 'react';
import { Modal, Button } from "react-bootstrap";
import validationAllCheck from "../AdvSearchFunctions/validationAllCheck";

const SearchSaveBtn = ({saveTitle,setSaveTitle,handleSaveTitle,saveAdvancedSearchRequest,word,criteria,filters}) => {
    const [ show, setShow ] = useState(false);
    const [ validation, setValidation ] = useState();

    const handleClose = () => setShow(false);
    const handleShow = async () => {
        console.log('show pop up');
        const allValidation = await  validationAllCheck(word,criteria,filters);
        if(allValidation === false){
            setValidation(false);
        }else {
            setValidation(true);
        }

        setSaveTitle('');
        setShow(true);
    };

    return (
        <>
            <button
                type="button"
                className="blueFill-btn"
                style={{marginLeft:"15px"}}
                onClick={handleShow}
            >Save</button>

            <Modal dialogClassName="test-dialog" show={show} onHide={handleClose}>
                <Modal.Title style={{display:"flex",justifyContent:"center",color:"#F57272",background:"#efeeef"}}>
                    SAVE NEW SEARCH
                </Modal.Title>
                <Modal.Body className="advanced-saveWindow">
                    <div className="advanced-saveWindow-bodyWrapper">
                        {validation ?
                            <>
                                <span className="advanced-notes">*Type here new search config name</span>
                                <input
                                    type={'text'}
                                    id={'searchTitleInput'}
                                    placeholder={'Request Name'}
                                    value={saveTitle}
                                    onChange={handleSaveTitle}
                                    width={'100%'}
                                />
                            </>:
                            <div style={{color:'red'}}>
                                <h4>Error!!!</h4>
                                <p>Please,check all search request parameters</p>
                            </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{background:"#efeeef"}}>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>

                    <Button variant="success" disabled={!validation} onClick={saveAdvancedSearchRequest}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default SearchSaveBtn;
