import React from 'react';
import { FaUserCircle } from "react-icons/fa";

const NoteUserParser = ({noteUser}) => {
    return (
        <div className="notes-userParser">
            <FaUserCircle/>
            <div>{noteUser}</div>
        </div>
    );
};

export default NoteUserParser;
