import React,{useRef,useEffect} from 'react';
import TextareaAutosize from "react-textarea-autosize";

const TextAreaInput = ({label, parentState, setParentState }) => {
    //change input value function
    const changeInpValue = (event)=>{
        event.preventDefault();
        setParentState(event.target.value);
    };

    //For change textarea direction leftToRight
    const textRef = useRef();
    useEffect(() => {
        if(textRef.current){
            const input = textRef.current;
            input.addEventListener('contextmenu', function(event) {
                event.preventDefault();
                input.classList.toggle("rtl");
                return false;
            }, false);
        }
    }, []);


    return (
        <div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text">{label}</span>
            </div>
            <TextareaAutosize
                className='form-control'
                ref={textRef}
                value={parentState}
                rows={5}
                maxRows={10}
                onChange={changeInpValue}/>
        </div>
    );
};

export default TextAreaInput;
