//create object Super
import React from "react";
import { Link } from "react-router-dom";
import titleNameReplacer from "../../../functions/titleNameReplacer";
import TableBigTextWrapper from "../../Candidates/TableBigTextWrapper";
import CreatedTimeWrapper from "../../Candidates/createdTimeWrapper";
import PreviewItemWrapper from "../../PreviewItem/PreviewItemWrapper";


const createSuperObjectJob = (data,dataItemFields,module,meta)=>{
    let superObject = {
        is:'Job'
    };

    data.forEach(dataItem =>{
        const prepareKeyName = titleNameReplacer(dataItem.val);

        const checkBigTextFunc = (key,str) =>{
            if(key !== 'Posting_Title' && key !== 'JOBOPENINGID'){
                if(str.length > 360){
                    return(<TableBigTextWrapper text={str} characters={360}/>);
                }else {
                    return str;
                }
            }else{
                return str;
            }
        };

        const prepareContent = checkBigTextFunc(dataItem.val,dataItem.content);

        Object.defineProperty(superObject,prepareKeyName,{
                value: prepareContent,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );
    });

    //additional for links
    if(superObject.Posting_Title && superObject.JOBOPENINGID){

        //create job title name link
        const JobTitleLink =(<Link to={{pathname:'/job', search:`id=${superObject.JOBOPENINGID}&title=${superObject.Posting_Title}`}}
                                   style={{fontWeight:"bold",fontSize:"12px"}}>
                                    {superObject.Posting_Title}
                            </Link>);

        Object.defineProperty(superObject,'Job_Title_link',{
                value: JobTitleLink,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );


        //create job preview link
        const JobPreviewLink = (<div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                                    <PreviewItemWrapper
                                        header={superObject.Posting_Title }
                                        id={`${superObject.JOBOPENINGID}`}
                                        btnName={'RiFileTextLine'}
                                        dataItemFields={dataItemFields}
                                        module={module}
                                        meta={meta}/>
                                </div>);

        Object.defineProperty(superObject,'JobPreview_Link',{
                value: JobPreviewLink,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );

    }

    if(superObject.Created_Time){
        superObject.Created_Time =(<CreatedTimeWrapper createdTime={superObject.Created_Time}/>);
    }

    return superObject;
};

export default createSuperObjectJob;
