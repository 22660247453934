import React,{useState,useEffect} from 'react';
import FieldBuilder from "../DisplayItem/FieldBuilder";
import getApiName from "../../functions/getFieldsApiName";
import RefreshToken from "../RefreshToken/RefreshToken";

const SummaryInfoParser = ({config, itemDataRec, itemId, module, meta}) => {

    const [ firstName, setFirstName ] = useState();
    const [ lastName , setLastName ] = useState();
    const [ main, setMain ] = useState();
    const [ rest, setRest ] = useState();
    const [ saveChangesFirst, setSaveChangesFirst ] = useState(false);
    const [ saveChangesLast, setSaveChangesLast ] = useState(false);
    const [ saveResultFirst, setSaveResultFirst ] = useState(null);
    const [ saveResultLast, setSaveResultLast ] = useState(null);

    const mainFields = ['Stage In Process','IsGraduate?','Education Type','Graduation Year','College Recommended','Team','Technological Domains','CV Evaluation','PhoneCall Evaluation','Frontal Interview Evaluation'];

    let prepareRecId;
    if(module === 'Candidates'){
        prepareRecId = itemDataRec.response.result.Candidates.row.FL;
    }else if(module === 'JobOpenings'){
        prepareRecId = itemDataRec.response.result.JobOpenings.row.FL;
    }

    const prepareChooseFields = (mainFields,configArray)=>{
        console.log('Config Arr:',configArray);
        console.log('mainFields:',mainFields);

        const prepareArr = mainFields.map( fieldName =>{
            const filed = configArray.find( item => item.dv === fieldName);
            return filed;
        });
        console.log('prepareArr:',prepareArr);
        setMain(prepareArr);
    };

    const prepareRestFields = (mainFields,configArray)=>{
        console.log('REST Config Arr:',configArray);
        console.log('REST mainFields:',mainFields);

        const fullMainFields = ['First Name','Last Name',...mainFields];
        console.log('REST fullMainFields:',fullMainFields);

        let cloneConfig = [...configArray];
        fullMainFields.forEach( fieldName => {
            cloneConfig = cloneConfig.filter( configItem => configItem.dv !== fieldName);
        });
        console.log('REST cloneConfig after:',cloneConfig);
        setRest(cloneConfig);
    };

    const ComponentDidMount = ()=>{
        const fieldValueFN = prepareRecId.find( candidateFl => candidateFl.val === 'First Name');
        if(fieldValueFN !== undefined){
            setFirstName(fieldValueFN.content);
        }else{
            setFirstName('');
        }

        const fieldValueLN = prepareRecId.find( candidateFl => candidateFl.val === 'Last Name');
        if(fieldValueLN !== undefined){
            setLastName(fieldValueLN.content);
        }else{
            setLastName('');
        }

        prepareChooseFields(mainFields,config);
        prepareRestFields(mainFields,config);
    };

    useEffect(()=>{
        ComponentDidMount();
    },[]);

    const inputChangeValue = ( event, setStateValue, setSaveChangesSTATE)=>{
        event.preventDefault();
        setStateValue(event.target.value);
        setSaveChangesSTATE(true);
    };

    //send update record request
    const updateRecord = async (name , inputValue, setSaveChangesSTATE, setSaveResultSTATE)=>{
        const token = localStorage.getItem('ZohoToken');
        const requestSingleURL = `/proxyZohoRecruit/recruit/v2/${module}/${itemId}`;
        let prepareName = await getApiName( meta,name );// for update customer field with api_name
        console.log('PREPARE NAME:',prepareName);

        const reqBodySingle = {"data": []};

        const reqBody = {[prepareName]: inputValue};
        reqBodySingle.data.push(reqBody);


        const requestOptions = {method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(reqBodySingle)
        };
        setSaveChangesSTATE(false);
        try{
            const res = await fetch(requestSingleURL,{...requestOptions});
            const resJson = await res.json();
            console.log('resJson:',resJson);
            if(resJson.code){
                if(resJson.code === 'INVALID_TOKEN'){
                    console.log('NEED REFRESH NOKEn');
                    setSaveResultSTATE(<RefreshToken update={()=>{updateRecord(name,inputValue)}}/>);
                }
            }else{
                setSaveResultSTATE(resJson.data[0].code);
                setTimeout(function() {
                    console.log('clear result');
                    setSaveResultSTATE(null);
                }, 4000);
            }
        }catch (e) {
            console.log('fetch update record ERROR:',e);
            setSaveResultSTATE('error');
            setTimeout(function() {
                console.log('clear result');
                setSaveResultSTATE(null);
            }, 4000);
        }

    };

    return (
        <>
            <div className="displCand-topNameWrapper">
                <div >
                    <label className="displCand-label fullName" htmlFor={'FirstN'}>First Name</label>
                    <input type='text'
                           className="displCand-input name"
                           value={firstName}
                           name={'FirstN'}
                           minLength={10}
                           maxLength={20}
                           key={'FirstN'}
                           readOnly={false}
                           onChange={(event)=>{inputChangeValue(event,setFirstName, setSaveChangesFirst)}}/>
                           {saveChangesFirst &&
                           <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord('First Name', firstName, setSaveChangesFirst, setSaveResultFirst)}}>Save</span>}

                    {saveResultFirst != null ? <span className="displCand-saveResult">saved {saveResultFirst}</span>:<span></span>}
                </div>

                <div >
                    <label className="displCand-label fullName" htmlFor={'LastN'}>Last Name</label>
                    <input type='text'
                           className="displCand-input name"
                           value={lastName}
                           name={'LastN'}
                           maxLength={20}
                           key={'LastN'}
                           readOnly={false}
                           onChange={(event)=>{inputChangeValue(event,setLastName,setSaveChangesLast)}}/>

                    {saveChangesLast &&
                    <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord('Last Name', lastName, setSaveChangesLast, setSaveResultLast)}}>Save</span>}

                    {saveResultLast != null ? <span className="displCand-saveResult" >saved {saveResultLast}</span>:<span></span>}
                </div>
            </div>

            {main && <>
                {main.map((c) => {return <FieldBuilder  IndividualConfig={c} prepareRecId={prepareRecId} itemId={itemId} module={module} meta={meta}/>})}
            </>}

            {rest && <>
                {rest.map((c) => {return <FieldBuilder  IndividualConfig={c} prepareRecId={prepareRecId} itemId={itemId} module={module} meta={meta}/>})}
            </>}

        </>
    );
};

export default SummaryInfoParser;
