import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
// import { Accordion,Card,useAccordionButton,AccordionContext } from 'react-bootstrap';
import {Accordion, AccordionContext, Card, OverlayTrigger, Tooltip, useAccordionToggle} from 'react-bootstrap'; //old bootstrap
import CreateNewSearch from "../CreateNewSearch/CreateNewSearch";
import DemoData from "../DemoData/DemoData";
import SearchDetailsParser from "./SearchDetailsParser";
//REDUX
import {connect} from "react-redux";
import {
    clearDataRecords,
    fetchDataRecords,
    fetchDataRecordsFilter,
    updateSearchArr
} from "../../redux/Actions/actionsCreator";
//Icons
import {BsFillCaretDownFill} from "react-icons/bs";


const SearchMain = (props) => {
    console.log('SEARCH MAIN PROPS',props);
    const [ sortSearches , setSortSearches ] = useState([]);

    const getNayaApiData = () =>{
        console.log('start fetch naya api');
        fetch('/api/search/getAllMongo', {method:"get"})
            .then(res=>res.json())
            // .then(json=>console.log('API JSON',json))
            .then( json => props.updateSearchArr (json) )
            .catch(err=>console.log('ERR',err))
    };

    useEffect(()=>{
        getNayaApiData();
    },[]);

    //for sorting searches list
    const sortArray = (arr)=>{
        return arr.sort(function (a, b) {
            if (a.searchTitle.toLowerCase() > b.searchTitle.toLowerCase()) {
                return 1;
            }
            if (a.searchTitle.toLowerCase() < b.searchTitle.toLowerCase()) {
                return -1;
            }
            return 0;
        });
    };

    useEffect(()=>{
        if (props.savedSearchRedux){
            const prepareData = sortArray(props.savedSearchRedux);
            setSortSearches(prepareData);
        }
    },[props.savedSearchRedux]);

    // delete item API
    async function deleteSearchItem(id){
        if(props.isDemo){
            const prevSearchArr = props.savedSearchRedux;
            let curItemIndex = prevSearchArr.findIndex( item => item.searchId === id);
            prevSearchArr.splice(curItemIndex, 1);
            props.updateSearchArr(prevSearchArr);
            console.log('REDUX STATE',props.savedSearchRedux);
        }else{
            try {
                console.log('start delete req');
                // const deleteUrl = `/api/search/delete?id=${id}`;//json data version
                const deleteUrl = `/api/search/deleteMongo?id=${id}`;//mongo db
                const res = await fetch(deleteUrl,{ method:"delete"});
                const json = await res.json();
                console.log('req delete json:',json);
                if(json.message === 'item deleted'){
                    getNayaApiData();
                }
            }catch (e) {
                console.log('delete search item req error:',e);
            }
        }
    }


    function getRecordsByItem (itemString,itemTitle,itemId,itemFilters) {
        props.clearDataRecords();
        if(itemFilters.length > 0){
            console.log('With Filter');
            console.log('FILTER MULTI:',itemFilters);
            props.fetchDataRecordsFilter(itemString,itemFilters,itemTitle,itemId);
            props.history.push('/candidates');
        }else{
            console.log('With OutFilter');
            props.fetchDataRecords(itemString,itemTitle,itemId);
            props.history.push('/candidates');
        }

    }

    // for accordion with active link
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        // const decoratedOnClick = useAccordionButton(
        //     eventKey,
        //     () => callback && callback(eventKey),
        // );

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <span
                className={ isCurrentEventKey ? 'searchDetailsBtn-active':'searchDetailsBtn'}
                onClick={decoratedOnClick}
            >
                {children}
            </span>
        );
    }

    //for tooltip
    const searchDetailsTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Search Details
        </Tooltip>
    );

    return(
        <div className="searchMain">
            <div className="container">
                <h3 className="containter__main-title">Search Configs</h3>
                {props.isAuthRedux && <CreateNewSearch/>}
                {/*<button onClick={()=>{console.log(nayaData)}}>check</button>*/}

                <div className="searchMain-wrapper">
                    <div className="searchMain-title">
                        <div style={{width:"40%"}}><span style={{marginLeft:"5px"}}>Search Name</span></div>
                        <div style={{width:"45%"}}>Key Words</div>
                        <div style={{width:"15%"}}>Actions</div>
                    </div>
                    {props.savedSearchRedux.length === 0 && <DemoData/>}

                    <Accordion style={{width:"100%"}}>
                        {sortSearches.map((item,index) =>(
                            <Card key={item._id}>
                                <Card.Header>
                                    <div className="searchMain-item">
                                        <div style={{width:"40%"}}>
                                            {props.isAuthRedux ?
                                                <span onClick={()=>{getRecordsByItem(item.searchString,item.searchTitle,item._id,item.searchFilterCriterias)}}
                                                      style={{fontWeight:"600",marginLeft:"8px",color:"#0f4c75",cursor:"pointer"}}>
                                                    {item.searchTitle}
                                                </span>:
                                                <span style={{fontWeight:"600",marginLeft:"8px",color:"#515252"}}>
                                                    {item.searchTitle}
                                                </span>}
                                        </div>
                                        <div style={{width:"45%"}}>{item.searchCriteria.keyWorld}</div>
                                        <div className="searchMain-actionsWrapper">
                                            <div>
                                                {props.isAuthRedux ?
                                                    <Link to={{pathname:'/advancedSearch',
                                                        search:`${item._id}`}}
                                                          style={{fontWeight:"700",fontSize:"14px"}}>
                                                        edit</Link>
                                                    :
                                                    <span style={{fontWeight:"700",fontSize:"14px"}}>edit</span>}

                                                {props.isAuthRedux ?
                                                    <span onClick={()=>{deleteSearchItem(item._id)}} className="searchMain-deleteBtn">
                                                        delete
                                                    </span>:
                                                    <span style={{fontWeight:"700",fontSize:"14px",marginLeft:"10px"}}>
                                                        delete
                                                    </span>}
                                            </div>

                                            <div>
                                                <ContextAwareToggle eventKey={index+1}>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 750, hide: 150 }}
                                                        overlay={searchDetailsTooltip}
                                                    >
                                                        <BsFillCaretDownFill/>
                                                    </OverlayTrigger>
                                                </ContextAwareToggle>
                                            </div>

                                        </div>
                                    </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey={index+1}>
                                    <Card.Body>
                                        <SearchDetailsParser
                                            searchCriteria={item.searchCriteria}
                                            searchAddCriterias={item.searchAddCriterias}
                                            searchFilterCriterias={item.searchFilterCriterias}/>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>

                </div>

            </div>


        </div>
    );
};

const mapStateToProps = state => {
    return{
        savedSearchRedux: state.savedSearchReducer.savedSearchArr,
        isDemo: state.demoReducer.isDemo,
        isAuthRedux: state.signinReducer.auth,
    }
};

const mapDispatchToProps =  {
    updateSearchArr,
    fetchDataRecords,
    clearDataRecords,
    fetchDataRecordsFilter
};

export default connect(mapStateToProps,mapDispatchToProps)(SearchMain);

