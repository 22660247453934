import React, {useEffect, useState} from 'react';
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import ContactItem from "./ContactItem";
import './contacts.css';


const ContactList = ({contactList,updateContactList}) =>{
    const [ show, setShow ] = useState(false);
    const [ sortContacts, setSortContacts ] = useState( null);

    useEffect(()=>{
        if(contactList){
            const sortContacts = contactList.sort();
            console.log('SORT:',sortContacts);
        }
    },[]);

    useEffect(()=>{
        if(contactList){
            const sortContacts = contactList.sort(function (a, b) {
                if (a.email.toLowerCase() > b.email.toLowerCase()) {
                    return 1;
                }
                if (a.email.toLowerCase() < b.email.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
            console.log('SORT:',sortContacts);
            setSortContacts(sortContacts);
        }
    },[contactList]);

    //FOR MODAL WINDOW
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //tooltip
    const contactTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Edit Contacts
        </Tooltip>
    );

    return (
        <>
            <OverlayTrigger
                placement="auto"
                delay={{ show: 750, hide: 150 }}
                overlay={contactTooltip}>
                <span className="modal-link" onClick={handleShow}><BsReverseLayoutTextSidebarReverse/></span>
            </OverlayTrigger>

            {/*<span className="modal-link" onClick={handleShow}> <BsReverseLayoutTextSidebarReverse/> </span>*/}

            <Modal show={show} onHide={handleClose} dialogClassName="contactsList-modalDialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Edit Contacts</h3>
                </Modal.Title>
                <Modal.Body>
                    {contactList &&
                        <div>
                            {contactList.map(contact => <ContactItem contact={contact} updateContactList={updateContactList}/>)}
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default ContactList;
