import React from 'react';
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const TableBigTextWrapper = ({text , characters = 70}) => {

    const bigTextToolTip = (props)=>{
        // console.log(text);
        return(
            <Tooltip id="bigText-toolTip" className="bigText-Tool"  {...props}>
                {text}
            </Tooltip>);
    };


    return (
        <>
            <div>
                {text.length > characters &&
                <OverlayTrigger
                    placement="auto"
                    delay={{ show: 750, hide: 150 }}
                    overlay={bigTextToolTip}
                    text={text}
                >
                    <div  className="table-BigTextWrapper">
                        {text.slice(0,characters)}
                        <span className="table-showMore">...</span>
                    </div>
                </OverlayTrigger>

                }
            </div>
        </>
    );
};

export default TableBigTextWrapper;
