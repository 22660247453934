import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import Loader from "../Loader/Loader";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash  } from "react-icons/fa";
import './resetPassword.css';

const ResetPasswordPage = () =>{
    const [ pass, setPass ] = useState('');
    const [ passConfirm, setPassConfirm ] = useState('');
    const [ passwordShown, setPasswordShown ] = useState(false);
    const [ validationConfirm, setValidationConfirm ] = useState(null);
    const [ isSending, setIsSending ] = useState(false);
    const [ result, setResult ] = useState(null);
    const [ error, setError] = useState(null);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let queryParams = useQuery();
    let resetToken = queryParams.get("resetToken");
    console.log('reset token:',resetToken);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const changeInputValue = (event, setState)=>{
        event.preventDefault();
        setState(event.target.value);
    };

    const changeInputConfirm = (event, setState)=>{
        event.preventDefault();
        setState(event.target.value);

        if(event.target.value.length > 0){
            if(event.target.value === pass){
                setValidationConfirm('valid');
            }else{
                setValidationConfirm('invalid');
            }
        }else{
            setValidationConfirm(null);
        }
    };

    const updateRequest = async (resetToken,pass)=>{
        setIsSending(true);
        const updateRequestURL = '/api/user/updatePassword';

        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({resetToken:resetToken, password:pass })
        };

        try {
            const res = await fetch(updateRequestURL,reqOptions);
            const json = await res.json();
            console.log('singInRequest JSON:',json);
            if(json.response === 'SUCCESS'){
                setResult(json.response);
            }else{
                setResult(json.response);
                setError(json.message);
            }
        }catch (e) {
            console.log('singInRequest ERROR:',e);
            setResult('ERROR');
        }
        setIsSending(false);
    };

    return (
        <div className="container">
            <h3 className="containter__main-title">Reset Password</h3>
            <div className="resetPassword-main">
                <h2 style={{color:"#b8b8b8"}}>Type new password</h2>
                <div className="loginModal-pass">
                    <input type={passwordShown ? "text" : "password"}
                           placeholder={'Password'}
                           onChange={(e)=>{changeInputValue(e,setPass)}}
                           style={{width:'100%'}}/>
                    <div className="loginModal-passEye"
                         onClick={togglePassword}>
                        {passwordShown ? <FaEye style={{color:"#33e426"}}/> :<FaEyeSlash style={{color:"#6c6a6b"}}/> }
                    </div>
                </div>

                <div className="loginModal-pass">
                    <input type={passwordShown ? "text" : "password"}
                           placeholder={'Confirm Password'}
                           onChange={(e)=>{changeInputConfirm(e,setPassConfirm)}}
                           style={{width:'100%'}}/>
                    <div className="loginModal-passEye"
                         onClick={togglePassword}>
                        {passwordShown ? <FaEye style={{color:"#33e426"}}/> :<FaEyeSlash style={{color:"#6c6a6b"}}/> }
                    </div>
                    {validationConfirm === 'invalid' && <span style={{fontSize:"10px",color:"red"}}>passwords do not match</span>}
                </div>

                <div className="resetPassword-btnBlock">
                    <button className="blueFill-btn"
                            onClick={()=>{updateRequest(resetToken,pass)}}
                            disabled={validationConfirm === 'invalid' || validationConfirm === null || isSending || result}>
                        Update password
                    </button>
                </div>

                {isSending && <Loader/>}
                {result &&
                    <div className={result === 'SUCCESS' ? 'resetPassword-success' : 'resetPassword-error'}>
                        <div><h3>{result}</h3></div>
                        {error && <div>{error}</div>}
                    </div>}
            </div>
        </div>
    );
};

export default ResetPasswordPage;
