import {CLEAR_RECORDS, SEARCH_RECORDS_KEYWORD} from '../Actions/actionTypes';
import {SEARCH_RECORDS} from "../Actions/actionTypes";

const init = {
    searchString: null,
    searchDataRecords: null,
    searchTitle: null,
    searchId: null,
    forJobTitle: null,
    forJobId: null,
    keyWord: null
};

export default function mainReducer( state = init, action ) {
    switch (action.type) {
        case CLEAR_RECORDS:
            return {...state, searchString: null , searchDataRecords: null ,searchTitle: null ,searchId: null, forJobTitle: null, forJobId: null, keyWord: null};

        case SEARCH_RECORDS:
            return {...state, searchDataRecords: action.payload, searchString:action.payloadString, searchTitle: action.payloadTitle,searchId: action.payloadId, forJobTitle: action.payloadJobTitle, forJobId:action.payloadJobId};

        case SEARCH_RECORDS_KEYWORD:
            return {...state, searchDataRecords: action.payload, searchString:action.payloadString, keyWord: action.payloadKeyWord ,searchTitle: null ,searchId: null, forJobTitle: null, forJobId: null};

        default: return state
    }
}

