import React, {useState,useEffect} from 'react';
import headerReq from "../../functions/headers";
import {Button, OverlayTrigger, Tab, Tabs, Tooltip} from "react-bootstrap";
import Loader from "../Loader/Loader";
import RefreshToken from "../RefreshToken/RefreshToken";
import EmailModal from "../Email/EmailModal";
import SummaryInfoParser from "../PreviewItem/SummaryInfoParser";
import MultiRecursive from "../DisplayItem/MultiRecursive";
import SingleRecursive from "../DisplayItem/SingleRecursive";
import RemoveItemList from "../buttons/RemoveItemList";
import {Link} from "react-router-dom";
import Linkedin from "../Linkedin/Linkedin";
import DisplayNotes from "../Notes/DisplayNotes/DisplayNotes";
import ZohoCandidate from "../ZohoCandidate/ZohoCandidate";
import CandidatesJobParser from "../CandidatesJobsParser/CandidatesJobParser";
import CreateNotesPriority from "../Notes/CreateNotesPriority/CreateNotesPriority";
//Icons
import { RiFileSearchLine } from "react-icons/ri";
import { BsX } from 'react-icons/bs';


const QuickEdit = ({header, id, dataItemFields, module, meta, showEdit , setShowEdit, handleShowCV, previewItemCV, closeBoth}) =>{
    const [ dataFields, setDataFields ] = useState({...dataItemFields});
    const [ dataItem, setDataItem ] = useState(null);
    const [ candidatesList, setCandidatesList ] = useState(null);
    const [ jobExceptions, setJobExceptions ] = useState(null);
    const [ linkedinLink, setLinkedinLink ] = useState(null);
    const [ candidateNotes, setCandidateNotes ] = useState(null);

    const getJobCandidates = async (jodID) =>{
        console.log('getJobCandidates-JOB ID:',jodID);

        const getJobCandidatesURL = `/api/dependency/getByIdMongo?id=${jodID}`;
        try{
            const res = await fetch( getJobCandidatesURL, {method:"get"});
            const json = await res.json();
            console.log('getByIdMongo JSON:',json);
            setCandidatesList(json.candidatesForJob);
            setJobExceptions(json.jobExceptions);
        }catch (e) {
            console.log('getByIdMongo ERROR');
        }
    };

    const getCandidateDataMongo = async (candidateID)=>{
        const getCandidateDataURL = `/api/candidate/getByCandidateId?id=${candidateID}`;

        try{
            const res = await fetch(getCandidateDataURL);
            const json = await res.json();
            console.log('getCandidateData JSON:',json);
            if(json.response){
                setLinkedinLink(json.response.linkedin);
                if(json.response.notes){
                    console.log('getCandidateWithNote JSON.notes:',json.response.notes);
                    console.log('getCandidateWithNote JSON.notes.notes:',json.response.notes.notes);
                    await setCandidateNotes(json.response.notes.notes);
                }
            }
        }catch (e) {
            console.log('getCandidateData ERROR:',e);
        }
    };

    async function downLoadItem (){
        console.log('Download ITEM START');
        let config = headerReq();

        const getDataCandidate = (getItemUrl) =>
            fetch(getItemUrl,{...config}).then(response => response.json());

        try{
            const getItemUrl=`/proxyZohoRecruit/recruit/private/json/${module}/getRecordById?version=2&id=${id}`;
            const jsonDataItem = await getDataCandidate(getItemUrl);
            console.log('Download JSON:',jsonDataItem);
            await setDataItem(jsonDataItem);
        }catch (e) {
            console.log('data item ERROR:',e);
        }

    }

    const closeQuickEdit = ()=>{
        if(!previewItemCV){
            closeBoth();
        }else{
            setShowEdit(false)
        }
    };

    useEffect( async ()=>{
        if(showEdit === true && dataItem === null){
            await downLoadItem ();
            await getCandidateDataMongo(id)
        }
    },[showEdit]);

    //Tooltips
    const openQuickCVTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Quick CV
        </Tooltip>
    );

    let dataSections;

    if(!dataFields || !dataItem){
        return (
            <div className={showEdit ? "prevItem-content" : "prevItem-content-hide"} onClick={event => event.stopPropagation()}>
                <div style={{display:"flex",textAlign:"center"}}>
                    <h3 style={{color:"#F57272",textAlign:"center"}}>Quick Edit</h3>
                    <Button variant="secondary" className="prevItem-closeBtn" onClick={()=>{closeQuickEdit()}}>
                        <BsX size={'22px'}/>
                    </Button>
                </div>
                <p style={{fontSize:"20px",color:"#1f1ede"}}><span>{header}</span><span style={{marginLeft:"20px"}}>id:{id}</span></p>
                <Loader/>
            </div>
        );
    }else if(!dataItem.response.result){
        if(dataItem.response.error.code === "4834" ){
            return (
                <div className={showEdit ? "prevItem-content" : "prevItem-content-hide"} onClick={event => event.stopPropagation()}>
                    <div style={{display:"flex",textAlign:"center"}}>
                        <h3 style={{color:"#F57272",textAlign:"center"}}>Quick Edit test</h3>
                        <Button variant="secondary" className="prevItem-closeBtn" onClick={()=>{closeQuickEdit()}}>
                            <BsX size={'22px'}/>
                        </Button>
                    </div>
                    <p style={{fontSize:"20px",color:"#1f1ede"}}><span>{header}</span><span style={{marginLeft:"20px"}}>id:{id}</span></p>
                    <div style={{textAlign:"center"}}>
                        <h3 style={{color:"#F57272"}}>Your token has expired!</h3>
                        <p style={{fontSize:"18px"}}> <RefreshToken update={downLoadItem}/> </p>
                    </div>
                </div>
            );
        }
    }else{
        if(module === 'Candidates'){
            console.log('PREVIEW DATA FIELDS:',dataFields);
            dataSections = dataFields.Candidates.section;
        }else if(module === 'JobOpenings'){
            dataSections = dataFields.JobOpenings.section;
        }}

    return (
        <div className={showEdit ? "prevItem-content" : "prevItem-content-hide"} onClick={event => event.stopPropagation()}>
                    <div style={{display:"flex",textAlign:"center"}}>
                        <h3 style={{color:"#F57272",textAlign:"center"}}>Quick Edit test</h3>
                        <Button variant="secondary" className="prevItem-closeBtn" onClick={()=>{closeQuickEdit()}}>
                            <BsX size={'22px'}/>
                        </Button>
                    </div>
                    <div style={{display:"flex",textAlign:"center",alignItems:"baseline"}}>
                        <span style={{fontSize:"20px",color:"#1f1ede",marginRight:"16px"}}>{header}</span>
                        <div className="prevItem-actionWrapper">

                            {module === 'Candidates' &&
                                <span className="blueWhite-Btn">
                                    <EmailModal candidateId={id} candidateFullName={header} btnName={'BsEnvelope'} forTable={false}/>
                                </span>
                            }

                            {module === 'Candidates' &&
                                <span className="blueWhite-Btn" onClick={()=>{handleShowCV()}}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 750, hide: 150 }}
                                        overlay={openQuickCVTooltip}>
                                        <RiFileSearchLine/>
                                    </OverlayTrigger>
                                </span>}

                            {module === 'Candidates' &&
                                <Linkedin
                                    candidateId={id}
                                    candidateFullName={header}
                                    linkToLinkedin={linkedinLink}
                                    setLinkedinLink={setLinkedinLink}/>}

                            {module === 'Candidates' &&
                                <CreateNotesPriority
                                    candidateID={id}
                                    candidateFullName={header}
                                    forComponent={'cv'}
                                    parentOpen={showEdit}
                                    updateFunc={getCandidateDataMongo}/>}

                            {module === 'Candidates' &&
                                <ZohoCandidate candidateID={id}/>}

                        </div>
                    </div>

                    <Tabs defaultActiveKey={module === 'Candidates' ? "מידע סיכומי" : "Potential Information"} id="uncontrolled-tab-example">
                        {dataSections.map((section,index)=>{
                            if(section.name === "מידע סיכומי"){
                                console.log('TAB PARSER');
                                return (
                                    <Tab eventKey={section.name} title={section.name} key={index}>
                                        <div className="prevItem-tab">
                                            <h2 className="prevItem-title">{section.name}</h2>
                                            <SummaryInfoParser
                                                key={index}
                                                config={section.FL}
                                                itemDataRec={dataItem}
                                                itemId={id}
                                                module={module}
                                                meta={meta}
                                            />
                                        </div>
                                    </Tab>
                                );
                            }
                            return(
                                <Tab eventKey={section.name} title={section.name} key={index}>
                                    <div className="prevItem-tab">
                                        <h2 className="prevItem-title">{section.name}</h2>
                                        {Array.isArray(section.FL) ?
                                            <MultiRecursive config={section.FL} itemDataRec={dataItem} key={index} itemId={id} module={module} meta={meta}/>:
                                            <SingleRecursive config={section.FL} itemDataRec={dataItem} key={index} itemId={id} module={module} meta={meta}/>}
                                    </div>
                                </Tab>
                            );
                        })}

                        { module === 'JobOpenings' &&
                        <Tab eventKey={'CANDIDATES'} title={'CANDIDATES'}>
                            <div className="prevItem-tab">
                                <h2 className="prevItem-title">Candidates List</h2>
                                { candidatesList === null ? <div style={{fontWeight:"bold",fontSize:"16px",color:"#33339e"}}> No selected candidates</div> :
                                    candidatesList.length === 0 ? <div style={{fontWeight:"bold",fontSize:"16px",color:"#33339e"}}> No selected candidates</div> :
                                        <div className="previewItem-candidateLink-container">
                                            {candidatesList.map( candidateItem =>
                                                <div>
                                                    <RemoveItemList
                                                        jobID={id}
                                                        candidateID={candidateItem.candidateID}
                                                        candidateFullName={candidateItem.candidateFullName}
                                                        updateFunc={getJobCandidates}
                                                        removeFrom={'CandidateList'}
                                                    />
                                                    <Link to={{pathname:'/candidate',search:`id=${candidateItem.candidateID}&title=${candidateItem.candidateFullName}`}}
                                                          style={{fontWeight:"bold",fontSize:"16px"}}>
                                                        {candidateItem.candidateFullName}
                                                    </Link>
                                                </div>)}
                                        </div>}
                            </div>
                        </Tab>
                        }

                        { module === 'JobOpenings' &&
                        <Tab eventKey={'Exceptions'} title={'EXCEPTIONS'}>
                            <div className="prevItem-tab">
                                <h2 className="prevItem-title">Exceptions List</h2>
                                { jobExceptions === null ? <div style={{fontWeight:"bold",fontSize:"16px",color:"#33339e"}}> No selected candidates</div> :
                                    jobExceptions.length === 0 ? <div style={{fontWeight:"bold",fontSize:"16px",color:"#33339e"}}> No selected candidates</div> :
                                        <div className="previewItem-candidateLink-container">
                                            {jobExceptions.map( candidateItem =>
                                                <div>
                                                    <RemoveItemList
                                                        jobID={id}
                                                        candidateID={candidateItem.candidateID}
                                                        candidateFullName={candidateItem.candidateFullName}
                                                        updateFunc={getJobCandidates}
                                                        removeFrom={'JobException'}
                                                    />
                                                    <Link to={{pathname:'/candidate',search:`id=${candidateItem.candidateID}&title=${candidateItem.candidateFullName}`}}
                                                          style={{fontWeight:"bold",fontSize:"16px"}}>
                                                        {candidateItem.candidateFullName}
                                                    </Link>
                                                </div>)}
                                        </div>}
                            </div>
                        </Tab>
                        }

                        { module === 'Candidates' &&
                        <Tab eventKey={'Notes'} title={'NOTES'}>
                            <div className="prevItem-tab">
                                <h2 className="prevItem-title">Notes</h2>
                                <DisplayNotes candidateID={id} fullName={header} candidateNotes={candidateNotes} setCandidateNotes={setCandidateNotes}/>
                            </div>
                        </Tab>}

                        { module === 'Candidates' &&
                        <Tab eventKey={'Jobs'} title={'JOBS'}>
                            <div className="prevItem-tab">
                                <h2 className="prevItem-title">Jobs</h2>
                                <CandidatesJobParser candidateID={id} candidateFullName={header}/>
                            </div>
                        </Tab>}

                    </Tabs>
        </div>
    );

};

export default QuickEdit;
