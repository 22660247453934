import React from 'react';
import SelectedCandidatesItem from "./SelectedCandidatesItem";
import SentCandidatesItem from "./SentCandidatesItem";
import "./candidateItemParser.css"

const CandidatesItemParser = ({candidatesList,jobID,getJobCandidates}) => {
    let selectedCandidates = [];
    let sentCandidates = [];

    candidatesList.map( candidateItem =>{
        if(candidateItem.candidateStatus){
            sentCandidates.push(candidateItem);
        }else {
            selectedCandidates.push(candidateItem);
        }
    });

    return (
        <div className="candidateItemParser">
            <div className="candidateItemParser-block">
                <div className="candidateItemParser-blockName">selected for job</div>
                {selectedCandidates.map( candidateItem =>
                    <SelectedCandidatesItem selectedCandidate={candidateItem} jobID={jobID} getJobCandidates={getJobCandidates}  actionBtn={'sent'}/>)}
            </div>

            <div className="candidateItemParser-block">
                <div className="candidateItemParser-blockName">sent to customer</div>
                {sentCandidates.length > 0 && sentCandidates.map( candidateItem =>
                    <SentCandidatesItem sentCandidate={candidateItem} jobID={jobID} getJobCandidates={getJobCandidates} />)}
            </div>

        </div>
    );
};

export default CandidatesItemParser;
