import React from 'react';


const SentTimeWrapper = ({statusHistory}) =>{
    console.log('SENT time-statusHistory:',statusHistory);

    const sentTime = statusHistory[0].modifiedData;
    console.log('SENT time:',sentTime);
    console.log('SENT time type:',typeof (sentTime));

    const test = new Date(sentTime);

    return (
        <div className="candidateItemParser-actionBtn-dis" >
            {test.toLocaleDateString()}
        </div>
    );
};

export default SentTimeWrapper;
