import React,{useEffect, useState} from 'react';
import Loader from "../Loader/Loader";
import WrapperFieldBuilderCand from "./WrapperFieldBuilderCand";
import headerReq from "../../functions/headers";
import RefreshToken from "../RefreshToken/RefreshToken";

const CreateNewCandidate = () => {
    const [ isLoading, setIsLoading ] = useState( false);
    const [ candidateFields, setCandidateFields ] = useState(null);
    const [ error, setError ] = useState(null);

    const getCandidateFields = async  ()=>{
        setError(null);
        let config = headerReq();
        const getFieldsURL = `/proxyZohoRecruit/recruit/private/json/Candidates/getFields?version=2`;

        try{
            const res = await fetch(getFieldsURL, {...config});
            const json = await res.json();
            console.log('getJobFields JSON:',json);
            if(json.response){
                console.log('IF  response  error JSON:',json);
                setError(<span style={{color:"red"}}>{json.response.error.message}<RefreshToken update={getCandidateFields}/></span>);
            }else {
                console.log('FIELDS:',json.Candidates);
                setCandidateFields(json.Candidates);
                // setJobFields(json.JobOpenings);
            }
        }catch (e) {
            console.log('getJobFields ERROR:',e);
        }
    };

    useEffect(async ()=>{
        setIsLoading(true);
        await getCandidateFields();
        setIsLoading(false);
    },[]);

    return (
        <div className="container">
            <h3 className="containter__main-title">Create New Candidate</h3>
            { isLoading ? <Loader/> :
                error ? <div style={{width:"100%",textAlign:"center"}}>{error}</div> :
                    candidateFields ? <WrapperFieldBuilderCand jobFields={candidateFields} /> :
                        <div></div>}

        </div>
    );
};

export default CreateNewCandidate;
