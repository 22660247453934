import React, {useEffect, useState} from 'react';
import SetUserRole from "./SetUserRole";
import DeleteUser from "./DeleteUser";

const UserRole = ({userID,currentRole,firstName,secondName,updateUsers}) => {
    const [ role, setRole ] = useState(currentRole);
    const [ selectLabel, setSelectLabel ] = useState(null);

    const roleOptions = [{label:'User',value:'User'},{label:'Recruiter',value:'recruiter_hr'},{label:'Manager',value:'naya_manager'},{label:'Admin',value:'naya_rt_admin'} ];

    const getLabel = (val,optionsArr)=>{
        const curOption = optionsArr.find( item => val === item.value);
        return curOption.label;
    };

    useEffect(()=>{
        const currentLabel = getLabel(role,roleOptions);
        setSelectLabel(currentLabel);
    },[]);

    useEffect(()=>{
        const currentLabel = getLabel(role,roleOptions);
        setSelectLabel(currentLabel);
    },[role]);

    return (
        <div className="adminPanel-usersRole">
            {selectLabel}
            <div>
                <SetUserRole  userID={userID} currentRole={role} roleOptions={roleOptions} setRole={setRole} firstName={firstName} secondName={secondName}/>
                <DeleteUser userID={userID} firstName={firstName} secondName={secondName} updateParent={updateUsers} />
            </div>
        </div>
    );
};

export default UserRole;
