import React from 'react';
import DisplayResume from "../DisplayResume/DisplayResume";
import PreviewItemWrapper from "../PreviewItem/PreviewItemWrapper";
import PreviewCvWrapper from "../PreviewCV/PreviewCvWrapper";
import EmailModal from "../Email/EmailModal";
import AddCandidateToJob from "../AddCandidateToJob/AddCandidateToJob";
import ExcludeCandidateForSearch from "../buttons/ExcludeCandidateForSearch";
import CreateNotesPriority from "../Notes/CreateNotesPriority/CreateNotesPriority";

const CandidateActionsWrapper = ({candidateID,candidateFullName,dataItemFields,metaData,module,jobId,jobCandidatesArr,updateJCandidatesSTATE,exceptions,setExceptions,withTag}) =>{

    return (
        <div className="table-allActionsWrapper">
            {(jobId !== null && jobCandidatesArr !== null) ?
                <AddCandidateToJob
                    candidateID={candidateID}
                    candidateFullName={candidateFullName}
                    jobID={jobId}
                    jobCandidatesArr={jobCandidatesArr}
                    updateJCandidatesSTATE={updateJCandidatesSTATE}
                    exceptions={exceptions}/>:<div></div>}

            {/*<DisplayResume candidateId={`${candidateID}`} btnName={'RiFileSearchLine'}/>*/}

            <div style={{width:"96px", display:"flex", flexDirection:"row"}}>
                <PreviewCvWrapper
                    header={candidateFullName}
                    id={`${candidateID}`}
                    btnName={'RiFileUserLine'}
                    dataItemFields={dataItemFields}
                    module={module}
                    meta={metaData}
                    withTag={withTag} />

                <PreviewItemWrapper
                    header={candidateFullName}
                    id={`${candidateID}`}
                    btnName={'RiFileUserLine'}
                    dataItemFields={dataItemFields}
                    module={module}
                    meta={metaData}
                    withTag={withTag} />

                <EmailModal candidateId={`${candidateID}`} candidateFullName={candidateFullName} btnName={'BsEnvelope'} forTable={true}/>

                <CreateNotesPriority
                    candidateID={candidateID}
                    candidateFullName={candidateFullName}
                    updateNotesFunc={()=>{console.log('update note function')}}
                    forComponent={'table'}
                    withTag={withTag} />
            </div>


            {(jobId !== null && jobCandidatesArr !== null) &&
                <ExcludeCandidateForSearch
                    candidateFullName={candidateFullName}
                    candidateID={`${candidateID}`}
                    exceptions={exceptions}
                    setExceptions={setExceptions}/>}
        </div>
    );
};

export default CandidateActionsWrapper;
