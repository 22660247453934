import React, {useState} from 'react';
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";

const EditJobNote = ({noteID,jobID, currentType,createdTime, currentTitle, currentText, updateNotesFunc }) => {
    const [ show, setShow ] = useState(false);
    const [ noteText, setNoteText ] = useState(currentText);
    const [ noteTitle, setNoteTitle] = useState(currentTitle);

    const userID = localStorage.getItem('NRuserID');
    const userName = localStorage.getItem('NRuserName');

    const noteType = currentType;

    const changeInputValue = ( event, setInputState )=>{
        event.preventDefault();
        console.log('changeInputValue:',event.target.value);
        setInputState(event.target.value);
    };

    //Create NOTE
    const createNoteBody = ()=>{
        const currentDate = new Date();
        console.log('NOTE currentDate:',currentDate);

        return ({
            createdTime: createdTime,
            createdByUser:{
                userID:userID,
                userName:userName
            },
            editModifiedTime:currentDate,
            editNoteType:noteType,
            editNoteTitle:noteTitle,
            editNoteContent:noteText
        })
    };

    const editNote = async () =>{
        console.log('NOTE edit note start');
        const editJobNoteURL = `/api/jobNote/editNoteMongo?jobId=${jobID}&noteId=${noteID}`;

        const noteBody = createNoteBody();
        const reqOptions = {
            method:"PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...noteBody})
        };
        console.log('NOTE editNote body:',noteBody);

        try{
            const res = await fetch( editJobNoteURL, reqOptions);
            const json = await res.json();
            console.log('Note JSON:',json);
            if(json.error){
                console.log('Note ERROR',json.error);
                return ;
            }else {
                console.log('Edit note response json:',json);
                if(json.message === 'note edited'){
                    await updateNotesFunc();
                    await handleClose();
                }
            }
        }catch (err) {
            console.log('fetch to send mail response with ERROR:',err);
        }

        console.log('NOTE edit note finish');
    };

    //FOR MODAL WINDOW
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //tooltip
    const editNoteTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Edit Note
        </Tooltip>
    );

    return (
        <>
            <OverlayTrigger
                placement="auto"
                delay={{ show: 750, hide: 150 }}
                overlay={editNoteTooltip}
            >
                <button className="blueWhite-Btn" onClick={handleShow}>edit</button>
            </OverlayTrigger>

            <Modal show={show} onHide={handleClose} dialogClassName="notes-addModalMain">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Edit Note</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="notesForm-main">
                        <div className="notesForm-form">
                            <div className="notesForm-top">
                                <div style={{display:"flex",flexWrap:"wrap"}}>
                                    <label className="sendMail-label">Type:</label>
                                    <input type='text'
                                           className="sendMail-input"
                                           style={{width:"120px",height:"38px"}}
                                           name="sendMail-from"
                                           value={noteType}
                                           readOnly={true}/>
                                </div>
                                <div>
                                    <label className="sendMail-label">Title:</label>
                                    <input type='text'
                                           className="notesForm-title"
                                           name="sendMail-from"
                                           value={noteTitle}
                                           readOnly={false}
                                           onChange={(e)=>{changeInputValue(e,setNoteTitle)}}/>
                                </div>
                            </div>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Note:</span>
                                </div>
                                <textarea className="form-control"
                                          value={noteText}
                                          readOnly={false}
                                          onChange={(e)=>{changeInputValue(e,setNoteText)}}
                                ></textarea>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn" onClick={()=>{editNote()}} >
                            Save Edit
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose} >
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default EditJobNote;
