import React, {useEffect, useState} from 'react';
import {Tabs,Tab} from 'react-bootstrap';
import ToolsPanel from "./ToolsPanel/ToolsPanel";
import DashBoardCandidates from "./DashBoardCandidates/DashBoardCandidates";
import DashBoardTable from "./DachBoardTable/DashBoardTable";
import DbCandidatesBar from "./DashBoardCandidates/DBCandidatesBar";
import headerReq from "../../functions/headers";
import headerReqZohoAuth from "../../functions/headerReqZohoAuth";
import './dashBoard.css';
import Loader from "../Loader/Loader";
import RefreshToken from "../RefreshToken/RefreshToken";


const DashBoard = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ itemFields, setItemFields ] = useState();
    const [ metaDataFields, setMetaDataFields ] = useState();
    const [ refreshToken, setRefreshToken ] = useState(false);

    //Download from zoho fields & metaFields
    const getFieldsData = async ()=>{
        let config = headerReq();
        const getFieldsUrl='/proxyZohoRecruit/recruit/private/json/Candidates/getFields?version=2';

        try {
            const res = await fetch(getFieldsUrl,{...config});
            const json = await res.json();
            console.log('getFieldsData JSON:',json);
            if(json.error){
                if(json.error.message === 'Invalid OAuthtoken'){
                    setRefreshToken(true);
                }}else {
                setItemFields(json);
            }
        }catch (e) {
            console.log('getFieldsData ERROR:',e);
        }
    };

    const getFieldsMeta = async () =>{
        const requestOption = await headerReqZohoAuth();
        const getFieldsMetaDataURL = '/proxyZohoRecruit/recruit/v2/settings/fields?module=Candidates';

        try{
            const res = await  fetch(getFieldsMetaDataURL,{...requestOption});
            const json = await res.json();
            console.log('get META DATA - JSON:',json);

            if(json.code){
                if(json.code === 'INVALID_TOKEN'){
                    console.log('getFieldsMeta need new token:');
                }else{
                    console.log('getFieldsMeta ERROR:',json.code);
                }
            }else {
                setMetaDataFields(json);
            }
        }catch (e) {
            console.log('get META DATA - ERROR;',e);
        }
    };

    const componentDidMount = async ()=>{
        setIsLoading(true);
        setRefreshToken(false);
        await getFieldsData();
        await getFieldsMeta();
        setIsLoading(false);
    };

    useEffect(async ()=>{
        await componentDidMount();
    },[]);

    return (
        <div className="searchMain">
            <div className="container">
                <h2 className="dashBoard-mainTitle">DASH BOARD</h2>
                {isLoading ?
                    <Loader/>
                :
                refreshToken ?
                    <div>Invalid token <RefreshToken update={componentDidMount}/></div>
                    :
                    <Tabs defaultActiveKey="jobs" id="uncontrolled-tab-dashboard" className="mb-3">
                        <Tab eventKey="jobs" title="Jobs">
                            <DashBoardTable setRefreshToken={setRefreshToken} />
                        </Tab>
                        <Tab eventKey="candidates" title="Candidates">
                            {/*<DashBoardCandidates/>*/}
                            { itemFields && metaDataFields && <DbCandidatesBar dataFields={itemFields} metaData={metaDataFields}/>}

                        </Tab>
                    </Tabs>
                }
            </div>
            {/*<BackUp/>*/}
        </div>
    );
};

export default DashBoard;
