import React from 'react';
import './adminPanel.css';
import UserRole from "./UserRole";

const UserTableItem = ({userItem,updateUsers}) => {
    return (
        <div className="adminPanel-userItem" key={userItem.id} >
            <div className="adminPanel-usersName">
                <span>{userItem.firstName}</span>
                <span style={{marginLeft:"4px"}}>{userItem.secondName}</span>
            </div>
            <div className="adminPanel-usersEmail">{userItem.email}</div>
            <UserRole userID={userItem.id} currentRole={userItem.role} firstName={userItem.firstName} secondName={userItem.secondName} updateUsers={updateUsers}/>
        </div>
    );
};

export default UserTableItem;
