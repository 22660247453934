import React,{useState,useEffect} from 'react';
import Select from "react-select";
import Loader from "../Loader/Loader";

const OptionsBuilder = ({optionsTitle,changeFunc,idx,val,fields}) => {
    // const [ fields,setFields ] = useState();
    const [ readyOptions,setReadyOptions ] = useState();

    function prepareArrOptions(e) {
        if(typeof e === 'string'){
            return Object.create({}, {
                value:{
                    value:e,
                    enumerable:true,
                    writable:true,
                },
                label:{
                    value:e,
                    enumerable:true,
                    writable:true,
                }});
        } else {return Object.create({}, {
            value:{
                value:e.content,
                enumerable:true,
                writable:true,
            },
            label:{
                value:e.content,
                enumerable:true,
                writable:true,
            }})}
    }

    const getOptionsByTitle = (title,fieldsArr)=>{
        // console.log('getOptionsByTitle-title:',title);
        // console.log('getOptionsByTitle-fieldsArr:',fieldsArr);
        let item;
        let options;
        fieldsArr.forEach( section =>{
            if(Array.isArray(section.FL)){
                item = section.FL.filter(item => item.dv === title);
                // console.log('filter ITEM:',item);

                if(item.length > 0){
                    // console.log('opt val:',item[0].val);
                    return  options = item[0].val;
                }
            }

        });

        return  options;
    };

    const setFieldsCriteria = async (title,fields)=>{

        if(fields){
            const rawOptions = await getOptionsByTitle(title,fields);
            // console.log('OPTIONS raw:', rawOptions);
            const prepareOptions = await rawOptions.map(e => prepareArrOptions(e));
            // console.log('OPTIONS prepare:', prepareOptions);
            setReadyOptions(prepareOptions);
        }

        // //old version
        // let config = headerReq();
        // const getFieldsUrl='/proxyZohoRecruit/recruit/private/json/Candidates/getFields?version=2';
        //
        // const res = await fetch(getFieldsUrl,{...config});
        // const json = await res.json();
        // console.log('FIELDS:',json.Candidates.section);
        // setFields(json.Candidates.section);
        // const rawOptions = await getOptionsByTitle(title,json.Candidates.section);
        // console.log('OPTIONS raw:', rawOptions);
        // const prepareOptions = await rawOptions.map(e => prepareArrOptions(e));
        // console.log('OPTIONS prepare:', prepareOptions);
        // setReadyOptions(prepareOptions);

    };

    useEffect(async ()=>{
        await setFieldsCriteria(optionsTitle,fields);
    },[optionsTitle,fields]);

    return(
        <div className="criteria-value">
            {readyOptions ?
                <Select
                value={val}
                name="criteriaValue"
                onChange={event => changeFunc(idx, event, 'criteriaValue')}
                options={readyOptions}/>:
                <Loader/>
            }
        </div>

    );
};

export default OptionsBuilder;
