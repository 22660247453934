//function for create table columns list (header & accessor)
import titleNameReplacer from "../../../functions/titleNameReplacer";

const createTableColumns = (columnsArray)=>{
    const columnsPrepare = [
        {
        Header: 'X',
        accessor: 'CandidateActions_Btn',
        },
        {
            Header: 'Candidate Name',
            accessor: 'CandidateName_Link',
        },
        {
            Header: 'Actions',
            accessor: 'Candidate_AllActions',
        }
    ];



    columnsArray.forEach(columnsTitle =>{
        if(columnsTitle !== 'First Name' && columnsTitle !== 'Last Name'){
            const createAccessor = titleNameReplacer(columnsTitle);
            columnsPrepare.push({
                Header: columnsTitle,
                accessor: createAccessor,
            })
        }
    });

    return columnsPrepare;
};

export default createTableColumns;
