import React,{useState,useEffect} from 'react';
import TagTableDateParser from "./TagTableDateParser";

const TagTableDate = ({addedDate}) => {
    return (
        <div className="tagTable-date">
            {addedDate && <TagTableDateParser noteDate={addedDate} />}
        </div>
    );
};

export default TagTableDate;
