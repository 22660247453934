import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isAuth from "../../functions/isAuth";

const ProtectedRoute = ({component:Component, role:role, permission:permission ,...rest}) => {
    // const user = isAuth();

    const checkPermission = (role, permissionsArr)=>{
        const matchPermission = permissionsArr.find( perItem => perItem === role );
        return !!matchPermission;
    };

    // console.log('PROTECTED role:',role);
    // console.log('PROTECTED permission:',permission);

    return (
        <Route {...rest} render={
            (props)=>{
                if(checkPermission(role,permission)){
                    return <Component {...props}/>
                }else{
                    return <Redirect to={
                        {
                            pathname:"/",
                            state:{
                                from: props.location
                            }
                        }
                    }/>
                }
            }
        }/>
    );
};

export default ProtectedRoute;
