import React, { useState } from "react";
import Select from "react-select";
import getApiName from "../../functions/getFieldsApiName";
import RefreshToken from "../RefreshToken/RefreshToken";


function PickListHookMulti(props) {
    // console.log('pick list multi MODULE:',props.module);
    const [ saveChanges,setSaveChanges ] = useState(false);
    const [ saveResult,setSaveResult ] = useState(null);
    // FOR OPTIONS
    const rawOptions = props.opt;

    function prepareArrOptions(e) {
        if(typeof e === 'string'){
            return Object.create({}, { value:{value:e},label:{value:e}});
        } else {return Object.create({}, { value:{value:e.content},label:{value:e.content}})}
    }

    const preOptions = rawOptions.map(e => prepareArrOptions(e));
    const options = preOptions;

    // FOR INITIAL VALUE
    let multiPropsVal = props.value;
    let multiStateVal =[];

    //test spilt multiprops
    if(multiPropsVal != null){
        multiPropsVal = multiPropsVal.split(';');
    }


    if(Array.isArray(multiPropsVal)){
        multiPropsVal.forEach((propsItem)  =>{
            let valItem = Object.create({}, { value:{value:propsItem},label:{value:propsItem}});
            multiStateVal.push(valItem);
        });
    }else if(multiPropsVal == null){
        multiStateVal = null;
    }else if(Array.isArray(multiPropsVal) == false){
        let valItem = Object.create({}, { value:{value:multiPropsVal},label:{value:multiPropsVal}});
        multiStateVal.push(valItem);
    }

    const prepareStateVal = multiStateVal;

    //onChange function
    const changeInpValue = selectedOption => {
        console.log('SELECTED OPTIONS:',selectedOption);
        setSelectedOption({ selectedOption });
        console.log(`Option selected:`, selectedOption);
        setSaveChanges(true);
    };

    //update record function
    const updateRecord = async (name)=>{
        const token = localStorage.getItem('ZohoToken');
        const itemId = `${props.itemId}`;
        const requestSingleURL = `/proxyZohoRecruit/recruit/v2/${props.module}/${itemId}`;

        console.log('update id:',props.itemId);
        console.log('update name:',name);
        console.log('update value:',selectedOption.selectedOption);

        // let prepareName = name.replace(/ /g,'_');// not work for custom field
        let prepareName = await getApiName( props.meta,name );// for update customer field with api_name
        console.log('update PREPARE NAME:',prepareName);

        const reqBodySingle = {
            "data": []
        };

        if(selectedOption.selectedOption){
            let valuesArr = selectedOption.selectedOption.map((valueObj)=>{return valueObj.value});
            console.log(' valuesArr:', valuesArr);
            const reqBody = {[prepareName]: valuesArr};
            reqBodySingle.data.push(reqBody);
        }else{
            const reqBody = {[prepareName]: null};
            reqBodySingle.data.push(reqBody);
        }


        console.log('reqBodySingle:',reqBodySingle);

        const requestOptions = {method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(reqBodySingle)
        };

        setSaveChanges(false);

        try{
            const res = await fetch(requestSingleURL,{...requestOptions});
            const resJson = await res.json();
            // console.log('resJson:',resJson);
            // if(resJson.code){
            //     if(resJson.code === 'INVALID_TOKEN'){
            //         console.log('NEED REFRESH NOKEn')
            //     }
            // }else{
            //     // setSaveResult(resJson.data[0].code);
            //     setSaveResult('SUCCESS');
            //     setTimeout(function() {
            //         console.log('clear result');
            //         setSaveResult(null);
            //     }, 4000);
            // }
            console.log('resJson:',resJson);
            if(resJson.code){
                if(resJson.code === 'INVALID_TOKEN'){
                    console.log('NEED REFRESH NOKEn');
                    setSaveResult(<RefreshToken update={()=>{updateRecord(name)}}/>);
                }
            }else{
                setSaveResult(resJson.data[0].code);
                setTimeout(function() {
                    console.log('clear result');
                    setSaveResult(null);
                }, 4000);
            }
        }catch (e) {
            console.log('fetch update record ERROR:',e);
            setSaveResult('error');
            setTimeout(function() {
                console.log('clear result');
                setSaveResult(null);
            }, 4000);
        }

    };


    const [selectedOption, setSelectedOption] = useState(prepareStateVal);
    // console.log('MULTi state',selectedOption);

    return (
        <div className="PickHook">
            <span className="select-label">{props.name}</span>
            <Select
                isMulti
                // name={'hookTest'}
                defaultValue={selectedOption}
                onChange={changeInpValue}
                options={options}/>
            {saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(props.name)}}>Save Changes</span>}
            {saveResult != null ? <span className="displCand-saveResult">saved {saveResult}</span>:<span></span>}
        </div>
    );
}

export default PickListHookMulti;
