//create object Super
import React from "react";
import { Link } from "react-router-dom";
import TableDeleteBtn from "../../buttons/tableDeleteBtn";
import CandidateActionsWrapper from "../CandidateActionsWrapper";
import titleNameReplacer from "../../../functions/titleNameReplacer";
import TableBigTextWrapper from "../TableBigTextWrapper";
import CreatedTimeWrapper from "../createdTimeWrapper";
import Avatar from "../../Avatar/Avatar";


const createSuperObject = (data,dataItemFields,metaDataFields,addToExceptions,exceptions,setExceptions,jobId,jobCandidatesArr,updateJCandidatesSTATE,withTagArr)=>{
    let superObject = {
        is:'Candidate',
        withTag: false
    };

    // const withTagArr = ['501602000008793010','501602000007291001', '501602000007286008', '501602000007286009', '501602000008499003', '501602000008626011', '501602000008626016'];

    data.forEach(dataItem =>{
        const prepareKeyName = titleNameReplacer(dataItem.val);

        const checkBigTextFunc = (key,str) =>{
            if(key !== 'First Name' && key !== 'Last Name' && key !== 'CANDIDATEID'){
                if(str.length > 70){
                    return(<TableBigTextWrapper text={str}/>);
                }else {
                    return str;
                }
            }else{
                return str;
            }
        };

        const prepareContent = checkBigTextFunc(dataItem.val,dataItem.content);

        Object.defineProperty(superObject,prepareKeyName,{
                value: prepareContent,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );
    });

    //additional for links
    if((superObject.First_Name || superObject.Last_Name) && superObject.CANDIDATEID){
        const candidateFullName = superObject.First_Name+" "+superObject.Last_Name;

        //check tag
        const idx = withTagArr.findIndex(withTagId => withTagId === superObject.CANDIDATEID);
        if(idx < 0){
            superObject.withTag = false
        }else {
            console.log("TRUE");
            superObject.withTag = true;
        }

        //create full name link
        const candidateFullNameLink = (<div className="table-fullName-wrapper">
                                            <Avatar fullName={candidateFullName} candidateID={superObject.CANDIDATEID}/>
                                            <Link
                                                to={{pathname:'/candidate',search:`id=${superObject.CANDIDATEID}&title=${candidateFullName}`}}
                                                style={{fontWeight:"bold",fontSize:"12px"}}>
                                                    {candidateFullName}
                                            </Link>
                                        </div>);

        Object.defineProperty(superObject,'CandidateName_Link',{
                value: candidateFullNameLink,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );

        //create delete btn
        const CandidateDeleteBtn =(<span style={{color:"blue",cursor:"pointer"}}>
                                    <TableDeleteBtn candidateFullName={candidateFullName} candidateID={superObject.CANDIDATEID} addToExceptionFunc={addToExceptions}/></span>);
        Object.defineProperty(superObject,'CandidateActions_Btn',{
                value: CandidateDeleteBtn,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );


        //create all actions btn
        const AllActionsBtn = (<CandidateActionsWrapper
                                candidateID={`${superObject.CANDIDATEID}`}
                                candidateFullName={candidateFullName}
                                dataItemFields={dataItemFields}
                                metaData={metaDataFields}
                                module={'Candidates'}
                                jobId={jobId}
                                jobCandidatesArr={jobCandidatesArr}
                                updateJCandidatesSTATE={updateJCandidatesSTATE}
                                exceptions={exceptions}
                                setExceptions={setExceptions}
                                withTag={superObject.withTag}/>);

        Object.defineProperty(superObject,'Candidate_AllActions',{
                value: AllActionsBtn,
                enumerable:true,
                writable:true,
                configurable: true
            }
        );

    }

    if(superObject.Created_Time){
        superObject.Created_Time =(<CreatedTimeWrapper createdTime={superObject.Created_Time}/>);
    }

    return superObject;
};

export default createSuperObject;
