import React,{useState,useEffect} from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import './candidateJobParser.css';
import {OverlayTrigger, Tooltip,Spinner} from "react-bootstrap";
import CandidateSentTime from "./CandidateSentTime";

const CandidatesJobItem = ({ jobItem, candidateID, candidateFullName, getCandidatesJobs }) =>{
    console.log('JOB ITEM-JobItem:',jobItem);
    const [ status, setStatus ] = useState(null);
    const [ history, setHistory ] = useState( null);
    const [ isSending, setIsSending ] = useState(false);

    //function for get history data
    const getHistoryData = (curStatus, historyArr)=>{
        let lastChange;
        for (let i = historyArr.length-1; i >= 0 ; i--) {
            if(historyArr[i].changedTo ===  curStatus){
                return lastChange = historyArr[i];
            }
        }
        return lastChange;
    };

    //function for get status & status history for current job by candidate id
    const getStatusData = (candidateID, jobCandidatesArr)=>{
        console.log('START getStatusData');
        const candidateMeta = jobCandidatesArr.find( candidateItem => candidateItem.candidateID === candidateID);
        // console.log('JOB ITEM-candidateMeta:',candidateMeta);
        if(candidateMeta.candidateStatus){
            setStatus(candidateMeta.candidateStatus);
            const curHistoryData = getHistoryData('sent',candidateMeta.statusHistory);
            console.log('JOB ITEM-curHistoryData:',curHistoryData);
            setHistory(curHistoryData);
        }
    };

    useEffect(()=>{
        getStatusData(candidateID,jobItem.candidatesForJob);
    },[]);

    useEffect(()=>{
        setStatus(null);
        setHistory(null);
        getStatusData(candidateID,jobItem.candidatesForJob);
    },[jobItem]);

    //change candidate status "send"
    const sendToCustomer = async (candidateID,candidateFullName,jobID,updateFunc)=>{
        setIsSending(true);
        console.log('sendToCustomer-candidateID:',candidateID);
        console.log('sendToCustomer-candidateFullName:',candidateFullName);
        console.log('sendToCustomer-jobID:',jobID);

        const sendToCustomerURL = '/api/dependency/updateCandidateStatus';

        //meta data for status history
        const userID = localStorage.getItem('NRuserID');
        const userName = localStorage.getItem('NRuserName');
        const currentDate = new Date();

        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                jobId: jobID,
                updateCandidate: {candidateID:candidateID, candidateFullName:candidateFullName, candidateStatus:'sent'},
                statusHistoryItem: { modifiedByID:userID, modifiedByName: userName, modifiedData:currentDate, changedTo:'sent' }
            })
        };

        try{
            const res = await fetch(sendToCustomerURL, {...reqOptions});
            const json = await res.json();
            console.log('sendToCustomer JSON:',json);
            if(json.response === "SUCCESS"){
                await updateFunc(candidateID);
            }
        }catch (e) {
            console.log('sendToCustomer ERROR');
        }
        setIsSending(false);
    };

    //function for remove candidate from job dependency
    const deleteJobsCandidate = async (candidateID,jobID)=>{
        console.log('candidateID:',candidateID);
        console.log('jobID:',jobID);
        const deleteJobsCandidateURL = `/api/dependency/removeJobsCandidateMongo?jobId=${jobID}&candidateId=${candidateID}`;

        try{
            const res = await fetch( deleteJobsCandidateURL, {method:"delete"});
            const json = await res.json();
            console.log('deleteJobsCandidate Mongo JSON:',json);
            if(json.message === "removed item"){
                await getCandidatesJobs(candidateID);
            }
        }catch (e) {
            console.log('deleteJobsCandidate Mongo ERROR');
        }
    };

    //tooltip
    const TooltipRemoveJob = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Remove Job From Candidate
        </Tooltip>
    );

    return (
        <div className="candidateJobParser-jobItem">
            <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={TooltipRemoveJob}>
            <RiCloseCircleLine  className="modal-link" fontSize={"18px"} onClick={()=>{deleteJobsCandidate(candidateID,jobItem.itemId)}}/>
            </OverlayTrigger>

            <span className="candidateJobParser-jobTitle">{jobItem.itemTitle}</span>
            {status ? <CandidateSentTime statusHistory={history} /> :
                <button className="candidateJobParser-jobRemove" onClick={()=>{sendToCustomer(candidateID,candidateFullName,jobItem.itemId,getCandidatesJobs)}}>
                    {isSending ? <Spinner animation="border" size="sm" variant="light" style={{marginRight:"12px",marginLeft:"12px"}}/> : <span>SENT</span>}
                </button>}
        </div>
    );
};

export default CandidatesJobItem;
