import React,{useState,useEffect} from 'react';
import CreateNotes from "../CreateNotes/CreateNotes";
import AddNote from "../AddNote/AddNote";

const NoteQuickCv = ({ candidateID, fullName, showCV }) =>{
    const [ candidateNotes, setCandidateNotes ] = useState(null);

    const getCandidateNotes = async ()=>{
        console.log('notes quick cv candidateID:',candidateID);
        console.log('notes quick cv fullName:',fullName);
        const updateNotesURL = `/api/candidate/getByCandidateId?id=${candidateID}`;

        try{
            const res = await fetch(updateNotesURL);
            const json = await res.json();
            console.log('updateNotes JSON:',json);
            if(json.response){
                if(json.response.notes){
                    console.log('updateNotes JSON.notes:',json.response.notes);
                    console.log('updateNotes JSON.notes.notes:',json.response.notes.notes);
                    await setCandidateNotes(json.response.notes.notes);
                }
            }
        }catch (e) {
            console.log('updateNotes ERROR:',e);
        }
    };

    useEffect(async ()=>{
        if(showCV){
            await getCandidateNotes();
        }
    },[showCV]);

    return (
        <>
            { candidateNotes ?
                <AddNote candidateID={candidateID} updateNotesFunc={getCandidateNotes} forCV={true} candidateNotes={candidateNotes}/>:
                <CreateNotes candidateID={candidateID} candidateFullName={fullName} updateNotesFunc={getCandidateNotes} forCV={true}/> }
        </>
    );
};

export default NoteQuickCv;
