// clear function for validation advanced search parameters

const validationCheck = async (keyWord,criteriaArray)=>{
    console.log('Validation-criteriaArray:',criteriaArray);
    if(!keyWord && criteriaArray.length === 0){
        return false;
    }else if(criteriaArray.length > 0){
        const newArr = criteriaArray.filter( item => item.criteriaName !== "" && item.criteriaValue !== "");
        console.log('Validation-newArr:',newArr);
        if(criteriaArray.length === newArr.length){
            console.log('Validation:true');
            return true;
        }else{
            console.log('Validation:false');
            return false;
        }
    }else{
        console.log('Validation:true');
        return true;
    }

};

export default validationCheck;
