import React,{ useState,useEffect } from 'react';
import headerReqZohoAuth from "../../functions/headerReqZohoAuth";
import './DisplayPhoto.css';
import { BsPeopleCircle } from 'react-icons/bs';


const DisplayPhoto = ({candidateID}) => {
    const [ downloadPhoto, setDownloadPhoto ] = useState('no photo');
    const headersZohoAuth = headerReqZohoAuth();
    console.log('Display Photo header:',headersZohoAuth);
    console.log('Display Photo candidateID:',candidateID);
    console.log('Display Photo candidateID type:',typeof (candidateID));
    const testCandidateId = '501602000006724111';
    const testCandidateId2 = '501602000005815001';

    const getCandidatePhoto = async (candidateID)=>{
        const getCandidatePhotoURL = `/proxyZohoRecruit/recruit/private/json/Candidates/downloadPhoto?scope=ALL&id=${candidateID}&version=2`;

         let outside;
         try {
             const response = await fetch(getCandidatePhotoURL,headersZohoAuth);
             console.log('my response:',response);
             console.log('my response type:',response.type);
             const myBlob = await response.blob();
             console.log('myBlob:',myBlob);
             console.log('myBlob type:',myBlob.type);
             if(myBlob.type === 'image/jpeg'){
                 outside = await URL.createObjectURL(myBlob);
                 console.log(outside);
                 setDownloadPhoto(outside);
             }else {
                 outside = 'no photo';
                 console.log(outside);
                 setDownloadPhoto(outside);
             }
         }catch (e) {
             console.log('error blob:',e);
             outside = 'no photo';
             console.log(outside);
             setDownloadPhoto(outside);
         }

    };

    useEffect(async ()=>{
        await getCandidatePhoto(candidateID);
    },[]);

    return (
        <>
            <div>
                {downloadPhoto === 'no photo' ?
                    <div className="DisplayPhoto-noPhoto"><BsPeopleCircle fontSize={'110px'}/></div>:
                    <div><img className="DisplayPhoto-photo" src={downloadPhoto} alt="candidate photo"/></div>}
            </div>
        </>
    );
};

export default DisplayPhoto;
