import React, {useState} from 'react';
import { Modal, Button } from "react-bootstrap";
import validationAllCheck from "../AdvSearchFunctions/validationAllCheck";

const SearchRequestBtn = ({sendAdvancedSearchRequest,word,criteria,filters}) => {
    const [ show, setShow ] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = async () => {
        console.log('show pop up');
        const allValidation = await  validationAllCheck(word,criteria,filters);
        if(allValidation === false){
            setShow(true);
        }else {
            sendAdvancedSearchRequest();
        }
    };

    return (
        <>
            <button
                type="button"
                className="greenFill-btn"
                onClick={handleShow}
            >Search</button>

            <Modal dialogClassName="test-dialog" show={show} onHide={handleClose}>
                <Modal.Title style={{display:"flex",justifyContent:"center",color:"#F57272",background:"#efeeef"}}>
                    SEARCH REQUEST
                </Modal.Title>
                <Modal.Body className="advanced-saveWindow">
                    <div className="advanced-saveWindow-bodyWrapper">
                        <div style={{color:'red'}}>
                            <h4>Error!!!</h4>
                            <p>Please,check all search request parameters</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{background:"#efeeef"}}>
                    <Button variant="secondary" onClick={()=>{handleClose()}}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default SearchRequestBtn;
