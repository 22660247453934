import React, {useState, useEffect, useRef} from 'react';
import PickListHook from "./PickListHook";
import PickListHookMulti from "./PickListHookMulti";
import getApiName from "../../functions/getFieldsApiName";
import RefreshToken from "../RefreshToken/RefreshToken";
import TextareaAutosize from 'react-textarea-autosize';


const FieldBuilder = ({IndividualConfig,prepareRecId,itemId,module,meta}) => {
    const [ inputValue, setInputValue ] = useState();
    const [ saveChanges, setSaveChanges ] = useState(false);
    const [ saveResult, setSaveResult ] = useState(null);
    const [ validation, setValidation ] = useState(null);
    // const [ rightLeft, setRightLeft ] = useState(false);

    //set field value
    useEffect(()=>{
        const fieldValue = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
        if(fieldValue !== undefined){
            setInputValue(fieldValue.content);
        }else{
            setInputValue('');
        }
    },[]);

    //For change textarea direction leftToRight
    const textRef = useRef();
    useEffect(() => {
        if(textRef.current){
            const input = textRef.current;
            input.addEventListener('contextmenu', function(event) {
                event.preventDefault();
                input.classList.toggle("rtl");
                return false;
            }, false);
        }
    }, []);

    //change input value function
    const changeInpValue = (event)=>{
        event.preventDefault();
        setInputValue(event.target.value);
        setSaveChanges(true);
    };

    //change input value function NUM
    const changeInpValueNum = (event)=>{
        event.preventDefault();
        const toNumber = parseInt(event.target.value);
        console.log('TO NUM:',toNumber);
        setInputValue(toNumber);
        setSaveChanges(true);
    };

    //change input value function NUM (double,float)
    const changeInpValueFloat = (event)=>{
        event.preventDefault();
        const toNumber = parseFloat(event.target.value);
        console.log('TO NUM:',toNumber);
        setInputValue(toNumber);
        setSaveChanges(true);
    };

    //change input value function with validation for e-mail
    const changeInpValueEmail = (event)=>{
        event.preventDefault();
        setInputValue(event.target.value);
        const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,4}$/;

        if(event.target.value.length > 0){
            if(event.target.value.match(pattern)){
                setValidation('valid');
                setSaveChanges(true);
            }else{
                setValidation('invalid');
                setSaveChanges(false);
            }
        }else{
            setValidation(null);
            setSaveChanges(true);
        }

    };

    //change input value function with validation for url
    const changeInpValueWebSite = (event)=>{
        event.preventDefault();
        setInputValue(event.target.value);
        const pattern = new RegExp( '(http|ftp|https)://[\\w-]+(\\.[\\w-]+)+([\\w-.,@?^=%&:/~+#-]*[\\w@?^=%&;/~+#-])?' );

        if(event.target.value.length > 0){
            if(event.target.value.match(pattern)){
                setValidation('valid');
                setSaveChanges(true);
            }else{
                setValidation('invalid');
                setSaveChanges(false);
            }
        }else{
            setValidation(null);
            setSaveChanges(true);
        }

    };

    //change input value function with validation for phone  number
    const changeInpValueTel = (event)=>{
        event.preventDefault();
        setInputValue(event.target.value);
        const pattern = new RegExp( /^(\+?\d[- .]*){12,12}$/);

        if(event.target.value.length > 0){
            if(event.target.value.match(pattern)){
                setValidation('valid');
                setSaveChanges(true);
            }else{
                setValidation('invalid');
                setSaveChanges(false);
            }
        }else{
            setValidation(null);
            setSaveChanges(true);
        }

    };

    //send update record request
    const updateRecord = async (name)=>{
        const token = localStorage.getItem('ZohoToken');
        const requestSingleURL = `/proxyZohoRecruit/recruit/v2/${module}/${itemId}`;

        // let prepareName = name.replace(/ /g,'_');
        let prepareName = await getApiName( meta,name );// for update customer field with api_name
        console.log('PREPARE NAME:',prepareName);

        const reqBodySingle = {
            "data": []
        };

        const reqBody = {[prepareName]: inputValue};
        reqBodySingle.data.push(reqBody);


        const requestOptions = {method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(reqBodySingle)
        };

        setSaveChanges(false);


        console.log('Type of VALUE:',typeof inputValue);
        try{
            const res = await fetch(requestSingleURL,{...requestOptions});
            const resJson = await res.json();
            console.log('resJson:',resJson);
            if(resJson.code){
                if(resJson.code === 'INVALID_TOKEN'){
                    console.log('NEED REFRESH NOKEn');
                    setSaveResult(<RefreshToken update={()=>{updateRecord(name)}}/>);
                }
            }else{
                setSaveResult(resJson.data[0].code);
                setTimeout(function() {
                    console.log('clear result');
                    setSaveResult(null);
                }, 4000);
            }
        }catch (e) {
            console.log('fetch update record ERROR:',e);
            setSaveResult('error');
            setTimeout(function() {
                console.log('clear result');
                setSaveResult(null);
            }, 4000);
        }

    };


    switch (IndividualConfig.type) {
        case 'Text':
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               ref={textRef}
                               className="displCand-input"
                               value={inputValue}
                               name={IndividualConfig.dv}
                               maxLength={IndividualConfig.maxlength}
                               key={IndividualConfig.dv}
                               readOnly={false}
                               onChange={changeInpValue}
                        />
                        {saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}
                        {saveResult != null ? <span className="displCand-saveResult">saved {saveResult}</span>:<span></span>}
                    </div>

                </>
            );

        case 'TextArea':
            return (
                <>
                    <div className="input-group">
                        {/*<button onClick={()=>{setRightLeft(!rightLeft)}}>rtl</button>*/}
                        <div className="input-group-prepend">
                            <span className="input-group-text">{IndividualConfig.dv}</span>
                        </div>
                        <TextareaAutosize
                            className='form-control'
                            ref={textRef}
                            value={inputValue}
                            rows={5}
                            maxRows={10}
                            onChange={changeInpValue}/>

                        {/*<textarea className="form-control"*/}
                        {/*          key={IndividualConfig.dv}*/}
                        {/*          value={inputValue}*/}
                        {/*          readOnly={false}*/}
                        {/*          onChange={changeInpValue}*/}
                        {/*          aria-label={IndividualConfig.dv}*/}
                        {/*          maxLength={IndividualConfig.maxlength}/>*/}

                        {saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}
                        {saveResult != null ? <span className="displCand-saveResult" >saved {saveResult}</span>:<span></span>}
                    </div>

                </>
            );

        // Select & MultiSelect
        case 'Picklist':

            const valDataPL = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
            let inputValDataPL;
            if(valDataPL != undefined){
                inputValDataPL = valDataPL.content;
            }else{
                inputValDataPL=null;
            }

            return (
                <>
                    <div >
                        <PickListHook
                            opt={IndividualConfig.val}
                            name={IndividualConfig.dv}
                            value={inputValDataPL}
                            key={IndividualConfig.dv}
                            itemId={itemId}
                            module={module}
                            meta={meta}/>
                    </div>
                </>
            );

        case 'MultiPicklist':
            const valDataMPL = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
            // console.log('valDataMPL',valDataMPL);
            let inputValDataMPL;
            if(valDataMPL != undefined){
                // console.log('inputValDataMPL',valDataMPL.content);
                inputValDataMPL = valDataMPL.content;
            }else{
                inputValDataMPL=null;
            }

            return (
                <>
                    <div>
                        <PickListHookMulti
                            opt={IndividualConfig.val}
                            name={IndividualConfig.dv}
                            value={inputValDataMPL}
                            key={IndividualConfig.dv}
                            itemId={itemId}
                            module={module}
                            meta={meta}/>
                    </div>
                </>
            );

        case 'number':

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='number'
                               className="displCand-input"
                               value={inputValue}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={false}
                               onChange={changeInpValueNum}
                        />

                        {saveResult != null ? <span className="displCand-saveResult" >saved {saveResult}</span>:<span></span>}
                        {validation === 'invalid' ? <span className="displCand-invalidValue">Invalid value</span> :
                            saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}
                    </div>
                </>
            );

        case 'AutoNumber':
            //READ ONLY
            const valDataANUM = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
            let inputValDataANUM;
            if(valDataANUM!= undefined){
                inputValDataANUM = valDataANUM.content;
            }else{
                inputValDataANUM='';
            }

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={inputValDataANUM}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'Double':
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='Number'
                               className="displCand-input"
                               value={inputValue}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={false}
                               maxLength={IndividualConfig.maxlength}
                               step="0.5"
                               min="0"
                               onChange={changeInpValueFloat}
                        />

                        {saveResult != null ? <span className="displCand-saveResult" >saved {saveResult}</span>:<span></span>}
                        {validation === 'invalid' ? <span className="displCand-invalidValue">Invalid value</span> :
                            saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}
                    </div>
                </>
            );

        case 'Phone':
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='tel'
                               className="displCand-input"
                               value={inputValue}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={false}
                               maxLength={IndividualConfig.maxlength}
                               placeholder={'+972XXXXXXXXX'}
                               onChange={changeInpValueTel}
                        />

                        {saveResult != null ? <span className="displCand-saveResult" >saved {saveResult}</span>:<span></span>}
                        {validation === 'invalid' ? <span className="displCand-invalidValue">Invalid value</span> :
                            saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}
                    </div>
                </>
            );

        case 'Website':
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='url'
                               className="displCand-input"
                               value={inputValue}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={false}
                               maxLength={IndividualConfig.maxlength}
                               onChange={changeInpValueWebSite}
                               placeholder={'https://example.com'}
                        />

                        {saveResult != null ? <span className="displCand-saveResult" >saved {saveResult}</span>:<span></span>}
                        {validation === 'invalid' ? <span className="displCand-invalidValue">Invalid value</span> :
                            saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}
                    </div>
                </>
            );

        case 'Email':
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={inputValue}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={false}
                               maxLength={IndividualConfig.maxlength}
                               onChange={changeInpValueEmail}
                        />
                        {/*{saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}*/}
                        {saveResult != null ? <span className="displCand-saveResult" >saved {saveResult}</span>:<span></span>}
                        {validation === 'invalid' ? <span className="displCand-invalidValue">Invalid value</span> :
                            saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}
                    </div>
                </>
            );

        case 'DateTime':

            const valDataTime = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
            let inputValDataTime;
            if(valDataTime!= undefined){
                inputValDataTime = valDataTime.content;
            }else{
                inputValDataTime='';
            }

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={inputValDataTime}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'Currency':
            return (
                <>
                    <div style={{display:"flex",alignItems:"stretch"}}>
                        <label className="displCand-label Cur" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='number'
                               className="displCand-inputCurrency"
                               value={inputValue}
                               id={IndividualConfig.dv}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={false}
                               min={0}
                               step={0.500}
                               onChange={changeInpValueFloat}
                        />
                        <span className="displCand-currency">$</span>

                        {saveResult != null ? <span className="displCand-saveResult" >saved {saveResult}</span>:<span></span>}
                        {validation === 'invalid' ? <span className="displCand-invalidValue">Invalid value</span> :
                            saveChanges && <span className="displCand-saveChangesBtn" onClick={()=>{updateRecord(IndividualConfig.dv)}}>Save Changes</span>}
                    </div>
                </>
            );

        case 'Lookup':

            const valDataLOOK = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
            let inputValDataLOOK;
            if(valDataLOOK!= undefined){
                inputValDataLOOK = valDataLOOK.content;
            }else{
                inputValDataLOOK='';
            }

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={inputValDataLOOK}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'OwnerLookup':

            const valDataOWLOOK = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
            // console.log('valDataText',valDataText);
            let inputValDataOWLOOK;
            if(valDataOWLOOK!= undefined){
                // console.log('valDataText',valDataText.content);
                inputValDataOWLOOK = valDataOWLOOK.content;
            }else{
                inputValDataOWLOOK='';
            }

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={inputValDataOWLOOK}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'UploadText':

            const valDataUPLOAD = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
            // console.log('valDataText',valDataText);
            let inputValDataUPLOAD;
            if(valDataUPLOAD!= undefined){
                // console.log('valDataText',valDataText.content);
                inputValDataUPLOAD = valDataUPLOAD.content;
            }else{
                inputValDataUPLOAD='';
            }

            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               className="displCand-input"
                               value={inputValDataUPLOAD}
                               name={IndividualConfig.dv}
                               key={IndividualConfig.dv}
                               readOnly={true}
                               maxLength={IndividualConfig.maxlength}
                        />
                    </div>
                </>
            );

        case 'Boolean':

            const valDataBOOL = prepareRecId.find( candidateFl => candidateFl.val === IndividualConfig.dv );
            let currentValue;

            if (valDataBOOL != undefined){
                console.log('valDataBOOL', valDataBOOL);
                currentValue = valDataBOOL.content === 'true';
            } else {
                currentValue = '';
            }

            return (
                <div >
                    <PickListHook
                        opt={'boolean'}
                        name={IndividualConfig.dv}
                        value={currentValue}
                        key={IndividualConfig.dv}
                        itemId={itemId}
                        module={module}
                        meta={meta}/>
                </div>
            );

        default:
            return (
                <>
                    <div>
                        <label className="displCand-label" htmlFor={IndividualConfig.dv}>{IndividualConfig.label}</label>
                        <input type='text'
                               name={IndividualConfig.dv}
                               value={inputValue}
                               className="displCand-input"
                               readOnly={true}
                        />
                    </div>
                </>
            )
    }

};

export default FieldBuilder;
