import React,{useState,useEffect} from 'react';
import DBoardBigTextWrapper from "../functions/DBoardBigTextWrapper";

const TagTableNote = ({notes = null}) => {
    const [ lastNote, setLastNote ] = useState(null);

    const checkBigTextFunc = (maxChart,str,date) =>{
        if(str.length > maxChart){
            return(<DBoardBigTextWrapper characters={maxChart} text={str} noteDate={date}/>);
        }else {
            return str;}
    };

    useEffect(()=>{
        if(notes){
            const notesArr = notes.notes;
            const lastContent = notesArr[notesArr.length-1].noteContent;
            const prepareContent = checkBigTextFunc(20, lastContent);
            setLastNote(prepareContent);
        }
    },[]);

    return (
        <div className="tagTable-topNote">
            {notes ? <div>{lastNote}<span>{}</span></div>:<div>no notes</div>}
        </div>
    );
};

export default TagTableNote;
