import React from 'react';
import SelectedSentParser from "./SelectedSentParser";
import { Link } from "react-router-dom";

const TableRowDetails = ({jobItem}) => {
    return (
        <div className="BDTable-itemMain details">
            <div className="BDTable-top-title">
                {jobItem.dependency.length > 0 &&
                    <div className="BDTable-detailsSearch">
                        <div style={{fontWeight:"500",fontSize:"8px"}}>searches</div>
                        {jobItem.dependency.map(search => <div className="BDTable-selectedSent" key={search._id}>{search.searchTitle}</div>)}
                    </div>}
            </div>
            <div className="BDTable-top-column">
                {jobItem.jobExceptions.length > 0 &&
                <div className="BDTable-detailsSearch">
                    {jobItem.jobExceptions.map(exception => <div className="BDTable-selectedSent" key={exception._id}>
                        <Link to={{pathname:'/candidate', search:`id=${exception.candidateID}&title=${exception.candidateFullName}`}}
                                target="_blank" rel="noopener noreferrer" style={{fontWeight:"bold",fontSize:"14px"}}>
                            {exception.candidateFullName}
                        </Link>
                    </div>)}
                </div>}
            </div>
            <div className="BDTable-column-double">
                <SelectedSentParser jobId={jobItem.itemId} jobCandidates={jobItem.candidatesForJob}/>
            </div>
            <div style={{minWidth:"2%"}}></div>
        </div>
    );
};

export default TableRowDetails;
