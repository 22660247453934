import React from 'react';
import './selectNaya.css'

const SelectNaya = ({label, selectValue, setSelectValue, options}) =>{
    const handleChange = (event)=>{
        setSelectValue(event.target.value);
    };

    return (
        <>
            <div className='selectNaya-filterStatus'>
                <span>{label}:</span>
                <select value={selectValue} onChange={handleChange} className="selectNaya-filterSelect">
                    {options.map( item =><option value={item} key={item}>{item}</option> )}
                </select>
            </div>
        </>
    );
};

export default SelectNaya;
