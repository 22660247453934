import React,{useState} from 'react';
import {Link} from "react-router-dom";
import {OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import './candidateItemParser.css';

//BsArrowLeftCircle;BiLeftArrowCircle;FiArrowLeftCircle;MdArrowBackIosNew
import { BiLeftArrowCircle } from "react-icons/bi";

const ClearCandidateSentStatus = ({candidateItem, jobId, updateFunc}) =>{
    const [isSending, setIsSending]= useState(false);

    //change candidate status "send"
    const clearStatus = async (candidateID, candidateFullName, jobId, updateFunc)=>{
        setIsSending(true);
        console.log('clearStatus-candidateID:', candidateID);
        console.log('clearStatus-candidateFullName:', candidateFullName);
        console.log('clearStatus-jobID:', jobId);

        const sendToCustomerURL = '/api/dependency/clearCandidateStatus';

        const reqOptions = {
            method:"PUT",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                jobId: jobId,
                updateCandidate: {candidateID:candidateID, candidateFullName:candidateFullName, candidateStatus:null, statusHistory:[]}
            })
        };

        try {
            const res = await fetch(sendToCustomerURL, {...reqOptions});
            const json = await res.json();
            console.log('clearStatus JSON:', json);
            if (json.response === "SUCCESS") {
                await updateFunc(jobId);
            }
        } catch (e) {
            console.log('clearStatus ERROR');
        }

        setIsSending(false);
    };

    const TooltipBackSelected = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Back To Selected
        </Tooltip>
    );

    return (
        <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={TooltipBackSelected}>
                <span className="modal-link"
                    onClick={()=>{clearStatus(candidateItem.candidateID, candidateItem.candidateFullName, jobId, updateFunc)}}>
                    {isSending ? <Spinner animation="border" size="sm"/> : <BiLeftArrowCircle fontSize={"18px"}/>}
                </span>
            </OverlayTrigger>
        </>
    );
};

export default ClearCandidateSentStatus;
