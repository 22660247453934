import React, {useState,useEffect} from 'react';
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import NoteItem from "../NoteItem/NoteItem";
import Loader from "../../Loader/Loader";
//Icons
// import { BsFillPenFill } from 'react-icons/bs';
import { FaUserEdit } from "react-icons/fa";
import Select from "react-select";


const AddNote = ({ candidateID, updateNotesFunc, forCV = false , forTag =false, candidateNotes = null }) =>{
    const [ show, setShow ] = useState(false);
    const [ noteText, setNoteText ] = useState();
    const [ noteTitle, setNoteTitle] = useState('Note');
    const [ showNotes, setShowNotes ] = useState(false);
    const [ notesPrepared, setNotesPrepared ] = useState();

    useEffect(()=>{
        if(candidateNotes){
            const preparedArray = candidateNotes.reverse();
            setNotesPrepared([...preparedArray]);
        }
    },[candidateNotes]);

    const userID = localStorage.getItem('NRuserID');
    const userName = localStorage.getItem('NRuserName');

    //note type
    const noteOptions = [{value:'text',label:'text'},{value:'call',label:'call'},{value:'email',label:'email'},{value:'meeting',label:'meeting'}];
    const initVal = noteOptions[0];
    const [selectedOpt, setSelectedOpt] = useState({...initVal});
    const changeInpValue = selectedOpt => {
        setSelectedOpt({ selectedOpt });
    };

    const getSelectedValue = ()=>{
        if(selectedOpt.selectedOpt){
            return selectedOpt.selectedOpt.value;
        }else{
            return selectedOpt.value;
        }
    };

    const changeInputValue = ( event, setInputState )=>{
        event.preventDefault();
        setInputState(event.target.value);
    };

    //Create NOTE
    const createNoteBody = ()=>{
        const currentDate = new Date();
        const noteType = getSelectedValue();
        return ({
            createdTime:currentDate,
            createdByUser:{
                userID:userID,
                userName:userName
            },
            modifiedTime:currentDate,
            noteType:noteType,
            noteTitle:noteTitle,
            noteContent:noteText
        })
    };

    const addNewNote = async () =>{
        const addNewNoteURL = `/api/note/addNewMongo?candidateId=${candidateID}`;

        const noteBody = createNoteBody();
        const reqOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...noteBody})
        };

        try{
            const res = await fetch(addNewNoteURL, reqOptions);
            const json = await res.json();
            console.log('Note JSON:',json);
            if(json.error){
                console.log('Note ERROR',json.error);
                return ;
            }else {
                console.log('response json note:',json);
                if(json.message === 'new note added to array'){
                    await updateNotesFunc();
                    await setNoteText('');
                    await setNoteTitle('Note');
                    await handleClose();
                }
            }

        }catch (err) {
            console.log('fetch to send mail response with ERROR:',err);
        }

        console.log('NOTE create new note finish');
    };

    //FOR MODAL WINDOW
    //for open & close modal window
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //tooltip
    const AddNoteTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Add Note
        </Tooltip>
    );

    return (
        <>
            { forCV ?
                <span className="blueWhite-Btn" onClick={handleShow}>
                    <OverlayTrigger
                        placement="auto"
                        delay={{ show: 750, hide: 150 }}
                        overlay={AddNoteTooltip}>
                        <FaUserEdit />
                    </OverlayTrigger>
                </span>:
                forTag ?
                    <FaUserEdit onClick={handleShow} className="notes-addNoteTag"/>:

                    <div className="notes-addNote" onClick={handleShow}><FaUserEdit />Add Note</div>}

            <Modal show={show} onHide={handleClose} dialogClassName="notes-addModalMain">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Add Note</h3>
                </Modal.Title>
                <Modal.Body>
                    <div className="notesForm-main">
                        <div className="notesForm-form">
                                <div className="notesForm-top">
                                    <div style={{display:"flex",flexWrap:"wrap"}}>
                                        <label className="sendMail-label">Type:</label>
                                        <Select
                                            defaultValue={selectedOpt}
                                            onChange={changeInpValue}
                                            options={noteOptions}
                                            className="notes"/>
                                    </div>
                                    <div>
                                        <label className="sendMail-label">Title:</label>
                                        <input type='email'
                                               className="notesForm-title"
                                               name="sendMail-from"
                                               value={noteTitle}
                                               readOnly={false}
                                               onChange={(e)=>{changeInputValue(e,setNoteTitle)}}/>
                                    </div>
                                </div>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Note:</span>
                                </div>
                                <textarea className="form-control"
                                          value={noteText}
                                          readOnly={false}
                                          onChange={(e)=>{changeInputValue(e,setNoteText)}}
                                ></textarea>
                            </div>

                        </div>
                    </div>

                    <div>
                        {candidateNotes !== null &&
                        <div className="notes-showNotesWrapper">
                            <span className="notes-showNotesBtn" onClick={()=>{setShowNotes(!showNotes)}}>all notes</span>
                        </div>}

                        {showNotes &&
                        <div className="notes-noteBox">
                            {notesPrepared.map(noteItem =><NoteItem key={noteItem._id} noteItem={noteItem} candidateID={candidateID} updateNotesFunc={updateNotesFunc}/>)}
                        </div>}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn" onClick={()=>{addNewNote()}} >
                            Save Note
                        </button>

                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose} >
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default AddNote;
