import React from 'react';
import { BsPeopleCircle } from 'react-icons/bs';
import { useSelector } from "react-redux";
import './user.css';

const User = () => {
    const isAuthRedux = useSelector((state)=>state.signinReducer.auth);
    const currentLabel = useSelector((state)=>state.signinReducer.label);
    const currentName = useSelector((state)=>state.signinReducer.firstName);
    const currentRole = useSelector((state)=>state.signinReducer.role);
    console.log('useSelector AUTH:',isAuthRedux);
    console.log('useSelector currentLabel:',currentLabel);
    console.log('useSelector currentName :',currentName );
    console.log('CURRENT ROLE:',currentRole);

    return (
        <div className="user-main">
            <div style={{marginRight:"6px"}}>
                <div className="user-name">
                    {currentName ? <span>{currentName}</span>:<span>need</span>}
                </div>
                <div className="user-role">
                    {currentLabel ? <span>{currentLabel}</span>:<span>login</span>}
                </div>
            </div>
            <div style={{color:"#8e8e8e"}}>
                <BsPeopleCircle fontSize={'40px'}/>
            </div>
        </div>
    );
};

export default User;
