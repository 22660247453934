import React, {useState} from 'react';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import PreviewItem from "./PreviewItem";
//Icons
import { RiFileUserLine } from "react-icons/ri";
import { RiFileTextLine } from "react-icons/ri";


const PreviewItemWrapper = ({btnName,header,id,dataItemFields,module,meta}) => {
    const [ previewItem, setPreviewItem ] = useState(false);

    const prevItemTooltip = (props) =>(
        <Tooltip id="button-tooltip"  {...props}>
            Quick Edit
        </Tooltip>
    );

    return (
        <div className={ module === 'JobOpenings' ? 'prevItem-actionBox-job':'prevItem-actionBox' }>
            <div style={{width:"80%",textAlign:"center"}}>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={prevItemTooltip}
                >
                    { btnName === 'RiFileUserLine' ? <RiFileUserLine  className="modal-link" fontSize={"21px"} onClick={()=>{setPreviewItem(!previewItem)}}/>:
                        btnName === 'RiFileTextLine' ? <RiFileTextLine  className="modal-link" fontSize={"21px"} onClick={()=>{setPreviewItem(!previewItem)}}/>
                            :<span className="modal-link" onClick={()=>{setPreviewItem(!previewItem)}}>{btnName}</span> }
                </OverlayTrigger>
            </div>
            <PreviewItem previewItem={previewItem} setPreviewItem={setPreviewItem} header={header} id={id} dataItemFields={dataItemFields} module={module} meta={meta}/>
        </div>
    );
};

export default PreviewItemWrapper;
