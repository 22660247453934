import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { logIn } from "../../redux/Actions/actionsCreator";
import { logOut } from "../../redux/Actions/actionsCreator";
import { BiWifi } from "react-icons/bi";
import { BiWifiOff } from "react-icons/bi";

function Login(props) {
    console.log('LOGIN CHECK',props.isLogin);

    let history = useHistory();
    const handleLogOut = () => {
        localStorage.removeItem('ZohoToken');
        localStorage.removeItem('ZohoRefToken');
        localStorage.removeItem('NRuserID');
        localStorage.removeItem('NRuserName');
        localStorage.removeItem('NRuserRole');
        props.logOut();
        history.push('/');
    };

    //<button className="login-out" onClick={handleLogOut}>Zoho Out</button> // save for test logout
    //<button className="login-in">Zoho In<BiWifiOff/></button> // save for test login

        return (
            <>
                <div >

                    {props.isLogin ?
                        <div onClick={handleLogOut}>
                            zoho
                            <div>
                                <BiWifi/>
                            </div>
                        </div>
                         :
                        //new token system with secret
                        <a href='https://accounts.zoho.com/oauth/v2/auth?scope=ZohoRecruit.modules.ALL,ZohoRecruit.settings.all,ZohoSearch.securesearch.READ&client_id=1000.UL0H86CHZWXRFECM2UW8K73BJGT2WN&response_type=code&access_type=offline&redirect_uri=https://naya-cv.link&prompt=consent'
                           style={{textDecoration:"none", color:"#0f4c75", margin:"16px"}}>
                            <div onClick={handleLogOut}>
                                zoho
                                <div>
                                    <BiWifiOff/>
                                </div>
                            </div>
                        </a>
                    }
                </div>
            </>
        );
}

const mapStateToProps = state => {
   return{
       isLogin: state.loginReducer.isLogin
   }
};

const mapDispatchToProps = {
    logIn,
    logOut
};


export default connect(mapStateToProps,mapDispatchToProps)(Login);
