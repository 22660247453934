import React from 'react';
import EditNote from "../EditNote/EditNote";
import DeleteNote from "../DeleteNote/DeleteNote";
import NoteTypeIcon from "../NoteTypeIcon/NoteTypeIcon";
import NoteDateParser from "../NoteDateParser/NoteDateParser";
import NoteUserParser from "../NoteUserParser/NoteUserParser";
import NoteContentEmail from "../NoteContentEmail/NoteContentEmail";

const NoteItem = ({ noteItem, candidateID, updateNotesFunc }) =>{

    return (
        <div className="notes-itemMain">
            <div className="notes-itemTop">
                <NoteTypeIcon noteType={noteItem.noteType}/>
                <div>{noteItem.noteTitle}</div>
                <div style={{display:"flex"}}>
                    <NoteUserParser noteUser={noteItem.createdByUser.userName}/>
                    <NoteDateParser noteDate={noteItem.modifiedTime}/>
                </div>
            </div>
            {noteItem.noteType === 'email' ?
                <NoteContentEmail text={noteItem.noteContent}/>
                :<div className="notes-content">{noteItem.noteContent}</div>
            }

            <div className="notes-itemBottom">
                <EditNote
                    candidateID={candidateID}
                    noteID={noteItem._id}
                    currentType={noteItem.noteType}
                    createdTime={noteItem.createdTime}
                    currentTitle={noteItem.noteTitle}
                    currentText={noteItem.noteContent}
                    updateNotesFunc={updateNotesFunc}/>

                <DeleteNote
                    candidateID={candidateID}
                    noteID={noteItem._id}
                    updateNotesFunc={updateNotesFunc}/>
            </div>

        </div>
    );
};

export default NoteItem;
