//function for refresh token (with out component)
import headerReqZohoAuth from "./headerReqZohoAuth";

const refreshTokenFunc = async (json,searchURL)=>{

    const getNewTokenFunc = async ()=>{
        console.log('REF TOKEN FUNC start:');
        const refToken = localStorage.getItem('ZohoRefToken');
        console.log('REF TOKEN:',refToken);

        const getFreshTokenUrl ='/api/zohoservice/refreshToken';
        const requestOptions = {
            method: 'POST',
            headers : {'Content-Type': 'application/json'},
            body : JSON.stringify({
                refresh_token: refToken
            }),
        };

        try{
            const res = await fetch(getFreshTokenUrl, {...requestOptions});
            const json = await res.json();
            console.log('RTF getNewTokenServer JSON',json);
            localStorage.setItem('ZohoToken',json.newAccToken.access_token);
        }catch (e) {
            console.log('fetch getTokenServer error:',e)
        }
    };

    if(json.response.error){
        if(json.response.error.code === "4834"){
            console.log('GET NEW TOKEN & UPDATE');
            await getNewTokenFunc();
            const headersZohoAuth = headerReqZohoAuth();
            const response = await fetch(searchURL,headersZohoAuth);
            const jsonNewData = await response.json();
            console.log('GET NEW TOKEN & UPDATE-jsonNewData:',jsonNewData);
            return jsonNewData;
        }else{
            console.log('JUST RETURN OLD JSON');
            return json;
        }

    }else{
        console.log('JUST RETURN OLD JSON');
        return json;
    }

};

export default refreshTokenFunc;
