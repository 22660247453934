import React from 'react';
import PreviewItemWrapper from "../../PreviewItem/PreviewItemWrapper";
import PreviewCvWrapper from "../../PreviewCV/PreviewCvWrapper";

const TagTableQuick = ({candidateFullName,candidateID,dataItemFields,metaData}) => {
    const module ='Candidates';

    return (
        <div style={{display:"flex",flexDirection:"row", width:"29%"}}>
            <PreviewCvWrapper header={candidateFullName} id={`${candidateID}`} btnName={'RiFileUserLine'} dataItemFields={dataItemFields}  module={module} meta={metaData} />
            <PreviewItemWrapper header={candidateFullName} id={`${candidateID}`} btnName={'RiFileUserLine'} dataItemFields={dataItemFields}  module={module} meta={metaData}/>
        </div>
    );
};

export default TagTableQuick;
