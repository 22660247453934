import React,{useState,useEffect} from 'react';
import { Form } from 'react-bootstrap';

const SearchDetailsParser = ({searchCriteria,searchAddCriterias,searchFilterCriterias}) => {

    const [ keyWord,setKeyWord ] = useState();
    const [ resume,setResume ] = useState();
    const [ fName,setFName ] = useState();
    const [ lName, setLName ] = useState();

    useEffect(()=>{
        setKeyWord(searchCriteria.keyWorld);
        setResume (searchCriteria.resume);
        setFName(searchCriteria.firstName);
        setLName(searchCriteria.lastName);
    },[]);

    return (
        <div className="searchDetails-wrapper">
            <div >
                {keyWord  &&
                <div style={{display:"flex"}}>
                    <span className="searchDetails-keyWordTitle">Key Word:<span className="searchDetails-keyWord">"{keyWord}"</span></span>

                    <div >
                        <Form.Check
                            disabled ={ true }
                            checked={ resume }
                            inline
                            label="Resume"
                            type={'checkbox'}
                            id={'ResumeCheck'}/>

                        <Form.Check
                            disabled ={ true }
                            checked={ fName }
                            inline
                            label="Job Title"
                            type={'checkbox'}
                            id={'firstNameCheck'}/>

                        <Form.Check
                            disabled ={ true }
                            checked={ lName }
                            inline
                            label="Skill Set"
                            type={'checkbox'}
                            id={'lastNameCheck'}/>
                    </div>
                </div>}
            </div>

            <div className="searchDetails-criteriaWrapper">
                {searchAddCriterias.length > 0 && searchAddCriterias.map((item,index) =>(
                    <div className="searchDetails-criteria" key={index}>
                        <span>{item.criteriaName.value}</span>
                        <span style={{marginRight:"4px"}}>{item.criteriaCondition.value}</span>
                        <span>{item.criteriaValue.value}</span>
                    </div>
                ))}
                {searchFilterCriterias.length > 0 && searchFilterCriterias.map((item,index) =>(

                    <div className="searchDetails-criteria" key={index}>
                        <span>{item.criteriaName.value}</span>
                        <span style={{marginRight:"4px",marginLeft:"4px"}}>{item.criteriaCondition.value}</span>
                        <span>{item.criteriaValue.map( (val,index) => {
                            // console.log('LENGTH:',item.criteriaValue.length);
                            // console.log('INDEX:',index);
                            if(index !== item.criteriaValue.length-1){
                                return `${val.value},`;
                            }else {
                                return `${val.value}`;
                            }
                        })}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SearchDetailsParser;
