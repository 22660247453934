import React,{useState} from 'react';
import { Modal } from "react-bootstrap";

const DeleteNote = ({ candidateID, noteID, updateNotesFunc}) =>{
    const [ show, setShow ] = useState(false);

    const deleteNote = async (candidateID,noteID)=>{
        const deleteNoteURL = `/api/note/deleteNoteById?candidateId=${candidateID}&noteId=${noteID}`;

        try{
            const res = await fetch( deleteNoteURL, {method:"DELETE"});
            const json = await res.json();
            console.log('deleteNote JSON:',json);
            if(json.message === 'note deleted from array'){
                await updateNotesFunc();
                await handleClose();
            }
        }catch (e) {
            console.log('deleteNote ERROR:',e);
        }

    };

    //FOR MODAL WINDOW
    //for open & close modal window
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button className="redWhite-Btn" onClick={handleShow}>delete</button>

            <Modal show={show} onHide={handleClose} dialogClassName="emailModal-dialog">
                <Modal.Title style={{display:"flex",justifyContent:"flex-start"}} >
                    <h3 style={{color:"#F57272",paddingTop:"10px",paddingLeft:"15px"}}>Delete Note</h3>
                </Modal.Title>
                <Modal.Body>
                    <div>Are you sure that you want to delete this note?</div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sendMail-btn">
                        <button className="blueFill-btn" onClick={()=>{deleteNote(candidateID,noteID)}}>
                            OK
                        </button>
                        <button className="greyFill-btn" style={{marginLeft:"5px"}} onClick={handleClose} >
                            Cancel
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteNote;
