import React from 'react';
import FieldBuilder from "./FieldBuilder";

const MultiRecursive = ({config,itemDataRec,itemId,module,meta}) => {

    let prepareRecId;
    if(module === 'Candidates'){
        prepareRecId = itemDataRec.response.result.Candidates.row.FL;
    }else if(module === 'JobOpenings'){
        prepareRecId = itemDataRec.response.result.JobOpenings.row.FL;
    }

    return (
        <>
            {config.map((c) => {return <FieldBuilder  IndividualConfig={c} prepareRecId={prepareRecId} itemId={itemId} module={module} meta={meta}/>} )}
        </>
    );
};

export default MultiRecursive;
