import React from "react";

const ShouldLogin = () => {
    return(
        <div style={{color:"grey",fontSize:"20px",textAlign:"center"}}>
            <p>Before start to use this application, you should connect to Zoho Recuit!</p>
            <p>click on "Zoho" button in right top conner</p>
        </div>
    );
};

export default ShouldLogin;
