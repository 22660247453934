import React from 'react';

const FilterDisableWrapper = ({item, filtersArray, board, dragStartHandlerCol, dragLeaveHandlerCol, dragEndHandlerCol,dragOverHandlerCol,onDropHandlerCol}) =>{
    console.log('FILTER DES item:',item);
    console.log('FILTER DES filtersArray:',filtersArray);
    const idx = filtersArray.findIndex(filterItem => filterItem.criteriaName.value === item.title);
    // console.log('1-FILTER DES idx:',idx);
    // console.log('2-FILTER DES  item.title:',item.title);
    if(idx >= 0){
        // console.log('3-FILTER DES filter item:',filtersArray[idx].criteriaName.value);
        return (<div className="dnd-item-disable"
                     key={item.id}
                     // draggable={true}
                     // onDragStart={(e)=>{dragStartHandlerCol(e,board,item)}}
                     onDragLeave={(e)=>{dragLeaveHandlerCol(e)}}
                     onDragEnd={(e)=>{dragEndHandlerCol(e)}}
                     onDragOver={(e)=>{dragOverHandlerCol(e)}}
                     onDrop={(e)=>{onDropHandlerCol(e,board,item)}}>
                    {item.title}
                <span style={{marginLeft:"4px"}}>(filter)</span>
        </div>);
    }else{
        return (
            <div className="dnd-item"
                 key={item.id}
                 draggable={true}
                 onDragStart={(e)=>{dragStartHandlerCol(e,board,item)}}
                 onDragLeave={(e)=>{dragLeaveHandlerCol(e)}}
                 onDragEnd={(e)=>{dragEndHandlerCol(e)}}
                 onDragOver={(e)=>{dragOverHandlerCol(e)}}
                 onDrop={(e)=>{onDropHandlerCol(e,board,item)}}
                >{item.title}</div>
            );
        }
};

export default FilterDisableWrapper;
