import React,{ useState, useEffect } from 'react';
import CreateJobNotes from "../CreateJobNotes/CreateJobNotes";
import AddJobNote from "../AddJobNote/AddJobNote";
// import NoteItem from "../NoteItem/NoteItem";
import JobNoteItem from "../JobNoteItem/JobNoteItem";
import '../../Notes/notes.css';

const DisplayJobNotes = ({jobID, jobTitle})=>{
    console.log('DisplayJobNotes-jobID:',jobID);
    const [ jobNotes, setJobNotes ] = useState(null);

    const getJobNotes = async ()=>{
        console.log('DisplayJobNotes-getJobNotes:',jobID);
        const getJobNotesURL = `/api/jobNote/getByIdMongo?id=${jobID}`;

        try{
            console.log('DisplayJobNotes-getJobNotesURL:',getJobNotesURL);
            const res = await fetch(getJobNotesURL);
            const json = await res.json();
            console.log('getJobNotes JSON:',json);
            if(json.jobNotes){
                const rawArr = [...json.jobNotes.notes];
                const reversArr = rawArr.reverse();
                console.log('job notes revers:', reversArr);
                setJobNotes(reversArr);
            }else {
                setJobNotes(null);
            }
        }catch (e) {
            console.log('updateNotes ERROR:',e);
        }
    };

    useEffect(async ()=>{
        await getJobNotes();
    },[]);

    return (
        <div className="notes-displayMain">
            {jobNotes ?
                <>
                    <AddJobNote jobID={jobID} updateNotesFunc={getJobNotes} />
                    <div>{jobNotes.map(noteItem => <JobNoteItem key={noteItem._id} noteItem={noteItem} jobID={jobID}  updateNotesFunc={getJobNotes}/>)}</div>
                </>:
                <CreateJobNotes jobID={jobID} updateNotesFunc={getJobNotes} />}
            {/*<button onClick={()=>{getJobNotes()}}>get notes</button>*/}
        </div>
    );
};

export default DisplayJobNotes;
