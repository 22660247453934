import React from 'react';
import { Link } from "react-router-dom";
import TagTableQuick from "./TagTableQuick";

const TagTableName = ({ candidateName, candidateID, dataItemFields, metaData }) => {
    return (
        <div className="tagTable-topName listItem">
            <Link
                to={{pathname:'/candidate',search:`id=${candidateID}&title=${candidateName}`}}
                target="_blank"
                rel="noopener noreferrer"
                style={{fontWeight:"bold",fontSize:"14px"}}>
                {candidateName}
            </Link>
            <TagTableQuick candidateFullName={candidateName} candidateID={candidateID} dataItemFields={dataItemFields} metaData={metaData}/>
        </div>
    );
};

export default TagTableName;
